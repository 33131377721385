import React, {useState, useEffect} from 'react';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactPaginate from 'react-paginate';
import { baseUrl } from '../../shared/constants';
 
const ProfilePaymentHistory = props => {
    const [topupLoading, setTopupLoading] = useState(false);
    const [incomeLoading, setIncomeLoading] = useState(false);
    const [triggerRefreshTopup, setTriggerRefreshTopup] = useState(0);
    const [triggerRefreshEarnings, setTriggerRefreshEarnings] = useState(0);
    const [topupData, setTopupData] = useState(null);
    const [incomeData, setIncomeData] = useState(null);
    const userType = localStorage.getItem('userType');
    const [offsetTopup, setOffsetTopup] = useState(0);
    const [perPageTopup] = useState(5);
    const [pageCountTopup, setPageCountTopup] = useState(0)
    const [offsetIncome, setOffsetIncome] = useState(0);
    const [perPageIncome] = useState(5);
    const [pageCountIncome, setPageCountIncome] = useState(0);

    useEffect(() => {
        getPaymentHistory('topup');
    }, [triggerRefreshTopup, offsetTopup]);

    useEffect(() => {
        getPaymentHistory('income');
    }, [triggerRefreshEarnings, offsetIncome]);

    function loadingSwitcher(type, flag) {
        switch(type) {
            case 'income':
                setIncomeLoading(flag);
                break;
            case 'topup':
                setTopupLoading(flag);
                break;
            default:
                break;
        }
    }

    const handlePageClickTopup = (e) => {
        const selectedPage = e.selected;
        setOffsetTopup(selectedPage + 1)
    };

    const handlePageClickIncome = (e) => {
        const selectedPage = e.selected;
        setOffsetIncome(selectedPage + 1)
    };

    async function getPaymentHistory(type) {
        loadingSwitcher(type, true)
        const token = localStorage.getItem("idToken");
        await axios.post(`${baseUrl}/get_my_transactions`, {
            token,
            type
        })
            .then(res => {
                const data = res.data;
                // console.log(`${type} history data ${JSON.stringify(data)}`);
                switch(type) {
                    case 'income':
                    {   
                        if (typeof(data) !== 'string') {
                            data.reverse();
                            let slicedData = data.slice(offsetIncome, offsetIncome + perPageIncome);
                            setIncomeData(slicedData);
                            setPageCountIncome(Math.ceil(data.length / perPageIncome));
                        } else {
                            setIncomeData(data);
                        }
                        break;
                    }
                    case 'topup':
                    { 
                        if (typeof(data) !== 'string') {
                            data.reverse();
                            let slicedData = data.slice(offsetTopup, offsetTopup + perPageTopup);
                            setTopupData(slicedData);
                            setPageCountTopup(Math.ceil(data.length / perPageTopup));
                        } else {
                            setTopupData(data);
                        }
                        break;
                    }
                    default:
                        break;
                }
                loadingSwitcher(type, false);
            })
            .catch(err => {
                // console.log(`error response ${err.response}`);
                // console.log(err.response);
                // topupLoading = false;
                // error = err;
                loadingSwitcher(type, false);
                if (err.response!==undefined) {
                  // client received error response (5xx, 4xx)
                //   console.log(`error response ${err.response}`);
                    // console.log(err.response);
                    if (err.response.data.message != undefined) {
                        if (err.response.data.message.includes('Firebase ID token has expired')) {
                            // console.log('refresh');
                            refreshTokenRequest(type);
                        }
                    }
                } else if (err.request!==undefined) {
                  // client never received a response, or a request never left
                //   console.log(`error request ${err.request}`);
                } else {
                //   console.log(`get error ${err}`);
                }
            })
    }

    async function refreshTokenRequest(type) {
        loadingSwitcher(type, true);
        const refresh_token = localStorage.getItem("refreshToken");
        // console.log(`refresh token in purchase history ${refresh_token}`);
        await axios.post(`${baseUrl}/refresh_token`, {
            refresh_token
        })
            .then(res => {
                const data = res.data
                // console.log(`refresh data ${JSON.stringify(data)}`);
                localStorage.setItem("idToken", data.id_token);
                localStorage.setItem("refreshToken", data.refresh_token);
                loadingSwitcher(type, false);
                switch (type) {
                    case 'topup':
                        setTriggerRefreshTopup(old => old+1);
                        break;
                    case 'income':
                        setTriggerRefreshEarnings(old => old+1);
                    case 'refund':
                        break;
                    default:
                        break;
                }
            })
            .catch(err => {
                // console.log(`error response ${err.response}`);
                // console.log(err.response);
                // topupLoading = false;
                // error = err;
                loadingSwitcher(type, false);
                if (err.response!==undefined) {
                  // client received error response (5xx, 4xx)
                    localStorage.setItem("idToken", "");
                    localStorage.setItem("customToken", "");
                    localStorage.setItem("refreshToken", "");
                    localStorage.setItem("fullName", "");
                    localStorage.setItem("username", "");
                    localStorage.setItem("userType", "");
                    localStorage.setItem("uid", "");
                    localStorage.setItem("completedRegister", "");
                    props.history.push('/');
                    alert('Session expired. Please login again.');
                //   console.log(`error response ${err.response}`);
                //   console.log(err.response);
                } else if (err.request!==undefined) {
                  // client never received a response, or a request never left
                //   console.log(`error request ${err.request}`);
                } else {
                //   console.log(`get error ${err}`);
                }
            })
    }

    function renderIncomeData(data) {
        if (incomeLoading) {
            return <div className="col-md-12 profile-text-centre"><CircularProgress /></div>;
        }
        if (data != null && data != undefined && data.length != 0 && data != 'Transactions not found') {
            return (
                data.map((a, idx) => {
                    return (
                    <div className="row profile-multiple-text profile-text-centre" key={idx}>
                        <div className="col-4 profile-text-left">
                            {/* <span>{a.orderDate._seconds}</span> */}
                            <div className="col-md-12">
                                <span>{a.orderDate ? (new Date(a.orderDate._seconds * 1000)).toDateString() : ''}</span>    
                            </div>
                            <div className="col-md-12">
                                <span>{a.orderDate ? (new Date(a.orderDate._seconds * 1000)).toTimeString() : ''}</span>    
                            </div>
                        </div>
                        <div className="col-4 profile-text-centre" >
                            <span>{a.orderID}</span>
                            {/* <span>{new Date(a.orderID.seconds*1000)}</span> */}
                        </div>
                        <div className="col-4 profile-text-right" >
                            <span style={{paddingRight: '15px'}}>RM{a.fee}</span>
                        </div>
                        <div className="col-12 line-confirm" />
                    </div>);
                })
            );
        } else {
            return <div className="row profile-multiple-text profile-text-centre"><div className="col-md-12"><span>No data</span></div></div>
        }
    }

    function renderEarnings(data) {
        switch (userType) {
            case 'driver':
                return (
                    <div className="border-radius-15 shadow-header">
                        <div className="row">
                            <div className="col-md-12 profile-box-top">
                                <span>Earning History</span>
                            </div>
                            <div className="col-md-12 profile-box-bottom">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row profile-multiple-text profile-text-centre">
                                            <div className="col-4 profile-text-left">
                                                <span className="bold-text" style={{paddingLeft: '15px'}}>DATE</span>
                                            </div>
                                            <div className="col-4 profile-text-centre" >
                                                <span className="bold-text">PAYMENT ID</span>
                                            </div>
                                            <div className="col-4 profile-text-right" >
                                                <span className="bold-text" style={{paddingRight: '15px'}}>CASH OUT AMOUNT</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        {renderIncomeData(data)}
                                    </div>
                                    <div className="col-md-12">
                                        <ReactPaginate
                                            previousLabel={"prev"}
                                            nextLabel={"next"}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={pageCountIncome}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={handlePageClickIncome}
                                            containerClassName={"pagination"}
                                            subContainerClassName={"pages pagination"}
                                            activeClassName={"active"}
                                        />
                                    </div>
                                </div>
                                <hr />
                            </div>
                        </div>
                    </div>
                );
            default:
                break;
        }
    }

    function renderTopup(data) {
        if (topupLoading) {
            return <div className="col-md-12 profile-text-centre"><CircularProgress /></div>;
        }
        if (data != null && data != undefined && data.length != 0 && data != 'Transactions not found') {
            // console.log(`topup in render ${JSON.stringify(data)}`)
            return (
                data.map((a, idx) => {
                    return (
                    <div className="row profile-multiple-text profile-text-centre" key={idx}>
                        <div className="col-4 profile-text-left">
                            {/* <span>{a.orderDate._seconds}</span> */}
                            <div className="col-md-12">
                                <span>{a.orderDate ? (new Date(a.orderDate._seconds * 1000)).toDateString() : ''}</span>    
                            </div>
                            <div className="col-md-12">
                                <span>{a.orderDate ? (new Date(a.orderDate._seconds * 1000)).toTimeString() : ''}</span>    
                            </div>
                        </div>
                        <div className="col-4 profile-text-centre" >
                            <span>{a.orderID}</span>
                        </div>
                        <div className="col-4 profile-text-right" >
                            <span style={{paddingRight: '15px'}}>RM{a.fee}</span>
                        </div>
                        <div className="col-12 line-confirm" />
                    </div>);
                })
            );
        } else {
            return <div className="row profile-multiple-text profile-text-centre"><div className="col-md-12"><span>No data</span></div></div>
        }
    }

    return(
        <div className={`${props.showDiv==true?'show-stuff':'hide-stuff'}`}>
            <div className="row justify-content-center padding-top-bottom-50">
                <div className="col-md-11">
                    {renderEarnings(incomeData)}
                    <br />
                    <div className="border-radius-15 shadow-header">
                        <div className="row">
                            <div className="col-md-12 profile-box-top">
                                <span>Topup History</span>
                            </div>
                            <div className="col-md-12 profile-box-bottom">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row profile-multiple-text profile-text-centre">
                                            <div className="col-4 profile-text-left">
                                                <span className="bold-text" style={{paddingLeft: '15px'}}>DATE</span>
                                            </div>
                                            <div className="col-4 profile-text-centre" >
                                                <span className="bold-text">ORDER ID</span>
                                            </div>
                                            <div className="col-4 profile-text-right">
                                                <span className="bold-text" style={{paddingRight: '15px'}}>FEE</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        {renderTopup(topupData)}
                                    </div>
                                    <div className="col-12">
                                        <ReactPaginate
                                            previousLabel={"prev"}
                                            nextLabel={"next"}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={pageCountTopup}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={handlePageClickTopup}
                                            containerClassName={"pagination"}
                                            subContainerClassName={"pages pagination"}
                                            activeClassName={"active"}
                                        />
                                    </div>
                                </div>
                                <hr />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProfilePaymentHistory;