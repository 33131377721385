import React, {useEffect, useState} from "react";
import {useForm} from 'react-hook-form';
import axios from "axios";
import {withRouter} from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { baseUrl } from "../../shared/constants";
import {storage, auth} from '../../shared/firebaseConfig';
import Logo from '../../assets/img/images/logo.svg';

const VendorRegist2Body = props => {
  const { register, handleSubmit, watch, formState: { errors } } = useForm();

  const [statesLoading, setStatesLoading] = useState(false);
  const [statesData, setStatesData] = useState(null);
  const [businessLoading, setBusinessLoading] = useState(false);
  const [businessData, setBusinessData] = useState(null);

  const [allValidate, setAllValidate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [profileData, setProfileData] = useState(null);
  const [triggerRefresh, setTriggerRefresh] = useState(0);

  const [ssmPhoto, setSsmPhoto] = useState(null);
  const [ssmUrl, setSsmUrl] = useState('');
  const [ssmProgress, setSsmProgress] = useState(0);
  const [errorSsm, setErrorSsm] = useState('');
  const [uploadedSsmFlag, setUploadedSsmFlag] = useState(false);

  const [icPic, setIcPic] = useState(null);
  const [icUrl, setIcUrl] = useState('');
  const [icProgress, setIcProgress] = useState(0);
  const [errorIc, setErrorIc] = useState('');
  const [uploadedIcFlag, setUploadedIcFlag] = useState(false);

  const [profilePic, setProfilePic] = useState(null);
  const [profileUrl, setProfileUrl] = useState('');
  const [profileProgress, setProfileProgress] = useState(0);
  const [errorProfile, setErrorProfile] = useState('');
  const [uploadedProfileFlag, setUploadedProfileFlag] = useState(false);

  useEffect(() => {
    getBusiness();
    getStates();
  }, []);

  useEffect(() => {
    validationChecking();
  }, [ssmPhoto, icPic, profilePic]);

  useEffect(() => {
    handleUpdateSSM();
  }, [ssmPhoto]);

  useEffect(() => {
    handleUpdateIC();
  }, [icPic]);

  useEffect(() => {
    handleUpdateProfile();
  }, [profilePic]);

  async function getStates() {
    loadingSwitcher('states', true);
    const token = localStorage.getItem("idToken");
    // const token = 'eyJhbGciOiJSUzI1NiIsImtpZCI6ImFiMGNiMTk5Zjg3MGYyOGUyOTg5YWI0ODFjYzJlNDdlMGUyY2MxOWQiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vdGFwYXctc3RhZ2luZy0xNzI3OSIsImF1ZCI6InRhcGF3LXN0YWdpbmctMTcyNzkiLCJhdXRoX3RpbWUiOjE2MjMxNzE3MDksInVzZXJfaWQiOiJYRElvb2dLbTBzV0xXQVkxWjRtcGgwSkxJSTczIiwic3ViIjoiWERJb29nS20wc1dMV0FZMVo0bXBoMEpMSUk3MyIsImlhdCI6MTYyMzE3MTcwOSwiZXhwIjoxNjIzMTc1MzA5LCJlbWFpbCI6ImJyb211c3RhZmFAZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7ImVtYWlsIjpbImJyb211c3RhZmFAZ21haWwuY29tIl19LCJzaWduX2luX3Byb3ZpZGVyIjoicGFzc3dvcmQifX0.MufoU2KRPVU61wABvB0AvXzprDuadFIuXYnpSYjRu9kudEJThtPM--R9KWLJB-V2m7rzGsDeax2YW1cwutLz0I9VncNYywexoAOM-n1tLTIngO9fhrVaaZ5LtOMf_H1xUQHdiVVzkmXoOC_0SlwqmkTrxsaePxRnbYkpCqxD_IV1Y0B-NfqevWyXFY9xyCWC4kgZR1vZzMPQSKYa7bFlzJyEOHQyaSEGwOWqNupsL312Kzvc-KTt1_v-LNdo8Vi2kDJxEA39vPY8CCHgHYKKUJH6IbQhRwWXy5XO5SxFbSSZ0BidtZE7OMMFoBmVXvpyoLdXIKr1YqR6y3pUCHgTvg';
    await axios.post(`${baseUrl}/get_states`, {
        token
    })
    .then(res => {
        const data = res.data
        console.log(`get states data ${JSON.stringify(data)}`);
        setStatesData(data);
        loadingSwitcher('states', false);
    })
    .catch(err => {
        console.log(`error response get states ${JSON.stringify(err.response)}`);
        // console.log(err.response);
        // loading = false;
        // error = err;
        loadingSwitcher('states', false);
        if (err.response!==undefined) {
          // client received error response (5xx, 4xx)
        //   console.log(`error response ${err.response}`);
            // console.log(err.response);
            if (err.response.data.message != undefined) {
                if (err.response.data.message.includes('Firebase ID token has expired')) {
                    // console.log('refresh');
                    refreshTokenRequest('states');
                }
            }
        } else if (err.request!==undefined) {
          // client never received a response, or a request never left
        //   console.log(`error request ${err.request}`);
        } else {
        //   console.log(`get error ${err}`);
        }
    })
  }

  async function getBusiness() {
    loadingSwitcher('business', true);
    const token = localStorage.getItem("idToken");
    // const token = 'eyJhbGciOiJSUzI1NiIsImtpZCI6ImFiMGNiMTk5Zjg3MGYyOGUyOTg5YWI0ODFjYzJlNDdlMGUyY2MxOWQiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vdGFwYXctc3RhZ2luZy0xNzI3OSIsImF1ZCI6InRhcGF3LXN0YWdpbmctMTcyNzkiLCJhdXRoX3RpbWUiOjE2MjMxNzE3MDksInVzZXJfaWQiOiJYRElvb2dLbTBzV0xXQVkxWjRtcGgwSkxJSTczIiwic3ViIjoiWERJb29nS20wc1dMV0FZMVo0bXBoMEpMSUk3MyIsImlhdCI6MTYyMzE3MTcwOSwiZXhwIjoxNjIzMTc1MzA5LCJlbWFpbCI6ImJyb211c3RhZmFAZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7ImVtYWlsIjpbImJyb211c3RhZmFAZ21haWwuY29tIl19LCJzaWduX2luX3Byb3ZpZGVyIjoicGFzc3dvcmQifX0.MufoU2KRPVU61wABvB0AvXzprDuadFIuXYnpSYjRu9kudEJThtPM--R9KWLJB-V2m7rzGsDeax2YW1cwutLz0I9VncNYywexoAOM-n1tLTIngO9fhrVaaZ5LtOMf_H1xUQHdiVVzkmXoOC_0SlwqmkTrxsaePxRnbYkpCqxD_IV1Y0B-NfqevWyXFY9xyCWC4kgZR1vZzMPQSKYa7bFlzJyEOHQyaSEGwOWqNupsL312Kzvc-KTt1_v-LNdo8Vi2kDJxEA39vPY8CCHgHYKKUJH6IbQhRwWXy5XO5SxFbSSZ0BidtZE7OMMFoBmVXvpyoLdXIKr1YqR6y3pUCHgTvg';
    await axios.post(`${baseUrl}/get_vendortype`, {
        token
    })
    .then(res => {
        const data = res.data
        // console.log(`profile data ${JSON.stringify(data)}`);
        setBusinessData(data);
        loadingSwitcher('business', false);
    })
    .catch(err => {
        // console.log(`error response ${err.response}`);
        // console.log(err.response);
        // loading = false;
        // error = err;
        loadingSwitcher('business', false);
        if (err.response!==undefined) {
          // client received error response (5xx, 4xx)
        //   console.log(`error response ${err.response}`);
            // console.log(err.response);
            if (err.response.data.message != undefined) {
                if (err.response.data.message.includes('Firebase ID token has expired')) {
                    // console.log('refresh');
                    refreshTokenRequest('business');
                }
            }
        } else if (err.request!==undefined) {
          // client never received a response, or a request never left
        //   console.log(`error request ${err.request}`);
        } else {
        //   console.log(`get error ${err}`);
        }
    })
  }

  const onSubmit = data => {
    console.log(data);
    setupUpdate(data);
  }

  function setupUpdate(dataPassed) {
    const token = localStorage.getItem("idToken");
    // check if in vendorEdit mode or not, if not in vendorEdit mode, needs to all data passed are required
    // if in vendorEdit mode, data passed are optional
    const {companyName, vendortype, registrationNo, nricNumber, cityResidence, states, emergencyNumber} = dataPassed;
    if (props.vendorEdit===undefined || props.vendorEdit===false) { 
      const data = {
        token,
        companyName,
        vendortype,
        registrationNo,
        nricNumber,
        cityResidence,
        states,
        emergencyNumber,
        completedRegister: true,
      }

      updateRequest(data);
    } else {
      let data = {};
      data.token = token;
      if (companyName!==undefined) data.companyName = companyName;
      if (vendortype!==undefined) data.vendortype = vendortype;
      if (registrationNo!==undefined) data.registrationNo = registrationNo;
      if (nricNumber!==undefined) data.nricNumber = nricNumber;
      if (cityResidence!==undefined) data.cityResidence = cityResidence;
      if (states!==undefined) data.states = states;
      if (emergencyNumber!==undefined) data.emergencyNumber = emergencyNumber;
      // user that completed 2nd part registration at early account creation already has completedRegister: true anyways
      data.completedRegister = true;
      
      updateRequest(data);
    }
    // updateRequest(company, businessType, registNo, ic, city, state, emergency);
  }

  async function updateRequest(data) {
    // console.log(`companyName ${companyName}, vendortype ${vendortype}, regist no ${registrationNo}, ic ${nricNumber}, city ${cityResidence}, state ${states}, emergency ${emergencyNumber}`);
    setLoading(true);
    await axios.post(`${baseUrl}/update_profile`, data)
    .then(res => {
        const data = res.data
        // console.log(`profile data ${JSON.stringify(data)}`);
        setProfileData(data);
        setLoading(false);
        props.history.push("/profile");
    })
    .catch(err => {
        // console.log(`error response ${err.response}`);
        // console.log(err.response);
        // loading = false;
        // error = err;
        setLoading(false);
        if (err.response!==undefined) {
          // client received error response (5xx, 4xx)
        //   console.log(`error response ${err.response}`);
            // console.log(err.response);
            if (err.response.data.message != undefined) {
                if (err.response.data.message.includes('Firebase ID token has expired')) {
                    // console.log('refresh');
                    refreshTokenRequest();
                }
            }
        } else if (err.request!==undefined) {
          // client never received a response, or a request never left
        //   console.log(`error request ${err.request}`);
        } else {
        //   console.log(`get error ${err}`);
        }
    })
  }

  async function refreshTokenRequest(type) {
    loadingSwitcher(type, true);
    const refresh_token = localStorage.getItem("refreshToken");
    // console.log(`refresh token in profile ${refresh_token}`);
    await axios.post(`${baseUrl}/refresh_token`, {
        refresh_token
    })
        .then(res => {
            const data = res.data
            // console.log(`refresh data ${JSON.stringify(data)}`);
            localStorage.setItem("idToken", data.id_token);
            localStorage.setItem("refreshToken", data.refresh_token);
            loadingSwitcher(type, false);
            setTriggerRefresh(old => old+1);
        })
        .catch(err => {
            // console.log(`error response ${err.response}`);
            // console.log(err.response);
            // loading = false;
            // error = err;
            loadingSwitcher(type, false);
            if (err.response!==undefined) {
              // client received error response (5xx, 4xx)
                localStorage.setItem("idToken", "");
                localStorage.setItem("customToken", "");
                localStorage.setItem("refreshToken", "");
                localStorage.setItem("fullName", "");
                localStorage.setItem("username", "");
                localStorage.setItem("userType", "");
                localStorage.setItem("uid", "");
                localStorage.setItem("completedRegister", "");
                props.history.push('/');
                alert('Session expired. Please login again.');
            //   console.log(`error response ${err.response}`);
            //   console.log(err.response);
            } else if (err.request!==undefined) {
              // client never received a response, or a request never left
            //   console.log(`error request ${err.request}`);
            } else {
            //   console.log(`get error ${err}`);
            }
        })
  }

  function loadingSwitcher(type, flag) {
    switch(type) {
      case 'states':
        setStatesLoading(flag);
        break;
      case 'business':
        setBusinessLoading(flag);
        break;
      default:
        break;
    }
  }

  // ---------
  // profile picture stuff
  const handleChangeProfile = e => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file["type"];
      const validImageTypes = ["image/gif", "image/jpeg", "image/jpg", "image/png"];
      if (validImageTypes.includes(fileType)) {
        setErrorProfile("");
        setProfilePic(file);
      } else {
        setErrorProfile("Please select an image to upload");
      }
    }
  };

  const handleUpdateProfile = () => {
    if (profilePic) {
      const uid = localStorage.getItem("uid");
      const customToken = localStorage.getItem("customToken");
      // console.log(`token at auth ${customToken}`)
      auth.signInWithCustomToken(customToken)
      .then(function(userCred) {
        const user = userCred.user;
        // console.log(`user login in regist driver ${JSON.stringify(userCred)}`)
        //
        const uploadTask = storage.ref(`${uid}/profilePhoto`).put(profilePic);

        uploadTask.on(
          "state_changed",
          snapshot => {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            console.log(`progress upload ${progress}`);
            setProfileProgress(progress);
          },
          error => {
            alert(error);
            setErrorProfile(error);
            setProfileProgress(0);
          },
          () => {
            setUploadedProfileFlag(true);
            setProfileProgress(0);
            // storage
            //   .ref("images")
            //   .child(image.name)
            //   .getDownloadURL()
            //   .then(url => {
            //     setUrl(url);
            //     setProgress(0);
            //   });
          }
        );
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        alert('Please relogin');;
        //
      })
    } else {
      setErrorProfile("Error please choose an image to upload");
      setProfileProgress(0);
    }
  };

  // -----

  // ---------
  // ssm picture stuff
  const handleChangeSSM = e => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file["type"];
      const validImageTypes = ["image/gif", "image/jpeg", "image/jpg", "image/png"];
      if (validImageTypes.includes(fileType)) {
        setErrorSsm("");
        setSsmPhoto(file);
      } else {
        setErrorSsm("Please select an image to upload");
      }
    }
  };

  const handleUpdateSSM = () => {
    if (ssmPhoto) {
      const uid = localStorage.getItem("uid");
      const customToken = localStorage.getItem("customToken");
      // console.log(`token at auth ${customToken}`)
      auth.signInWithCustomToken(customToken)
      .then(function(userCred) {
        const user = userCred.user;
        // console.log(`user login in regist driver ${JSON.stringify(userCred)}`)
        //
        const uploadTask = storage.ref(`${uid}/registrationPhoto`).put(ssmPhoto);

        uploadTask.on(
          "state_changed",
          snapshot => {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            console.log(`progress upload ${progress}`);
            setSsmProgress(progress);
          },
          error => {
            alert(error);
            setErrorSsm(error);
            setSsmProgress(0);
          },
          () => {
            setUploadedSsmFlag(true);
            setSsmProgress(0);
            // storage
            //   .ref("images")
            //   .child(image.name)
            //   .getDownloadURL()
            //   .then(url => {
            //     setUrl(url);
            //     setProgress(0);
            //   });
          }
        );
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        alert('Please relogin');;
        //
      })
    } else {
      setErrorSsm("Error please choose an image to upload");
      setSsmProgress(0);
    }
  };

  // ------

  // ---------
  // ic picture stuff
  const handleChangeIC = e => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file["type"];
      const validImageTypes = ["image/gif", "image/jpeg", "image/jpg", "image/png"];
      if (validImageTypes.includes(fileType)) {
        setErrorIc("");
        setIcPic(file);
      } else {
        setErrorIc("Please select an image to upload");
      }
    }
  };

  const handleUpdateIC = () => {
    if (icPic) {
      const uid = localStorage.getItem("uid");
      const customToken = localStorage.getItem("customToken");
      // console.log(`token at auth ${customToken}`)
      auth.signInWithCustomToken(customToken)
      .then(function(userCred) {
        const user = userCred.user;
        // console.log(`user login in regist driver ${JSON.stringify(userCred)}`)
        //
        const uploadTask = storage.ref(`${uid}/nricPhoto`).put(icPic);

        uploadTask.on(
          "state_changed",
          snapshot => {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            console.log(`progress upload ${progress}`);
            setIcProgress(progress);
          },
          error => {
            alert(error);
            setErrorIc(error);
            setIcProgress(0);
          },
          () => {
            setUploadedIcFlag(true);
            setIcProgress(0);
            // storage
            //   .ref("images")
            //   .child(image.name)
            //   .getDownloadURL()
            //   .then(url => {
            //     setUrl(url);
            //     setProgress(0);
            //   });
          }
        );
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // alert('Please relogin');;
        alert('Please relogin');
        //
      })
    } else {
      setErrorIc("Error please choose an image to upload");
      setIcProgress(0);
    }
  };

  function validationChecking () {
    //company, businessType, registNo, ssmPhoto, ic, icPic, city, state, profilePic, emergency
    // && (ssmPhoto != '')
    // && (icPic != '') 
    // && (profilePic != '')

    // check if not in edit mode
    if (props.vendorEdit===undefined || props.vendorEdit===false) { 
      if ((uploadedSsmFlag==true) && (uploadedIcFlag==true) && (uploadedProfileFlag==true) ) {
        setAllValidate(true);
        // console.log(`allValidate ${allValidate}`);
      } else {
        setAllValidate(false);
        // console.log(`allValidate ${allValidate}`);
      }
    } else {
      setAllValidate(true);
    }
  }

  function renderStates(data) {
    if (statesLoading) {
      return <div className="col-lg-12 profile-text-centre"><CircularProgress /></div>;
    }
    if (data != null && data != undefined && data.length != 0) {
      let tmp = [];
      data.forEach((a,idx) => {
        tmp.push({id: idx, label: a.state, value: a.state});
      });
      return (
        <select
          {...register("states", { required: true })}
          id="states"
          name="states"
          data-name="states"
          className="select-field w-select"
        >{tmp.map(a => (
          <option key={a.id} value={a.value}>{a.label}</option>
        ))}
      </select>
      );
    }
  }

  function renderBusinessType(data) {
    if (businessLoading) {
      return <div className="col-lg-12 profile-text-centre"><CircularProgress /></div>;
    }
    if (data != null && data != undefined && data.length != 0) {
      let tmp = [];
      data.forEach((a,idx) => {
        tmp.push({id: idx, label: a.type, value: a.type});
      });
      return (
        <select
          {...register("vendortype", { required: true })}
          id="vendortype"
          name="vendortype"
          data-name="vendortype"
          className="select-field w-select"
        >{tmp.map(a => (
          <option key={a.id} value={a.value}>{a.label}</option>
        ))}
      </select>
      );
    }
  }

  return (
    <div>
      <div className="wf-section">
        <div className="div-block-8 vendor">
          <div className="container w-container">
            <div className="columns-2 w-row">
              <div className="column-3 w-col w-col-6">
                <div className="div-block-18">
                  <div className="div-block-19 driver">
                    <h1 className="heading-9">Earn Extra Income with Us</h1>
                    <p className="paragraph-title">
                      Seek an opportunity to earn extras, but no where to start?
                      Take your journey with us by turning your smartphone and
                      any of your vehicles into a money making machine.
                    </p>
                  </div>
                </div>
              </div>
              <div className="column-4 w-col w-col-6">
                <div className="div-block-9">
                  <div className="form-block w-form">
                    <div className="col-md-12" style={{marginLeft: '65px'}}>
                      <img src={Logo} className="logo-regist" />
                    </div>
                    <div className="col-md-12" style={{marginLeft: '65px'}}>
                      <span className="regist-title">Vendor Registration</span>
                    </div>
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      className="form-2 details"
                    >
                      <div className="div-block-23">
                        <h5 className="heading-11">You're Almost There!</h5>
                        <p className="paragraph-5">
                          A few more steps before you can start earning
                        </p>
                      </div>
                      <div className="text-block-6">Personal Vendor Form</div>
                      <div className="columns-3 w-row">
                        <div className="w-col w-col-6">
                          <label
                            htmlFor="name"
                            className="field-label secondary"
                          >
                            Company Name
                          </label>
                          <input
                            {...register("companyName", { required: true })}
                            type="text"
                            className="form w-input"
                            maxLength={256}
                            name="companyName"
                            data-name="companyName"
                            placeholder="Company name"
                            id="companyName"
                          />
                          {errors.companyName && <span>This field is required</span>}
                          <label
                            htmlFor="Vehicle-Plate"
                            className="field-label secondary"
                          >
                            Business Registration No.
                          </label>
                          <input
                            {...register("registrationNo", { required: true })}
                            type="text"
                            className="form w-input"
                            maxLength={256}
                            name="registrationNo"
                            data-name="registrationNo"
                            placeholder="Business Registration No."
                            id="registrationNo"
                          />
                          {errors.registrationNo && <span>This field is required</span>}
                          <label
                            htmlFor="IC-Number"
                            className="field-label secondary"
                          >
                            IC Number
                          </label>
                          <input
                            {...register("nricNumber", { required: true })}
                            type="text"
                            className="form w-input"
                            maxLength={256}
                            name="nricNumber"
                            data-name="nricNumber"
                            placeholder="Insert IC Number Here"
                            id="nricNumber"
                          />
                          {errors.nricNumber && <span>This field is required</span>}
                          <label
                            htmlFor="IC-Number-2"
                            className="field-label secondary"
                          >
                            City of Residence
                          </label>
                          <input
                            {...register("cityResidence", { required: true })}
                            type="text"
                            className="form w-input"
                            maxLength={256}
                            name="cityResidence"
                            data-name="cityResidence"
                            placeholder="Kuala Lumpur, Seremban.."
                            id="cityResidence"
                          />
                          {errors.cityResidence && <span>This field is required</span>}
                          <label
                            htmlFor="IC-Number-3"
                            className="field-label secondary"
                          >
                            Profile Photo
                          </label>
                          <input className="dark-blue-text form-control" style={{paddingTop: '10px', paddingBottom: '10px', width: '90%'}} type="file" name="profile_photo" onChange={handleChangeProfile} required />
                          <div style={{height: '15px'}}>
                            {profileProgress>0?<progress value={profileProgress} max='100' />: ''}
                            {uploadedProfileFlag?<div><CheckCircleIcon style={{color: 'green'}} /><span>Status: Profile photo uploaded</span></div>: ''}
                          </div>
                        </div>
                        <div className="w-col w-col-6">
                          <label
                            htmlFor="name-6"
                            className="field-label secondary"
                          >
                            Business Type
                          </label>
                          {renderBusinessType(businessData)}
                          <label
                            htmlFor="Vehicle-Plate-3"
                            className="field-label secondary"
                            style={{marginTop: '20px'}}
                          >
                            Picture of SSM Certificate
                          </label>
                          <input className="dark-blue-text form-control" style={{paddingTop: '13px', paddingBottom: '13px'}} type="file" name="ssm" onChange={handleChangeSSM} />
                          <div style={{height: '30px'}}>
                            {ssmProgress>0?<progress value={ssmProgress} max='100' />: ''}
                            {uploadedSsmFlag?<div><CheckCircleIcon style={{color: 'green'}} /><span>Status: Done uploading</span></div>: ''}
                          </div>
                          <label
                            htmlFor="IC-Number-3"
                            className="field-label secondary"
                            style={{marginTop: '-24px'}}
                          >
                            IC Picture
                          </label>
                          <input className="dark-blue-text form-control" style={{paddingTop: '13px', paddingBottom: '13px'}} type="file" name="ic_pic" onChange={handleChangeIC} />
                          <div style={{height: '30px'}}>
                            {icProgress>0?<progress value={icProgress} max='100' />: ''}
                            {uploadedIcFlag?<div><CheckCircleIcon style={{color: 'green'}} /><span>Status: Done uploading</span></div>: ''}
                          </div>
                          <label
                            htmlFor="IC-Number-3"
                            className="field-label secondary"
                            style={{marginTop: '-20px'}}
                          >
                            State
                          </label>
                          {renderStates(statesData)}
                          <label
                            htmlFor="IC-Number-3"
                            className="field-label secondary"
                            style={{marginTop: '20px'}}
                          >
                            Emergency Contact
                          </label>
                          <input
                            {...register("emergencyNumber", { required: true })}
                            type="text"
                            className="form w-input"
                            maxLength={256}
                            name="emergencyNumber"
                            data-name="emergencyNumber"
                            placeholder="Emergency contact"
                            id="emergencyNumber"
                          />
                        </div>
                      </div>
                      <div className="div-block-10" />
                      {
                      loading? <div className="col-lg-12 profile-text-centre"><CircularProgress /></div>:
                      <button type="submit" className="submit-register">SUBMIT</button>
                      // <input
                      //   type="submit"
                      //   defaultValue="Submit"
                      //   data-wait="Please wait..."
                      //   className='button w-button'
                      //   // className="button registration w-button"
                      // />
                      }
                    </form>
                    <div className="success w-form-done">
                      <img
                        src="images/success_button.gif"
                        loading="lazy"
                        width={219}
                        alt
                        className="image-7"
                      />
                      <div className="text-block-2">
                        Thank you! Your submission has been received!
                      </div>
                    </div>
                    <div className="error-message error-message-2 w-form-fail">
                      <div>
                        Oops! Something went wrong while submitting the form.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        src="images/transition-products-transparent.png"
        loading="lazy"
        sizes="(max-width: 1919px) 100vw, 1920px"
        srcSet="images/transition-products-transparent-p-500.png 500w, images/transition-products-transparent-p-800.png 800w, images/transition-products-transparent-p-1080.png 1080w, images/transition-products-transparent-p-1600.png 1600w, images/transition-products-transparent.png 1920w"
        alt
        className="image-5"
      />
    </div>
  );
}

export default withRouter(VendorRegist2Body);
