import React, { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CustomGoogleMap from './customGoogleMap';

const AddAddress = props => {
    const [latLng, setLatLng] = useState(null);


    useEffect(() => {
        getLocation();
    }, []);

    const confirmFunction = (data) => {
        // console.log(`data added ${JSON.stringify(data)}`);
        props.addFunc(data);
        props.expandSpace(false);
    }

    function cancelFunction() {
        props.expandSpace(false);
    }

    function getLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition);
        } else {
            // alert('Geolocation is not supported by this browser');
            alert('Please enable location for this browser! Refresh after you have enable it');
        }
    }

    function showPosition(position) {
        // console.log(postion)
        // console.log(`lat ${postion.coords.latitude}`);
        // console.log(`lng ${postion.coords.longitude}`);
        // setLat(postion.coords.latitude);
        // setLng(postion.coords.longitude);
        setLatLng({lat: position.coords.latitude, lng: position.coords.longitude});
    }

    return (
        <div className="justify-content-center">
            <div className="col-md-12">
                    <div className="row" style={{ justifyContent: 'center' }}>
                        <div className="col-lg-12" style={{height: '80vh'}}>
                            <CustomGoogleMap
                                confirmfunc={confirmFunction}
                                confirmtext={props.confirmtext?props.confirmtext:"SAVE ADDRESS"}
                                cancelfunc={cancelFunction}
                            />
                        </div>
                            
                        {/* <div className="col-lg-12">
                            <div className="form-group">
                                <label>Pick up point</label>
                                <input type="text" name="point" placeholder="" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label>Block/Level/Unit</label>
                                <input type="text" name="block" placeholder="" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label>Building/Complex Name</label>
                                <input type="text" name="building" placeholder="" />
                            </div>
                        </div> */}
                    </div>
            </div>
        </div>
    );
}

export default AddAddress;