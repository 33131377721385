import { createMuiTheme } from '@material-ui/core';


export const customTheme = createMuiTheme({
	palette: {
		primary: {
			main: '#ffce28',
		},
		secondary: {
			main: '#ffce28',
		},
	},
})
