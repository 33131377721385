import React from "react";
import logo from '../../assets/img/tapaw/logo.png';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { customLanguage } from "../../shared/languages";
class Faq extends React.Component {
  render() {
    return (
      <div className="container" style={{ marginTop: '40px' }}>
        <div style={{ textAlign: 'center', marginBottom: '40px' }}>
          <h4 style={{ textAlign: 'center', color: '#0e4761', marginBottom: '0px' }}>{customLanguage(localStorage.getItem('language')).faq.mainTitle}</h4>
          <img src={logo} alt=""></img>
        </div>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography >{customLanguage(localStorage.getItem('language')).faq.user.q1}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            {customLanguage(localStorage.getItem('language')).faq.user.a1}
          </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography >{customLanguage(localStorage.getItem('language')).faq.user.q2}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            {customLanguage(localStorage.getItem('language')).faq.user.a2}
          </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography >{customLanguage(localStorage.getItem('language')).faq.user.q3}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            {customLanguage(localStorage.getItem('language')).faq.user.a3}
          </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4a-content"
            id="panel4a-header"
          >
            <Typography >{customLanguage(localStorage.getItem('language')).faq.user.q4}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            {customLanguage(localStorage.getItem('language')).faq.user.a4}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5a-content"
            id="panel5a-header"
          >
            <Typography >{customLanguage(localStorage.getItem('language')).faq.user.q5}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            {customLanguage(localStorage.getItem('language')).faq.user.a5}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel6a-content"
            id="panel6a-header"
          >
            <Typography >{customLanguage(localStorage.getItem('language')).faq.user.q6}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            {customLanguage(localStorage.getItem('language')).faq.user.a6}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel7a-content"
            id="panel7a-header"
          >
            <Typography >{customLanguage(localStorage.getItem('language')).faq.user.q7}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            {customLanguage(localStorage.getItem('language')).faq.user.a7}
          </Typography>
          </AccordionDetails>
        </Accordion>
        <hr />
        <div style={{ textAlign: 'center', marginBottom: '40px' }}>
          <h4 style={{ textAlign: 'center', color: '#0e4761', marginBottom: '0px' }}>FAQ RIDER</h4>
          <img src={logo} alt=""></img>
        </div>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel7a-content"
            id="panel7a-header"
          >
            <Typography >{customLanguage(localStorage.getItem('language')).faq.rider.q1}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            {customLanguage(localStorage.getItem('language')).faq.rider.a1}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel8a-content"
            id="panel8a-header"
          >
            <Typography >{customLanguage(localStorage.getItem('language')).faq.rider.q2}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            {customLanguage(localStorage.getItem('language')).faq.rider.a2}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel9a-content"
            id="panel9a-header"
          >
            <Typography >{customLanguage(localStorage.getItem('language')).faq.rider.q3}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            {customLanguage(localStorage.getItem('language')).faq.rider.a3} <a href="https://www.tapaw.com/" target="_blank">https://www.tapaw.com/</a>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel10a-content"
            id="panel10a-header"
          >
            <Typography >{customLanguage(localStorage.getItem('language')).faq.rider.q4}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            {customLanguage(localStorage.getItem('language')).faq.rider.a4}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel11a-content"
            id="panel11a-header"
          >
            <Typography >{customLanguage(localStorage.getItem('language')).faq.rider.q5}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            {customLanguage(localStorage.getItem('language')).faq.rider.a5}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel12a-content"
            id="panel12a-header"
          >
            <Typography >{customLanguage(localStorage.getItem('language')).faq.rider.q6}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            {customLanguage(localStorage.getItem('language')).faq.rider.a6}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel13a-content"
            id="panel13a-header"
          >
            <Typography >{customLanguage(localStorage.getItem('language')).faq.rider.q7}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            {customLanguage(localStorage.getItem('language')).faq.rider.a7}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel14a-content"
            id="panel14a-header"
          >
            <Typography >{customLanguage(localStorage.getItem('language')).faq.rider.q8}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            {customLanguage(localStorage.getItem('language')).faq.rider.a8}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }
}

export default Faq;
