import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import { appStoreLink, baseUrl, googlePlayLink, huaweiStoreLink, tapawLink } from '../../shared/constants';
import EditProfile from '../modals/edit_profile';
import AddAddress from './add_address';
import Topup from '../modals/topup';
import downloadImg from '../../assets/img/tapaw/download.png'
import google_play from '../../assets/img/tapaw-google-play.png';
import huawei from '../../assets/img/tapaw-gallery.png';
import apple from '../../assets/img/tapaw-appstore.png';
import { DuplicateIcon } from "@primer/octicons-react";
import ChangePassword from '../modals/change_password';
import EditProfileEmailPhone from '../modals/edit_profile_email_phone';

const ProfileUser = props => {
    const username = localStorage.getItem("username");
    const textAreaRef = useRef(null);
    const [modalShow, setModalShow] = useState(false);
    const [editEmailPhoneShow, setEditEmailPhoneShow] = useState(false);
    const [editType, setEditType] = useState('');
    const [topupFlag, setTopupFlag] = useState(false);
    const [passwordFlag, setPasswordFlag] = useState(false);

    function copyToClipboard() {
        textAreaRef.current.select();
        textAreaRef.current.setSelectionRange(0, 99999);
        document.execCommand('copy');
        alert('Copied');        
    }
    
    return(
        <div className={`${props.showDiv==true?'show-stuff':'hide-stuff'}`}>
            <div className={`row justify-content-center padding-top-bottom-50`}>
                <div className="col-md-11">
                    <form method="post">
                        <div className="row">
                            <div className="col-md-6" style={{paddingBottom: '32px'}}>
                                <span>Welcome {localStorage.getItem("username")} </span><br/><br/>
                                <span style={{ fontWeight: '500'}}>DASHBOARD</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6" style={{ paddingLeft: 0 }}>
                                <div className="border-radius-15 shadow-header">
                                    <div className="col-md-12 profile-box-top">
                                        <span>Account Balance</span>
                                    </div>
                                    <div className="col-md-12 profile-box-bottom" style={{paddingBottom: '44px'}}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <span>RM{props.data.wallet!=undefined?props.data.wallet.balance:'0'}</span>
                                            </div>
                                            <div className="col-md-6 profile-text-right">
                                                <span className="profile-box-underlined cursor" onClick={() => setTopupFlag(true)}>TOP UP</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6" style={{ paddingRight: 0 }}>
                                <div className="border-radius-15 shadow-header">
                                    <div className="col-md-12 profile-box-top">
                                        <span>Referral Code</span>
                                    </div>
                                    <div className="col-md-12 profile-box-bottom">
                                        <div className="row">
                                            <div className="col-md-9">
                                                <textarea ref={textAreaRef} value={`${tapawLink}/${username}`} className="plain-text-area" style={{width: '100%'}} />
                                            </div>
                                            <div className="col-md-3 profile-text-centre cursor">
                                                <div className="col-md-12"><span onClick={() => copyToClipboard()}><DuplicateIcon size={16} /></span></div>
                                                <div className="col-md-12"><span style={{fontSize: '12px'}}>Copy Link</span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="border-radius-15 shadow-header">
                            <div className="row">
                                <div className="col-md-12 profile-box-top">
                                    <span>Personal Information</span>
                                </div>
                                <div className="col-md-12 profile-box-bottom">
                                    <div className="row">
                                        <div className="col-md-8">
                                            <div className="row profile-multiple-text">
                                                <div className="col-md-6 col-xl-4">
                                                    <span>Full Name :</span>
                                                </div>
                                                <div className="col-md-6 col-xl-8" >
                                                    <span>{props.data.fullName}</span>
                                                </div>
                                            </div>
                                            <div className="row profile-multiple-text">
                                                <div className="col-md-6 col-xl-4">
                                                    <span>Email Address :</span>
                                                </div>
                                                <div className="col-md-6 col-xl-8" >
                                                    <span>{props.data.email}</span>
                                                </div>
                                            </div>
                                            <div className="row profile-multiple-text">
                                                <div className="col-md-6 col-xl-4">
                                                    <span>Preferred Contact Number :</span>
                                                </div>
                                                <div className="col-md-6 col-xl-8" >
                                                    <span>{props.data.phoneNumber}</span>
                                                </div>
                                            </div>
                                            <div className="row profile-multiple-text">
                                                <div className="col-md-6 col-xl-4">
                                                    <span>Password :</span>
                                                </div>
                                                <div className="col-md-6 col-xl-8" >
                                                    <span className="profile-box-underlined cursor" onClick={() => setPasswordFlag(true)}>CHANGE PASSWORD</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="col-md-12 profile-text-right">
                                                <span className="profile-box-underlined cursor" onClick={() => setModalShow(true)}>EDIT FULL NAME</span>
                                            </div>
                                            <div className="col-md-12 profile-text-right">
                                                <span className="profile-box-underlined cursor" onClick={() => {setEditType('email'); setEditEmailPhoneShow(true)}}>EDIT EMAIL</span>
                                            </div>
                                            <div className="col-md-12 profile-text-right">
                                                <span className="profile-box-underlined cursor" onClick={() => {setEditType('phone'); setEditEmailPhoneShow(true)}}>EDIT CONTACT NUMBER</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br/>
                        <div className="col-md-12" style={{paddingLeft: 0, paddingRight: 0}}>
                            <span>Download APP</span>
                        </div>
                        <div className="col-md-10" style={{paddingLeft: 0, paddingRight: 0}}>
                            <div className="row">
                                <div className="col-md-4">
                                    <a onClick={() => window.open(googlePlayLink, '_blank',  'noopener, noreferrer')}><img src={google_play} alt="google" className="cursor" style={{ width: '100%' }} /></a>
                                </div>
                                <div className="col-md-4">
                                    <a onClick={() => window.open(appStoreLink, '_blank',  'noopener, noreferrer')}><img src={apple} alt="apple" className="cursor" style={{ width: '100%' }} /></a>
                                </div>
                                {/* <div className="col-md-4">
                                    <a onClick={() => window.open(huaweiStoreLink, '_blank',  'noopener, noreferrer')}><img src={huawei} alt="huawei" className="cursor" style={{ width: '100%' }} /></a>
                                </div> */}
                            </div>
                        </div>
                    </form>
                </div>
                <EditProfile
                    show={modalShow}
                    onHide={(flag) => setModalShow(flag)}
                    onRefresh={props.onRefresh}
                />
                <Topup
                    show={topupFlag}
                    onHide={(flag) => setTopupFlag(flag)}
                    balance={props.data.wallet!=undefined?props.data.wallet.balance:'0'}
                />
                <ChangePassword
                    show={passwordFlag}
                    onHide={(flag) => setPasswordFlag(flag)}
                    email={props.data.email}
                />
                <EditProfileEmailPhone
                    show={editEmailPhoneShow}
                    onHide={(flag) => setEditEmailPhoneShow(flag)}
                    onRefresh={props.onRefresh}
                    editType={editType} 
                />
            </div>
        </div>
    );
}

export default ProfileUser;