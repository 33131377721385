import React from "react";
import logo from '../../assets/img/tapaw/logo.png';
import { customLanguage } from "../../shared/languages";
class Privacy extends React.Component {
  render() {
    return (
      <div className="container justify-privacy-text" style={{ marginTop: '40px' }}>
        <div style={{ textAlign: 'center', marginBottom: '40px' }}>
          <h4 style={{ textAlign: 'center', color: '#0e4761', marginBottom: '0px' }}>{customLanguage(localStorage.getItem('language')).privacy.mainTitle}</h4>
          <img src={logo}></img>
        </div>
        <p>{customLanguage(localStorage.getItem('language')).privacy.intro}</p>
        <h4>{customLanguage(localStorage.getItem('language')).privacy.p1}</h4>
        <p>{customLanguage(localStorage.getItem('language')).privacy.p1desc}</p>
        <h4>{customLanguage(localStorage.getItem('language')).privacy.p2}</h4>
        <p>{customLanguage(localStorage.getItem('language')).privacy.p2desc}</p>
        <h4>{customLanguage(localStorage.getItem('language')).privacy.p3}</h4>
        <p>{customLanguage(localStorage.getItem('language')).privacy.p3desc}</p>
        <p>{customLanguage(localStorage.getItem('language')).privacy.p3desc2}</p>
        <h4>{customLanguage(localStorage.getItem('language')).privacy.p4}</h4>
        <p>{customLanguage(localStorage.getItem('language')).privacy.p4desc}</p>
        <h4>{customLanguage(localStorage.getItem('language')).privacy.p5}</h4>
        <p>{customLanguage(localStorage.getItem('language')).privacy.p5desc}</p>
        <h4>{customLanguage(localStorage.getItem('language')).privacy.p6}</h4>
        <p>{customLanguage(localStorage.getItem('language')).privacy.p6desc}</p>
        <h4>{customLanguage(localStorage.getItem('language')).privacy.p7}</h4>
        <p>{customLanguage(localStorage.getItem('language')).privacy.p7desc}</p>
        <h4>{customLanguage(localStorage.getItem('language')).privacy.p8}</h4>
        <p>{customLanguage(localStorage.getItem('language')).privacy.p8desc}</p>
        <h4>{customLanguage(localStorage.getItem('language')).privacy.p9}</h4>
        <p>{customLanguage(localStorage.getItem('language')).privacy.p9desc}</p>
        <h4>{customLanguage(localStorage.getItem('language')).privacy.p10}</h4>
        <p>{customLanguage(localStorage.getItem('language')).privacy.p10desc}</p>
      </div>
    );
  }
}

export default Privacy;
