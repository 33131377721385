import React from "react";
import about from '../../assets/img/tapaw/about.png';
import about2 from '../../assets/img/tapaw/about2.png';
import { customLanguage } from "../../shared/languages";
class AboutUs extends React.Component {
  render() {
    return (
      <div>
        <div
          className="sigma_subheader style-11 bg-cover bg-center"
          style={{
            backgroundImage: `url(${about})`,
            height: '397px'
          }}
        >
          <div className="container" style={{ position: 'relative', top: '40%'}}>
            {/* <div className="">
              <h3 className="text-white" style={{ textAlign: 'end' }}>Team</h3>
            </div> */}
          </div>
        </div>
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-lg-5">
                <div className="style-9 w-100 h-100">
                  <div className="sigma_about-image-1">
                    <img src={about2} alt="img" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 offset-lg-1">
                <div className="sigma_about style-9 mt-5 mt-lg-0">
                  <div className="section-title">
                    <span className="subtitle">{customLanguage(localStorage.getItem('language')).about.var1}</span>
                    <h3 className="title">{customLanguage(localStorage.getItem('language')).about.title}</h3>
                  </div>
                  <div className="sigma_about-content">
                    <p>
                    {customLanguage(localStorage.getItem('language')).about.desc} 
                    </p>
                    {/* <div className="row">
                      <div className="col-md-6">
                        <div className="sigma_info style-15">
                          <div className="sigma_info-description">
                            <h3>1000+</h3>
                            <h5>
                              <a href="#">Delivery Across Malaysia</a>
                            </h5>
                            <p>
                              Lorem ipsum
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="sigma_info style-15">
                          <div className="sigma_info-description">
                            <h3>99%</h3>
                            <h5>
                              <a href="#">Delivery Completed</a>
                            </h5>
                            <p>
                              Lorem ipsum
                            </p>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div className="d-sm-flex d-block align-items-center">
                      <a href="/" className="sigma_btn">
                      {customLanguage(localStorage.getItem('language')).about.btn}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutUs;
