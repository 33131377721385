import firebase from 'firebase';
import 'firebase/storage';
import 'firebase/auth';
// import 'firebase/analytic';

/*****  staging  *****/

// const config = {
//     apiKey: "AIzaSyDQaXLsN497XYEnmdQbjUCqGINvP3RDbRA",
//     authDomain: "tapaw-staging-17279.firebaseapp.com",
//     databaseURL: "https://tapaw-staging-17279.firebaseio.com",
//     projectId: "tapaw-staging-17279",
//     storageBucket: "tapaw-staging-17279.appspot.com",
//     messagingSenderId: "960223944052",
//     appId: "1:960223944052:web:8dc721446d635a0454a021",
//     measurementId: "G-JPL547HPV6"
//   };

/*****  production  *****/

const config = {
    apiKey: "AIzaSyCEmv83qOao-KTuFasgDAPMwSaddPFWz5o",
    authDomain: "tapaw-8fae0.firebaseapp.com",
    databaseURL: "https://tapaw-8fae0.firebaseio.com",
    projectId: "tapaw-8fae0",
    storageBucket: "tapaw-8fae0.appspot.com",
    messagingSenderId: "773584863476",
    appId: "1:773584863476:web:4996efe2d390e015795ea1",
    measurementId: "G-YCJEDW99HH"
  };

 firebase.initializeApp(config);

 const storage = firebase.storage();
 const auth = firebase.auth();

  export { storage, auth, firebase as default};
