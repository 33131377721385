import React from "react";
import { customLanguage } from "../../shared/languages";

const  IndexPart2 = props => {
  return (
    <div className="wf-section">
      <div className="container w-container" style={{textAlign: 'center'}}>
        <h2
          data-w-id="c94128e6-c3f6-7c16-edf4-6ec84e6edcf3"
          style={{
            opacity: 1,
            marginTop: '10px'
          }}
          className="heading-5 capitalize-all"
        >
        {customLanguage(localStorage.getItem('language')).home.indexPart2.title}
        </h2>
        
        <div
          data-w-id="03b6c7d7-a93f-8121-b22f-72302d8d8594"
          style={{
            opacity: 1
          }}
          className="div-block-4"
        />
        <div style={{paddingBottom: '20px'}}>
          <span className="description-text">{customLanguage(localStorage.getItem('language')).home.indexPart2.desc}</span>
        </div>
       
        <div
          style={{
            paddingTop: "56.17021276595745%",
            opacity: 1
          }}
          data-w-id="e5e2e196-259f-53a5-36e4-fc852773bd6c"
          className="w-embed-youtubevideo youtube"
        >
          <iframe
            src="https://www.youtube.com/embed/-q9bDaDsZ5U?rel=0&controls=1&autoplay=0&mute=0&start=0"
            frameBorder={0}
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
              pointerEvents: "auto",
              borderColor: '#ffcd3d', 
              borderRadius: '5px', 
              borderWidth: '10px', 
              textShadow: 'none'
            }}
            allow="autoplay; encrypted-media"
            allowFullScreen
          />
        </div>
      </div>
    </div>
  );
}

export default IndexPart2;
