import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactPaginate from 'react-paginate';
import { baseUrl } from '../../shared/constants';
import PdfComponent from './pdf/pdf_component';

const ProfileDeliveryHistory = props => {
    const [deliveryData, setDeliveryData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [triggerRefresh, setTriggerRefresh] = useState(false);
    const [offsetDelivery, setOffsetDelivery] = useState(0);
    const [perPageDelivery] = useState(5);
    const [pageCountDelivery, setPageCountDelivery] = useState(0);

    useEffect(() => {
        getDeliveryHistory()
    }, [triggerRefresh, offsetDelivery]);

    // useEffect(() => {
    //     console.log(`delivery data ${JSON.stringify(deliveryData)}`);
    // }, [deliveryData]);

    const handlePageClickDelivery = (e) => {
        const selectedPage = e.selected;
        setOffsetDelivery(selectedPage + 1)
    };

    async function getDeliveryHistory() {
        setLoading(true);
        const token = localStorage.getItem("idToken");
        await axios.post(`${baseUrl}/get_order`, {
            token
        })
            .then(res => {
                const data = res.data;
                if (typeof (data) !== 'string') {
                    data.reverse();
                    let slicedData = data.slice(offsetDelivery, offsetDelivery + perPageDelivery);
                    // console.log(`delivery history data ${JSON.stringify(data)}`);
                    setDeliveryData(slicedData);
                    setPageCountDelivery(Math.ceil(data.length / perPageDelivery));
                } else {
                    setDeliveryData(data);
                }
                setLoading(false);
            })
            .catch(err => {
                // console.log(`error response ${err.response}`);
                setLoading(false);
                // loading = false;
                // error = err;
                if (err.response !== undefined) {
                    // client received error response (5xx, 4xx)
                    // console.log(`error response ${err.response}`);
                    //   console.log(err.response);
                    if (err.response.data.message != undefined) {
                        if (err.response.data.message.includes('Firebase ID token has expired')) {
                            //   console.log('refresh');
                            refreshTokenRequest();
                        }
                    }
                } else if (err.request !== undefined) {
                    // client never received a response, or a request never left
                    // console.log(`error request ${err.request}`);
                } else {
                    // console.log(`get error ${err}`);
                }
            })
    }

    async function refreshTokenRequest() {
        setLoading(true);
        const refresh_token = localStorage.getItem("refreshToken");
        // console.log(`refresh token in purchase history ${refresh_token}`);
        await axios.post(`${baseUrl}/refresh_token`, {
            refresh_token
        })
            .then(res => {
                const data = res.data
                // console.log(`refresh data ${JSON.stringify(data)}`);
                localStorage.setItem("idToken", data.id_token);
                localStorage.setItem("refreshToken", data.refresh_token);
                setLoading(false);
                setTriggerRefresh(old => old + 1);
            })
            .catch(err => {
                // console.log(`error response ${err.response}`);
                // console.log(err.response);
                // loading = false;
                // error = err;
                setLoading(false);
                if (err.response !== undefined) {
                    // client received error response (5xx, 4xx)
                    localStorage.setItem("idToken", "");
                    localStorage.setItem("customToken", "");
                    localStorage.setItem("refreshToken", "");
                    localStorage.setItem("fullName", "");
                    localStorage.setItem("username", "");
                    localStorage.setItem("userType", "");
                    localStorage.setItem("uid", "");
                    localStorage.setItem("completedRegister", "");
                    props.history.push('/');
                    alert('Session expired. Please login again.');
                    //   console.log(`error response ${err.response}`);
                    //   console.log(err.response);
                } else if (err.request !== undefined) {
                    // client never received a response, or a request never left
                    //   console.log(`error request ${err.request}`);
                } else {
                    //   console.log(`get error ${err}`);
                }
            })
    }

    function renderDelivery(data) {
        if (loading) {
            return <div className="col-md-12 profile-text-centre"><CircularProgress /></div>;
        }
        if (data != null && data != undefined && data.length != 0 && data != 'No Order') {
            // console.log(`delivery in render ${JSON.stringify(data)}`)
            return (
                data.map((a, idx) => {
                    return (
                        <div className="row profile-multiple-text profile-text-centre" key={idx}>
                            <div className="col-md-2">
                                <div className="col-md-12">
                                    <span>{a.orderDate ? (new Date(a.orderDate._seconds * 1000)).toDateString() : ''}</span>    
                                </div>
                                <div className="col-md-12">
                                    <span>{a.orderDate ? (new Date(a.orderDate._seconds * 1000)).toTimeString() : ''}</span>    
                                </div>
                            </div>
                            <div className="col-md-1" >
                                <span>{a.orderID}</span>
                            </div>
                            <div className="col-md-3" >
                                <span>{a.pickupLocation.address}</span>
                            </div>
                            <div className="col-md-3">
                                <span>{a.dropoffLocation.address}</span>
                            </div>
                            <div className="col-md-1" >
                                <div className="row">
                                    <div className="col-md-12"><span>RM{a.totalFare}</span></div>
                                    <div className="col-md-12"><span>{a.paymentMethod==='Wallet'?'(Wallet)':'(COD)'}</span></div>
                                </div>
                            </div>
                            <div className="col-md-1">
                                <span>{a.status}</span>
                            </div>
                            <div className="col-md-1" >
                                <PdfComponent pdfData={a} />
                            </div>
                            <div className="col-md-12 line-confirm" />
                        </div>);
                })
            );
        } else {
            return <div className="row profile-multiple-text profile-text-centre"><div className="col-md-12"><span>No data</span></div></div>
        }
    }

    return (
        <div className={`${props.showDiv == true ? 'show-stuff' : 'hide-stuff'}`}>
            <div className="row justify-content-center padding-top-bottom-50">
                <div className="col-md-11">
                    <div className="border-radius-15 shadow-header">
                        <div className="row">
                            <div className="col-md-12 profile-box-top">
                                <span>Delivery History</span>
                            </div>
                            <div className="col-md-12 profile-box-bottom">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="row profile-multiple-text profile-text-centre">
                                            <div className="col-md-2">
                                                <span className="bold-text">DATE</span>
                                            </div>
                                            <div className="col-md-1">
                                                <span className="bold-text">ORDER ID</span>
                                            </div>
                                            <div className="col-md-3" >
                                                <span className="bold-text">PICKUP ADDRESS</span>
                                            </div>
                                            <div className="col-md-3">
                                                <span className="bold-text">DELIVERY ADDRESS</span>
                                            </div>
                                            <div className="col-md-1" >
                                                <span className="bold-text">PAYMENT</span>
                                            </div>
                                            <div className="col-md-1">
                                                <span className="bold-text">STATUS</span>
                                            </div>
                                            <div className="col-md-1" >
                                                <span className="bold-text">INVOICE</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        {renderDelivery(deliveryData)}
                                    </div>
                                    <div className="col-md-12">
                                        <ReactPaginate
                                            previousLabel={"prev"}
                                            nextLabel={"next"}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={pageCountDelivery}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={handlePageClickDelivery}
                                            containerClassName={"pagination"}
                                            subContainerClassName={"pages pagination"}
                                            activeClassName={"active"}
                                        />
                                    </div>
                                </div>
                                <hr />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProfileDeliveryHistory;
