import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import logo from '../../assets/img/tapaw/logo.png';
import { withRouter } from 'react-router-dom';

const PleaseTopup = (props) => {
    return (
        <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // dialogClassName="modal-rounded"
        >
            
            <Modal.Body className="modal-content">
                <form method="post">
                    <div className="col-lg-12 profile-text-centre">
                        <h5>{props.message}</h5>
                    </div>
                    <div className="col-lg-12">
                        <div className="col-lg-12 profile-text-centre">
                            <label>Go to profile to topup</label>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-lg-6">
                                <button type="button" className="tempah-sekarang dark-blue-text white-background" style={{border: '1px solid #ffce28'}} onClick={() => {props.onHide(false)}}>
                                    Cancel order
                                </button>
                            </div>
                            <div className="col-lg-6"> 
                                <button type="button" className="tempah-sekarang dark-blue-text" onClick={() => {props.history.push("/profile"); props.onHide(false)}}>
                                    Top Up
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default withRouter(PleaseTopup);