// import logo from './logo.svg';
// import './App.css';
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import "./assets/css/plugins/bootstrap.min.css";
import "./assets/css/plugins/slick.css";
import "./assets/css/plugins/ion.rangeSlider.min.css";
import "./assets/css/plugins/magnific-popup.css";
// import "./assets/css/style.css";
// import "./assets/css/style-2.css";
import "./assets/css/style-3.css";
import "./assets/css/webflow.css";
import "./assets/css/tapaw.webflow.css";
import "./assets/css/normalize.css";

import "./assets/fonts/font-awesome.min.css";
import "./assets/fonts/flaticon/flaticon.css";
import Body from './components/main/body';
import Home from './components/home/homeBody';
import News from "./components/news/news";
import NewsDetails from "./components/news/news_details";
import BusinessCentre from "./components/business/business_centre";
import AboutUs from "./components/about/about_us";
// import Registration from "./components/registration/registration";
import Profile from "./components/profile/profile";
import Confirmation from './components/confirmation/confirmation';
import TrackGuest from './components/track/track_guest';
import TrackUser from './components/track/track_user';
import { ContextProvider } from './shared/context';
import Privacy from './components/privacy/privacy';
import Faq from './components/faq/faq';
import Team from './components/team/team';
import DeliveryRate from './components/delivery_rate/delivery_rate';
// import TopupSuccessPage from './components/profile/topupCallback/topup_success_page';
// import TopupFailPage from './components/profile/topupCallback/topup_fail_page';
import ReturnProfile from './components/profile/topupCallback/return_profile';
import UserRegistBody from './components/registration/UserRegistBody';
import DriverRegistBody from './components/registration/DriverRegistBody';
import VendorRegistBody from './components/registration/VendorRegistBody';
import DriverRegist2Body from './components/registration/DriverRegist2Body';
import VendorRegist2Body from './components/registration/VendorRegist2Body';
import Order from './components/work_order/order';
import AgentRegistBody from './components/registration/AgentRegistBody';
import PartnerRegistBody from './components/registration/PartnerRegistBody';
import ConnectBody from './components/business/connectBody';
// import {Helmet} from 'react-helmet';

const App = () => {
  return (
    <Router>
      {/* <Helmet>
        <script src="./assets/js/webflow.js" />
      </Helmet> */}
      <div className="App">
        <ContextProvider>
          <Body>
            <Switch>
              <Route exact path="/order">
                <Order />
              </Route>
              <Route exact path="/confirm">
                <Confirmation />
              </Route>
              <Route exact path="/trackguest">
                <TrackGuest />
              </Route>
              <Route exact path="/trackuser">
                <TrackUser />
              </Route>
              <Route exact path="/team">
                <AboutUs />
              </Route>
              <Route exact path="/contact">
                <BusinessCentre />
              </Route>
              <Route exact path="/connect">
                <ConnectBody />
              </Route>
              <Route exact path="/customer">
                <Home />
              </Route>
              <Route exact path="/news">
                <News />
              </Route>
              <Route exact path="/newsDetails/:id">
                <NewsDetails />
              </Route>
              <Route exact path="/profile">
                <Profile />
              </Route>
              <Route exact path="/privacy">
                <Privacy />
              </Route>
              <Route exact path="/faq">
                <Faq />
              </Route>
              <Route exact path="/about">
                <Team />
              </Route>
              <Route exact path="/delivery_rate">
                <DeliveryRate />
              </Route>
              {/* <Route path="/register/:ref?">
                <Registration />
              </Route> */}
              <Route exact path="/registeruser">
                <UserRegistBody />
              </Route>
              <Route exact path="/registerdriver">
                <DriverRegistBody />
              </Route>
              <Route exact path="/registerdriver2">
                <DriverRegist2Body />
              </Route>
              <Route exact path="/registervendor">
                <VendorRegistBody />
              </Route>
              <Route exact path="/registervendor2">
                <VendorRegist2Body />
              </Route>
              <Route exact path="/registeragent">
                <AgentRegistBody />
              </Route>
              {/* <Route exact path="/registerpartner/:ref?">
                <PartnerRegistBody />
              </Route> */}
              <Route exact path="/return_profile">
                <ReturnProfile />
              </Route>
              {/* <Route path="/topupSuccess">
                <TopupSuccessPage />
              </Route>
              <Route path="/topupFail">
                <TopupFailPage />
              </Route> */}
              <Route path="/:referral">
                <Home />
              </Route>
              <Route exact path="/">
                <Home />
              </Route>
            </Switch>
          </Body>
        </ContextProvider>
      </div>
    </Router>
  );
}

export default App;
