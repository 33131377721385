import React from "react";
import {useHistory} from 'react-router-dom';
import userImg from '../../assets/img/cta/1-tapawuser.png';
import driverImg from '../../assets/img/cta/2-tapawdriver.png';
import vendorImg from '../../assets/img/cta/4-tapawvendor.png';
import agentImg from '../../assets/img/cta/3-tapawagent.png';
import partnerImg from '../../assets/img/cta/5-tapawpartner.png';
import DownloadApp from "./downloadapp";
import { customLanguage } from "../../shared/languages";

const CTA = props => {
  let history = useHistory();
  // pass referral if exist, to register page
  const handleLinks = route => {
    history.push(route);
  }
    return (
      <div className="wf-section">
        <div className="container w-container" style={{marginTop: '0'}}>
          <div className="div-new cta">
            <div className="w-layout-grid grid-cta">
              <div
                id="w-node-df90338c-3fa0-6c41-3852-c8d6595c3cc5-1b204b53"
                className="div-cta cursor"
                onClick={() => handleLinks('/registeruser')}
              >
                <img
                  src={userImg}
                  loading="lazy"
                  alt
                  className="image-cta"
                />
                <div className="text-block-cta">{customLanguage(localStorage.getItem('language')).home.cta.var1}</div>
              </div>
              <div
                id="w-node-_3e5cfb0a-985c-5110-e5b9-4d4d27eb2a04-1b204b53"
                className="div-cta cursor"
                onClick={() => handleLinks('/registerdriver')}
              >
                <img
                  src={driverImg}
                  loading="lazy"
                  alt
                  className="image-cta"
                />
                <div className="text-block-cta">{customLanguage(localStorage.getItem('language')).home.cta.var2}</div>
              </div>
              <div
                id="w-node-_4e5fe6a0-1371-df9a-49be-d4a8f47f2dd3-1b204b53"
                className="div-cta cursor"
                onClick={() => handleLinks('/registervendor')}
              >
                <img
                  src={vendorImg}
                  loading="lazy"
                  alt
                  className="image-cta"
                />
                <div className="text-block-cta">{customLanguage(localStorage.getItem('language')).home.cta.var3}</div>
              </div>
              <div
                id="w-node-aa43203e-059f-f063-51b4-23d24fc6d2ec-1b204b53"
                className="div-cta cursor"
                onClick={() => handleLinks('/registeragent')}
              >
                <img
                  src={agentImg}
                  loading="lazy"
                  alt
                  className="image-cta"
                />
                <div className="text-block-cta">{customLanguage(localStorage.getItem('language')).home.cta.var4}</div>
              </div>
              <div
                id="w-node-_086ff49c-4e8d-d7ad-7525-50baf85c3f30-1b204b53"
                className="div-cta"
              >
                <img
                  src={partnerImg}
                  loading="lazy"
                  alt
                  className="image-cta cursor"
                  onClick={() => handleLinks('/connect')}
                />
                <div className="text-block-cta">{customLanguage(localStorage.getItem('language')).home.cta.var5}</div>
              </div>
            </div>
          </div>
        </div>
        <DownloadApp />
      </div>
    );
}

export default CTA;
