import React from "react";
import logo from '../../assets/img/tapaw/logo.png';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import vehicle1 from '../../assets/img/vehicles/penghataran-motor.png';
import vehicle2 from '../../assets/img/vehicles/penghantaran-kereta.png';
import vehicle3 from '../../assets/img/vehicles/penghantaran-ranger.png';
import vehicle4 from '../../assets/img/vehicles/penghatantara-van.png';
import vehicle5 from '../../assets/img/vehicles/penghantaran-lori.png';
import vehicle6 from '../../assets/img/vehicles/penghantaran-lori-3-tan.png';
import { customLanguage } from "../../shared/languages";
class DeliveryRate extends React.Component {
  render() {
    function createData(name, detail, subdetail) {
      return { name, detail, subdetail };
    }

    const rows = [
      createData(customLanguage(localStorage.getItem('language')).deliveryRate.var2, '<10 kg'),
      createData(customLanguage(localStorage.getItem('language')).deliveryRate.var3, '35 cm x 35 cm x 35 cm'),
      createData(`${customLanguage(localStorage.getItem('language')).deliveryRate.var4} (0 - 3 km)`, 'RM4.00'),
      createData(`${customLanguage(localStorage.getItem('language')).deliveryRate.var5} 3 km`, 'RM1.00/km'),
    ];
    const rows_additional = [
      createData(customLanguage(localStorage.getItem('language')).deliveryRate.var6, 'RM2.00'),
      createData(customLanguage(localStorage.getItem('language')).deliveryRate.var7, customLanguage(localStorage.getItem('language')).deliveryRate.var8),
      createData(customLanguage(localStorage.getItem('language')).deliveryRate.var9, customLanguage(localStorage.getItem('language')).deliveryRate.var10),
    ];
    const rows2 = [
      createData(customLanguage(localStorage.getItem('language')).deliveryRate.var2, '< 40 kg'),
      createData(customLanguage(localStorage.getItem('language')).deliveryRate.var3, '45 cm x 45 cm x 45 cm'),
      createData(`${customLanguage(localStorage.getItem('language')).deliveryRate.var4} (0 - 4 km)`, 'RM6.50'),
      createData('4.1 - 10 km', 'RM10.00'),
      createData(`${customLanguage(localStorage.getItem('language')).deliveryRate.var5} 10 km`, 'RM1.40/km'),
    ];
    const rows_additional2 = [
      createData(customLanguage(localStorage.getItem('language')).deliveryRate.var6, 'RM4.00'),
      createData(customLanguage(localStorage.getItem('language')).deliveryRate.var7, customLanguage(localStorage.getItem('language')).deliveryRate.var8),
      createData(customLanguage(localStorage.getItem('language')).deliveryRate.var11, 'RM8.00'),
    ];
    const rows3 = [
      createData(customLanguage(localStorage.getItem('language')).deliveryRate.var2, '450 kg'),
      createData(customLanguage(localStorage.getItem('language')).deliveryRate.var3, '160 cm x 110 cm x 110 cm'),
      createData(`${customLanguage(localStorage.getItem('language')).deliveryRate.var4} (0 - 10 km)`, 'RM40.00'),
      createData('11 - 50 km', 'RM3.50/km'),
      createData('51 - 100 km', 'RM2.50/km'),
      createData('101 - 200 km', 'RM2.00/km'),
      createData(`${customLanguage(localStorage.getItem('language')).deliveryRate.var12} 200 km`, 'RM1.50/km'),
    ];
    const rows_additional3 = [
      createData(customLanguage(localStorage.getItem('language')).deliveryRate.var6, 'RM10.00'),
      createData(`${customLanguage(localStorage.getItem('language')).deliveryRate.var11} : ${customLanguage(localStorage.getItem('language')).deliveryRate.var13}`, 'RM25.00'),
      createData(`${customLanguage(localStorage.getItem('language')).deliveryRate.var11} : ${customLanguage(localStorage.getItem('language')).deliveryRate.var14}`, 'RM80.00'),
    ];
    const rows4 = [
      createData(customLanguage(localStorage.getItem('language')).deliveryRate.var2, '250 kg'),
      createData(customLanguage(localStorage.getItem('language')).deliveryRate.var3, '110 cm x 85 cm x 85 cm'),
      createData(`${customLanguage(localStorage.getItem('language')).deliveryRate.var4} (0 - 10 km)`, 'RM20.00'),
      createData('11 - 100 km', 'RM1.90/km'),
      createData(`${customLanguage(localStorage.getItem('language')).deliveryRate.var12} 100 km`, 'RM1.60/km'),
    ];
    const rows_additional4 = [
      createData(customLanguage(localStorage.getItem('language')).deliveryRate.var6, 'RM10.00'),
      createData(customLanguage(localStorage.getItem('language')).deliveryRate.var11, 'RM20.00'),
    ];
    const rows5 = [
      createData(customLanguage(localStorage.getItem('language')).deliveryRate.var2, '450kg'),
      createData(customLanguage(localStorage.getItem('language')).deliveryRate.var3, '270 cm x 150 cm x 150 cm'),
      createData(`${customLanguage(localStorage.getItem('language')).deliveryRate.var4} (0 - 10 km)`, 'RM65.00'),
      createData('11 - 50 km', 'RM3.50/km'),
      createData('51 - 100 km', 'RM3.00/km'),
      createData('101 - 200 km', 'RM2.50/km'),
      createData(`${customLanguage(localStorage.getItem('language')).deliveryRate.var12} 200 km`, 'RM2.00/km'),
    ];
    const rows_additional5 = [
      createData(customLanguage(localStorage.getItem('language')).deliveryRate.var6, 'RM10.00'),
      createData(`${customLanguage(localStorage.getItem('language')).deliveryRate.var11} : ${customLanguage(localStorage.getItem('language')).deliveryRate.var13}`, 'RM65.00'),
      createData(`${customLanguage(localStorage.getItem('language')).deliveryRate.var11} : ${customLanguage(localStorage.getItem('language')).deliveryRate.var14}`, 'RM120.00'),
      createData(`${customLanguage(localStorage.getItem('language')).deliveryRate.var11} : ${customLanguage(localStorage.getItem('language')).deliveryRate.var15}`, 'RM180.00'),
    ];
    const rows6 = [
      createData(customLanguage(localStorage.getItem('language')).deliveryRate.var2, '450kg'),
      createData(customLanguage(localStorage.getItem('language')).deliveryRate.var3, '420 cm x 210 cm x 210 cm'),
      createData(`${customLanguage(localStorage.getItem('language')).deliveryRate.var4} (0 - 10 km)`, 'RM150.00'),
      createData('11 - 50 km', 'RM4.00/km'),
      createData('51 - 100 km', 'RM4.00/km'),
      createData('101 - 200 km', 'RM3.00/km'),
      createData(`${customLanguage(localStorage.getItem('language')).deliveryRate.var12} 200 km`, 'RM2.50/km'),
    ];
    const rows_additional6 = [
      createData(customLanguage(localStorage.getItem('language')).deliveryRate.var6, 'RM20.00'),
      createData(`${customLanguage(localStorage.getItem('language')).deliveryRate.var11} : ${customLanguage(localStorage.getItem('language')).deliveryRate.var13}`, 'RM70.00'),
      createData(`${customLanguage(localStorage.getItem('language')).deliveryRate.var11} : ${customLanguage(localStorage.getItem('language')).deliveryRate.var14}`, 'RM120.00'),
      createData(`${customLanguage(localStorage.getItem('language')).deliveryRate.var11} : ${customLanguage(localStorage.getItem('language')).deliveryRate.var15}`, 'RM180.00'),
    ];
    const vehicles = [
      {
        name: customLanguage(localStorage.getItem('language')).deliveryRate.vehicle1,
        image: vehicle1,
        detail: rows,
        additional: rows_additional
      },
      {
        name: customLanguage(localStorage.getItem('language')).deliveryRate.vehicle2,
        image: vehicle2,
        detail: rows2,
        additional: rows_additional2
      },
      {
        name: customLanguage(localStorage.getItem('language')).deliveryRate.vehicle3,
        image: vehicle4,
        detail: rows3,
        additional: rows_additional3
      },
      {
        name: customLanguage(localStorage.getItem('language')).deliveryRate.vehicle4,
        image: vehicle3,
        detail: rows4,
        additional: rows_additional4
      },
      {
        name: customLanguage(localStorage.getItem('language')).deliveryRate.vehicle5,
        image: vehicle5,
        detail: rows5,
        additional: rows_additional5
      },
      {
        name: customLanguage(localStorage.getItem('language')).deliveryRate.vehicle6,
        image: vehicle6,
        detail: rows6,
        additional: rows_additional6
      }
    ];
    return (
      <div className="container" style={{ marginTop: '40px', marginBottom: '40px' }}>
        <div style={{ textAlign: 'center', marginBottom: '40px' }}>
          <h2 style={{ textAlign: 'center', color: '#0e4761', marginBottom: '0px' }}>{customLanguage(localStorage.getItem('language')).deliveryRate.mainTitle}</h2>
          <img src={logo} alt=""></img>
        </div>
        {
          vehicles.map((item) => (
            <div style={{ margin: 30 }}>
              <TableContainer component={Paper} style={{ textAlign: '-webkit-center' }}>
                <TableHead>
                  <h3 style={{ textAlign: 'center', color: '#0e4761', marginBottom: '0px' }}>{item.name}</h3>
                  <div className="row">
                    <div className="col-md-4" />
                    <div
                        className="col-md-4"
                      >
                        <div className="yellow-line" />
                      </div>
                  </div>
                  <img style={{ textAlign: 'center' }} src={item.image} alt=""></img>
                </TableHead>
                <Table aria-label="vehicles">
                  <TableBody>
                    {item.detail.map((row) => (
                      <TableRow key={row.name}>
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell align="right">{row.detail}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <h4>{customLanguage(localStorage.getItem('language')).deliveryRate.var1}</h4>
                <Table aria-label="vehicles">
                  <TableBody>
                    {item.additional.map((row) => (
                      <TableRow key={row.name}>
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell align="right">{row.detail}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          ))
        }
        <h4 style={{ textAlign: 'center', color: '#0e4761', marginBottom: '0px' }}>{customLanguage(localStorage.getItem('language')).deliveryRate.otherMainTitle}</h4>
        <h5>{customLanguage(localStorage.getItem('language')).deliveryRate.var11}</h5>
        <ul className="circle">
          <li>
          {customLanguage(localStorage.getItem('language')).deliveryRate.p1a}
        </li>
          <li>
          {customLanguage(localStorage.getItem('language')).deliveryRate.p1b}
        </li>
          <li>
          {customLanguage(localStorage.getItem('language')).deliveryRate.p1c}
        </li>
        </ul>
        <h5>{customLanguage(localStorage.getItem('language')).deliveryRate.p2}</h5>
        <ul className="circle">
          <li>
          {customLanguage(localStorage.getItem('language')).deliveryRate.p2a}
        </li>
          <li>
          {customLanguage(localStorage.getItem('language')).deliveryRate.p2b}
        </li>
        </ul>
        <h5>{customLanguage(localStorage.getItem('language')).deliveryRate.p3}</h5>
        <p>{customLanguage(localStorage.getItem('language')).deliveryRate.p3a}</p>
        <h5>{customLanguage(localStorage.getItem('language')).deliveryRate.p4}</h5>
        <p>{customLanguage(localStorage.getItem('language')).deliveryRate.p4a}</p>
        <h5>{customLanguage(localStorage.getItem('language')).deliveryRate.p5}</h5>
        <p>{customLanguage(localStorage.getItem('language')).deliveryRate.p5a}</p>
        <ul className="circle">
          <li>
          {customLanguage(localStorage.getItem('language')).deliveryRate.p5b}
        </li>
          <li>
          {customLanguage(localStorage.getItem('language')).deliveryRate.p5c}
        </li>
          <li>
          {customLanguage(localStorage.getItem('language')).deliveryRate.p5d}
        </li>
        </ul>
      </div>
    );
  }
}

export default DeliveryRate;
