import * as S from './styles';
import Logo from '../../../assets/img/images/logo.svg';

// import Logo from '../../assets/logo.png';
import Burger from './burger';

export default function Navbar(props) {
  return (
    <>
      <S.Nav>
        <a href='/'><S.Logo src={Logo} alt='Tapaw' /></a>
      </S.Nav>
      <Burger />
      {props.children}
    </>
  );
}