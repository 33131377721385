import React, { useState, useEffect } from "react";
import downloadImg from '../../assets/img/tapaw/download.png'
import EditProfile from '../modals/edit_profile';
import DeleteAddress from '../modals/delete_address';
import AddAddress from "./add_address";
import Topup from '../modals/topup';
import { Link } from "react-router-dom";
import { DuplicateIcon } from "@primer/octicons-react";
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import { baseUrl } from '../../shared/constants';
import ProfileUser from "./profile_user";
import ProfileAddress from "./profile_address";
import ProfilePaymentHistory from "./profile_paymentHistory";
import ProfileDriver from "./profile_driver";
import ProfileVendor from "./profile_vendor";
import ProfileTravelHistory from "./profile_deliveryHistory";
import ProfileReferralHistory from "./profile_referralHistory";

const Profile = (props) => {
    // const [modalShow, setModalShow] = useState(false);
    const [addressDelete, setAddressDelete] = useState(false);
    // const [expandedAddress, setExpandedAddress] = useState(false);
    const [profileData, setProfileData] = useState(null);
    const [showProfileUser, setShowProfileUser] = useState(false);
    const [showProfileDriver, setShowProfileDriver] = useState(true);
    const [showProfileVendor, setShowProfileVendor] = useState(false);
    const [showProfileAgent, setShowProfileAgent] = useState(false);
    const [showAddress, setShowAddress] = useState(false);
    const [showPaymentHistory, setShowPaymentHistory] = useState(false);
    const [showReferral, setShowReferral] = useState(false);
    const [showTravelHistory, setShowTravelHistory] = useState(false);
    const [currentMenu, setCurrentMenu] = useState('');
    const [userType, setUserType] = useState('');
    const [profileLoading, setProfileLoading] = useState(false);
    const [triggerRefreshProfile, setTriggerRefreshProfile] = useState(0);
    const [informLoading, setInformLoading] = useState(false);
    const [triggerRefreshInform, setTriggerRefreshInform] = useState(0);
    const [payResData, setPayResData] = useState(null);

    useEffect(() => {
        getProfile()

        // repeat every 1 minutes (1 seconds = 1000), 1000x60=60000
        // const interval = setInterval(() => {
        //     getProfile();
        // }, 60000);
        // return () => clearInterval(interval);
    }, [triggerRefreshProfile]);


    useEffect(() => {
        setupSide(currentMenu)
    }, [currentMenu]);

    useEffect(() => {
        setupUserType();
    }, [profileData]);

    useEffect(() => {
        userSwitching(userType);
    }, [userType]);

    useEffect(() => {
        paymentInformRequest();
    }, []);

    function setupUserType() {
        if (profileData != null) {
            //console.log(profileData);
            switch (profileData.usertype[0].type) {
                case 'user':
                    setUserType('User');
                    localStorage.setItem("userType", 'user');
                    break;
                case 'driver':
                    setUserType('Driver');
                    localStorage.setItem("userType", 'driver');
                    break;
                case 'vendor':
                    setUserType('Vendor');
                    localStorage.setItem("userType", 'vendor');
                    break;
                case 'agent':
                    setUserType('Agent');
                    localStorage.setItem("userType", 'agent');
                    break;
                case 'agent-driver':
                        setUserType('Agent');
                        localStorage.setItem("userType", 'agent');
                        break;
                case 'partner':
                    setUserType('Partner');
                    localStorage.setItem("userType", 'partner');
                    break;
                default:
                    break;
            }
        }
    }

    function setupSide(menu) {
        switch (menu) {
            case 'ProfileUser':
                setShowProfileUser(true);
                setShowProfileDriver(false);
                setShowProfileVendor(false);
                setShowProfileAgent(false);
                setShowAddress(false);
                setShowPaymentHistory(false);
                setShowReferral(false);
                setShowTravelHistory(false);
                break;
            case 'ProfileDriver':
                setShowProfileUser(false);
                setShowProfileDriver(true);
                setShowProfileVendor(false);
                setShowProfileAgent(false);
                setShowAddress(false);
                setShowPaymentHistory(false);
                setShowReferral(false);
                setShowTravelHistory(false);
                break;
            case 'ProfileVendor':
                setShowProfileUser(false);
                setShowProfileDriver(false);
                setShowProfileVendor(true);
                setShowProfileAgent(false);
                setShowAddress(false);
                setShowPaymentHistory(false);
                setShowReferral(false);
                setShowTravelHistory(false);
                break;
            case 'ProfileAgent':
                setShowProfileUser(false);
                setShowProfileDriver(false);
                setShowProfileVendor(false);
                setShowProfileAgent(true);
                setShowAddress(false);
                setShowPaymentHistory(false);
                setShowReferral(false);
                setShowTravelHistory(false);
                break;
            case 'ProfileAddress':
                setShowProfileUser(false);
                setShowProfileDriver(false);
                setShowProfileVendor(false);
                setShowProfileAgent(false);
                setShowAddress(true);
                setShowPaymentHistory(false);
                setShowReferral(false);
                setShowTravelHistory(false);
                break;
            case 'ProfilePaymentHistory':
                setShowProfileUser(false);
                setShowProfileDriver(false);
                setShowProfileVendor(false);
                setShowProfileAgent(false);
                setShowAddress(false);
                setShowPaymentHistory(true);
                setShowReferral(false);
                setShowTravelHistory(false);
                break;
            case 'ProfileReferral':
                setShowProfileUser(false);
                setShowProfileDriver(false);
                setShowProfileVendor(false);
                setShowProfileAgent(false);
                setShowAddress(false);
                setShowPaymentHistory(false);
                setShowReferral(true);
                setShowTravelHistory(false);
                break;
            case 'ProfileTravelHistory':
                setShowProfileUser(false);
                setShowProfileDriver(false);
                setShowProfileVendor(false);
                setShowProfileAgent(false);
                setShowAddress(false);
                setShowPaymentHistory(false);
                setShowReferral(false);
                setShowTravelHistory(true);
                break;
            default:
                break;

        }
    }

    function loadingSwitcher(type, flag) {
        switch (type) {
            case 'profile':
                setProfileLoading(flag);
                break;
            case 'inform':
                setInformLoading(flag);
                break;
            default:
                break;
        }
    }

    async function paymentInformRequest() {
        loadingSwitcher('inform', true);
        const uid = localStorage.getItem("uid");
        await axios.get(`${baseUrl}/successfull_payment?uid=${uid}`)
            .then(res => {
                const data = res.data
                setPayResData(data);
                // console.log(`payment inform request data ${JSON.stringify(data)}`);
                loadingSwitcher('inform', false);
                // getAddress();
                setTriggerRefreshInform(old => old + 1);
            })
            .catch(err => {
                console.log(`pay inform error response ${JSON.stringify(err.response)}`);
                loadingSwitcher('inform', false);
                if (err.response !== undefined) {
                    // client received error response (5xx, 4xx)
                    // console.log(`error response ${err.response}`);
                    //   console.log(err.response);
                    if (typeof (err.response.data) === 'string') {
                        if (err.response.data.includes('Missing required data')) {
                            // console.log('refresh');
                            // refreshTokenRequest('inform');
                        }
                    }
                } else if (err.request !== undefined) {
                    // client never received a response, or a request never left
                    // console.log(`error request ${err.request}`);
                } else {
                    // console.log(`get error ${err}`);
                }
            })
    }

    async function getProfile() {
        loadingSwitcher('profile', true);
        const token = localStorage.getItem("idToken");
        await axios.post(`${baseUrl}/get_profile`, {
            token
        })
            .then(res => {
                const data = res.data
                // console.log(`profile data ${JSON.stringify(data)}`);
                setProfileData(data);
                loadingSwitcher('profile', false);
            })
            .catch(err => {
                // console.log(`error response ${err.response}`);
                // console.log(err.response);
                // profileLoading = false;
                // error = err;
                loadingSwitcher('profile', false);
                if (err.response !== undefined) {
                    // client received error response (5xx, 4xx)
                    //   console.log(`error response ${err.response}`);
                    // console.log(err.response);
                    if (typeof (err.response.data) === 'string') {
                        // console.log(`get profile error type string`);
                        if (err.response.data.includes('Firebase ID token has expired')) {
                            // console.log('refresh');
                            refreshTokenRequest('profile');
                        }
                    }
                } else if (err.request !== undefined) {
                    // client never received a response, or a request never left
                    //   console.log(`error request ${err.request}`);
                } else {
                    //   console.log(`get error ${err}`);
                }
            })
    }

    async function refreshTokenRequest(type) {
        loadingSwitcher(type, true);
        const refresh_token = localStorage.getItem("refreshToken");
        // console.log(`refresh token in profile ${refresh_token}`);
        await axios.post(`${baseUrl}/refresh_token`, {
            refresh_token
        })
            .then(res => {
                const data = res.data
                // console.log(`refresh data ${JSON.stringify(data)}`);
                localStorage.setItem("idToken", data.id_token);
                localStorage.setItem("refreshToken", data.refresh_token);
                loadingSwitcher(type, false);
                switch (type) {
                    case 'profile':
                        setTriggerRefreshProfile(old => old + 1);
                        break;
                    case 'inform':
                        setTriggerRefreshInform(old => old + 1);
                    default:
                        break;
                }
            })
            .catch(err => {
                // console.log(`error response ${err.response}`);
                // console.log(err.response);
                // profileLoading = false;
                // error = err;
                loadingSwitcher(type, false);
                if (err.response !== undefined) {
                    // client received error response (5xx, 4xx)
                    localStorage.setItem("idToken", "");
                    localStorage.setItem("customToken", "");
                    localStorage.setItem("refreshToken", "");
                    localStorage.setItem("fullName", "");
                    localStorage.setItem("username", "");
                    localStorage.setItem("userType", "");
                    localStorage.setItem("uid", "");
                    localStorage.setItem("completedRegister", "");
                    props.history.push('/');
                    alert('Session expired. Please login again.');
                    //   console.log(`error response ${err.response}`);
                    //   console.log(err.response);
                    alert(err.response.data.error.message);
                } else if (err.request !== undefined) {
                    // client never received a response, or a request never left
                    //   console.log(`error request ${err.request}`);
                } else {
                    //   console.log(`get error ${err}`);
                }
            })
    }

    function userSwitching(user) {
        switch (user) {
            case 'User':
                setCurrentMenu('ProfileUser');
                break;
            case 'Driver':
                setCurrentMenu('ProfileDriver');
                break;
            case 'Vendor':
                setCurrentMenu('ProfileVendor');
                break;
            case 'Agent':
                setCurrentMenu('ProfileAgent');
                break;
            case 'Partner':
                setCurrentMenu('ProfilePartner');
                break;
            default:
                break;

        }
    }

    function renderSideMenu(menu) {
        if (profileLoading || informLoading) {
            return (<div className="col-lg-12 profile-text-centre"><CircularProgress /></div>);
        }
        switch (menu) {
            case 'ProfileUser':
                return <ProfileUser showDiv={showProfileUser} data={profileData} onRefresh={count => setTriggerRefreshProfile(old => old + count)} />;
            case 'ProfileDriver':
                return <ProfileDriver showDiv={showProfileDriver} data={profileData} onRefresh={count => setTriggerRefreshProfile(old => old + count)} />;
            case 'ProfileVendor':
                return <ProfileVendor showDiv={showProfileVendor} data={profileData} onRefresh={count => setTriggerRefreshProfile(old => old + count)} />;
            case 'ProfileAgent': // currently same ui
                return <ProfileUser showDiv={showProfileAgent} data={profileData} onRefresh={count => setTriggerRefreshProfile(old => old + count)} />;
            case 'ProfilePartner': // currently same ui
                return <ProfileUser showDiv={showProfileAgent} data={profileData} onRefresh={count => setTriggerRefreshProfile(old => old + count)} />;
            case 'ProfileAddress':
                return <ProfileAddress showDiv={showAddress} />;
            case 'ProfilePaymentHistory':
                return <ProfilePaymentHistory showDiv={showPaymentHistory} />;
            case 'ProfileReferral':
                return <ProfileReferralHistory showDiv={showReferral} />;
            case 'ProfileTravelHistory':
                return <ProfileTravelHistory showDiv={showTravelHistory} />;
            default:
                break;
        }
    }


    return (
        <div className="blue-background">
            <div className="row">
                <div className="col-md-2" style={{ paddingRight: 0 }}>
                    <div className={`col-md-12 ${currentMenu == 'ProfileUser' || currentMenu == 'ProfileAgent' || currentMenu == 'ProfileDriver' || currentMenu == 'ProfileVendor' ? 'side-regist-container-selected' : 'side-regist-container'}`} onClick={() => userSwitching(userType)}><span>Personal</span></div>
                    <div className={`col-md-12 ${currentMenu == 'ProfileAddress' ? 'side-regist-container-selected' : 'side-regist-container'}`} onClick={() => setCurrentMenu('ProfileAddress')}><span >Address</span></div>
                    <div className={`col-md-12 ${currentMenu == 'ProfilePaymentHistory' ? 'side-regist-container-selected' : 'side-regist-container'}`} onClick={() => setCurrentMenu('ProfilePaymentHistory')}><span >Payment History</span></div>
                    <div className={`col-md-12 ${currentMenu == 'ProfileReferral' ? 'side-regist-container-selected' : 'side-regist-container'}`} onClick={() => setCurrentMenu('ProfileReferral')}><span >Referral History</span></div>
                    <div className={`col-md-12 ${currentMenu == 'ProfileTravelHistory' ? 'side-regist-container-selected' : 'side-regist-container'}`} onClick={() => setCurrentMenu('ProfileTravelHistory')}><span >Delivery History</span></div>
                </div>
                <div className="col-md-10 background-grey min-page-height">
                    {renderSideMenu(currentMenu)}
                </div>
            </div>

            {/* <DeleteAddress
                show={addressDelete}
                onHide={(flag) => setAddressDelete(flag)}
            /> */}
        </div>
    );
}

export default Profile;
