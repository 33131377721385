import React, {useEffect, useState} from "react";
import Select from 'react-select';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import accepted_grey from '../../assets/img/tapaw/accepted-grey.png';
import accepted_yellow from '../../assets/img/tapaw/accepted-yellow.png';
import assigned_grey from '../../assets/img/tapaw/assigned-grey.png';
import assigned_yellow from '../../assets/img/tapaw/assigned-yellow.png';
import ongoing_grey from '../../assets/img/tapaw/ongoing-grey.png';
import ongoing_yellow from '../../assets/img/tapaw/ongoing-yellow.png';
import completed_grey from '../../assets/img/tapaw/completed-grey.png';
import completed_yellow from '../../assets/img/tapaw/completed-yellow.png';
import { baseUrl } from "../../shared/constants";

const TrackUser = props => {
    const [loading, setLoading] = useState(false);
    const [deliveryData, setDeliveryData] = useState(null);
    const [triggerRefresh, setTriggerRefresh] = useState(false);
    const [currentTrack, setCurrentTrack] = useState(null);
    const [restructuredData, setRestructuredData] = useState(null);
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    const timerComponents = [];

    // to set time left
    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         setTimeLeft(calculateTimeLeft());
    //     }, 1000);
        
    //     return () => clearTimeout();
    // }, [currentTrack]);

    useEffect(() => {
        setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);
    });

    // useEffect(() => {
    //     if (currentTrack) {
    //         console.log(`created date at ${new Date(currentTrack.created._seconds*1000)}`);
    //     }
    // }, [currentTrack]);

    useEffect(() => {
        getDeliveryHistory();

        scheduler();
    }, [triggerRefresh]);

    useEffect(() => {
        restructureDeliveryData();
    }, [deliveryData]);

    // useEffect(() => {
    //     console.log(`delivery data ${JSON.stringify(deliveryData)}`);
    // }, [deliveryData]);

    // useEffect(() => {
    //     console.log(`current track is ${JSON.stringify(currentTrack)}`)
    // }, [currentTrack]);

    function scheduler() {
        // repeat every 5 minutes (1 seconds = 1000), 1000x60x5=300000
        const interval = setInterval(() => {
            // console.log('get delivery history');
            getDeliveryHistory();
        }, 300000);
        return () => clearInterval(interval);
    } 

    async function getDeliveryHistory() {
        // console.log('run get delivery history');
        setLoading(true);
        const token = localStorage.getItem("idToken");
        await axios.post(`${baseUrl}/get_order`, {
            token
        })
            .then(res => {
                const data = res.data
                // console.log(`delivery history data in tracking ${JSON.stringify(data)}`);
                setLoading(false);
                setDeliveryData(data)
            })
            .catch(err => {
                // console.log(`error response ${err.response}`);
                setLoading(false);
                // loading = false;
                // error = err;
                if (err.response!==undefined) {
                    // client received error response (5xx, 4xx)
                    // console.log(`error response ${err.response}`);
                    //   console.log(err.response);
                    if (err.response.data.message!==undefined && typeof(err.response.data.message) === 'string') {
                      if (err.response.data.message.includes('Firebase ID token has expired')) {
                        //   console.log('refresh');
                          refreshTokenRequest();
                      }
                    }
                  } else if (err.request!==undefined) {
                    // client never received a response, or a request never left
                    // console.log(`error request ${err.request}`);
                  } else {
                    // console.log(`get error ${err}`);
                  }
            })
    }

    async function cancelOrderRequest(id) {
        // console.log('run get delivery history');
        setLoading(true);
        const token = localStorage.getItem("idToken");
        await axios.post(`${baseUrl}/cancel_order`, {
            token,
            order_id: id
        })
            .then(res => {
                const data = res.data
                // console.log(`data in successfull cancel order ${JSON.stringify(data)}`);
                setLoading(false);
                setTriggerRefresh(old => old+1);
            })
            .catch(err => {
                // console.log(`error response ${err.response}`);
                setLoading(false);
                // loading = false;
                // error = err;
                if (err.response!==undefined) {
                    // client received error response (5xx, 4xx)
                    // console.log(`error response ${err.response}`);
                    //   console.log(err.response);
                    if (err.response.data.message!==undefined && typeof(err.response.data.message) === 'string') {
                      if (err.response.data.message.includes('Firebase ID token has expired')) {
                        //   console.log('refresh');
                          refreshTokenRequest();
                      }
                    }
                  } else if (err.request!==undefined) {
                    // client never received a response, or a request never left
                    // console.log(`error request ${err.request}`);
                  } else {
                    // console.log(`get error ${err}`);
                  }
            })
    }

    async function refreshTokenRequest() {
        setLoading(true);
        const refresh_token = localStorage.getItem("refreshToken");
        // console.log(`refresh token in purchase history ${refresh_token}`);
        await axios.post(`${baseUrl}/refresh_token`, {
            refresh_token
        })
            .then(res => {
                const data = res.data
                // console.log(`refresh data ${JSON.stringify(data)}`);
                localStorage.setItem("idToken", data.id_token);
                localStorage.setItem("refreshToken", data.refresh_token);
                setLoading(false);
                setTriggerRefresh(old => old+1);
            })
            .catch(err => {
                // console.log(`error response ${err.response}`);
                // console.log(err.response);
                // loading = false;
                // error = err;
                setLoading(false);
                if (err.response!==undefined) {
                  // client received error response (5xx, 4xx)
                    localStorage.setItem("idToken", "");
                    localStorage.setItem("customToken", "");
                    localStorage.setItem("refreshToken", "");
                    localStorage.setItem("fullName", "");
                    localStorage.setItem("username", "");
                    localStorage.setItem("userType", "");
                    localStorage.setItem("uid", "");
                    localStorage.setItem("completedRegister", "");
                    props.history.push('/');
                    alert('Session expired. Please login again.');
                //   console.log(`error response ${err.response}`);
                //   console.log(err.response);
                } else if (err.request!==undefined) {
                  // client never received a response, or a request never left
                //   console.log(`error request ${err.request}`);
                } else {
                //   console.log(`get error ${err}`);
                }
            })
    }

    function restructureDeliveryData() {
        if (deliveryData !== null && deliveryData !== 'No Order') {
            let temp = [...deliveryData];
            // assuming the latest order is the last array
            temp.reverse();
            let newArr = [];
            for (let i=0; i<temp.length; i++) {
                if (i<5) {
                    newArr.push(temp[i]);
                }
            }
            let modifiedArr = [];
            newArr.forEach(a => {
                modifiedArr.push({label: a.orderID, value: a})
            });
            setRestructuredData(modifiedArr);
            setCurrentTrack(modifiedArr[0].value);
        }
    }

    function calculateTimeLeft() {
        // console.log('calculate time left');
        // let year = new Date().getFullYear();

        /* +new Date() is short form to tell js to cast object as integer */
        // const difference = +new Date(`7/11/${year}`) - +new Date();

        let timeLeft = {};

        if (currentTrack!==null && currentTrack.created!==undefined) {
            /* created is in firebase timestamp format */
            const createdDate = +new Date(currentTrack.created._seconds*1000);
            /* 5 minutes = 5 * 60 * 1000 = 300000; */
            const fiveMinPassed = createdDate + 300000;
            const difference = fiveMinPassed - +new Date();
            // console.log(`difference ${difference}`);
            if (difference > 0) {
                timeLeft = {
                    days: Math.floor( difference / (1000 * 60 * 60 * 24) ),
                    hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                    minutes: Math.floor((difference / 1000 / 60) % 60),
                    seconds: Math.floor((difference / 1000) % 60)
                };
            }
        }

        return timeLeft;
    }

    Object.keys(timeLeft).forEach(interval => {
        if (!timeLeft[interval]) {
            return;
        }

        timerComponents.push(
            <span>
                {timeLeft[interval]} {interval}{" "}
            </span>
        );
    });



    function renderSelect() {
        if (restructuredData !== null && restructuredData !== 'No Order') {
            return (
                <Select
                    options={restructuredData}
                    onChange={b => setCurrentTrack(b.value)}
                    // onChange={a => console.log(`track label ${a.label}, track value: ${a.value}`)}
                />
            );
        }
    }

    function renderProgress() {
        if (loading) {
            return <div className="col-md-12 profile-text-centre"><CircularProgress /></div>;
        }
        if (currentTrack != null) {
            switch(currentTrack.status) {
                case 'new':
                    return(
                        <div className="row justify-content-center padding-track">
                            <img src={accepted_yellow} style={{ width: '10%', height: '100%' }} />
                            <img src={assigned_grey} style={{ width: '30%', height: '100%' }} />
                            <img src={ongoing_grey} style={{ width: '30%', height: '100%' }} />
                            <img src={completed_grey} style={{ width: '30%', height: '100%' }} />
                        </div>
                    );
                case 'cancelled':
                    return(
                        <div className="row justify-content-center padding-track">
                            <img src={accepted_grey} style={{ width: '10%', height: '100%' }} />
                            <img src={assigned_grey} style={{ width: '30%', height: '100%' }} />
                            <img src={ongoing_grey} style={{ width: '30%', height: '100%' }} />
                            <img src={completed_grey} style={{ width: '30%', height: '100%' }} />
                        </div>
                    );
                case 'assigned':
                    return (
                        <div className="row justify-content-center padding-track">
                            <img src={accepted_yellow} style={{ width: '10%', height: '100%' }} />
                            <img src={assigned_yellow} style={{ width: '30%', height: '100%' }} />
                            <img src={ongoing_grey} style={{ width: '30%', height: '100%' }} />
                            <img src={completed_grey} style={{ width: '30%', height: '100%' }} />
                        </div>
                    );
                case 'ongoing':
                    return (
                        <div className="row justify-content-center padding-track">
                            <img src={accepted_yellow} style={{ width: '10%', height: '100%' }} />
                            <img src={assigned_yellow} style={{ width: '30%', height: '100%' }} />
                            <img src={ongoing_yellow} style={{ width: '30%', height: '100%' }} />
                            <img src={completed_grey} style={{ width: '30%', height: '100%' }} />
                        </div>
                    );
                case 'completed':
                    return(
                        <div className="row justify-content-center padding-track">
                            <img src={accepted_yellow} style={{ width: '10%', height: '100%' }} />
                            <img src={assigned_yellow} style={{ width: '30%', height: '100%' }} />
                            <img src={ongoing_yellow} style={{ width: '30%', height: '100%' }} />
                            <img src={completed_yellow} style={{ width: '30%', height: '100%' }} />
                        </div>
                    );
                default:
                    break;
            }
        }
        return (<div className="col-md-12 profile-text-centre dark-blue-text">No tracking data available</div>);
    } 

    function renderInfo() {
        if (loading) {
            return <div className="col-md-12 profile-text-centre"><CircularProgress /></div>;
        } 
        if (currentTrack != null) {
            return(
                <div>
                    <div className="row justify-content-center" style={{ paddingLeft: 10, paddingRight: 20 }}>
                        <div className="col-3 text-justify">
                            <p className="no-padding-no-margin">{currentTrack.status === 'cancelled'?'Order Cancelled':'Order'}</p>
                            <p className="no-padding-no-margin">{currentTrack.orderDate?(new Date(currentTrack.orderDate._seconds * 1000)).toDateString() : ''}</p>
                            <p className="no-padding-no-margin bold-text">{currentTrack.status} RM{currentTrack.totalFare}</p>
                        </div>
                        <div className="col-3">
                            <p style={{ textAlign: 'center' }}>Rider Assigned</p>
                        </div>
                        <div className="col-3">
                            <p style={{ textAlign: 'end' }}>Driver Ongoing</p>
                        </div>
                        <div className="col-3">
                            <p style={{ textAlign: 'end' }}>Completed</p>
                        </div>
                    </div>
                    <div className="row" style={{paddingRight: '20px'}}>
                        <div className="col-md-12 profile-text-right">
                            {(timerComponents.length && currentTrack.status!=='cancelled')?timerComponents: <span></span>}
                            {(timerComponents.length && currentTrack.status!=='cancelled')?
                                <button 
                                className="text-white cursor" 
                                style={{
                                backgroundColor: 'red',
                                float: 'right',
                                borderRadius: 5, paddingTop: 5, paddingBottom: 5, paddingLeft: 10, paddingRight: 10, 
                                }}
                                onClick={() => cancelOrderRequest(currentTrack.orderID)}
                                >
                                    Cancel Order
                                </button>
                                : <div/>
                            }
                        </div>
                    </div>
                    <div className={`row padding-pengesahan-top ${(currentTrack.status==='assigned') || (currentTrack.status==='ongoing') ? 'show-stuff':'hide-stuff'}`}>
                        <div className="col-md-12">
                            <div className="track-address-box">
                                <div className="col-md-12 white-text"><span className="bold-text">Rider's Info</span></div>
                                <div className="col-md-12 white-text"><span>{currentTrack.driver.fullName}</span></div>
                                <div className="col-md-12 white-text"><span>{currentTrack.driver.phoneNumber}</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="row padding-pengesahan-top">
                        <div className="col-md-12">
                            <div className="track-address-box">
                                <div className="col-md-12 white-text"><span className="bold-text">Delivery Location</span></div>
                                <div className="col-md-12 white-text"><span>{currentTrack.dropoffLocation.contactName}</span></div>
                                <div className="col-md-12 white-text"><span>{currentTrack.dropoffLocation.contactPhoneNo}</span></div>
                                <div className="col-md-12 white-text"><span>{currentTrack.dropoffLocation.address}</span></div>
                            </div>
                        </div>
                        <div className={`col-md-12 ${currentTrack.stopLocation1.address!==''?'show-stuff':'hide-stuff'}`} style={{marginTop: '10px'}}>
                            <div className="track-address-box">
                                <div className="col-md-12 white-text"><span className="bold-text">Stop Location 1</span></div>
                                <div className="col-md-12 white-text"><span>{currentTrack.stopLocation1.address!==''?currentTrack.stopLocation1.contactName:''}</span></div>
                                <div className="col-md-12 white-text"><span>{currentTrack.stopLocation1.address!==''?currentTrack.stopLocation1.contactPhoneNo:''}</span></div>
                                <div className="col-md-12 white-text"><span>{currentTrack.stopLocation1.address!==''?currentTrack.stopLocation1.address:''}</span></div>
                            </div>
                        </div>
                        <div className={`col-md-12 ${currentTrack.stopLocation2.address!==''?'show-stuff':'hide-stuff'}`} style={{marginTop: '10px'}}>
                            <div className="track-address-box">
                                <div className="col-md-12 white-text"><span className="bold-text">Stop Location 2</span></div>
                                <div className="col-md-12 white-text"><span>{currentTrack.stopLocation2.address!==''?currentTrack.stopLocation2.contactName:''}</span></div>
                                <div className="col-md-12 white-text"><span>{currentTrack.stopLocation2.address!==''?currentTrack.stopLocation2.contactPhoneNo:''}</span></div>
                                <div className="col-md-12 white-text"><span>{currentTrack.stopLocation2.address!==''?currentTrack.stopLocation2.address:''}</span></div>
                            </div>
                        </div>
                        <div className={`col-md-12 ${currentTrack.stopLocation3.address!==''?'show-stuff':'hide-stuff'}`} style={{marginTop: '10px'}}>
                            <div className="track-address-box">
                                <div className="col-md-12 white-text"><span className="bold-text">Stop Location 3</span></div>
                                <div className="col-md-12 white-text"><span>{currentTrack.stopLocation3.address!==''?currentTrack.stopLocation3.contactName:''}</span></div>
                                <div className="col-md-12 white-text"><span>{currentTrack.stopLocation3.address!==''?currentTrack.stopLocation3.contactPhoneNo:''}</span></div>
                                <div className="col-md-12 white-text"><span>{currentTrack.stopLocation3.address!==''?currentTrack.stopLocation3.address:''}</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }

    return (
        <div className="blue-background" style={{ paddingBottom: 100, marginBottom: -75 }}>
            <div className="section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10">
                            <div className="row">
                                <div className="col-md-5" style={{ marginBottom: '32px'}}>
                                    {renderSelect()}
                                </div>
                                <div className="col-md-12 confirmation-box">
                                    {renderProgress()}
                                    {renderInfo()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TrackUser;
