import React, {useEffect, useState} from 'react';
import { withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import { baseUrl } from '../../../shared/constants';

const ReturnToProfile = (props) => {
    const [loading, setLoading] = useState(false);
    const [triggerRefresh, setTriggerRefresh] = useState(0);
    const [payResData, setPayResData] = useState(null);

    useEffect(() => {
        paymentInformRequest();
    }, []);

    async function paymentInformRequest() {
        setLoading(true);
        const uid = localStorage.getItem("uid");
        await axios.get(`${baseUrl}/successfull_payment?uid=${uid}`)
            .then(res => {
                const data = res.data
                setPayResData(data);
                // console.log(`payment inform request data ${JSON.stringify(data)}`);
                setLoading(false);
                // getAddress();
                setTriggerRefresh(old => old+1);
            })
            .catch(err => {
                // console.log(`pay inform error response ${JSON.stringify(err.response)}`);
                setLoading(false);
                if (err.response!==undefined) {
                    // client received error response (5xx, 4xx)
                    // console.log(`error response ${err.response}`);
                    //   console.log(err.response);
                    if (err.response.data != undefined) {
                        if (err.response.data.includes('Missing required data')) {
                            // console.log('refresh');
                            // refreshTokenRequest();
                        }
                    }
                  } else if (err.request!==undefined) {
                    // client never received a response, or a request never left
                    // console.log(`error request ${err.request}`);
                  } else {
                    // console.log(`get error ${err}`);
                  }
            })
    }

    async function refreshTokenRequest() {
        setLoading(true);
        const refresh_token = localStorage.getItem("refreshToken");
        // console.log(`refresh token in profile ${refresh_token}`);
        await axios.post(`${baseUrl}/refresh_token`, {
            refresh_token
        })
            .then(res => {
                const data = res.data
                // console.log(`refresh data ${JSON.stringify(data)}`);
                localStorage.setItem("idToken", data.id_token);
                localStorage.setItem("refreshToken", data.refresh_token);
                setLoading(false);
                setTriggerRefresh(old => old+1);
            })
            .catch(err => {
                // console.log(`error response ${err.response}`);
                // console.log(err.response);
                // loading = false;
                // error = err;
                setLoading(false);
                if (err.response!==undefined) {
                  // client received error response (5xx, 4xx)
                    localStorage.setItem("idToken", "");
                    localStorage.setItem("customToken", "");
                    localStorage.setItem("refreshToken", "");
                    localStorage.setItem("fullName", "");
                    localStorage.setItem("username", "");
                    localStorage.setItem("userType", "");
                    localStorage.setItem("uid", "");
                    localStorage.setItem("completedRegister", "");
                    props.history.push('/');
                    alert('Session expired. Please login again.');
                //   console.log(`error response ${err.response}`);
                //   console.log(err.response);
                } else if (err.request!==undefined) {
                  // client never received a response, or a request never left
                //   console.log(`error request ${err.request}`);
                } else {
                //   console.log(`get error ${err}`);
                }
            })
    }

    function renderText(data) {
        if (loading) {
            return (<div className="col-lg-12"><div className="col-lg-12 profile-text-centre"><CircularProgress /></div></div>);
        }
        if (data != null && data != undefined && data.includes('success wallet update')) {
            return (<div className="col-lg-12">
                <div className="col-lg-12 profile-text-centre">
                    <h5>Transaction Success</h5>
                </div>
                <div className="col-lg-12">
                    <div className="col-lg-12 profile-text-centre">
                        <label>Return to profile</label>
                    </div>
                </div> 
            </div>);
        }
        if (data != null && data != undefined && data.includes('pending wallet update ')) {
            return (<div className="col-lg-12">
                <div className="col-lg-12 profile-text-centre">
                    <h5>Transaction Pending. Please refresh your profile page after 30 minutes.</h5>
                </div>
                <div className="col-lg-12">
                    <div className="col-lg-12 profile-text-centre">
                        <label>Return to profile</label>
                    </div>
                </div> 
            </div>);
        } else {
            return (<div className="col-lg-12">
                <div className="col-lg-12 profile-text-centre">
                    <h5>Transaction Fail</h5>
                </div>
                <div className="col-lg-12">
                    <div className="col-lg-12 profile-text-centre">
                        <label>Return to profile</label>
                    </div>
                </div> 
            </div>);
        }
    }

    return (
        <div className="row" style={{minHeight: '500px', paddingTop: '100px', paddingBottom: '100px'}}>
            {renderText(payResData)}
            {/* <div className="col-lg-12 profile-text-centre">
                <h5>Return to profile page</h5>
            </div> */}
            {/* <div className="col-lg-12">
                <div className="form-group">
                    <label>Successfully top up RM{props.topup} in your wallet</label>
                </div>
            </div> */}
            <div className="col-lg-12">
                <div className="row">
                    <div className="col-lg-6 offset-lg-3">
                        <button type="button" className="tempah-sekarang" onClick={() => {props.history.push("/profile")}}>
                            CONFIRM
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(ReturnToProfile);