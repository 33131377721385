import React, { useState } from 'react';
const { Provider, Consumer } = React.createContext();

const ContextProvider = props => {
    const [loginToken, setLoginToken] = useState(null);
    const [refreshToken, setRefreshToken] = useState(null);

    return <Provider value={{ loginT: [loginToken, setLoginToken], refreshT: [refreshToken, setRefreshToken] }} >{props.children}</Provider>
}

export { ContextProvider, Consumer as ContextConsumer };
