import React from 'react';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

const DeleteAddress = (props) => {

    function confirmFunction () {
        props.deletefunc(); 
        props.onHide(false);
    }

    return (
        <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // dialogClassName="modal-rounded"
        >
            
            <Modal.Body className="modal-content">
                <form method="post">
                    <div className="col-lg-12">
                        <h4>ATTENTION</h4>
                    </div>
                    <div className="col-lg-12">
                        <div className="form-group">
                            <label>Are you sure to delete this address</label>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-lg-6">
                                <button type="button" className="tempah-sekarang cancel-profile" onClick={() => {props.history.push("/profile"); props.onHide(false)}}>
                                    CANCEL
                                </button>
                            </div>
                            <div className="col-lg-6">
                                <button type="button" className="tempah-sekarang" onClick={() => confirmFunction()}>
                                    CONFIRM
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default withRouter(DeleteAddress);