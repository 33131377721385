import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import logo from '../../assets/img/tapaw/logo.png';
import logo_at_footer from '../../assets/img/logo-at-footer.svg';
import Login from "../modals/login";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PersonIcon from '@material-ui/icons/Person';
import MenuIcon from '@material-ui/icons/Menu';
import footer_transition from '../../assets/img/footer-transition.png';
import footer_transition_transparent from '../../assets/img/footer-transition-transparent.png';
import footer_transition2 from '../../assets/img/footer-transition2.jpg';
import footerIcon1 from '../../assets/img/footer-icons/footer-icon-location.png';
import footerIcon2 from '../../assets/img/footer-icons/footer-icon-mail.png';
import footerIcon3 from '../../assets/img/footer-icons/footer-icon-call.png';
import { wpBase } from "../../shared/constants";
import axios from "axios";
import { useLocation } from 'react-router-dom';
import Header from "./header";
import Footer from "./footer";
import Navbar from "./head/navbar";
import { DesktopNav, MobileNav } from "./head/styles";

const Body = (props) => {
  const location = useLocation();
  const [modalShow, setModalShow] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [wpData, setWpData] = useState([]);
  const [mobileClicked, setMobileClicked] = useState(false);

  useEffect(()=> {
    allNewsRequest();
  },[]);

  // useEffect(() => {
  //   console.log(`location ${JSON.stringify(location)}`);
  // }, []);

  // useEffect(() => {
  //   console.log(`mobile clicked ${mobileClicked}`)
  // }, [mobileClicked]);

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  // const goProfile = () => {
  //   setAnchorEl(null);
  //   const userType = localStorage.getItem("userType");
  //   const completedRegister = localStorage.getItem("completedRegister");
  //   // console.log(`user type ${userType}, completed register ${completedRegister}, completed register type ${typeof(completedRegister)}`);
  //   // check if driver/vendor completed 2nd part registration, if not yet, go to 2nd part registration
  //   if (userType.toLowerCase() === 'user' || userType.toLowerCase() === 'agent') {
  //     // console.log('go to profile');
  //       props.history.push("/profile");
  //   } else if (userType.toLowerCase() === 'driver' || userType.toLowerCase() === 'vendor') {
  //     if (completedRegister==='true') {
  //       // console.log('completed true');
  //       props.history.push("/profile");
  //     } else {
  //       if(userType.toLowerCase()==='vendor') props.history.push({pathname:'/register', state:{vendorEdit: true}});
  //       if(userType.toLowerCase()==='driver') props.history.push({pathname:'/register', state:{driverEdit: true}});
  //     }
  //   }
  // };
  // const goLogout = () => {
  //   setAnchorEl(null);
  //   localStorage.setItem("idToken", "");
  //   localStorage.setItem("customToken", "");
  //   localStorage.setItem("refreshToken", "");
  //   localStorage.setItem("fullName", "");
  //   localStorage.setItem("username", "");
  //   localStorage.setItem("userType", "");
  //   localStorage.setItem("uid", "");
  //   localStorage.setItem("completedRegister", "");
  //   window.location.href = "/";
  // };

  async function allNewsRequest () {
    let data = null;
    let error = '';
    let loading = true;
    
    await axios.get(`${wpBase}/posts?page=1`)
    .then(res => {
        // console.log('get data')
        loading = false;
        data = res.data;
        // console.log(`all news data ${JSON.stringify(data)}`);
        let arr = [];
        if(data.length > 2) {
          arr.push(data[data.length - 1]);
          arr.push(data[data.length - 2]);
        } else if(data.length === 2) {
          arr.push(data[data.length - 1]);
          arr.push(data[data.length - 2]);
        } else if(data.length < 2) {
          arr.push(data[data.length - 1]);
        }
        
        setWpData(arr);
        // console.log(`arr ${JSON.stringify(arr)}`);
    })
    .catch(err => {
        loading = false;
        error = err;
        if (err.response!==undefined) {
            // client received error response (5xx, 4xx)
            // console.log(`error response ${err.response}`);
        } else if(err.request!==undefined) {
            // client never received a response, or a request never left
            // console.log(`error request ${err.request}`);
        } else {
            // console.log(`get error ${err}`);
        }
    })
    // .finally(() => loading = false)

    // console.log(`loading ${loading}`);
    // console.log(`data ${data}`);
  }
  
  const checkFooter = () => {
    if (location.pathname !== '/profile' && location.pathname !== '/trackuser') {
      return (
        <Footer />
      );
    }
  }
  return (
    <div>
      <DesktopNav>
        <Header signInCallback={() => setModalShow(true)}/>
      </DesktopNav>

      <MobileNav>
        <Navbar />
      </MobileNav>
      <div>
        {props.children}
      </div>
      <Login
        show={modalShow}
        onHide={(flag) => setModalShow(flag)}
      />
      {checkFooter()}
    </div>
  );
}

export default withRouter(Body);
