import React, {useState, useEffect} from 'react';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import { baseUrl } from '../../shared/constants';
import AddAddress from './add_address';
import DeleteAddress from '../modals/delete_address';
import downloadImg from '../../assets/img/tapaw/download.png';

const ProfileAddress = props => {
    const [addressDelete, setAddressDelete] = useState(false);
    const [expandedAddress, setExpandedAddress] = useState(false);
    const [addressData, setAddressData] = useState([]);
    const [idDeleted, setIdDeleted] = useState(null);
    const [idUpdated, setIdUpdated] = useState(null);
    const [loading, setLoading] = useState(false);
    const [triggerRefresh, setTriggerRefresh] = useState(0);

    // useEffect(() => {
    //     getAddress();
    // }, []);

    // useEffect(() => {
    //     console.log('address is changing');
    // }, [addressData]);

    // useEffect(() => {
    //     console.log('id deleted is changing');
    // }, [idDeleted]);

    useEffect(() => {
        getAddress();
    }, [triggerRefresh]);

    async function getAddress() {
        // console.log('run get address')
        setLoading(true);
        const token = localStorage.getItem("idToken");
        await axios.post(`${baseUrl}/get_address`, {
            token
        })
            .then(res => {
                const data = res.data;
                setLoading(false);
                // console.log(`address data ${JSON.stringify(data)}`);
                setAddressData(data)
            })
            .catch(err => {
                setLoading(false);
                // console.log(`error response ${err.response}`);
                if (err.response!==undefined) {
                    // client received error response (5xx, 4xx)
                    // console.log(`error response ${err.response}`);
                    //   console.log(err.response);
                    if (err.response.data.message != undefined) {
                        if (err.response.data.message.includes('Firebase ID token has expired')) {
                            // console.log('refresh');
                            refreshTokenRequest();
                        }
                    }
                  } else if (err.request!==undefined) {
                    // client never received a response, or a request never left
                    // console.log(`error request ${err.request}`);
                  } else {
                    // console.log(`get error ${err}`);
                  }
            })
    }

    function addFunction (data) {
        // setExpandedAddress(!expandedAddress);        
        // console.log('run add function');
        // console.log(data);
        addRequest(data);
    }

    async function addRequest(data) {
        setLoading(true);
        const token = localStorage.getItem("idToken");
        await axios.post(`${baseUrl}/update_address`, {
            token,
            address: {
                "contactName" : data.contact,
                "contactPhoneNo" : data.phone,
                "type" : "pickup",
                "address" : data.address,
                "latitude" : data.lat,
                "longitude" : data.lng,
                "primary" : false,
                "additionalInfo": data.info,
            }
        })
            .then(res => {
                const data = res.data
                // console.log(`added data ${JSON.stringify(data)}`);
                setLoading(false);
                // getAddress();
                setTriggerRefresh(old => old+1);
            })
            .catch(err => {
                // console.log(`error response ${err.response}`);
                // console.log(err.response);
                // loading = false;
                // error = err;
                setLoading(false);
                if (err.response!==undefined) {
                  // client received error response (5xx, 4xx)
                //   console.log(`error response ${err.response}`);
                    // console.log(err.response);
                    if (err.response.data.message != undefined) {
                        if (err.response.data.message.includes('Firebase ID token has expired')) {
                            // console.log('refresh');
                            refreshTokenRequest();
                        }
                    }
                } else if (err.request!==undefined) {
                  // client never received a response, or a request never left
                //   console.log(`error request ${err.request}`);
                } else {
                //   console.log(`get error ${err}`);
                }
            })
    }
    
    function deleteAddressFlag (id) {
        // console.log(`delete id ${id}`);
        setIdDeleted(id);
        setAddressDelete(true); // open modal
    }

    function deleteFunction () {
        // console.log('run delete function');
        deleteRequest();
    }

    async function deleteRequest() {
        setLoading(true);
        const token = localStorage.getItem("idToken");
        await axios.post(`${baseUrl}/update_address`, {
            token,
            address: {
                id: idDeleted
            }
        })
            .then(res => {
                const data = res.data
                // console.log(`deleted data ${JSON.stringify(data)}`);
                setLoading(false);
                // getAddress();
                setTriggerRefresh(old => old+1);
            })
            .catch(err => {
                // console.log(`error response ${err.response}`);
                // console.log(err.response);
                // loading = false;
                // error = err;
                setLoading(false);
                if (err.response!==undefined) {
                  // client received error response (5xx, 4xx)
                //   console.log(`error response ${err.response}`);
                    // console.log(err.response);
                    if (err.response.data.message != undefined) {
                        if (err.response.data.message.includes('Firebase ID token has expired')) {
                            // console.log('refresh');
                            refreshTokenRequest();
                        }
                    }
                } else if (err.request!==undefined) {
                  // client never received a response, or a request never left
                //   console.log(`error request ${err.request}`);
                } else {
                //   console.log(`get error ${err}`);
                }
            })
    }

    async function setDefaultAddressRequest(data) {
        setLoading(true);
        const token = localStorage.getItem("idToken");
        console.log(`token ${token}, id ${data.id}, address ${data.address}`);
        await axios.post(`${baseUrl}/update_address`, {
            token,
            address: {
                id: data.id,
                primary: true,
                address: data.address,
                // latitude: data.latlng._latitude,
                // longitude: data.latlng._longitude
            }
        })
            .then(res => {
                const data = res.data
                console.log(`set default address data ${JSON.stringify(data)}`);
                setLoading(false);
                // getAddress();
                setTriggerRefresh(old => old+1);
            })
            .catch(err => {
                // console.log(`error response set default address ${JSON.stringify(err.response)}`);
                console.log(err.response);
                setLoading(false);
                if (err.response!==undefined) {
                    // client received error response (5xx, 4xx)
                    // console.log(`error response ${err.response}`);
                    //   console.log(err.response);
                    if (err.response.data.message !== undefined && typeof(err.response.data.message) === 'string') {
                        if (err.response.data.message.includes('Firebase ID token has expired')) {
                            // console.log('refresh');
                            refreshTokenRequest();
                        }
                    }
                  } else if (err.request!==undefined) {
                    // client never received a response, or a request never left
                    // console.log(`error request ${err.request}`);
                  } else {
                    // console.log(`get error ${err}`);
                  }
            })
    }

    async function refreshTokenRequest() {
        setLoading(true);
        const refresh_token = localStorage.getItem("refreshToken");
        // console.log(`refresh token in profile ${refresh_token}`);
        await axios.post(`${baseUrl}/refresh_token`, {
            refresh_token
        })
            .then(res => {
                const data = res.data
                // console.log(`refresh data ${JSON.stringify(data)}`);
                localStorage.setItem("idToken", data.id_token);
                localStorage.setItem("refreshToken", data.refresh_token);
                setLoading(false);
                setTriggerRefresh(old => old+1);
            })
            .catch(err => {
                // console.log(`error response ${err.response}`);
                // console.log(err.response);
                // loading = false;
                // error = err;
                setLoading(false);
                if (err.response!==undefined) {
                  // client received error response (5xx, 4xx)
                    localStorage.setItem("idToken", "");
                    localStorage.setItem("customToken", "");
                    localStorage.setItem("refreshToken", "");
                    localStorage.setItem("fullName", "");
                    localStorage.setItem("username", "");
                    localStorage.setItem("userType", "");
                    localStorage.setItem("uid", "");
                    localStorage.setItem("completedRegister", "");
                    props.history.push('/');
                    alert('Session expired. Please login again.');
                //   console.log(`error response ${err.response}`);
                //   console.log(err.response);
                } else if (err.request!==undefined) {
                  // client never received a response, or a request never left
                //   console.log(`error request ${err.request}`);
                } else {
                //   console.log(`get error ${err}`);
                }
            })
    }

    return(
        <div className={`${props.showDiv==true?'show-stuff':'hide-stuff'}`}>
            <div className="row justify-content-center padding-top-bottom-50">
                <div className="col-md-11">
                    {/* <form method="post"> */}
                        <div className="border-radius-15 shadow-header">
                            <div className="row">
                                <div className="col-md-12 profile-box-top">
                                    <span>Address Information</span>
                                </div>
                                <div className="col-md-12 profile-box-bottom">
                                    { loading?<div className="row"><div className="col-md-12 profile-text-centre"><CircularProgress /></div></div>:
                                    addressData.map(a => <div key={a.id}>
                                        <div className="row">
                                            <div className="col-md-8">
                                                <div className="row profile-multiple-text">
                                                    <div className="col-md-6" onClick={() => setDefaultAddressRequest(a)} >
                                                        <input type="radio" name="address" id="address" value={a.primary} checked={a.primary} onChange={() => console.log('placeholder')} />
                                                        <label>{a.contactName}</label>
                                                    </div>
                                                    <div className="col-md-6" >
                                                        <span>{a.address}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 profile-text-right">
                                                <span className="profile-box-underlined blue-text" onClick={() => deleteAddressFlag(a.id)}>REMOVE</span>
                                            </div>
                                        </div>
                                        <hr />
                                    </div>
                                    )
                                    }
                                    <span className="blue-text" onClick={() => setExpandedAddress(!expandedAddress)}>+ ADD ADDRESS</span>
                                    <div className={`col-md-12 ${expandedAddress ? `expandedSpace` : `normalSpace`}`}>
                                        <AddAddress
                                            expandSpace={flag => setExpandedAddress(flag)}
                                            addFunc={addFunction}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    {/* </form> */}
                </div>
            </div>
            <DeleteAddress
                show={addressDelete}
                onHide={(flag) => setAddressDelete(flag)}
                deletefunc={deleteFunction}
            />
        </div>
    );
}

export default ProfileAddress;