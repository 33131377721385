import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from 'axios';
// import service1 from '../../assets/img/services/xl/1.jpg';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel as Carousel2 } from 'react-responsive-carousel';
import DateFnsUtils from '@date-io/date-fns';
import {
  DatePicker,
  TimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import CircularProgress from '@material-ui/core/CircularProgress';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { Checkbox, FormControlLabel, MuiThemeProvider } from '@material-ui/core';
import lodash from 'lodash';
import { PlusCircleIcon, NoEntryIcon } from '@primer/octicons-react';
import vehicle1 from '../../assets/img/vehicles/penghataran-motor.png';
import vehicle2 from '../../assets/img/vehicles/penghantaran-kereta.png';
import vehicle3 from '../../assets/img/vehicles/penghantaran-ranger.png';
import vehicle4 from '../../assets/img/vehicles/penghatantara-van.png';
import vehicle5 from '../../assets/img/vehicles/penghantaran-lori.png';
import vehicle6 from '../../assets/img/vehicles/penghantaran-lori-3-tan.png';
import main2 from '../../assets/img/tapaw-app-image.png';
import google_play from '../../assets/img/tapaw-google-play.png';
import huawei from '../../assets/img/tapaw-gallery.png';
import apple from '../../assets/img/tapaw-appstore.png';
// import logo from '../../assets/img/tapaw/logo.png';
import logo_at_footer from '../../assets/img/logo-at-footer.svg';
// import veggie from '../../assets/img/tapaw/extra2/vege.png';
// import mainbanner from '../../assets/img/tapaw/design2.jpg';
import minibanner from '../../assets/img/tapaw/extra2/mini_banner.png';
import services1 from '../../assets/img/tapaw/document.png';
import services2 from '../../assets/img/tapaw/delivery.png';
import services3 from '../../assets/img/tapaw/beef.png';
import services4 from '../../assets/img/tapaw/supermarket.png';
import services5 from '../../assets/img/tapaw/veggie.png';
import s1 from '../../assets/img/icons_services/express.png';
import s2 from '../../assets/img/icons_services/box.png';
import s3 from '../../assets/img/icons_services/tracking.png';
import s4 from '../../assets/img/icons_services/delivery.png';
import s5 from '../../assets/img/icons_services/packages.png';
import s6 from '../../assets/img/icons_services/warehouse.png';
import banner1 from '../../assets/img/banner/banner1.jpg';
import banner2 from '../../assets/img/banner/banner2.jpg';
import banner3 from '../../assets/img/banner/banner3.jpg';
import banner4 from '../../assets/img/banner/banner4.jpg';
import form_header from '../../assets/img/form-header.png';
// import transition_our_products from '../../assets/img/transition-our-products-2-1.jpeg';
// import transition_products2 from '../../assets/img/transition-products2.png';
// import transition_tapaw_semua from '../../assets/img/transition-tapaw-semua.png';
// import formImg from '../../assets/img/tapaw/order-form.png';
import { appStoreLink, baseUrl, googlePlayLink, huaweiStoreLink } from "../../shared/constants";
import { customTheme } from './picker_theme';
import AddAddress from '../profile/add_address';
import Login from '../modals/login';

const items = [
  { id: 1, name: 'Express Shipping', img: s1, icon: 'icon-box', desc: 'Instant delivery, quick and precise to suit your needs.' },
  { id: 2, name: 'Same Day Delivery', img: s2, icon: 'icon-box', desc: 'Why wait until tomorrow to satisfy your customers’ expectations?' },
  { id: 3, name: 'Scheduled Delivery', img: s3, icon: 'icon-box', desc: 'Plan and arrange your deliveries according to your requirements.' },
  { id: 4, name: 'Two Way Delivery', img: s4, icon: 'icon-box', desc: 'Pick-up, deliver, and return your documents within the same day or the next day.' },
  { id: 5, name: 'Any-Size Delivery', img: s5, icon: 'icon-box', desc: 'Package delivery of any size to suit your needs and requirements.' },
  { id: 6, name: 'Simple Moving', img: s6, icon: 'icon-box', desc: 'Moving is now easier with TAPAW.' },
];

const carouselItems = [
  { id: 1, name: 'TAPAWMALL- SHOPPING WITH NEW NORM!', img: services1, desc: 'Shopping platforms are breaking new norms! A wide selection of items with different categories. Window shopping while relaxing at home and look forward to your home bell!' },
  { id: 2, name: 'TAPAWFOOD - HOT PLATES IN FRONT OF YOUR DOOR!', img: services3, desc: `TAPAWFOOD presents a variety of delicious, exciting, and worth trying. let's have a taste!` },
  { id: 3, name: 'TAPAWMART - YOUR VIRTUAL GROCERY SHOPPING', img: services4, desc: `Get a wide selection of groceries with just one click to fill your daily stock! no need to go out and save time!` },
  { id: 4, name: 'TAPAWFRESH-FRESH AND HEALTHY EVERY DAY', img: services5, desc: `Various options such as in the real market! Seafood, vegetables, herbs, and dairy products are all available at TAPAWFRESH.` },
  { id: 5, name: 'TAPAW DELIVERY - DELIVER FAST, WIDE AND SAFE!', img: services2, desc: `The logistics and delivery services platform are fast and extensive with a wide variety of vehicles operating throughout the country including Sabah and Sarawak, both urban and rural.` },
  // { id: 6, name: 'Document Parcel', img: services1, },
  // { id: 7, name: 'Delivery Now', img: services2, },
  // { id: 8, name: 'TAPAW Food', img: services3, },
  // { id: 9, name: 'TAPAW Mart', img: services4, },
  // { id: 10, name: 'TAPAW Fresh', img: services5, },
];

const vehicles = [
  { id: 1, name: 'Bike', val: 'Motorcycle', img: vehicle1 },
  { id: 2, name: 'Car', val: 'Car', img: vehicle2 },
  { id: 3, name: '4x4', val: 'Pickup (4x4)', img: vehicle3 },
  { id: 4, name: 'Van', val: 'Van', img: vehicle4 },
  { id: 5, name: '1 Tonne Lorry', val: '1-Ton Lorry', img: vehicle5 },
  { id: 6, name: '3 Tonne Lorry', val: '3-Ton Lorry', img: vehicle6 },
];

const Order = props => {
  const token = localStorage.getItem("idToken");
  const [pickupLocation, setPickupLocation] = useState({ 
    address: null, 
    lat: null,
    lng: null,
    contact: null,
    phone: null,
    info: null, });
  const [selectedDate, handleDateChange] = useState(new Date());
  const [deliveryLocation, setDeliveryLocation] = useState([{ 
    address: null, 
    lat: null,
    lng: null,
    contact: null,
    phone: null,
    info: null, }]);
  const [defaultAddressFlag, setDefaultAddressFlag] = useState(false);
  const [bikeChecks, setBikeChecks] = useState({});
  const [carChecks, setCarChecks] = useState({});
  const [pickupTruckChecks, setPickupTruckChecks] = useState({});
  const [vanChecks, setVanChecks] = useState({});
  const [lorry1TanChecks, setLorry1TanChecks] = useState({});
  const [lorry3TanChecks, setLorry3TanChecks] = useState({});
  const [currentVehicle, setCurrentVehicle] = useState('');
  const [defaultTimeFlag, setDefaultTimeFlag] = useState(true);
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [expandedAddress, setExpandedAddress] = useState(false);
  const [expandedAddressDeli, setExpandedAddressDeli] = useState({flag0: false});
  const [currentDeliveryIndex, setCurrentDeliveryIndex] = useState(0);
  const [allValidate, setAllValidate] = useState(false);
  const [anoTrack, setAnoTrack] = useState('');
  const [primaryAddressData, setPrimaryAddressData] = useState(null);
  const [loginFlag, setLoginFlag] = useState(false);

  //----------------------------------
  const bikeCheckBoxes = [
    { name: 'return_trip', key: 'bike1', label: 'Return Trip' },
    { name: 'TAPAW_bag', key: 'bike2', label: 'TAPAW Bag' }
  ]

  const carCheckBoxes = [
    { name: 'return_trip', key: 'car1', label: 'Return Trip' },
    { name: 'door_door', key: 'car2', label: 'Door-to-Door Service (up to 15m)' }
  ]

  const pickupTruckCheckBoxes = [
    { name: 'door_door', key: 'pickupTruck1', label: 'Door-to-Door Service (up to 15m)' },
  ]

  const vanCheckBoxes = [
    { name: 'door_door1', key: 'van1', label: 'Door-to-Door Service (up to 15m): Driver as Manpower' },
    { name: 'door-door2', key: 'van2', label: 'Door-to-Door Service (up to 15m): Driver + 1 Manpower' }
  ]

  const lorry1TanCheckBoxes = [
    { name: 'door_door1', key: 'lorry1T1', label: 'Door-to-Door Service (up to 15m): Driver as Manpower' },
    { name: 'door_door2', key: 'lorry1T2', label: 'Door-to-Door Service (up to 15m): Driver + 1 Manpower' },
    { name: 'door_door3', key: 'lorry1T3', label: 'Door-to-Door Service (up to 15m): Driver + 2 Manpower' },
  ]

  const lorry3TanCheckBoxes = [
    { name: 'door_door1', key: 'lorry1T1', label: 'Door-to-Door Service (up to 15m): Driver as Manpower' },
    { name: 'door_door2', key: 'lorry1T2', label: 'Door-to-Door Service (up to 15m): Driver + 1 Manpower' },
    { name: 'door_door3', key: 'lorry1T3', label: 'Door-to-Door Service (up to 15m): Driver + 2 Manpower' },
  ]

  //---------------------------------

  // useEffect(() => {
  //   console.log(`pickup location ${JSON.stringify(pickupLocation)}`);
  // }, [pickupLocation]);

  useEffect(() => {
    console.log(`default address flag ${defaultAddressFlag}`);
  }, [defaultAddressFlag]);

  // useEffect(() => {
  //   console.log(`delivery location ${JSON.stringify(deliveryLocation)}`);
  // }, [deliveryLocation]);

  useEffect(() => {
    validationChecking();
  }, [pickupLocation, selectedDate, deliveryLocation, currentVehicle, defaultTimeFlag, defaultAddressFlag]);

  
  useEffect(() => {
    if (defaultTimeFlag === true) {
      handleDateChange(a => {
        // console.log(`new date ${new Date()}`);
        return new Date();
      });
    }
  }, [defaultTimeFlag]);

  useEffect(() => {
    getPrimaryAddress();
  }, []);

  useEffect(() => {
    defaultAddressSetup();
  }, [defaultAddressFlag]);

  useEffect(() => {
    const { state } = props.location;
    if (state!==undefined) {
      // console.log(`state not undefined`);
      const { passedCurrentVehicle, passedSelectedDate, passedOrderType, passedPickupLocation, passedDeliveryLocation, passedAddOn } = state;
      if (passedCurrentVehicle!==undefined) setCurrentVehicle(passedCurrentVehicle);
      if (passedSelectedDate!==undefined) handleDateChange(passedSelectedDate);
      const defaultAddressData = { 
        address: null, 
        lat: null,
        lng: null,
        contact: null,
        phone: null,
        info: null, };
        
      let defaultAddressDataArr = [];
      defaultAddressDataArr.push(defaultAddressData);

      if (passedPickupLocation!==undefined) {
        // console.log(`passed pickup location ${JSON.stringify(passedPickupLocation)}`);
        if (!lodash.isEqual(passedPickupLocation,defaultAddressData)) {
          setPickupLocation(passedPickupLocation);
        }
      }
      if (passedDeliveryLocation!==undefined) {
        // console.log(`passed delivery location ${JSON.stringify(passedDeliveryLocation)}`);
        if (!lodash.isEqual(passedDeliveryLocation,defaultAddressDataArr)) {
          setDeliveryLocation(passedDeliveryLocation);
        }
      }
    }
    
    // if (passedAddOn!=={}) setaddo
  }, []);
  //------------------------
  function handleBikeChecks(e) {
    setBikeChecks({ ...bikeChecks, [e.target.name]: e.target.checked });
  }

  function handleCarChecks(e) {
    setCarChecks({ ...carChecks, [e.target.name]: e.target.checked });
  }

  function handlePickupTruckChecks(e) {
    setPickupTruckChecks({ ...pickupTruckChecks, [e.target.name]: e.target.checked });
  }

  function handleVanChecks(e) {
    setVanChecks({ ...vanChecks, [e.target.name]: e.target.checked });
  }

  function handleLorry1TanChecks(e) {
    setLorry1TanChecks({ ...lorry1TanChecks, [e.target.name]: e.target.checked });
  }

  function handleLorry3TanChecks(e) {
    setLorry3TanChecks({ ...lorry3TanChecks, [e.target.name]: e.target.checked });
  }

  // ---------

  // old function for onChange
  function handleDeliveryChange(i, event) {
    const values = [...deliveryLocation];
    values[i].address = event.target.value;
    setDeliveryLocation(values);
  }

  // new function from passed data
  function handleDeliveryPassedData (i, data) {
    const values = [...deliveryLocation];
    values[i].address = data.address;
    values[i].lat = data.lat;
    values[i].lng = data.lng;
    values[i].contact = data.contact;
    values[i].phone = data.phone;
    values[i].info = data.info;
    // console.log(`delivery passed data ${JSON.stringify(values)}`);
    setDeliveryLocation(values);
  }

  function handleAdd() {
    const values = [...deliveryLocation];
    values.push({ 
      address: null, 
      lat: null,
      lng: null,
      contact: null,
      phone: null,
      info: null,
    });
    setDeliveryLocation(values);
  }

  function handleRemove(i) {
    const values = [...deliveryLocation];
    values.splice(i, 1);
    setDeliveryLocation(values);
  }

  function defaultAddressSetup() {
    // if user tick default address, take primary address from get_address then set pickupLocation state
    if (primaryAddressData!==null && primaryAddressData.length!==0 && defaultAddressFlag===true) {
      // console.log(`primary ${JSON.stringify(primaryAddressData)}`)
      const {address, contactName, contactPhoneNo, additionalInfo, latlng} = primaryAddressData[0];
      const {_latitude, _longitude} = latlng;
      let data = {};
      data = {
        address,
        contact: contactName,
        phone: contactPhoneNo,
        info: additionalInfo,
        lat: _latitude,
        lng: _longitude
      };
      setPickupLocation(data); 
    } else {
      setPickupLocation({ 
        address: null, 
        lat: null,
        lng: null,
        contact: null,
        phone: null,
        info: null, });
    }
  }

  function passPickupAddressFunction (data) {
    // setExpandedAddress(!expandedAddress);        
    // console.log('run pass address function');
    // console.log(data);
    setPickupLocation(data);

    // test order
    // orderRequest(data, deliveryLocation[0], deliveryLocation);
  }

  function passDeliveryAddressFunction (data) {
    // setExpandedAddress(!expandedAddress);        
    // console.log('run pass delivery address function');
    // console.log(data);
    handleDeliveryPassedData(currentDeliveryIndex, data);
  }

  async function getPrimaryAddress() {
    if (token!==undefined && token!==null && token!== '') {
      setLoading(true);
      // console.log('run get address')
      await axios.post(`${baseUrl}/get_address`, {
          token,
          primary: true
      })
      .then(res => {
          const data = res.data
          // console.log(`address data ${JSON.stringify(data)}`);
          setPrimaryAddressData(data);
          setLoading(false);
      })
      .catch(err => {
          // console.log(`error response ${err.response}`);
          if (err.response!==undefined) {
              // client received error response (5xx, 4xx)
              // console.log(`error response ${err.response}`);
              //   console.log(err.response);
              setLoading(false);
              if (err.response.data.message != undefined) {
                  if (err.response.data.message.includes('Firebase ID token has expired')) {
                      // console.log('refresh');
                      refreshTokenRequest();
                  }
              }
            } else if (err.request!==undefined) {
              // client never received a response, or a request never left
              // console.log(`error request ${err.request}`);
            } else {
              // console.log(`get error ${err}`);
            }
      })
    }
  }

  async function refreshTokenRequest() {
    setLoading(true);
    const refresh_token = localStorage.getItem("refreshToken");
    // console.log(`refresh token in profile ${refresh_token}`);
    await axios.post(`${baseUrl}/refresh_token`, {
        refresh_token
    })
      .then(res => {
          const data = res.data
          // console.log(`refresh data ${JSON.stringify(data)}`);
          localStorage.setItem("idToken", data.id_token);
          localStorage.setItem("refreshToken", data.refresh_token);
          setLoading(false);
          setTriggerRefresh(old => old+1);
      })
      .catch(err => {
          // console.log(`error response ${err.response}`);
          // console.log(err.response);
          // loading = false;
          // error = err;
          setLoading(false);
          if (err.response!==undefined) {
            localStorage.setItem("idToken", "");
            localStorage.setItem("customToken", "");
            localStorage.setItem("refreshToken", "");
            localStorage.setItem("fullName", "");
            localStorage.setItem("username", "");
            localStorage.setItem("userType", "");
            localStorage.setItem("uid", "");
            localStorage.setItem("completedRegister", "");
            props.history.push('/');
            alert('Session expired. Please login again.');
            // client received error response (5xx, 4xx)
            // console.log(`error response ${err.response}`);
            // console.log(err.response);
          } else if (err.request!==undefined) {
            // client never received a response, or a request never left
            // console.log(`error request ${err.request}`);
          } else {
            // console.log(`get error ${err}`);
          }
      })
  }

  function validationChecking() {
    // pickupLocation, deliveryLocation, currentVehicle
    const defaultAddressData = { 
      address: null, 
      lat: null,
      lng: null,
      contact: null,
      phone: null,
      info: null, };
      
    let defaultAddressDataArr = [];
    defaultAddressDataArr.push(defaultAddressData);

    if (!lodash.isEqual(pickupLocation,defaultAddressData) && !lodash.isEqual(deliveryLocation,defaultAddressDataArr) && currentVehicle != '') {
      setAllValidate(true);
    } else {
      // console.log(`pickuplocation validation ${pickupLocation} , deliverylocation validation ${JSON.stringify(deliveryLocation)}, currentvehicle validation ${currentVehicle}`);
      setAllValidate(false);
    }
  }

  function passAddOn(vehicle) {
    switch(vehicle) {
      case 'Motorcycle':
        return bikeChecks;
      case 'Car':
        return carChecks;
      case 'Pickup (4x4)':
        return pickupTruckChecks;
      case 'Van':
        return vanChecks;
      case '1-Ton Lorry':
        return lorry1TanChecks;
      case '3-Ton Lorry':
        return lorry3TanChecks;
      default:
        return null;
    }
  }

  function passAllData() {
    let orderType = '';
    if (defaultTimeFlag) {
      orderType = 'ondemand';
    } else {
      orderType = 'scheduled';
    }

    const addOn = passAddOn(currentVehicle);

    let allData = {
      currentVehicle, 
      selectedDate, 
      orderType, 
      // paymentMethod: 'Wallet', 
      pickupLocation, 
      deliveryLocation,
      addOn
    };

    if (token !== undefined && token !== '') {
      props.history.push({pathname:'/confirm', state: allData});
    } else {
      setLoginFlag(true);
    }
  }

  function passTrackingNo() {
    if (anoTrack != '') {
      props.history.push({pathname:'/trackguest', anoTrack})
    }
  }

  function vehiclesOptionsUI(vehicleType) {
    // console.log('run vehicle ui')
    switch (vehicleType) {
      case 'Motorcycle':
        return bikeCheckBoxes.map(item => (
          <div className="home-checkbox">
            <label key={item.key}>
              <Checkbox name={item.name} value={bikeChecks[item.name]} onChange={handleBikeChecks} />
              {item.label}
            </label>
          </div>
        ));
      case 'Car':
        return carCheckBoxes.map(item => (
          <div className="home-checkbox">
            <label key={item.key}>
              <Checkbox name={item.name} value={carChecks[item.name]} onChange={handleCarChecks} />
              {item.label}
            </label>
          </div>
        ));
      case 'Pickup (4x4)':
        return pickupTruckCheckBoxes.map(item => (
          <div className="home-checkbox">
            <label key={item.key}>
              <Checkbox name={item.name} value={pickupTruckChecks[item.name]} onChange={handlePickupTruckChecks} />
              {item.label}
            </label>
          </div>
        ));
      case 'Van':
        return vanCheckBoxes.map(item => (
          <div className="home-checkbox">
            <label key={item.key}>
              <Checkbox name={item.name} value={vanChecks[item.name]} onChange={handleVanChecks} />
              {item.label}
            </label>
          </div>
        ));
      case '1-Ton Lorry':
        return lorry1TanCheckBoxes.map(item => (
          <div className="home-checkbox">
            <label key={item.key}>
              <Checkbox name={item.name} value={lorry1TanChecks[item.name]} onChange={handleLorry1TanChecks} />
              {item.label}
            </label>
          </div>
        ));
      case '3-Ton Lorry':
        return lorry3TanCheckBoxes.map(item => (
          <div className="home-checkbox">
            <label key={item.key}>
              <Checkbox name={item.name} value={lorry3TanChecks[item.name]} onChange={handleLorry3TanChecks} />
              {item.label}
            </label>
          </div>
        ));
      default:
        return (<div style={{ padding: '50px' }}></div>);
    }
  }

  function timeActions(a) {
    setDefaultTimeFlag(false);
    a.setMinutes(0);
    // console.log(`a value ${JSON.stringify(a)}`);
    handleDateChange(a);
    // onChange={handleDateChange} onClick={() => setDefaultTimeFlag(false)}
  }

  return (
    <div className="light-background">
      <div className="home-form-spacing">
        <div className="row">
       
          <div className="col-lg-12">
            <div className="col-lg-12 home-form-header"
              // style={{
              //   backgroundImage: `url(${form_header})`,
              //   backgroundSize: 'cover',
              //   backgroundPosition: 'center',
              //   display: 'flex',
              //   padding: '70px',
              // }} 
            />
          </div>
          <div className="row" style={{marginLeft: '15px', marginRight: '15px'}}>
            <div className="col-lg-12">
            <MuiThemeProvider theme={customTheme}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div className="row white-background">
                  <div className="col-lg-12 padding-horizontal-45" style={{paddingTop: '30px'}}>
                    <span className="bold-text">MAKE A DELIVERY</span>
                  </div>
                </div>
                <div className="row home-form">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Pick Up Location</label>
                      <div onClick={defaultAddressFlag===false?() => setExpandedAddress(!expandedAddress): () => console.log('nothing')}>
                        <input type="text" name="pickup_location" placeholder="Search the address" value={pickupLocation.address} disabled 
                        style={{ paddingLeft: '50px', width: '100%' }} />
                      </div>
                      <LocationOnIcon className="location-icon" />
                    </div>
                    {/* <span className="blue-text" onClick={() => setExpandedAddress(!expandedAddress)}>+ ADD ADDRESS</span> */}
                    <div className={`col-lg-12 ${expandedAddress ? `expanded-home` : `normalSpace`}`}>
                        <AddAddress
                            expandSpace={flag => {setExpandedAddress(flag); window.scrollTo({top: 500, behavior: 'smooth'})} }
                            addFunc={passPickupAddressFunction}
                            confirmtext="CONFIRM"
                        />
                    </div>
                    {
                    loading?<div className="row"><div className="col-lg-12 profile-text-centre"><CircularProgress /></div></div>:
                      token!==null&&token!==undefined&&token!==''?
                      <FormControlLabel
                        value={defaultAddressFlag}
                        control={<Checkbox color="primary" onChange={a => setDefaultAddressFlag(a.target.checked)} />}
                        label="Default Address"
                        labelPlacement="end"
                      />: <div />
                    }
                  </div>
                  <div className="col-lg-6">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label>Delivery Location</label>
                        {deliveryLocation.map((field, idx) => {
                          return (
                            <div key={`${field}-${idx}`} className="row delivery-location-container">
                              <div className="input-group col-lg-12" 
                                onClick={() => {
                                    setCurrentDeliveryIndex(idx); 
                                    setExpandedAddressDeli(prev =>  ({ ...prev, [`flag${idx}`]: !expandedAddressDeli[`flag${idx}`] }));
                                  }}>
                                <input
                                  type="text"
                                  // className="form-control"
                                  value={field.address}
                                  placeholder='Search the address'
                                  disabled
                                  style={{ paddingLeft: '50px' }}
                                />
                                <LocationOnIcon className="location-icon-1" />
                                <div className="centre-blue-edit-text align-right" id="button-addon4">
                                  {idx === 0 ? null : <span onClick={() => handleRemove(idx)} ><NoEntryIcon size={24} /></span>}
                                </div>
                              </div>
                              <div className={`col-lg-12 ${expandedAddressDeli[`flag${idx}`] ? `expanded-home` : `normalSpace`}`}>
                                  <AddAddress
                                      expandSpace={flag => {setExpandedAddressDeli(flag); window.scrollTo({top: 500, behavior: 'smooth'})} }
                                      addFunc={passDeliveryAddressFunction}
                                      confirmtext="CONFIRM"
                                  />
                              </div>
                              {/* <div className="col-lg-4 centre-blue-edit-text" style={{ }}>
                                {idx == 0 ? null : <span onClick={() => handleRemove(idx)} ><NoEntryIcon size={24} /></span>}
                              </div> */}
                            </div>
                          );
                        })}
                        <div className="col-lg-12 centre-blue-edit-text">
                          <span className="row" onClick={() => handleAdd()}>
                            <div className="col-lg-1">
                              <PlusCircleIcon size={16} />
                            </div>
                            <div className="col-lg-11">
                              <span>More delivery</span>
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 margin-picker-container">
                    <div>
                      <label>Delivery Date</label>
                    </div>
                    <div >
                      <DatePicker style={{ width: '100%' }} value={selectedDate} onChange={handleDateChange} InputProps={{ disableUnderline: true }} />
                    </div>
                  </div>
                  <div className="col-lg-6 margin-picker-container">
                    <div className="col-lg-12">
                      <label>Pick Up Time</label>
                    </div>
                    <div className="col-lg-12">
                      <TimePicker views="hours" style={{ width: '100%' }} value={selectedDate} onChange={a => timeActions(a)} minutesStep={60} InputProps={{ disableUnderline: true }} />
                    </div>
                    <div className="col-lg-12">
                      <FormControlLabel
                        checked={defaultTimeFlag}
                        // value={defaultTimeFlag}
                        control={<Checkbox color="primary" onChange={a => setDefaultTimeFlag(a.target.checked)} />}
                        label="Set Current Time"
                        labelPlacement="end"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-lg-12">
                        <label>Select Your Vehicle</label>
                      </div>
                      {vehicles.map(a =>
                        <div className="col-lg-2 col-md-3" key={a.id}>
                          <label className="radio">
                            <input type="radio" className="home-page-radio" name="v1" id={a.id} value={a.val} onChange={a => setCurrentVehicle(a.target.value)} />
                            <span style={{ padding: '0px' }}>
                              <img src={a.img} className="radio-img" alt="" />
                              <p className="text-transform-none">{a.name}</p>
                            </span>
                          </label>
                        </div>)
                      }
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-lg-6">
                        {
                          vehiclesOptionsUI(currentVehicle)
                        }
                      </div>
                      <div className="col-lg-4 offset-lg-2 tempah-sekarang-container">
                        {
                          loading?<div className="row"><div className="col-lg-12 profile-text-centre"><CircularProgress /></div></div>:
                          <button type="button" className={`tempah-sekarang ${allValidate?'enabled-order':'disabled-btn'}`} disabled={allValidate?false:true} onClick={() => passAllData()}>
                            <Link  style={{ color: 'white' }}>
                              BOOK A DELIVERY
                            </Link>
                          </button>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </MuiPickersUtilsProvider>
            </MuiThemeProvider>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="row">
        <div className="col-6">
          <div className="sigma_contact-map">
            <div className="container">
              <div className="row justify-content-end ">
                <div className="col-lg-10">
                  <div className="home-page-banner-box shadow-header" style={{
                    backgroundImage: `url(${minibanner})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    padding: 20,
                    display: 'flex'
                  }} >
                    <h5 className="text-white home-earn-extra"
                      >Earn extra income and
                      work at your convenience</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="sigma_contact-map">
            <div className="container">
              <div className="row">
                <div className="col-lg-10">
                  <div className="home-page-banner-box shadow-header" style={{ padding: 20 }}>
                    <span className="text-white" >TRACK YOUR DELIVERY</span>
                    <input style={{ marginTop: '20px' }} type="text" className="h-25" name="v1" placeholder="Key in your tracking number ie: [#EG12345]" onChange={a => setAnoTrack(a.target.value)} />
                    <button 
                      className="text-white" 
                      style={{
                      backgroundColor: 'red',
                      float: 'right',
                      borderRadius: 5, paddingTop: 5, paddingBottom: 5, paddingLeft: 10, paddingRight: 10, marginTop: 15
                      }}
                      onClick={() => passTrackingNo()}
                    >
                      <Link style={{ color: 'white' }}>
                        Track Now!
                        </Link>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      
      <Login
          justCloseModal={true}
          show={loginFlag}
          onHide={(flag) => setLoginFlag(flag)}
      />
    </div>

  );
}

export default withRouter(Order);
