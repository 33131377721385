import React from 'react';
import logo_at_footer from '../../../assets/img/logo-at-footer.svg';

const thStyle = {
    fontFamily: "Anton",
    fontWeight: "normal",
    fontStyle: "normal"
}; 
  
class PdfTemplate extends React.Component {
  render(){
    const {orderID, customer, date, pickup,delivery,fare,status} = this.props.data;
    return (
      <div className="pad-horizontal-30">
        <div className="pad-vertical-60">
          <div>
            <img src={logo_at_footer} alt="logo" className="home-service-logo"/>
          </div>
          <div>
            <h3>Invoice {orderID}</h3>
          </div>
        </div>
        <div className="pad-vertical-60">
          <h2>Customer Information</h2>
          <div>{customer.fullName?customer.fullName: ''}</div>
          <div>{customer.email?customer.email: ''}</div>
          <div>{customer.phoneNumber?customer.phoneNumber: ''}</div>
        </div>
        <div className="pad-vertical-60">
          <table style={thStyle} className="table">
            <thead>
                <tr>
                  {/* <th>&nbsp;</th> */}
                  <th>DATE</th>
                  <th>PICKUP ADDRESS</th>
                  <th>DELIVERY ADDRESS</th>
                  <th>PAYMENT</th>
                  <th>STATUS</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                  <td>{date}</td>
                  <td>{pickup}</td>
                  <td>{delivery}</td>
                  <td>RM{fare}</td>
                  <td>{status}</td>
                </tr>
            </tbody>
          </table>
        </div> 
      </div>      
    );
  }
}
export default PdfTemplate;