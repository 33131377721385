import React, {useState, useEffect} from "react";
import axios from "axios";
import Wave500 from '../../assets/img/images/transition-products-transparent-p-500.png';
import Wave800 from '../../assets/img/images/transition-products-transparent-p-800.png';
import Wave1080 from '../../assets/img/images/transition-products-transparent-p-1080.png';
import Wave1600 from '../../assets/img/images/transition-products-transparent-p-1600.png';
import Wave1920 from '../../assets/img/images/transition-products-transparent.png';

import Logo from '../../assets/img/images/logo.svg';
import NewLogo from '../../assets/img/images/tapaw-logo-top.svg';
import { appStoreLink, fbLink, googlePlayLink, instagramLink, tapawmallLink, tapawshopLink, termsLink, twitterLink, wpBase } from "../../shared/constants";
import google_play from '../../assets/img/tapaw-google-play.png';
import apple from '../../assets/img/tapaw-appstore.png';
import twitter from '../../assets/img/social/twitter-icon.png';
import fb from '../../assets/img/social/facebook-icon.png';
import insta from '../../assets/img/social/insta-icon.png';
import { customLanguage } from "../../shared/languages";

const Footer = props => {
  const [wpData, setWpData] = useState([]);

  useEffect(()=> {
    allNewsRequest();
  },[]);

  async function allNewsRequest () {
    let data = null;
    let error = '';
    let loading = true;
    
    await axios.get(`${wpBase}/posts?page=1`)
    .then(res => {
        // console.log('get data')
        loading = false;
        data = res.data;
        // console.log(`all news data ${JSON.stringify(data)}`);
        let arr = [];
        if(data.length > 2) {
          arr.push(data[data.length - 1]);
          arr.push(data[data.length - 2]);
        } else if(data.length === 2) {
          arr.push(data[data.length - 1]);
          arr.push(data[data.length - 2]);
        } else if(data.length < 2) {
          arr.push(data[data.length - 1]);
        }
        
        setWpData(arr);
        // console.log(`arr ${JSON.stringify(arr)}`);
    })
    .catch(err => {
        loading = false;
        error = err;
        if (err.response!==undefined) {
            // client received error response (5xx, 4xx)
            // console.log(`error response ${err.response}`);
        } else if(err.request!==undefined) {
            // client never received a response, or a request never left
            // console.log(`error request ${err.request}`);
        } else {
            // console.log(`get error ${err}`);
        }
    })
    // .finally(() => loading = false)

    // console.log(`loading ${loading}`);
    // console.log(`data ${data}`);
  }

  return (
    <div>
      <div className="wf-section">
        <img
          src={Wave1920}
          loading="lazy"
          sizes="(max-width: 1919px) 100vw, 1920px"
          srcSet={`${Wave500} 500w, ${Wave800} 800w, ${Wave1080} 1080w, ${Wave1600} 1600w, ${Wave1920} 1920w`}
          // alt
          className="image-5"
          style={{marginTop: 0}}
        />
      </div>
      <footer id="footer" className="footer wf-section">
        <div className="container w-container">
          <div className="footer-flex-container">
            <a href="/" className="footer-logo-link">
              <img src={NewLogo} 
              // alt 
              className="footer-image" />
            </a>
            <div className="div-block-6">
              <h2 className="footer-heading">{customLanguage(localStorage.getItem('language')).footer.var1}</h2>
              <ul role="list" className="w-list-unstyled">
                <li>
                  <a href="/about" className="footer-link">
                  {customLanguage(localStorage.getItem('language')).footer.var2}
                  </a>
                </li>
                <li>
                  <a href="/team" className="footer-link">
                  {customLanguage(localStorage.getItem('language')).footer.var3}
                  </a>
                </li>
                <li>
                  <a href="/delivery_rate" className="footer-link">
                  {customLanguage(localStorage.getItem('language')).footer.var4}
                  </a>
                </li>
                <li>
                  <a href="/contact" className="footer-link">
                  {customLanguage(localStorage.getItem('language')).footer.var5}
                  </a>
                </li>
                <li>
                  <a href="/news" className="footer-link">
                  {customLanguage(localStorage.getItem('language')).footer.var6}
                  </a>
                </li>
                <li>
                  <a href="#" onClick={() => window.open(tapawshopLink)} className="footer-link" >
                  {customLanguage(localStorage.getItem('language')).footer.var7}
                  </a>
                </li>
                <li>
                  <a href="#" onClick={() => window.open(tapawmallLink)} className="footer-link" >
                  {customLanguage(localStorage.getItem('language')).footer.var8}
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="footer-heading footer-add-space">{customLanguage(localStorage.getItem('language')).footer.var9}</h2>
              <ul role="list" className="w-list-unstyled footer-add-space">
                <li>
                  <a href="/privacy" className="footer-link">
                  {customLanguage(localStorage.getItem('language')).footer.var10}
                  </a>
                </li>
                <li>
                  <a href="#" onClick={() => window.open(termsLink)} className="footer-link">
                  {customLanguage(localStorage.getItem('language')).footer.var11}
                  </a>
                </li>
                <li>
                  <a href="/faq" className="footer-link">
                  {customLanguage(localStorage.getItem('language')).footer.var12}
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="footer-heading">{customLanguage(localStorage.getItem('language')).footer.var14}</h2>
              <ul role="list" className="w-list-unstyled">
                { 
                wpData.map((j,idx) => <li key={idx}>
                    <a href="#" className="footer-link">
                      <a className="footer-link" href={wpData.length !== 0 ? `/newsDetails/${j.id}` : '/'}>{j.title.rendered}</a>
                    </a>
                </li>)
                }
                {/* <li>
                  <a href="#" className="footer-link">
                    Blog Post 1
                  </a>
                </li>
                <li>
                  <a href="#" className="footer-link">
                    Blog Post 2
                  </a>
                </li>
                <li>
                  <a href="#" className="footer-link">
                    Blog Post3
                  </a>
                </li> */}
              </ul>
              <h2 className="footer-heading" style={{paddingTop: '20px'}}>{customLanguage(localStorage.getItem('language')).footer.var15}</h2>
              <div className="row">
                  <a href="/#" className="footer-link" onClick={() => window.open(twitterLink, '_blank',  'noopener, noreferrer')}>
                    <img src={twitter} alt="google" className="cursor" style={{ width: '50px', marginLeft: '20px' }} />
                  </a>
                  <a href="#" className="footer-link" onClick={() => window.open(fbLink, '_blank',  'noopener, noreferrer')}>
                    <img src={fb} alt="apple" className="cursor" style={{ width: '50px', marginLeft: '20px' }} />
                  </a>
                  <a href="#" className="footer-link" onClick={() => window.open(instagramLink, '_blank',  'noopener, noreferrer')}>
                    <img src={insta} alt="apple" className="cursor" style={{ width: '50px', marginLeft: '20px' }} />
                  </a>
              </div>
              <h2 className="footer-heading" style={{paddingTop: '15px'}}>{customLanguage(localStorage.getItem('language')).footer.var13}</h2>
              <ul role="list" className="w-list-unstyled row">
                <li>
                  <a href="/#" className="footer-link" onClick={() => window.open(googlePlayLink, '_blank',  'noopener, noreferrer')}>
                    <img src={google_play} alt="google" className="cursor" style={{ width: '200px' }} />
                  </a>
                </li>
                <li>
                  <a href="#" className="footer-link" onClick={() => window.open(appStoreLink, '_blank',  'noopener, noreferrer')}>
                    <img src={apple} alt="apple" className="cursor" style={{ width: '200px' }} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div>{customLanguage(localStorage.getItem('language')).footer.var16}</div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
