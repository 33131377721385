import React, {useState, useEffect} from 'react';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import { baseUrl } from '../../shared/constants';

const ChangePassword = (props) => {
    const [passwordRes, setPasswordRes] = useState(null);
    const [loading, setLoading] = useState(false);
    const [triggerRefresh, setTriggerRefresh] = useState(0);
    const [doneRequest, setDoneRequest] = useState(false);

    async function changePasswordRequest(email) {
        setDoneRequest(false);
        setLoading(true);
        const token = localStorage.getItem("idToken");
        await axios.post(`${baseUrl}/forgot_password`, {
            email
        })
            .then(res => {
                const data = res.data
                // console.log(`forgot data ${JSON.stringify(data)}`);
                setPasswordRes(data);
                setLoading(false);
                setDoneRequest(true);
            })
            .catch(err => {
                // console.log(`error response ${err.response}`);
                // console.log(err.response);
                // loading = false;
                // error = err;
                setLoading(false);
                if (err.response!==undefined) {
                  // client received error response (5xx, 4xx)
                //   console.log(`error response ${err.response}`);
                    // console.log(err.response);
                    if (err.response.data.message != undefined) {
                        if (err.response.data.message.includes('Firebase ID token has expired')) {
                            // console.log('refresh');
                            refreshTokenRequest();
                        }
                    }
                } else if (err.request!==undefined) {
                  // client never received a response, or a request never left
                //   console.log(`error request ${err.request}`);
                } else {
                //   console.log(`get error ${err}`);
                }
            })
    }

    async function refreshTokenRequest() {
        setLoading(true);
        const refresh_token = localStorage.getItem("refreshToken");
        // console.log(`refresh token in profile ${refresh_token}`);
        await axios.post(`${baseUrl}/refresh_token`, {
            refresh_token
        })
            .then(res => {
                const data = res.data
                // console.log(`refresh data ${JSON.stringify(data)}`);
                localStorage.setItem("idToken", data.id_token);
                localStorage.setItem("refreshToken", data.refresh_token);
                setLoading(false);
                setTriggerRefresh(old => old+1);
                alert('Try again');
            })
            .catch(err => {
                // console.log(`error response ${err.response}`);
                // console.log(err.response);
                // loading = false;
                // error = err;
                setLoading(false);
                if (err.response!==undefined) {
                  // client received error response (5xx, 4xx)
                    localStorage.setItem("idToken", "");
                    localStorage.setItem("customToken", "");
                    localStorage.setItem("refreshToken", "");
                    localStorage.setItem("fullName", "");
                    localStorage.setItem("username", "");
                    localStorage.setItem("userType", "");
                    localStorage.setItem("uid", "");
                    localStorage.setItem("completedRegister", "");
                    props.history.push('/');
                    alert('Session expired. Please login again.');
                //   console.log(`error response ${err.response}`);
                //   console.log(err.response);
                } else if (err.request!==undefined) {
                  // client never received a response, or a request never left
                //   console.log(`error request ${err.request}`);
                } else {
                //   console.log(`get error ${err}`);
                }
            })
    }

    return (
        <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // dialogClassName="modal-rounded"
        >
            
            <Modal.Body className="modal-content">
                <form method="post">
                    <div className="col-lg-12 profile-text-centre">
                        <h4>Confirm change email?</h4>
                    </div>
                    <div className="col-lg-12">
                        <div className="form-group profile-text-centre">
                            <label>{passwordRes?passwordRes.data: ''}</label>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-lg-12">
                                {
                                loading?<div className="col-lg-12 profile-text-centre"><CircularProgress /></div>:
                                (doneRequest==false)?<div className=" profile-text-centre">
                                    <button type="button" className="tempah-sekarang" onClick={() => {changePasswordRequest(props.email)}}>
                                        Request
                                    </button>
                                </div>:
                                <div className=" profile-text-centre">
                                    <button type="button" className="tempah-sekarang" onClick={() => {props.onHide(false)}}>
                                        OK
                                    </button>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default withRouter(ChangePassword);