import React, {useState, useEffect} from "react";
import {useForm} from 'react-hook-form';
import axios from "axios";
import {useParams, withRouter} from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Carousel } from 'react-responsive-carousel';
import yScheduleImg from '../../assets/img/images/y-schedule.jpg';
import yMoveImg from '../../assets/img/images/y-move.jpg';
import yAllImg from '../../assets/img/images/y-move.jpg';
import yMalaysiaImg from '../../assets/img/images/y-malaysia.jpg';
import yCheaperImg from '../../assets/img/images/y-cheaper.jpg';
import yFasterImg from '../../assets/img/images/y-faster.jpg';
import { baseUrl } from "../../shared/constants";
import regimg from '../../assets/img/images/screenshot-1-select-pickup-location.png';
import regimg2 from '../../assets/img/images/screenshot-2-dropoff-location.png';
import regimg3 from '../../assets/img/images/screenshot-3-select-vehicle.png';
import regimg4 from '../../assets/img/images/screenshot-4-schedul-order.png';
import regimg5 from '../../assets/img/images/screenshot-5-payment-method.png';
import regimg6 from '../../assets/img/images/screenshot-6-placeorder.png';

const PartnerRegistBody = props => {
  const {ref} = useParams();
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const [phone, setPhone] = useState('');
  const [phoneValidate, setPhoneValidate] = useState('');
  const [referralValidate, setReferralValidate] = useState('');
  const [referal, setReferral] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (ref) {
      setReferral(ref)
    }
  }, []);

  useEffect(() => {
    referralSetup();
  }, [referal]);

  async function phoneRequest ( phone ) {
    let data = null;
    let error = '';
    // let loading = true;
   
    await axios.post(`${baseUrl}/check_phone`, {
        phone, 
    })
    .then(res => {
        // console.log('get data')
        // loading = false;
        data = res.data;
        setPhoneValidate(data);
        setPhone(phone);
    })
    .catch(err => {
        // loading = false;
        error = err;
        if (err.response!==undefined) {
            // client received error response (5xx, 4xx)
            // console.log(`error response ${err.response}`);
            setPhoneValidate('error');
        } else if(err.request!==undefined) {
            // client never received a response, or a request never left
            // console.log(`error request ${err.request}`);
            setPhoneValidate('error');
        } else {
            // console.log(`get error ${err}`);
            setPhoneValidate('error');
        }
    })
    // .finally(() => loading = false)

    // console.log(`loading ${loading}`);
    // console.log(`data ${data}`);
  }

  function referralSetup() {
    if (referal != '') {
      referralRequest(referal)
    }
  }

  async function referralRequest ( referal ) {
    let data = null;
    let error = '';
    // let loading = true;
    if (referal != '') {
      await axios.post(`${baseUrl}/check_referal`, {
          referal,
      })
      .then(res => {
          // loading = false;
          data = res.data;
          console.log(`referral request data ${JSON.stringify(data)}`);
          setReferralValidate(data);
          // setReferral(referal);
      })
      .catch(err => {
          // loading = false;
          error = err;
          if (err.response!==undefined) {
              // client received error response (5xx, 4xx)
              // console.log(`error response ${err.response}`);
              setReferralValidate('error');
          } else if(err.request!==undefined) {
              // client never received a response, or a request never left
              // console.log(`error request ${err.request}`);
              setReferralValidate('error');
          } else {
              // console.log(`get error ${err}`);
              setReferralValidate('error');
          }
      })
    }
    // .finally(() => loading = false)

    // console.log(`loading ${loading}`);
    // console.log(`data ${data}`);
  }

  const onSubmit = data => {
    console.log(data);
    registerUser(data);
  }

  const registerUser = async (dataPassed) => 
  {
    let data = null;
    let error = '';
    const {email, password, name, username} = dataPassed;
    setLoading(true);
    await axios.post(`${baseUrl}/register`, {
      email,
      password,
      phone, 
      full_name: name, 
      username, 
      referal,
      usertype: 'partner'
    })
    .then(async res => {
      setLoading(false);
      // console.log('get data')
      // loading = false;
      data = res.data;
      // setSecondPhase('Default');
      
      setLoading(true);
      //console.log(`email ${email}, password ${password}`)
      await axios.post(`${baseUrl}/login`, {
          email,
          password,
      })
      .then(res => {
          // console.log('get data')
          setLoading(false);
          data = res.data;
          // console.log(`login data ${JSON.stringify(data)}`);
          localStorage.setItem("idToken", data.idToken);
          localStorage.setItem("customToken", data.customToken);
          localStorage.setItem("refreshToken", data.refreshToken);
          localStorage.setItem("fullName", data.fullName);
          localStorage.setItem("username", data.username);
          localStorage.setItem("uid", data.uid);
          localStorage.setItem("userType", data.usertype[0].type);
          localStorage.setItem("completedRegister", data.completedRegister!==undefined?data.completedRegister:false);
          props.history.push("/profile");
          // props.onHide(false)
      })
      .catch(err => {
          setLoading(false);
          error = err;
          if (err.response!==undefined) {
              // client received error response (5xx, 4xx)
              // console.log(`error response ${err.response}`);
          } else if (err.request!==undefined) {
              // client never received a response, or a request never left
              // console.log(`error request ${err.request}`);
          } else {
              // console.log(`get error ${err}`);
          }
      })
    })
    .catch(err => {
      setLoading(false);
      // loading = false;
      error = err;
      if (err.response!==undefined) {
          // client received error response (5xx, 4xx)
          // console.log(`error response ${JSON.stringify(err.response)}`);
      } else if(err.request!==undefined) {
          // client never received a response, or a request never left
          // console.log(`error request ${err.request}`);
      } else {
          // console.log(`get error ${err}`);
      }
    })
  }

  return (
    <div>
      <div className="wf-section">
        <div className="div-block-8">
          <div className="container w-container">
            <div className="columns-2 w-row">
              <div className="column-3 w-col w-col-6">
                <div className="div-block-18">
                  <div className="div-block-19 user">
                    <h1 className="heading-9">
                      We Make Delivery Easy For You!
                    </h1>
                    <p className="paragraph-title">
                      Sign up and start Tapaw now!
                      <br />
                      We make shipping now easier.
                    </p>
                  </div>
                </div>
              </div>
              <div className="column-4 w-col w-col-6">
                <div className="div-block-9">
                  <div className="form-block w-form">
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      className="form-2"
                    >
                      <label htmlFor="name" className="field-label">
                        Name
                      </label>
                      <input
                        {...register("name", { required: true })}
                        type="text"
                        className="form w-input"
                        maxLength={256}
                        name="name"
                        data-name="Name"
                        placeholder="Enter your name"
                        id="name"
                      />
                      {errors.name && <span>This field is required</span>}
                      <label htmlFor="email" className="field-label">
                        Email Address
                      </label>
                      <input
                        {...register("email", { required: true })}
                        type="email"
                        className="form w-input"
                        maxLength={256}
                        name="email"
                        data-name="Email"
                        placeholder="Enter your email address"
                        id="email"
                      />
                      {errors.email && <span>This field is required</span>}
                      <label htmlFor="email-2" className="field-label">
                        Password
                      </label>
                      <input
                        {...register("password", { required: true })}
                        type="password"
                        className="form w-input"
                        maxLength={256}
                        name="password"
                        data-name="password"
                        placeholder="*****"
                        id="password"
                      />
                      {errors.password && <span>This field is required</span>}
                      <label htmlFor="name-6" className="field-label">
                        Phone Number{" "}
                      </label>
                      <input
                        // {...register("phoneNumber", { required: true })}
                        onChange={e => phoneRequest(e.target.value)}
                        type="text"
                        className="form w-input"
                        maxLength={256}
                        name="phoneNumber"
                        data-name="phoneNumber"
                        placeholder="Enter your phone number"
                        id="phoneNumber"
                      />
                      <span className={phoneValidate == 'Phone is available to use' ? 'validation-msg-passed' : 'validation-msg-error'}>{phoneValidate}</span>
                      {/* {errors.phoneNumber && <span>This field is required</span>} */}
                      <label htmlFor="name-6" className="field-label">
                        Username{" "}
                      </label>
                      <input
                        {...register("username", { required: true })}
                        type="text"
                        className="form username w-input"
                        maxLength={256}
                        name="username"
                        data-name="username"
                        placeholder="Enter your username"
                        id="username"
                      />
                      {errors.username && <span>This field is required</span>}
                      <div className="div-block-10" />
                      <label htmlFor="name-6" className="field-label">
                        Referred by
                      </label>
                      <input
                        type="text"
                        className="form no-edit w-input"
                        maxLength={256}
                        name="name-5"
                        data-name="Name 5"
                        placeholder={ref}
                        id="name-5"
                        disabled
                      />
                      <span className={'validation-msg-error'}>{referralValidate=='Referal not available'||referralValidate=='error'?referralValidate:''}</span>
                      <div className="div-block-11">
                        <label className="w-checkbox checkbox-field">
                          <input
                            type="checkbox"
                            id="checkbox"
                            name="checkbox"
                            data-name="Checkbox"
                            className="w-checkbox-input"
                            required
                          />
                          <span className="checkbox-label w-form-label">
                            By signing up, I agree to the{" "}
                            <a href="https://tapaw.com/static/media/GeneralTerms.08000de2.pdf">
                              <strong className="bold-text">
                                Terms and Conditions
                              </strong>
                            </a>{" "}
                            and{" "}
                            <a href="/privacy">
                              <span className="bold-text">
                                <strong>TAPAW's Privacy Policy</strong>
                              </span>
                            </a>
                          </span>
                        </label>
                        <label className="w-checkbox checkbox-field">
                          <input
                            type="checkbox"
                            id="checkbox-2"
                            name="checkbox-2"
                            data-name="Checkbox 2"
                            className="w-checkbox-input"
                            required
                          />
                          <span className="checkbox-label w-form-label">
                            I agree with the usage of my personal data in line
                            with the stipulated Privacy Policy for marketing
                            purpose.
                          </span>
                        </label>
                      </div>
                      {
                      loading? <div className="col-lg-12 profile-text-centre"><CircularProgress /></div>:
                      <button type="submit" className="submit-register">SUBMIT</button>
                      // <input
                      //   type="submit"
                      //   defaultValue="Submit"
                      //   data-wait="Please wait..."
                      //   className='button w-button'
                      //   // className="button registration w-button"
                      // />
                      }
                    </form>
                    <div className="success w-form-done">
                      <img
                        src="images/success_button.gif"
                        loading="lazy"
                        width={219}
                        // alt
                        className="image-7"
                      />
                      <div className="text-block-2">
                        Thank you! Your submission has been received!
                      </div>
                    </div>
                    <div className="error-message error-message-2 w-form-fail">
                      <div>
                        Oops! Something went wrong while submitting the form.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-7 wf-section">
        <div className="container w-container">
          <h2 className="heading-5">WHY CHOOSE TAPAW?</h2>
          <p className="description-text">We make shipping now easier!</p>
          <div
            data-w-id="596615e2-11b9-05f2-bf47-7f7055e79bc7"
            style={{
              opacity: 0
            }}
            className="div-block-4"
          />
          <div className="w-layout-grid grid-2">
            <div className="div-tapaw-unique">
              <img
                src={yScheduleImg}
                loading="lazy"
                width={80}
                height={80}
                // alt
              />
              <h4 className="heading-4 tapaw-feature">
                ON-DEMAND AND SCHEDULED DELIVERY
              </h4>
              <p className="paragraph-3">
                Get your tasks done in less than 60 minutes with our on-demand
                deliveryor Plan and arrange your deliveries according to your
                requirements.
              </p>
            </div>
            <div className="div-tapaw-unique">
              <img
                src={yMoveImg}
                loading="lazy"
                width={80}
                height={80}
                // alt
              />
              <h4 className="heading-4 tapaw-feature">SIMPLE MOVING</h4>
              <p className="paragraph-3">Moving is now easier with TAPAW.</p>
            </div>
            <div className="div-tapaw-unique">
              <img
                src={yAllImg}
                loading="lazy"
                width={80}
                height={80}
                // alt
              />
              <h4 className="heading-4 tapaw-feature">ANY-SIZE DELIVERY</h4>
              <p className="paragraph-3">
                Package delivery of any size to suit yourneeds and
                requirements.
              </p>
            </div>
            <div className="div-tapaw-unique">
              <img
                src={yMalaysiaImg}
                loading="lazy"
                width={80}
                height={80}
                // alt
              />
              <h4 className="heading-4 tapaw-feature">
                WE SEND ALL OVER MALAYSIA!
              </h4>
              <p className="paragraph-3">
                Are you upset because there are noriders in your area? Tapaw
                je!
              </p>
            </div>
            <div className="div-tapaw-unique">
              <img
                src={yCheaperImg}
                loading="lazy"
                width={80}
                height={80}
                // alt
              />
              <h4 className="heading-4 tapaw-feature">
                TAPAW EVERYTHING AS LOW AS RM4!
              </h4>
              <p className="paragraph-3">
                Get faster deliveries from only RM4 with our motorcycle. We
                calculate a delivery fee based on the distance.
              </p>
            </div>
            <div className="div-tapaw-unique">
              <img
                src={yFasterImg}
                loading="lazy"
                width={80}
                height={80}
                // alt
              />
              <h4 className="heading-4 tapaw-feature">SAVE TIME</h4>
              <p className="paragraph-3">
                No need to wait until tomorrow to
                <br />
                ensure your customer satisfaction.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="section-6 wf-section">
          <div className="col-md-12">
            <Carousel infiniteLoop autoPlay interval={3000} showStatus={false} showArrows={true} showIndicators={true} showThumbs={false} >
              <div className="row" style={{height: '100vh'}}>
                  <div className="col-md-5" style={{alignSelf: 'center'}}>
                    <img
                      src={regimg}
                      loading="lazy"
                      style={{width: '15vw', height: 'auto',}}
                    />
                  </div>
                  <div className="col-md-7" style={{alignSelf: 'center'}}>
                    <h2 className="slider-text">
                      HOW TO PLACE A DELIVERY ORDER?
                    </h2>
                    <div className="animated-div">
                      <div className="div-block-16">
                        <div className="text-block-3">q</div>
                        <div className="text-block-4">
                          Set your Pick Up Location
                        </div>
                      </div>
                      <p className="description-text slider">
                        Set where do you want to have your item pick up,
                        whether its at your home or your office
                      </p>
                    </div>
                  </div>
              </div>
              <div className="row" style={{height: '100vh'}}>
                  <div className="col-md-6" style={{alignSelf: 'center'}}>
                    <img
                      src={regimg2}
                      loading="lazy"
                      style={{width: '15vw', height: 'auto',}}
                    />
                  </div>
                  <div className="col-md-6" style={{alignSelf: 'center'}}>
                    <h2 className="slider-text">
                      HOW TO PLACE A DELIVERY ORDER?
                    </h2>
                    <div className="animated-div">
                      <div className="div-block-16">
                        <div className="text-block-3">w</div>
                        <div className="text-block-4">
                          Set your Drop off Location
                        </div>
                      </div>
                      <p className="description-text slider">
                        Once set, let us know where do you want to send the
                        parcel. Put a note on how we will notify the recipient
                        upon sending.
                      </p>
                    </div>
                  </div>
              </div>
              <div className="row" style={{height: '100vh'}}>
                  <div className="col-md-6" style={{alignSelf: 'center'}}>
                    <img
                      src={regimg3}
                      loading="lazy"
                      style={{width: '15vw', height: 'auto',}}
                    />
                  </div>
                  <div className="col-md-6" style={{alignSelf: 'center'}}>
                    <h2 className="slider-text">
                      HOW TO PLACE A DELIVERY ORDER?
                    </h2>
                    <div className="animated-div">
                      <div className="div-block-16">
                        <div className="text-block-3">e</div>
                        <div className="text-block-4">Pick a vehicle</div>
                      </div>
                      <p className="description-text slider">
                        Now we know where to get and send your parcel. Up
                        next, with your parcel, how do want us to send with?
                        Guide on vehicles is{" "}
                        <a href="#" className="link-2">
                          here
                        </a>
                      </p>
                    </div>
                  </div>
              </div>
              <div className="row" style={{height: '100vh'}}>
                  <div className="col-md-6" style={{alignSelf: 'center'}}>
                    <img
                      src={regimg4}
                      loading="lazy"
                      style={{width: '15vw', height: 'auto',}}
                    />
                  </div>
                  <div className="col-md-6" style={{alignSelf: 'center'}}>
                    <h2 className="slider-text">
                      HOW TO PLACE A DELIVERY ORDER?
                    </h2>
                    <div className="animated-div">
                      <div className="div-block-16">
                        <div className="text-block-3">r</div>
                        <div className="text-block-4">
                          Choose the scheduling
                        </div>
                      </div>
                      <p className="description-text slider">
                        Everything is done, now it's time to instruct us what
                        time to fetch the parcel. By default it's
                        on-demand (now), but if you feel to take some breather
                        before sending it, well you can set a time!
                      </p>
                    </div>
                  </div>
              </div>
              <div className="row" style={{height: '100vh'}}>
                  <div className="col-md-6" style={{alignSelf: 'center'}}>
                    <img
                      src={regimg5}
                      loading="lazy"
                      style={{width: '15vw', height: 'auto',}}
                    />
                  </div>
                  <div className="col-md-6" style={{alignSelf: 'center'}}>
                    <h2 className="slider-text">
                      HOW TO PLACE A DELIVERY ORDER?
                    </h2>
                    <div className="animated-div">
                      <div className="div-block-16">
                        <div className="text-block-3">t</div>
                        <div className="text-block-4">
                          Select payment method
                        </div>
                      </div>
                      <p className="description-text slider">
                        We're almost there! Now to send the parcel you can
                        either pay by in-app wallet or cash. To have the
                        parcel sent by cash, you need at least to have RM20 in
                        the wallet.
                      </p>
                    </div>
                  </div>
              </div>
              <div className="row" style={{height: '100vh'}}>
                  <div className="col-md-6" style={{alignSelf: 'center'}}>
                    <img
                      src={regimg6}
                      loading="lazy"
                      style={{width: '15vw', height: 'auto',}}
                    />
                  </div>
                  <div className="col-md-6" style={{alignSelf: 'center'}}>
                    <h2 className="slider-text">
                      HOW TO PLACE A DELIVERY ORDER?
                    </h2>
                    <div className="animated-div">
                      <div className="div-block-16">
                        <div className="text-block-3">y</div>
                        <div className="text-block-4">
                          Place Order & Wait Our Driver to Accept!
                        </div>
                      </div>
                      <p className="description-text slider">
                        Yes you heard it. Simply let this app running in the
                        background, have a sip of tea while waiting our
                        nearest driver accepting the job.
                      </p>
                    </div>
                  </div>
              </div>
            </Carousel>
          </div>
          
      </div>
      <div className="section-10 wf-section">
        <div className="w-container">
          <div
            style={{
              paddingTop: "56.17021276595745%"
            }}
            className="w-embed-youtubevideo"
          >
            <iframe
              src="https://www.youtube.com/embed/HOvSkyecj1M?rel=0&controls=1&autoplay=0&mute=0&start=0"
              frameBorder={0}
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                pointerEvents: "auto"
              }}
              allow="autoplay; encrypted-media"
              allowFullScreen
            />
          </div>
        </div>
      </div>
      <div className="wf-section">
        <div className="container with-paragraph w-container">
          <h2 className="heading-5">WHAT SERVICES DOES TAPAW OFFER?</h2>
          <p className="description-text">Some of the items you could send</p>
          <div
            data-w-id="daea7551-0993-1f1a-2455-d802e792a1d8"
            style={{
              opacity: 0
            }}
            className="div-block-4"
          />
          <div className="w-layout-grid grid">
            <div className="div-offer">
              <div className="title-image">
                FOOD &<br />
                GROCERY
              </div>
            </div>
            <div className="div-offer petplant">
              <div className="title-image">
                PET &<br />
                PLANT
              </div>
            </div>
            <div className="div-offer goods">
              <div className="title-image">
                SURPRISE DELIVERY & VALUABLE GOODS
              </div>
            </div>
            <div className="div-offer largeitems">
              <div className="title-image">LARGE ITEMS</div>
            </div>
            <div className="div-offer smallitems">
              <div className="title-image">SMALL ITEMS</div>
            </div>
            <div className="div-offer movinghouse">
              <div className="title-image">
                MOVING
                <br />
                HOUSE
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wf-section">
        <div className="container-2 faq-container">
          <div className="div-block-17">
            <h2 className="pricing-h1 faq-h2">Frequently Asked Questions</h2>
            <div
              data-w-id="ecd20145-b589-5596-2637-0f5072b9b33a"
              style={{
                opacity: 1
              }}
              className="div-block-4"
            />
          </div>
          <div className="faq-wrap">
            <div className="faq-question">
              <div className="faq-plus-wrap">
                <div className="faq-plus-l" />
                <div className="faq-plus" />
              </div>
              <div className="faq-q-text">WHAT IS TAPAW?</div>
            </div>
            <div className="faq-answer">
              <p className="faq-answer-text">
                TAPAW is a growing logistics and delivery service start-up
                company. We offer the best service quality of all time through
                a complete ecosystem among merchants, riders and users. Now,
                delivery service is no longer a burden, all your solutions are
                here.
              </p>
            </div>
          </div>
          <div className="faq-wrap">
            <div className="faq-question">
              <div className="faq-plus-wrap">
                <div className="faq-plus-l" />
                <div className="faq-plus" />
              </div>
              <div className="faq-q-text">
                WHAT CAN BE DELIVERED BY TAPAW?
              </div>
            </div>
            <div className="faq-answer">
              <p className="faq-answer-text">
                Anything can be delivered using a rider service but the size
                of the item must be appropriate for our rider. It depends on
                the terms and conditions by TAPAW.
              </p>
            </div>
          </div>
          <div className="faq-wrap">
            <div className="faq-question">
              <div className="faq-plus-wrap">
                <div className="faq-plus-l" />
                <div className="faq-plus" />
              </div>
              <div className="faq-q-text">WHO WILL MAKE THE DELIVERY?</div>
            </div>
            <div className="faq-answer">
              <p className="faq-answer-text">
                Riders who have registered under TAPAW will make deliveries to
                your customers.
              </p>
            </div>
          </div>
          <div className="faq-wrap">
            <div className="faq-question">
              <div className="faq-plus-wrap">
                <div className="faq-plus-l" />
                <div className="faq-plus" />
              </div>
              <div className="faq-q-text">
                WHAT ARE THE OPERATING TIMES FOR TAPAW?
              </div>
            </div>
            <div className="faq-answer">
              <p className="faq-answer-text">
                Our delivery service is 24/7 and 365 Days but the processing
                time is between 9.00 am to 6.00 pm.
              </p>
            </div>
          </div>
          <div className="faq-wrap">
            <div className="faq-question">
              <div className="faq-plus-wrap">
                <div className="faq-plus-l" />
                <div className="faq-plus" />
              </div>
              <div className="faq-q-text">
                CAN I SEND ORDERS TO OTHER STATE?
              </div>
            </div>
            <div className="faq-answer">
              <p className="faq-answer-text">
                Yes, it should be as long as you are able to pay the delivery
                service fee according to the distance of the area that will be
                charged to the TAPAW rider. Everything becomes easier, faster
                and faster.
              </p>
            </div>
          </div>
          <div className="faq-wrap">
            <div className="faq-question">
              <div className="faq-plus-wrap">
                <div className="faq-plus-l" />
                <div className="faq-plus" />
              </div>
              <div className="faq-q-text">
                What are the benefits of using TAPAW?
              </div>
            </div>
            <div className="faq-answer">
              <p className="faq-answer-text">
                On-time - Orders are sent on schedule
                <br />
                <strong>| Affordable </strong>- Meets consumerexpectations at
                a faster cost
                <br />
                <strong>| Safe </strong>- All assignments are handled by
                certified and experienced riders
                <br />
                <strong>| Reliable</strong> - High performance in ensuring
                orders aredelivered in good condition <br />
                <strong>| Flexibility</strong> - Various types of
                transportation to complete various delivery sizes
                <br />
                <strong>| Quality</strong> - Superior service quality for each
                process is always a priority at all times.
              </p>
            </div>
          </div>
          <div className="faq-wrap">
            <div className="faq-question">
              <div className="faq-plus-wrap">
                <div className="faq-plus-l" />
                <div className="faq-plus" />
              </div>
              <div className="faq-q-text">
                What should I do if after I successfully top up but ewallet no
                such transaction?
              </div>
            </div>
            <div className="faq-answer">
              <p className="faq-answer-text">
                Do not worry. Contact admin for assistance admin@tapaw.com
              </p>
            </div>
          </div>
        </div>
        <img
          src="images/transition-products-transparent.png"
          loading="lazy"
          sizes="(max-width: 1919px) 100vw, 1920px"
          srcSet="images/transition-products-transparent-p-500.png 500w, images/transition-products-transparent-p-800.png 800w, images/transition-products-transparent-p-1080.png 1080w, images/transition-products-transparent-p-1600.png 1600w, images/transition-products-transparent.png 1920w"
          // alt
          className="image-5"
        />
      </div>
    </div>
  );
}

export default withRouter(PartnerRegistBody);
