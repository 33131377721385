import React, {useState} from 'react';
import * as S from './styles';

// import Logo from '../../assets/logo.png';
// import IconReact from '../../assets/react.svg';
// import IconMegamen from '../../assets/megamen.png';
// import IconMario from '../../assets/mario.png';
// import IconTurtle from '../../assets/turtle.png';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  Redirect,
} from 'react-router-dom';
import { customLanguage } from '../../../shared/languages';
import { withRouter } from 'react-router-dom';
import { NavDropdown } from 'react-bootstrap';
import Login from '../../modals/login';

function RightNav(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const [loginFlag, setLoginFlag] = useState(false);

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose2 = () => {
        setAnchorEl2(null);
    };

    const handleClick2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };
    
      const goLogout = () => {
        localStorage.clear();
        // console.log(`custom token ${localStorage.getItem('customToken')}`)
        window.location.href = "/";
      }
      const goProfile = () => {
        // setAnchorEl(null);
        const userType = localStorage.getItem("userType");
        const completedRegister = localStorage.getItem("completedRegister");
        // console.log(`user type ${userType}, completed register ${completedRegister}, completed register type ${typeof(completedRegister)}`);
        // check if driver/vendor completed 2nd part registration, if not yet, go to 2nd part registration
        if (userType.toLowerCase() === 'driver' || userType.toLowerCase() === 'vendor') {
          if (completedRegister==='true') {
            // console.log('completed true');
            props.history.push("/profile");
          } else {
            if(userType.toLowerCase()==='vendor') props.history.push('/registervendor2');
            if(userType.toLowerCase()==='driver') props.history.push('/registerdriver2');
          }
        } else {
          props.history.push('/profile')
        }
      };

    const loginChecker = () => {
        if (localStorage.getItem("idToken") === "" || localStorage.getItem("idToken") === null) {
            setLoginFlag(true);
        } else {
            setLoginFlag(false);
            window.location.href = "/order";
        }
    }
  return (
    <>
      <S.Ul open={props.open}>        
          <a href='/' style={{fontSize: '15px'}}>{customLanguage(localStorage.getItem('language')).header.var1}</a>
          <a href='/team' style={{fontSize: '15px', marginTop: '15px'}}>{customLanguage(localStorage.getItem('language')).header.var2}</a>
          <a href='/news' style={{fontSize: '15px', marginTop: '15px'}}>{customLanguage(localStorage.getItem('language')).header.var3}</a>
          <a href="#" onClick={() => loginChecker()} style={{fontSize: '15px', marginTop: '15px'}}>{customLanguage(localStorage.getItem('language')).header.var4}</a>
                <NavDropdown style={{ border: 0, marginTop: '8px'}} title={customLanguage(localStorage.getItem('language')).header.var5} id="offcanvasNavbarDropdown">
                    <NavDropdown.Item href="#" onClick={() => {
                    localStorage.setItem('language', 'english'); 
                    window.location.reload()
                    }}>ENGLISH</NavDropdown.Item>
                    <NavDropdown.Item href="#" onClick={() => {
                    localStorage.setItem('language', 'malay');
                    window.location.reload()
                    }}>BAHASA MELAYU</NavDropdown.Item>
                </NavDropdown>
                {
                  localStorage.getItem("idToken") === "" ||
                  localStorage.getItem("idToken") === null ?
                  <>
                    <NavDropdown style={{ marginTop: '5px' }} title={customLanguage(localStorage.getItem('language')).header.var6} id="offcanvasNavbarDropdown">
                      <NavDropdown.Item href="/registeruser">{customLanguage(localStorage.getItem('language')).header.var8}</NavDropdown.Item>
                      <NavDropdown.Item href="/registerdriver">{customLanguage(localStorage.getItem('language')).header.var9}</NavDropdown.Item>
                      <NavDropdown.Item href="/registervendor">{customLanguage(localStorage.getItem('language')).header.var10}</NavDropdown.Item>
                      <NavDropdown.Item href="/registeragent">{customLanguage(localStorage.getItem('language')).header.var11}</NavDropdown.Item>
                      <NavDropdown.Item href="/connect">{customLanguage(localStorage.getItem('language')).header.var12}</NavDropdown.Item>
                    </NavDropdown>
                    <div  onClick={() => loginChecker()} style={{ borderRadius: '5px', border: '1px solid gray', width: '90%', textAlign: 'center', padding: '5px', marginTop: '50px'}}>
                      <a href="#" style={{ marginTop: '15px' }}>{customLanguage(localStorage.getItem('language')).header.var7}</a>
                    </div>
                  </>
                  :
                  <>
                    <a href="/trackuser" style={{textAlign: 'center', fontSize: '15px', marginTop: '15px'}}>{customLanguage(localStorage.getItem('language')).header.var13}</a>
                    <a onClick={() => goProfile()} style={{fontSize: '15px', textAlign: 'center', marginTop: '15px'}}>{localStorage.getItem("fullName")}</a>
                    <div onClick={() => goLogout()} style={{ borderRadius: '5px', border: '1px solid gray', width: '90%', textAlign: 'center', padding: '5px', marginTop: '50px'}}>
                      <a href="#" style={{fontSize: '15px'}}>{customLanguage(localStorage.getItem('language')).header.var14}</a>
                    </div>
                  </>
                }
      </S.Ul>
        <div style={{ flex: 1 }}>
            <Login
            justCloseModal={true}
            show={loginFlag}
            onHide={(flag) => setLoginFlag(flag)}
            />
        </div>
      
    </>
  );
}

export default withRouter(RightNav);