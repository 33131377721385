import React from "react";
import RegExtras from "./reg_extras";
import logo from '../../assets/img/tapaw/logo.png';
import user1 from '../../assets/img/tapaw/Registrations Picture/User/user1.jpg';
import user2 from '../../assets/img/tapaw/Registrations Picture/User/user2.jpg';
import user3 from '../../assets/img/tapaw/Registrations Picture/User/user3.jpg';
import user4 from '../../assets/img/tapaw/Registrations Picture/User/user4.jpg';
import user5 from '../../assets/img/tapaw/Registrations Picture/User/user5.jpg';
import user6 from '../../assets/img/tapaw/Registrations Picture/User/user6.jpg';
import { customLanguage } from "../../shared/languages";
class Team extends React.Component {
  render() {
    const userTitle = customLanguage(localStorage.getItem('language')).team.title;
    const userDesc = customLanguage(localStorage.getItem('language')).team.desc;
    const itemsUser = [
      { id: '1', name: customLanguage(localStorage.getItem('language')).team.p1, desc: customLanguage(localStorage.getItem('language')).team.p1a, img: user1 },
      { id: '2', name: customLanguage(localStorage.getItem('language')).team.p2, desc: customLanguage(localStorage.getItem('language')).team.p2a, img: user2 },
      { id: '3', name: customLanguage(localStorage.getItem('language')).team.p3, desc: customLanguage(localStorage.getItem('language')).team.p3a, img: user3 },
      { id: '4', name: customLanguage(localStorage.getItem('language')).team.p4, desc: customLanguage(localStorage.getItem('language')).team.p4a, img: user4 },
      { id: '5', name: customLanguage(localStorage.getItem('language')).team.p5, desc: customLanguage(localStorage.getItem('language')).team.p5a, img: user5 },
      { id: '6', name: customLanguage(localStorage.getItem('language')).team.p6, desc: customLanguage(localStorage.getItem('language')).team.p6a, img: user6 },
    ];
    return (
      <div className="container" style={{ marginTop: '40px' }}>
        <div style={{ textAlign: 'center', marginBottom: '40px' }}>
          <h2 className="capitalize-all" style={{ textAlign: 'center', color: '#0e4761', marginBottom: '0px' }}>{customLanguage(localStorage.getItem('language')).team.mainTitle}</h2>
          <div className="row">
            <div className="col-md-4" />
            <div
                className="col-md-4"
              >
              <div className="yellow-line" style={{width: '30%', marginTop: '12px'}} />
            </div>
          </div>
          <img src={logo}></img>
        </div>
        <p>{customLanguage(localStorage.getItem('language')).team.intro}
        </p>
        <RegExtras title={userTitle} desc={userDesc} items={itemsUser} />
      </div>
    );
  }
}

export default Team;
