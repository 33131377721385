import React from 'react';
import googleImg from '../../assets/img/cta/68650-ipad-play-google-button-iphone-android-now.png';
import appleImg from '../../assets/img/cta/download-on-the-app-store-badge.jpeg';
import { appStoreLink, googlePlayLink } from "../../shared/constants";
import { customLanguage } from '../../shared/languages';

const DownloadApp = props => {
    return (
    <div>
        <div className="col-md-12">
            <h2
            style={{
                opacity: 1
            }}
            className="heading-5 capitalize-all"
            >
            {customLanguage(localStorage.getItem('language')).home.downloadApp.var1}
            </h2>
            <div className="row">
            <div className="col-md-4" />
            <div
                className="col-md-4"
                >
                <div className="yellow-line" style={{width: '60%', marginTop: '12px', marginBottom: '24px'}} />
            </div>
            </div>
        </div>
        <div className="w-container">
            <div className="columns-cta w-row">
                <div className="column-cta w-col w-col-6">
                    <img
                    src={googleImg}
                    loading="lazy"
                    sizes="(max-width: 479px) 96vw, (max-width: 767px) 46vw, 345.8125px"
                    srcSet={`${googleImg} 500w, ${googleImg} 800w, ${googleImg} 1080w, ${googleImg} 1600w, ${googleImg} 2000w`}
                    alt
                    className="image-2-google cursor"
                    onClick={() => window.open(googlePlayLink, '_blank',  'noopener, noreferrer')}
                    />
                </div>
                <div className="column-2-cta w-col w-col-6">
                    <img
                    src={appleImg}
                    loading="lazy"
                    alt
                    className="image-3-apple cursor"
                    onClick={() => window.open(appStoreLink, '_blank',  'noopener, noreferrer')}
                    />
                </div>
            </div>
        </div>
    </div>
    );
}

export default DownloadApp;

