import React, {useEffect, useState} from "react";
import {useForm} from 'react-hook-form';
import axios from "axios";
import {withRouter} from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import successBtnImg from '../../assets/img/images/success_button.gif';
import one_photoImg from '../../assets/img/images/1-photo.02dbcde0-1.jpg';
import two_photoImg from '../../assets/img/images/2-photo.048b62f9.jpg';
import three_photoImg from '../../assets/img/images/3-photo.25270a7c.jpg';
import four_photoImg from '../../assets/img/images/4-photo.6db3e5a2.jpg';
import five_photoImg from '../../assets/img/images/5-photo.1d2b0227.jpg';
import six_photoImg from '../../assets/img/images/6-photo.f6de640f.jpg';
import { baseUrl } from "../../shared/constants";
import Logo from '../../assets/img/images/logo.svg';
import DownloadApp from "../home/downloadapp";
import BaseAccordion from "./baseAccordion";

const vendortypes = [
  { id: 1, label: 'Food', value: 'Makanan' },
  { id: 2, label: 'Fresh', value: 'Fresh' },
  { id: 3, label: 'Grocery', value: 'Runcit' },
];

const frequently = [
  { title: 'INTRODUCTION TO TAPAW VENDORS', desc: `We are looking for vendors to expand your business. TAPAW is a deliveryservice specialist. Start the business on a new platform at just yourfingertips, the delivery process will be much faster and safe.` },
  { title: 'HOW TO REGISTER AS A TAPAW VENDOR?', desc: `Click on the link to register https //www.TAPAW.com` },
  { title: 'WHO WILL SEND THE PRE-PACKAGED ORDER TO THE CUSTOMER?', desc: `Our riders will take the pre-packaged to the customer. Our riders are readyto fulfil every delivery in a high-quality manner at all times.` },
  { title: 'WHAT ARE THE OPERATING TIMES FOR TAPAW?', desc: `Our delivery service is 24/7 and 365 Days but the processing time is between9.00 am to 6.00 pm` },
  { title: 'WHAT ARE THE CONDITIONS OR CRITERIA TO BECOME A TAPAW VENDOR?', desc: `Click on the link to register https //www.TAPAW.com` },
  { title: 'HOW DO I VIEW ORDERS THAT HAVE BEEN PLACED FROM CUSTOMERS?', desc: `You can check the order journey from our system or through the apps.` },
  { title: 'WHAT SHOULD I DO IF AFTER I SUCCESSFULLY TOP UP BUT E WALLET NO SUCH TRANSACTION?', desc: `Do not worry. Contact admin for assistance` },
  { title: 'WHAT ARE THE BENEFITS OF BEING A TAPAW VENDOR?', desc: `You can save costs and operating time by handing over your delivery serviceto TAPAW. Take your business to a new level and reach more customers.Start to generate more income by leveraging your new business tocustomers and our brand. Our team will always help you to overcome anybusiness challenges.` },
  { title: 'WHAT DOCUMENTS ARE REQUIRED TO BECOME A TAPAW VENDOR?', desc: `Click on the link to register https //www.TAPAW.com` },
];

const VendorRegistBody = props => {
  const {state} = props.location;
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const [phone, setPhone] = useState('');
  const [phoneValidate, setPhoneValidate] = useState('');
  const [referralValidate, setReferralValidate] = useState('');
  const [referal, setReferral] = useState('');
  const [loading, setLoading] = useState(false);
  const [statesLoading, setStatesLoading] = useState(false);
  const [businessLoading, setBusinessLoading] = useState(false);
  const [statesData, setStatesData] = useState(null);
  const [businessData, setBusinessData] = useState(null);
  const [triggerRefresh, setTriggerRefresh] = useState(0);

  useEffect(() => {
    if (localStorage.getItem('referralCode')) {
      setReferral(localStorage.getItem('referralCode'))
    }
  }, []);

  useEffect(() => {
    referralSetup();
  }, [referal]);

  async function phoneRequest ( phone ) {
    let data = null;
    let error = '';
    let loadingtmp = true;
   
    await axios.post(`${baseUrl}/check_phone`, {
        phone, 
    })
    .then(res => {
        // console.log('get data')
        loadingtmp = false;
        data = res.data;
        setPhoneValidate(data);
        setPhone(phone);
    })
    .catch(err => {
        loadingtmp = false;
        error = err;
        if (err.response!==undefined) {
            // client received error response (5xx, 4xx)
            // console.log(`error response ${err.response}`);
            setPhoneValidate('error');
        } else if(err.request!==undefined) {
            // client never received a response, or a request never left
            // console.log(`error request ${err.request}`);
            setPhoneValidate('error');
        } else {
            // console.log(`get error ${err}`);
            setPhoneValidate('error');
        }
    })
    // .finally(() => loading = false)

    // console.log(`loading ${loading}`);
    // console.log(`data ${data}`);
  }

  function referralSetup() {
    if (referal != '') {
      referralRequest(referal)
    }
  }

  async function referralRequest ( referal ) {
    let data = null;
    let error = '';
    let loadingtmp = true;
    if (referal != '') {
      await axios.post(`${baseUrl}/check_referal`, {
          referal,
      })
      .then(res => {
          loadingtmp = false;
          data = res.data;
          // console.log(`referral request data ${JSON.stringify(data)}`);
          setReferralValidate(data);
          // setReferral(referal);
      })
      .catch(err => {
          loadingtmp = false;
          error = err;
          if (err.response!==undefined) {
              // client received error response (5xx, 4xx)
              // console.log(`error response ${err.response}`);
              setReferralValidate('error');
          } else if(err.request!==undefined) {
              // client never received a response, or a request never left
              // console.log(`error request ${err.request}`);
              setReferralValidate('error');
          } else {
              // console.log(`get error ${err}`);
              setReferralValidate('error');
          }
      })
    }
    // .finally(() => loading = false)

    // console.log(`loading ${loading}`);
    // console.log(`data ${data}`);
  }

  const onSubmit = data => {
    // console.log(data);

    registerVendor(data);
  }

  const registerVendor = async (dataPassed) =>
  {
    let data = null;
    let error = '';
    const {email, password, name, username, vendortype} = dataPassed;
    setLoading(true);
    await axios.post(`${baseUrl}/register`, {
      email,
      password,
      phone, 
      full_name: name, 
      username, 
      referal,
      usertype: 'vendor',
      vendortype
    })
    .then(async res => {
      setLoading(false);
      // console.log('success register vendor');
      // loading = false;
      data = res.data;
      // setSecondPhase('Vendor');
      
      setLoading(true);
      //console.log(`email ${email}, password ${password}`)
      await axios.post(`${baseUrl}/login`, {
          email,
          password,
      })
      .then(res => {
          // console.log('success login')
          setLoading(false);
          data = res.data;
          // console.log(`login data ${JSON.stringify(data)}`);
          localStorage.setItem("idToken", data.idToken);
          localStorage.setItem("customToken", data.customToken);
          localStorage.setItem("refreshToken", data.refreshToken);
          localStorage.setItem("fullName", data.fullName);
          localStorage.setItem("username", data.username);
          localStorage.setItem("uid", data.uid);
          localStorage.setItem("userType", data.usertype[0].type);
          localStorage.setItem("completedRegister", data.completedRegister!==undefined?data.completedRegister:false);
          props.history.push('/registervendor2')
          // getBusiness();
          // getStates();
          // props.history.push("/profile");
          // props.onHide(false)
      })
      .catch(err => {
          setLoading(false);
          // console.log(`fail login ${JSON.stringify(err)}`);
          error = err;
          if (err.response!==undefined) {
              // client received error response (5xx, 4xx)
              // console.log(`error response ${err.response}`);
          } else if (err.request!==undefined) {
              // client never received a response, or a request never left
              // console.log(`error request ${err.request}`);
          } else {
              // console.log(`get error ${err}`);
          }
      })
    })
    .catch(err => {
      // loading = false;
      // console.log(`err ${JSON.stringify(err)}`);
      error = err;
      setLoading(false);
      if (err.response!==undefined) {
          // client received error response (5xx, 4xx)
          // console.log(`error response ${JSON.stringify(err.response)}`);
      } else if(err.request!==undefined) {
          // client never received a response, or a request never left
          // console.log(`error request ${err.request}`);
      } else {
          // console.log(`get error ${err}`);
      }
    })
  }

  return (
    <div>
      <div className="wf-section">
        <div className="div-block-8 vendor">
          <div className="container w-container">
            <div className="columns-2 w-row">
              <div className="column-3 w-col w-col-6">
                <div className="div-block-18">
                  <div className="div-block-19 vendor">
                    <h1 className="heading-9">
                      Level Up Your Service and Profitability
                    </h1>
                    <p className="paragraph-title">
                      Starting a new online business and hassle with setting up
                      the faster delivery process? Tapaw for Business is One
                      Stop Solution that you are looking for.
                    </p>
                  </div>
                </div>
              </div>
              <div className="column-4 w-col w-col-6">
                <div className="div-block-9">
                  <div className="form-block w-form">
                    <div className="col-md-12" style={{marginLeft: '65px'}}>
                      <img src={Logo} className="logo-regist" />
                    </div>
                    <div className="col-md-12" style={{marginLeft: '65px'}}>
                      <span className="regist-title">Vendor Registration</span>
                    </div>
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      className="form-2"
                    >
                      <label htmlFor="name" className="field-label">
                        Name
                      </label>
                      <input
                        {...register("name", { required: true })}
                        type="text"
                        className="form w-input"
                        maxLength={256}
                        name="name"
                        data-name="name"
                        placeholder="Enter your name"
                        id="name"
                      />
                      {errors.name && <span>This field is required</span>}
                      <label htmlFor="email" className="field-label">
                        Email Address
                      </label>
                      <input
                        {...register("email", { required: true })}
                        type="email"
                        className="form w-input"
                        maxLength={256}
                        name="email"
                        data-name="email"
                        placeholder="Enter your email address"
                        id="email"
                      />
                      {errors.email && <span>This field is required</span>}
                      <label htmlFor="email-2" className="field-label">
                        Password
                      </label>
                      <input
                        {...register("password", { required: true })}
                        type="password"
                        className="form w-input"
                        maxLength={256}
                        name="password"
                        data-name="password"
                        placeholder="*****"
                        id="password"
                      />
                      {errors.password && <span>This field is required</span>}
                      <label htmlFor="name-6" className="field-label">
                        Phone Number{" "}
                      </label>
                      <input
                        // {...register("phoneNumber", { required: true })}
                        onChange={e => phoneRequest(e.target.value)}
                        type="text"
                        className="form w-input"
                        maxLength={256}
                        name="phoneNumber"
                        data-name="phoneNumber"
                        placeholder="Enter your phone number"
                        id="phoneNumber"
                      />
                      <span className={phoneValidate == 'Phone is available to use' ? 'validation-msg-passed' : 'validation-msg-error'}>{phoneValidate}</span>
                      {/* {errors.phoneNumber && <span>This field is required</span>} */}
                      <label htmlFor="username" className="field-label">
                        Username
                      </label>
                      <input
                        {...register("username", { required: true })}
                        type="text"
                        className="form username w-input"
                        maxLength={256}
                        name="username"
                        data-name="username"
                        placeholder="Enter your username"
                        id="username"
                      />
                      {errors.username && <span>This field is required</span>}
                      <label htmlFor="username-2" className="field-label">
                        Select your sector
                      </label>
                      <select
                        {...register("vendortype", { required: true })}
                        id="vendortype"
                        name="vendortype"
                        data-name="vendortype"
                        className="select-field vendor w-select"
                      >
                      {vendortypes.map(a => (
                        <option key={a.id} value={a.value}>{a.label}</option>
                      ))}
                      </select>
                      {/* <div className="div-block-10" /> */}
                      <label htmlFor="name-6" className="field-label">
                        Referred by
                      </label>
                      <input
                        type="text"
                        className="form no-edit w-input"
                        maxLength={256}
                        name="name-5"
                        data-name="Name 5"
                        placeholder={referal}
                        id="name-5"
                      />
                      <div className="div-block-11">
                        <label className="w-checkbox checkbox-field">
                          <input
                            type="checkbox"
                            id="checkbox"
                            name="checkbox"
                            data-name="Checkbox"
                            className="w-checkbox-input"
                            required
                          />
                          <span className="checkbox-label w-form-label">
                            By signing up, I agree to the{" "}
                            <a href="https://tapaw.com/static/media/GeneralTerms.08000de2.pdf">
                              <strong className="bold-text">
                                Terms and Conditions
                              </strong>
                            </a>{" "}
                            and{" "}
                            <a href="/privacy">
                              <span className="bold-text">
                                <strong>TAPAW's Privacy Policy</strong>
                              </span>
                            </a>
                          </span>
                        </label>
                        <label className="w-checkbox checkbox-field">
                          <input
                            type="checkbox"
                            id="checkbox-2"
                            name="checkbox-2"
                            data-name="Checkbox 2"
                            className="w-checkbox-input"
                            required
                          />
                          <span className="checkbox-label w-form-label">
                            I agree with the usage of my personal data in line
                            with the stipulated Privacy Policy for marketing
                            purpose.
                          </span>
                        </label>
                      </div>
                      {
                      loading? <div className="col-lg-12 profile-text-centre"><CircularProgress /></div>:
                      <button type="submit" className="submit-register">SUBMIT</button>
                      // <input
                      //   type="submit"
                      //   defaultValue="Submit"
                      //   data-wait="Please wait..."
                      //   className='button w-button'
                      //   // className="button registration w-button"
                      // />
                      }
                    </form>
                    <div className="success w-form-done">
                      <img
                        src={successBtnImg}
                        loading="lazy"
                        width={219}
                        alt
                        className="image-7"
                      />
                      <div className="text-block-2">
                        Thank you! Your submission has been received!
                      </div>
                    </div>
                    <div className="error-message error-message-2 w-form-fail">
                      <div>
                        Oops! Something went wrong while submitting the form.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{marginTop: '30px'}}>
        <DownloadApp />
      </div>
      <div className="section-7 wf-section">
        <div className="container w-container">
          <h2 className="heading-5">Empower your Business With TAPAW</h2>
          <div className="row">
            <div className="col-md-4" />
            <div
                className="col-md-4"
              >
              <div className="yellow-line" style={{width: '60%', marginTop: '12px'}} />
            </div>
          </div>
          <p className="description-text">
          TAPAW for Business is One Stop Solution that you are looking for. Build the trust and
credibility of your business by fulfilling the needs of your customer timely!
          </p>
          <div
            data-w-id="596615e2-11b9-05f2-bf47-7f7055e79bc7"
            style={{
              opacity: 0
            }}
            className="div-block-4"
          />
          <div
            style={{
              paddingTop: "56.17021276595745%",
              marginLeft: 'auto',
              marginRight: 'auto'
            }}
            className="w-embed-youtubevideo youtube"
          >
            <iframe
              src="https://www.youtube.com/embed/tXTnWTpqbQE?rel=0&controls=1&autoplay=0&mute=0&start=0"
              frameBorder={0}
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                pointerEvents: "auto",
                borderColor: '#ffcd3d', 
                borderRadius: '5px', 
                borderWidth: '10px', 
                textShadow: 'none'
              }}
              allow="autoplay; encrypted-media"
              allowFullScreen
            />
          </div>
        </div>
      </div>
      <div className="wf-section">
        <div className="container with-paragraph w-container">
          <h2 className="heading-5">WHY DO YOU NEED TO JOIN US</h2>
          <div className="yellow-line" style={{width: '200px', marginTop: '12px'}} />
          <p className="description-text">
          TAPAW offers delivery services at competitive prices while offering comprehensive savings
on your business operations!
          </p>
          <div
            data-w-id="16b84342-af42-28e1-da87-912da640b183"
            style={{
              opacity: 0
            }}
            className="div-block-4"
          />
          <div className="w-layout-grid grid-2 features">
            <div className="div-tapaw-unique extra">
              <img
                src={one_photoImg}
                loading="lazy"
                width={80}
                height={80}
                alt
                className="image-10 _1 extra1"
              />
              <div className="div-block-20">
                <h4 className="heading-4 tapaw-feature">Be in Charge</h4>
                <p className="paragraph-3">
                  With no one to report to, you are your own boss
                </p>
              </div>
            </div>
            <div className="div-tapaw-unique extra">
              <img
                src={two_photoImg}
                loading="lazy"
                width={80}
                height={80}
                alt
                className="image-10 _2 extra2"
              />
              <div className="div-block-20">
                <h4 className="heading-4 tapaw-feature">
                  Same Day Delivery
                  <br />
                </h4>
                <p className="paragraph-3">
                  Build the trust and credibility of your business by
                  fulfiling the needs of your customer timely.
                  <br />
                </p>
              </div>
            </div>
            <div className="div-tapaw-unique extra">
              <img
                src={three_photoImg}
                loading="lazy"
                width={80}
                height={80}
                alt
                className="image-10 _3 extra3"
              />
              <div className="div-block-20">
                <h4 className="heading-4 tapaw-feature">
                  Save Money & Time
                  <br />
                </h4>
                <p className="paragraph-3">
                  Save your operation cost and time by outsourcing your
                  delivery service to the expert.
                  <br />
                </p>
              </div>
            </div>
            <div className="div-tapaw-unique extra">
              <img
                src={four_photoImg}
                loading="lazy"
                width={80}
                height={80}
                alt
                className="image-10 _4 extra4"
              />
              <div className="div-block-20">
                <h4 className="heading-4 tapaw-feature">
                  Expand Your Business
                  <br />
                </h4>
                <p className="paragraph-3">
                  Scale-up your business to the new heights and reach more
                  customers.
                  <br />
                </p>
              </div>
            </div>
            <div className="div-tapaw-unique extra">
              <img
                src={five_photoImg}
                loading="lazy"
                width={80}
                height={80}
                alt
                className="image-10 _5 extra5"
              />
              <div className="div-block-20">
                <h4 className="heading-4 tapaw-feature">
                  Brand Leveraging
                  <br />
                </h4>
                <p className="paragraph-3">
                  Start earning more by leverage your new business to our
                  strong customer base and brand.
                  <br />
                </p>
              </div>
            </div>
            <div className="div-tapaw-unique extra">
              <img
                src={six_photoImg}
                loading="lazy"
                width={80}
                height={80}
                alt
                className="image-10 _7 extra6"
              />
              <div className="div-block-20">
                <h4 className="heading-4 tapaw-feature">
                  Support System
                  <br />
                </h4>
                <p className="paragraph-3">
                  Our team is always there for you to overcome any business
                  challenges.
                  <a href="https://tapaw.com/en/">
                    <br />
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wf-section">
        <div className="container-2 faq-container">
          <div className="div-block-17">
            <h2 className="pricing-h1 faq-h2">Frequently Asked Questions</h2>
            <div
              data-w-id="ecd20145-b589-5596-2637-0f5072b9b33a"
              style={{
                opacity: 1
              }}
              className="div-block-4"
            />
          </div>
          <BaseAccordion item={frequently} />
          {/* <div className="faq-wrap">
            <div className="faq-question">
              <div className="faq-plus-wrap">
                <div className="faq-plus-l" />
                <div className="faq-plus" />
              </div>
              <div className="faq-q-text">Introduction to TAPAW vendors</div>
            </div>
            <div className="faq-answer">
              <p className="faq-answer-text">
                We are looking for vendors to expand your business. TAPAW is a
                deliveryservice specialist. Start the business on a new
                platform at just yourfingertips, the delivery process will be
                much faster and safe.
              </p>
            </div>
          </div>
          <div className="faq-wrap">
            <div className="faq-question">
              <div className="faq-plus-wrap">
                <div className="faq-plus-l" />
                <div className="faq-plus" />
              </div>
              <div className="faq-q-text">
                How to register as a TAPAW vendor?
              </div>
            </div>
            <div className="faq-answer">
              <p className="faq-answer-text">
                Click on the link to register https //www.TAPAW.com
              </p>
            </div>
          </div>
          <div className="faq-wrap">
            <div className="faq-question">
              <div className="faq-plus-wrap">
                <div className="faq-plus-l" />
                <div className="faq-plus" />
              </div>
              <div className="faq-q-text">
                Who will send the pre-packaged order to the customer?
              </div>
            </div>
            <div className="faq-answer">
              <p className="faq-answer-text">
                Our riders will take the pre-packaged to the customer. Our
                riders are readyto fulfil every delivery in a high-quality
                manner at all times.
              </p>
            </div>
          </div>
          <div className="faq-wrap">
            <div className="faq-question">
              <div className="faq-plus-wrap">
                <div className="faq-plus-l" />
                <div className="faq-plus" />
              </div>
              <div className="faq-q-text">
                WHAT ARE THE OPERATING TIMES FOR TAPAW?
              </div>
            </div>
            <div className="faq-answer">
              <p className="faq-answer-text">
                Our delivery service is 24/7 and 365 Days but the processing
                time is between9.00 am to 6.00 pm
              </p>
            </div>
          </div>
          <div className="faq-wrap">
            <div className="faq-question">
              <div className="faq-plus-wrap">
                <div className="faq-plus-l" />
                <div className="faq-plus" />
              </div>
              <div className="faq-q-text">
                What are the conditions or criteria to become a TAPAW vendor?
              </div>
            </div>
            <div className="faq-answer">
              <p className="faq-answer-text">
                Click on the link to register https //www.TAPAW.com
              </p>
            </div>
          </div>
          <div className="faq-wrap">
            <div className="faq-question">
              <div className="faq-plus-wrap">
                <div className="faq-plus-l" />
                <div className="faq-plus" />
              </div>
              <div className="faq-q-text">
                How do I view orders that have been placed from customers?
              </div>
            </div>
            <div className="faq-answer">
              <p className="faq-answer-text">
                You can check the order journey from our system or through the
                apps.
              </p>
            </div>
          </div>
          <div className="faq-wrap">
            <div className="faq-question">
              <div className="faq-plus-wrap">
                <div className="faq-plus-l" />
                <div className="faq-plus" />
              </div>
              <div className="faq-q-text">
                What should I do if after I successfully top up but e wallet
                no such transaction?
              </div>
            </div>
            <div className="faq-answer">
              <p className="faq-answer-text">
                Do not worry. Contact admin for assistance
              </p>
            </div>
          </div>
          <div className="faq-wrap">
            <div className="faq-question">
              <div className="faq-plus-wrap">
                <div className="faq-plus-l" />
                <div className="faq-plus" />
              </div>
              <div className="faq-q-text">
                What are the benefits of being a TAPAW vendor?
              </div>
            </div>
            <div className="faq-answer">
              <p className="faq-answer-text">
                You can save costs and operating time by handing over your
                delivery serviceto TAPAW. Take your business to a new level
                and reach more customers.Start to generate more income by
                leveraging your new business tocustomers and our brand. Our
                team will always help you to overcome anybusiness challenges.
              </p>
            </div>
          </div>
          <div className="faq-wrap">
            <div className="faq-question">
              <div className="faq-plus-wrap">
                <div className="faq-plus-l" />
                <div className="faq-plus" />
              </div>
              <div className="faq-q-text">
                What documents are required to become a TAPAW vendor?
              </div>
            </div>
            <div className="faq-answer">
              <p className="faq-answer-text">
                Click on the link to register https //www.TAPAW.com
              </p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default withRouter(VendorRegistBody);
