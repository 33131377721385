import React, {useState, useEffect} from "react";
// import {useHistory} from 'react-router-dom';
import { Carousel as Carousel2 } from 'react-responsive-carousel';
import { useParams } from 'react-router-dom';
import GroupImg from '../../assets/img/images/Group.png';
import Group1Img from '../../assets/img/images/Group-1.png';
import Group2Img from '../../assets/img/images/Group-2.png';
import Group3Img from '../../assets/img/images/Group-3.png';
import Group4Img from '../../assets/img/images/Group-4.png';
import TapawMain800Img from '../../assets/img/images/tapaw-main.jpg';
import TapawMain500Img from '../../assets/img/images/tapaw-main-p-500.jpeg';
import TapawUserImg from '../../assets/img/images/tapaw-user.png';
import TapawDriverImg from '../../assets/img/images/tapaw-driver.png';
import TapawVendorImg from '../../assets/img/images/tapaw-vendor.png';
import TapawAgentImg from '../../assets/img/images/tapaw-agent.png';
import banner1 from '../../assets/img/images/tapaw-header1.jpg';
import banner2 from '../../assets/img/images/tapaw-header2.jpg.jpg';
import banner3 from '../../assets/img/images/tapaw-header3..jpg';
import banner4 from '../../assets/img/images/tapaw-header4.jpg';
import productimg1 from '../../assets/img/images/nathan-dumlao-bRdRUUtbxO0-unsplash.png';
import productimg2 from '../../assets/img/images/nathan-dumlao-bRdRUUtbxO0-unsplash.png';
import IndexPart1 from "./indexPart1";
import IndexPart2 from "./indexPart2";
import IndexPart3 from "./indexPart3";
import IndexPart4 from "./indexPart4";
import IndexPart5 from "./indexPart5";
import CTA from "./cta";
import Login from "../modals/login";
import { customLanguage } from "../../shared/languages";

const productsArray = [
  {id: 1, title: 'Product 1', img: productimg1},
  {id: 2, title: 'Product 2', img: productimg1},
  {id: 3, title: 'Product 3', img: productimg1},
  {id: 4, title: 'Product 4', img: productimg1},
  {id: 5, title: 'Product 5', img: productimg1},
  {id: 6, title: 'Product 6', img: productimg1},
  {id: 7, title: 'Product 7', img: productimg1},
  {id: 8, title: 'Product 8', img: productimg1},
]

const HomeBody = props => {
  const {referral} = useParams();
  const [loginFlag, setLoginFlag] = useState(false);

  useEffect(() => {
    if (referral!==undefined)
      localStorage.setItem('referralCode', referral);
  },[])

  const loginChecker = () => {
    if (localStorage.getItem("idToken") === "" || localStorage.getItem("idToken") === null) {
      setLoginFlag(true);
    } else {
      setLoginFlag(false);
      window.location.href = "/order";
    }
  }

  return (
    <div>
      <div className="section wf-section">
        
          <div className="col-lg-12" style={{ marginBottom: '40px', paddingLeft: 0, paddingRight: 0}}>
            <Carousel2 infiniteLoop autoPlay interval={3000} showStatus={false} showArrows={true} showIndicators={false} showThumbs={false} >
              <div>
                <div
                  className="sigma_subheader style-11 bg-cover bg-center"
                  style={{
                    backgroundImage: `url(${banner1})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: '0% 50%',
                    backgroundSize: 'cover',
                    textAlign: 'initial',
                    height: '80vh',
                    // height: '379px'
                  }}
                >
                  <div className="col-md-12 height-inside-banner">
                    <div className="col-md-4 text-banner-spacing">
                      <h2 className="heading-5 capitalize-all white-text justify-content-start banner-txt">{customLanguage(localStorage.getItem('language')).home.body.bannerTitle1}</h2>
                      <div className="div-block-4 background-white" />
                      <h3 className="title white-text">
                      {customLanguage(localStorage.getItem('language')).home.body.bannerSub1}                      </h3>
                      <a
                        href="#"
                        className="button w-button"
                        onClick={() => loginChecker()}
                      >
                        {customLanguage(localStorage.getItem('language')).home.body.bookBtn}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div
                  className="sigma_subheader style-11 bg-cover bg-center"
                  style={{
                    backgroundImage: `url(${banner2})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: '0% 50%',
                    backgroundSize: 'cover',
                    textAlign: 'initial',
                    height: '80vh'
                    // height: '379px'
                  }}
                >
                  <div className="col-md-12 height-inside-banner">
                    <div className="col-md-4 text-banner-spacing">
                      <h2 className="heading-5 capitalize-all white-text justify-content-start banner-txt">{customLanguage(localStorage.getItem('language')).home.body.bannerTitle2}</h2>
                      <div className="div-block-4 background-white" />
                      <h3 className="title white-text">
                      {customLanguage(localStorage.getItem('language')).home.body.bannerSub2}                      </h3>
                     
                      <a
                        href="#"
                        className="button w-button"
                        onClick={() => loginChecker()}
                      >
                        {customLanguage(localStorage.getItem('language')).home.body.bookBtn}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div
                  className="sigma_subheader style-11 bg-cover bg-center"
                  style={{
                    backgroundImage: `url(${banner3})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: '0% 50%',
                    backgroundSize: 'cover',
                    textAlign: 'initial',
                    height: '80vh'
                    // height: '379px'
                  }}
                >
                  <div className="col-md-12 height-inside-banner">
                    <div className="col-md-4 text-banner-spacing">
                      <h2 className="heading-5 capitalize-all white-text justify-content-start banner-txt">{customLanguage(localStorage.getItem('language')).home.body.bannerTitle3}</h2>
                      <div className="div-block-4 background-white" />
                      <h3 className="title white-text">
                      {customLanguage(localStorage.getItem('language')).home.body.bannerSub3}                      </h3>
                      <a
                        href="#"
                        className="button w-button"
                        onClick={() => loginChecker()}
                      >
                        {customLanguage(localStorage.getItem('language')).home.body.bookBtn}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div
                  className="sigma_subheader style-11 bg-cover bg-center"
                  style={{
                    backgroundImage: `url(${banner4})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: '0% 50%',
                    backgroundSize: 'cover',
                    textAlign: 'initial',
                    height: '80vh',
                    // height: '379px'
                  }}
                >
                  <div className="col-md-12 height-inside-banner">
                    <div className="col-md-4 text-banner-spacing">
                      <h2 className="heading-5 capitalize-all white-text justify-content-start banner-txt">{customLanguage(localStorage.getItem('language')).home.body.bannerTitle4}</h2>
                      <div className="div-block-4 background-white" />
                      <h3 className="title white-text">
                      {customLanguage(localStorage.getItem('language')).home.body.bannerSub4}
                      </h3>
                      <h3 className="title white-text">
                        {customLanguage(localStorage.getItem('language')).home.body.bannerSub4_2}
                      </h3>
                      <a
                        href="#"
                        className="button w-button"
                        onClick={() => loginChecker()}
                      >
                        {customLanguage(localStorage.getItem('language')).home.body.bookBtn}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel2>
          </div>
      </div>
      <CTA />
      {/* <div className="section-2 wf-section">
        <div className="container w-container">
          <div
            data-w-id="f69ba8e8-2e4d-e7f9-a26e-aa5fb5701e5b"
            style={{
              opacity:  1,
              // top: '-10vh'
            }}
            className="div-block-2"
          >
            <div>
              <div className="div-block-3 user" onClick={() => handleLinks('/registeruser')}>
                <img
                  src={TapawUserImg}
                  loading="lazy"
                  width={70}
                  height={70}
                  // alt
                  className="icon"
                />
                <h4 className="heading-2">USER</h4>
              </div>
            </div>
            <div className="div-block-3 driver" onClick={() => handleLinks('/registerdriver')}>
              <img
                src={TapawDriverImg}
                loading="lazy"
                width={70}
                height={70}
                // alt
                className="icon"
              />
              <h4 className="heading-2">DRIVER</h4>
            </div>
            <div className="div-block-3 vendor" onClick={() => handleLinks('/registervendor')}>
              <img
                src={TapawVendorImg}
                loading="lazy"
                width={70}
                height={70}
                // alt
                className="icon"
              />
              <h4 className="heading-2">VENDOR</h4>
            </div>
            <div className="div-block-3 agent" onClick={() => handleLinks('/registeragent')}>
              <img
                src={TapawAgentImg}
                loading="lazy"
                width={70}
                height={70}
                // alt
                className="icon"
              />
              <h4 className="heading-2">Agent</h4>
            </div>
            <div className="div-block-3 partner"
              // onClick={() => window.open('https://tapaw-apidoc.web.app/')}
             onClick={() => handleLinks('/connect')}
            >
              <img
                src={TapawAgentImg}
                loading="lazy"
                width={70}
                height={70}
                // alt
                className="icon"
              />
              <h4 className="heading-2">Partner</h4>
            </div>
          </div>
        </div>
      </div> */}
      <IndexPart1 />
      <IndexPart2 />
      <IndexPart3 />
      <IndexPart4 />
      <IndexPart5 />
      <Login
          justCloseModal={true}
          show={loginFlag}
          onHide={(flag) => setLoginFlag(flag)}
      />
    </div>
  );
}

export default HomeBody;
