import React, {useEffect, useState} from 'react';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import { baseUrl } from '../../shared/constants';

const EditProfileEmailPhone = (props) => {
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [allValidate, setAllValidate] = useState(false);
    const [loading, setLoading] = useState(false);
    const [triggerRefresh, setTriggerRefresh] = useState(false);
    const [updatedProfileData, setUpdatedProfileData] = useState(null);

    useEffect(() => {
        validationChecking();
    }, [email, phone]);

    function validationChecking() {
        if (props.editType === 'email' && email !== '') {
            setAllValidate(true);
        } 
        else if (props.editType === 'phone' && phone !== '') {
            setAllValidate(true);
        } 
        else {
            // console.log(`edit type ${props.editType}, email ${email}, phone ${phone}`);
            setAllValidate(false);
        }
    }

    async function updateProfileRequest(type, val) {
        const token = localStorage.getItem("idToken");
        const inp = {
            token,
        };
        if (type==='email') { 
            inp.type = 'email';
            inp.email = val; 
        } else {
            inp.type = 'phoneNumber'
            inp.phoneNumber = val;
        }
        // console.log(`data to update ${JSON.stringify(inp)}`);
        setLoading(true);
        await axios.post(`${baseUrl}/request_change_profile`, inp)
            .then(res => {
                const data = res.data
                // console.log(`update profile data ${JSON.stringify(data)}`);
                setUpdatedProfileData(data);
                setLoading(false);
                // localStorage.setItem("fullName", email);
                props.onRefresh(1); // to trigger refresh parent get profile
                // props.history.push("/profile"); 
                props.onHide(false);
            })
            .catch(err => {
                // console.log(`error response ${err.response}`);
                // console.log(err.response);
                // loading = false;
                // error = err;
                setLoading(false);
                if (err.response!==undefined) {
                  // client received error response (5xx, 4xx)
                //   console.log(`error response ${err.response}`);
                    // console.log(err.response);
                    if (err.response.data.includes('Firebase ID token has expired')) {
                        // console.log('refresh');
                        refreshTokenRequest();
                    }
                } else if (err.request!==undefined) {
                  // client never received a response, or a request never left
                //   console.log(`error request ${err.request}`);
                } else {
                //   console.log(`get error ${err}`);
                }
            })
    }

    async function refreshTokenRequest() {
        setLoading(true);
        const refresh_token = localStorage.getItem("refreshToken");
        // console.log(`refresh token in purchase history ${refresh_token}`);
        await axios.post(`${baseUrl}/refresh_token`, {
            refresh_token
        })
            .then(res => {
                const data = res.data
                // console.log(`refresh data ${JSON.stringify(data)}`);
                localStorage.setItem("idToken", data.id_token);
                localStorage.setItem("refreshToken", data.refresh_token);
                setLoading(false);
                setTriggerRefresh(old => old+1);
                  
            })
            .catch(err => {
                // console.log(`error response ${err.response}`);
                // console.log(err.response);
                // loading = false;
                // error = err;
                setLoading(false);
                if (err.response!==undefined) {
                  // client received error response (5xx, 4xx)
                    localStorage.setItem("idToken", "");
                    localStorage.setItem("customToken", "");
                    localStorage.setItem("refreshToken", "");
                    localStorage.setItem("fullName", "");
                    localStorage.setItem("username", "");
                    localStorage.setItem("userType", "");
                    localStorage.setItem("uid", "");
                    localStorage.setItem("completedRegister", "");
                    props.history.push('/');
                    alert('Session expired. Please login again.');
                //   console.log(`error response ${err.response}`);
                //   console.log(err.response);
                } else if (err.request!==undefined) {
                  // client never received a response, or a request never left
                //   console.log(`error request ${err.request}`);
                } else {
                //   console.log(`get error ${err}`);
                }
            })
    }

    function selectVal() {
        if (props.editType === 'email') {
            return email;
        } else {
            return phone;
        }
    }

    return (
        <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        // dialogClassName="modal-rounded"
        >
            
            <Modal.Body className="modal-content">
                <div className="col-lg-12">
                    <h4>EDIT CUSTOMER PROFILE</h4>
                </div>
                {props.editType==='email'?
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>Email</label>
                                    <input type="text" name="email" placeholder="test@gmail.comm" onChange={a => setEmail(a.target.value)} />
                                </div>
                            </div>
                        </div>
                    </div>
                    : 
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>Preferred Contact Number</label>
                                    <input type="text" name="phone" placeholder="xxxxx" onChange={a => setPhone(a.target.value)} />
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-lg-4 offset-lg-2">
                            <button type="button" className="tempah-sekarang cancel-profile" onClick={() => {props.onHide(false); setEmail(''); setPhone('');}}>
                                CANCEL
                            </button>
                        </div>
                        <div className="col-lg-4">
                            {
                                loading?<div className="col-lg-12 profile-text-centre"><CircularProgress /></div>:
                                <button type="button" className={`tempah-sekarang ${allValidate?'enabled-btn':'disabled-btn'}`} disabled={allValidate?false:true}  onClick={() => updateProfileRequest(props.editType, selectVal() )}>
                                    CONFIRM CHANGE
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default withRouter(EditProfileEmailPhone);