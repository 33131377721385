import React, {useState, useEffect} from "react";
import {useForm} from 'react-hook-form';
import axios from "axios";
import {withRouter} from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Carousel } from 'react-responsive-carousel';
import yScheduleImg from '../../assets/img/images/y-schedule.jpg';
import yMoveImg from '../../assets/img/images/y-move.jpg';
import yAllImg from '../../assets/img/images/y-move.jpg';
import yMalaysiaImg from '../../assets/img/images/y-malaysia.jpg';
import yCheaperImg from '../../assets/img/images/y-cheaper.jpg';
import yFasterImg from '../../assets/img/images/y-faster.jpg';
import { baseUrl } from "../../shared/constants";
import regimg from '../../assets/img/images/screenshot-1-select-pickup-location.png';
import regimg2 from '../../assets/img/images/screenshot-2-dropoff-location.png';
import regimg3 from '../../assets/img/images/screenshot-3-select-vehicle.png';
import regimg4 from '../../assets/img/images/screenshot-4-schedul-order.png';
import regimg5 from '../../assets/img/images/screenshot-5-payment-method.png';
import regimg6 from '../../assets/img/images/screenshot-6-placeorder.png';
import Logo from '../../assets/img/images/logo.svg';
import DownloadApp from "../home/downloadapp";
import BaseAccordion from "./baseAccordion";
import { customLanguage } from "../../shared/languages";

const frequently = [
  { title: customLanguage(localStorage.getItem('language')).registration.agent.q1, desc: 'TAPAW is a growing logistics and delivery service start-up company. We offer the best service quality of all time through a complete ecosystem among merchants, riders and users. Now, delivery service is no longer a burden, all your solutions are here.' },
  { title: customLanguage(localStorage.getItem('language')).registration.agent.q2, desc: 'Anything can be delivered using a rider service but the size of the item must be appropriate for our rider. It depends on the terms and conditions by TAPAW.' },
  { title: customLanguage(localStorage.getItem('language')).registration.agent.q3, desc: 'Riders who have registered under TAPAW will make deliveries to your customers.' },
  { title: customLanguage(localStorage.getItem('language')).registration.agent.q4, desc: 'Our delivery service is 24/7 and 365 Days but the processing time is between 9.00 am to 6.00 pm.' },
  { title: customLanguage(localStorage.getItem('language')).registration.agent.q5, desc: 'Yes, it should be as long as you are able to pay the delivery service fee according to the distance of the area that will be charged to the TAPAW rider. Everything becomes easier, faster and faster.' },
  { title: customLanguage(localStorage.getItem('language')).registration.agent.q6, 
  customDesc: <p className="faq-answer-text">
    <strong>| {customLanguage(localStorage.getItem('language')).registration.agent.q6a_1a}</strong>- {customLanguage(localStorage.getItem('language')).registration.agent.q6a_1b}
    <br />
    <strong>| {customLanguage(localStorage.getItem('language')).registration.agent.q6a_2a} </strong>- {customLanguage(localStorage.getItem('language')).registration.agent.q6a_2b}
    <br />
    <strong>| {customLanguage(localStorage.getItem('language')).registration.agent.q6a_3a} </strong>- {customLanguage(localStorage.getItem('language')).registration.agent.q6a_3b}
    <br />
    <strong>| {customLanguage(localStorage.getItem('language')).registration.agent.q6a_4a}</strong> - {customLanguage(localStorage.getItem('language')).registration.agent.q6a_4b}
    <br />
    <strong>| {customLanguage(localStorage.getItem('language')).registration.agent.q6a_5a}</strong> - {customLanguage(localStorage.getItem('language')).registration.agent.q6a_5b}
    <br />
    <strong>| {customLanguage(localStorage.getItem('language')).registration.agent.q6a_6a}</strong> - {customLanguage(localStorage.getItem('language')).registration.agent.q6a_6b}
  </p>,},
  { title: customLanguage(localStorage.getItem('language')).registration.agent.q7, desc: 'Do not worry. Contact admin for assistance admin@tapaw.com' },
];

const AgentRegistBody = props => {
  const {state} = props.location;
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const [phone, setPhone] = useState('');
  const [phoneValidate, setPhoneValidate] = useState('');
  const [referralValidate, setReferralValidate] = useState('');
  const [referal, setReferral] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('referralCode')) {
      // console.log(`referral passed to agent register ${state.referral}`);
      setReferral(localStorage.getItem('referralCode'));
    }
  }, []);


  useEffect(() => {
    referralSetup();
  }, [referal]);

  async function phoneRequest ( phone ) {
    let data = null;
    let error = '';
    // let loading = true;
   
    await axios.post(`${baseUrl}/check_phone`, {
        phone, 
    })
    .then(res => {
        // console.log('get data')
        // loading = false;
        data = res.data;
        setPhoneValidate(data);
        setPhone(phone);
    })
    .catch(err => {
        // loading = false;
        error = err;
        if (err.response!==undefined) {
            // client received error response (5xx, 4xx)
            // console.log(`error response ${err.response}`);
            setPhoneValidate('error');
        } else if(err.request!==undefined) {
            // client never received a response, or a request never left
            // console.log(`error request ${err.request}`);
            setPhoneValidate('error');
        } else {
            // console.log(`get error ${err}`);
            setPhoneValidate('error');
        }
    })
    // .finally(() => loading = false)

    // console.log(`loading ${loading}`);
    // console.log(`data ${data}`);
  }

  function referralSetup() {
    if (referal != '') {
      referralRequest(referal)
    }
  }

  // backend use typo version => referal
  async function referralRequest ( referal ) {
    let data = null;
    let error = '';
    // let loading = true;
    if (referal != '') {
      await axios.post(`${baseUrl}/check_referal`, {
          referal,
      })
      .then(res => {
          // loading = false;
          data = res.data;
          // console.log(`referral request data ${JSON.stringify(data)}`);
          setReferralValidate(data);
          // setReferral(referal);
      })
      .catch(err => {
          // loading = false;
          error = err;
          if (err.response!==undefined) {
              // client received error response (5xx, 4xx)
              // console.log(`error response ${err.response}`);
              setReferralValidate('error');
          } else if(err.request!==undefined) {
              // client never received a response, or a request never left
              // console.log(`error request ${err.request}`);
              setReferralValidate('error');
          } else {
              // console.log(`get error ${err}`);
              setReferralValidate('error');
          }
      })
    }
    // .finally(() => loading = false)

    // console.log(`loading ${loading}`);
    // console.log(`data ${data}`);
  }

  const onSubmit = data => {
    // console.log(data);
    registerUser(data);
  }

  const registerUser = async (dataPassed) => 
  {
    let data = null;
    let error = '';
    const {email, password, name, username} = dataPassed;
    setLoading(true);
    await axios.post(`${baseUrl}/register`, {
      email,
      password,
      phone, 
      full_name: name, 
      username, 
      referal,
      usertype: 'agent'
    })
    .then(async res => {
      setLoading(false);
      // console.log('get data')
      // loading = false;
      data = res.data;
      // setSecondPhase('Default');
      
      setLoading(true);
      //console.log(`email ${email}, password ${password}`)
      await axios.post(`${baseUrl}/login`, {
          email,
          password,
      })
      .then(res => {
          // console.log('get data')
          setLoading(false);
          data = res.data;
          // console.log(`login data ${JSON.stringify(data)}`);
          localStorage.setItem("idToken", data.idToken);
          localStorage.setItem("customToken", data.customToken);
          localStorage.setItem("refreshToken", data.refreshToken);
          localStorage.setItem("fullName", data.fullName);
          localStorage.setItem("username", data.username);
          localStorage.setItem("uid", data.uid);
          localStorage.setItem("userType", data.usertype[0].type);
          localStorage.setItem("completedRegister", data.completedRegister!==undefined?data.completedRegister:false);
          props.history.push("/profile");
          // props.onHide(false)
      })
      .catch(err => {
          setLoading(false);
          error = err;
          if (err.response!==undefined) {
              // client received error response (5xx, 4xx)
              // console.log(`error response ${err.response}`);
          } else if (err.request!==undefined) {
              // client never received a response, or a request never left
              // console.log(`error request ${err.request}`);
          } else {
              // console.log(`get error ${err}`);
          }
      })
    })
    .catch(err => {
      setLoading(false);
      // loading = false;
      error = err;
      if (err.response!==undefined) {
          // client received error response (5xx, 4xx)
          // console.log(`error response ${JSON.stringify(err.response)}`);
      } else if(err.request!==undefined) {
          // client never received a response, or a request never left
          // console.log(`error request ${err.request}`);
      } else {
          // console.log(`get error ${err}`);
      }
    })
  }

  return (
    <div>
      <div className="wf-section">
        <div className="div-block-8 agent">
          <div className="container w-container">
            <div className="columns-2 w-row">
              <div className="column-3 w-col w-col-6">
                <div className="div-block-18">
                  <div className="div-block-19 user">
                    <h1 className="heading-9">
                    {customLanguage(localStorage.getItem('language')).registration.agent.headline1}
                    </h1>
                    <p className="paragraph-title">
                    {customLanguage(localStorage.getItem('language')).registration.agent.headline2}
                      <br />
                      {customLanguage(localStorage.getItem('language')).registration.agent.headline3}
                    </p>
                  </div>
                </div>
              </div>
              <div className="column-4 w-col w-col-6">
                <div className="div-block-9">
                  <div className="form-block w-form">
                    <div className="col-md-12" style={{marginLeft: '65px'}}>
                      <img src={Logo} className="logo-regist" />
                    </div>
                    <div className="col-md-12" style={{marginLeft: '65px'}}>
                      <span className="regist-title">{customLanguage(localStorage.getItem('language')).registration.agent.mainTitle}</span>
                    </div>
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      className="form-2"
                    >
                      <label htmlFor="name" className="field-label">
                      {customLanguage(localStorage.getItem('language')).registration.agent.field_name}
                      </label>
                      <input
                        {...register("name", { required: true })}
                        type="text"
                        className="form w-input"
                        maxLength={256}
                        name="name"
                        data-name="Name"
                        placeholder={customLanguage(localStorage.getItem('language')).registration.agent.name_placeholder}
                        id="name"
                      />
                      {errors.name && <span>{customLanguage(localStorage.getItem('language')).registration.field_error}</span>}
                      <label htmlFor="email" className="field-label">
                      {customLanguage(localStorage.getItem('language')).registration.agent.field_email}
                      </label>
                      <input
                        {...register("email", { required: true })}
                        type="email"
                        className="form w-input"
                        maxLength={256}
                        name="email"
                        data-name="Email"
                        placeholder={customLanguage(localStorage.getItem('language')).registration.agent.email_placeholder}
                        id="email"
                      />
                      {errors.email && <span>{customLanguage(localStorage.getItem('language')).registration.field_error}</span>}
                      <label htmlFor="email-2" className="field-label">
                      {customLanguage(localStorage.getItem('language')).registration.agent.field_password}
                      </label>
                      <input
                        {...register("password", { required: true })}
                        type="password"
                        className="form w-input"
                        maxLength={256}
                        name="password"
                        data-name="password"
                        placeholder={customLanguage(localStorage.getItem('language')).registration.agent.password_placeholder}
                        id="password"
                      />
                      {errors.password && <span>{customLanguage(localStorage.getItem('language')).registration.field_error}</span>}
                      <label htmlFor="name-6" className="field-label">
                      {customLanguage(localStorage.getItem('language')).registration.agent.field_phone}{" "}
                      </label>
                      <input
                        // {...register("phoneNumber", { required: true })}
                        onChange={e => phoneRequest(e.target.value)}
                        type="text"
                        className="form w-input"
                        maxLength={256}
                        name="phoneNumber"
                        data-name="phoneNumber"
                        placeholder={customLanguage(localStorage.getItem('language')).registration.agent.phone_placeholder}
                        id="phoneNumber"
                      />
                      <span className={phoneValidate == 'Phone is available to use' ? 'validation-msg-passed' : 'validation-msg-error'}>{phoneValidate}</span>
                      {/* {errors.phoneNumber && <span>This field is required</span>} */}
                      <label htmlFor="name-6" className="field-label">
                      {customLanguage(localStorage.getItem('language')).registration.agent.field_username}{" "}
                      </label>
                      <input
                        {...register("username", { required: true })}
                        type="text"
                        className="form username w-input"
                        maxLength={256}
                        name="username"
                        data-name="username"
                        placeholder={customLanguage(localStorage.getItem('language')).registration.agent.username_placeholder}
                        id="username"
                      />
                      {errors.username && <span>{customLanguage(localStorage.getItem('language')).registration.field_error}</span>}
                      {/* <div className="div-block-10" /> */}
                      <label htmlFor="name-6" className="field-label">
                      {customLanguage(localStorage.getItem('language')).registration.agent.refer}
                      </label>
                      <input
                        type="text"
                        className="form no-edit w-input"
                        maxLength={256}
                        name="name-5"
                        data-name="Name 5"
                        placeholder={referal}
                        id="name-5"
                        disabled
                      />
                      <span className={'validation-msg-error'}>{referralValidate=='Referal not available'||referralValidate=='error'?referralValidate:''}</span>
                      <div className="div-block-11">
                        <label className="w-checkbox checkbox-field">
                          <input
                            type="checkbox"
                            id="checkbox"
                            name="checkbox"
                            data-name="Checkbox"
                            className="w-checkbox-input"
                            required
                          />
                          <span className="checkbox-label w-form-label">
                          {customLanguage(localStorage.getItem('language')).registration.checkbox_1a}{" "}
                            <a href="https://tapaw.com/static/media/GeneralTerms.08000de2.pdf">
                              <strong className="bold-text">
                              {customLanguage(localStorage.getItem('language')).registration.checkbox_1b}
                              </strong>
                            </a>{" "}
                            {customLanguage(localStorage.getItem('language')).registration.and}{" "}
                            <a href="/privacy">
                              <span className="bold-text">
                                <strong>{customLanguage(localStorage.getItem('language')).registration.checkbox_1c}</strong>
                              </span>
                            </a>
                          </span>
                        </label>
                        <label className="w-checkbox checkbox-field">
                          <input
                            type="checkbox"
                            id="checkbox-2"
                            name="checkbox-2"
                            data-name="Checkbox 2"
                            className="w-checkbox-input"
                            required
                          />
                          <span className="checkbox-label w-form-label">
                          {customLanguage(localStorage.getItem('language')).registration.checkbox_2}
                          </span>
                        </label>
                      </div>
                      {
                      loading? <div className="col-lg-12 profile-text-centre"><CircularProgress /></div>:
                      <button type="submit" className="submit-register">{customLanguage(localStorage.getItem('language')).registration.submit}</button>
                      // <input
                      //   type="submit"
                      //   defaultValue="Submit"
                      //   data-wait="Please wait..."
                      //   className='button w-button'
                      //   // className="button registration w-button"
                      // />
                      }
                    </form>
                    <div className="success w-form-done">
                      <img
                        src="images/success_button.gif"
                        loading="lazy"
                        width={219}
                        // alt
                        className="image-7"
                      />
                      <div className="text-block-2">
                      {customLanguage(localStorage.getItem('language')).registration.success}
                      </div>
                    </div>
                    <div className="error-message error-message-2 w-form-fail">
                      <div>
                      {customLanguage(localStorage.getItem('language')).registration.fail}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{marginTop: '30px'}}>
        <DownloadApp />
      </div>
      <div className="section-7 wf-section">
        <div className="container w-container">
          <h2 className="heading-5">{customLanguage(localStorage.getItem('language')).registration.agent.reason_title}</h2>
          <div className="row">
            <div className="col-md-4" />
            <div
                className="col-md-4"
              >
              <div className="yellow-line" style={{width: '100%', marginTop: '12px'}} />
            </div>
          </div>
          <p className="description-text">{customLanguage(localStorage.getItem('language')).registration.agent.reason_desc}</p>
          <div
            data-w-id="596615e2-11b9-05f2-bf47-7f7055e79bc7"
            style={{
              opacity: 0
            }}
            className="div-block-4"
          />
          <div className="w-layout-grid grid-2">
            <div className="div-tapaw-unique">
              <img
                src={yScheduleImg}
                loading="lazy"
                width={80}
                height={80}
                // alt
              />
              <h4 className="heading-4 tapaw-feature">
              {customLanguage(localStorage.getItem('language')).registration.agent.r1_title}
              </h4>
              <p className="paragraph-3">
              {customLanguage(localStorage.getItem('language')).registration.agent.r1_desc}
              </p>
            </div>
            <div className="div-tapaw-unique">
              <img
                src={yMoveImg}
                loading="lazy"
                width={80}
                height={80}
                // alt
              />
              <h4 className="heading-4 tapaw-feature">{customLanguage(localStorage.getItem('language')).registration.agent.r2_title}</h4>
              <p className="paragraph-3">{customLanguage(localStorage.getItem('language')).registration.agent.r2_desc}</p>
            </div>
            <div className="div-tapaw-unique">
              <img
                src={yAllImg}
                loading="lazy"
                width={80}
                height={80}
                // alt
              />
              <h4 className="heading-4 tapaw-feature">{customLanguage(localStorage.getItem('language')).registration.agent.r3_title}</h4>
              <p className="paragraph-3">
              {customLanguage(localStorage.getItem('language')).registration.agent.r3_desc}
              </p>
            </div>
            <div className="div-tapaw-unique">
              <img
                src={yMalaysiaImg}
                loading="lazy"
                width={80}
                height={80}
                // alt
              />
              <h4 className="heading-4 tapaw-feature">
              {customLanguage(localStorage.getItem('language')).registration.agent.r4_title}
              </h4>
              <p className="paragraph-3">
              {customLanguage(localStorage.getItem('language')).registration.agent.r4_desc}
              </p>
            </div>
            <div className="div-tapaw-unique">
              <img
                src={yCheaperImg}
                loading="lazy"
                width={80}
                height={80}
                // alt
              />
              <h4 className="heading-4 tapaw-feature">
              {customLanguage(localStorage.getItem('language')).registration.agent.r5_title}
              </h4>
              <p className="paragraph-3">
              {customLanguage(localStorage.getItem('language')).registration.agent.r5_desc}
              </p>
            </div>
            <div className="div-tapaw-unique">
              <img
                src={yFasterImg}
                loading="lazy"
                width={80}
                height={80}
                // alt
              />
              <h4 className="heading-4 tapaw-feature">{customLanguage(localStorage.getItem('language')).registration.agent.r6_title}</h4>
              <p className="paragraph-3">
              {customLanguage(localStorage.getItem('language')).registration.agent.r6_desc}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="section-6 wf-section">
          <div className="col-md-12">
            <Carousel infiniteLoop autoPlay interval={3000} showStatus={false} showArrows={true} showIndicators={true} showThumbs={false} >
              <div className="row" style={{height: '100vh'}}>
                  <div className="col-md-5" style={{alignSelf: 'center'}}>
                    <img
                      src={regimg}
                      loading="lazy"
                      className="reg-slider"
                    />
                  </div>
                  <div className="col-md-7" style={{alignSelf: 'center'}}>
                    <h2 className="slider-text">
                    {customLanguage(localStorage.getItem('language')).registration.agent.banner_1}
                    </h2>
                    <div className="animated-div">
                      <div className="div-block-16">
                        <div className="text-block-3">q</div>
                        <div className="text-block-4">
                        {customLanguage(localStorage.getItem('language')).registration.agent.banner_1sub}
                        </div>
                      </div>
                      <p className="description-text slider">
                      {customLanguage(localStorage.getItem('language')).registration.agent.banner_1desc}
                      </p>
                    </div>
                  </div>
              </div>
              <div className="row" style={{height: '100vh'}}>
                  <div className="col-md-6" style={{alignSelf: 'center'}}>
                    <img
                      src={regimg2}
                      loading="lazy"
                      className="reg-slider"
                    />
                  </div>
                  <div className="col-md-6" style={{alignSelf: 'center'}}>
                    <h2 className="slider-text">
                    {customLanguage(localStorage.getItem('language')).registration.agent.banner_2}
                    </h2>
                    <div className="animated-div">
                      <div className="div-block-16">
                        <div className="text-block-3">w</div>
                        <div className="text-block-4">
                        {customLanguage(localStorage.getItem('language')).registration.agent.banner_2sub}
                        </div>
                      </div>
                      <p className="description-text slider">
                      {customLanguage(localStorage.getItem('language')).registration.agent.banner_2desc}
                      </p>
                    </div>
                  </div>
              </div>
              <div className="row" style={{height: '100vh'}}>
                  <div className="col-md-6" style={{alignSelf: 'center'}}>
                    <img
                      src={regimg3}
                      loading="lazy"
                      className="reg-slider"
                    />
                  </div>
                  <div className="col-md-6" style={{alignSelf: 'center'}}>
                    <h2 className="slider-text">
                    {customLanguage(localStorage.getItem('language')).registration.agent.banner_3}
                    </h2>
                    <div className="animated-div">
                      <div className="div-block-16">
                        <div className="text-block-3">e</div>
                        <div className="text-block-4">{customLanguage(localStorage.getItem('language')).registration.agent.banner_3sub}</div>
                      </div>
                      <p className="description-text slider">
                      {customLanguage(localStorage.getItem('language')).registration.agent.banner_3desc}{" "}
                        <a href="#" className="link-2">
                        {customLanguage(localStorage.getItem('language')).registration.agent.banner_3_2desc}
                        </a>
                      </p>
                    </div>
                  </div>
              </div>
              <div className="row" style={{height: '100vh'}}>
                  <div className="col-md-6" style={{alignSelf: 'center'}}>
                    <img
                      src={regimg4}
                      loading="lazy"
                      className="reg-slider"
                    />
                  </div>
                  <div className="col-md-6" style={{alignSelf: 'center'}}>
                    <h2 className="slider-text">
                    {customLanguage(localStorage.getItem('language')).registration.agent.banner_4}
                    </h2>
                    <div className="animated-div">
                      <div className="div-block-16">
                        <div className="text-block-3">r</div>
                        <div className="text-block-4">
                        {customLanguage(localStorage.getItem('language')).registration.agent.banner_4sub}
                        </div>
                      </div>
                      <p className="description-text slider">
                      {customLanguage(localStorage.getItem('language')).registration.agent.banner_4desc}
                      </p>
                    </div>
                  </div>
              </div>
              <div className="row" style={{height: '100vh'}}>
                  <div className="col-md-6" style={{alignSelf: 'center'}}>
                    <img
                      src={regimg5}
                      loading="lazy"
                      className="reg-slider"
                    />
                  </div>
                  <div className="col-md-6" style={{alignSelf: 'center'}}>
                    <h2 className="slider-text">
                    {customLanguage(localStorage.getItem('language')).registration.agent.banner_5}
                    </h2>
                    <div className="animated-div">
                      <div className="div-block-16">
                        <div className="text-block-3">t</div>
                        <div className="text-block-4">
                        {customLanguage(localStorage.getItem('language')).registration.agent.banner_5sub}
                        </div>
                      </div>
                      <p className="description-text slider">
                      {customLanguage(localStorage.getItem('language')).registration.agent.banner_5desc}
                      </p>
                    </div>
                  </div>
              </div>
              <div className="row" style={{height: '100vh'}}>
                  <div className="col-md-6" style={{alignSelf: 'center'}}>
                    <img
                      src={regimg6}
                      loading="lazy"
                      className="reg-slider"
                    />
                  </div>
                  <div className="col-md-6" style={{alignSelf: 'center'}}>
                    <h2 className="slider-text">
                    {customLanguage(localStorage.getItem('language')).registration.agent.banner_6}
                    </h2>
                    <div className="animated-div">
                      <div className="div-block-16">
                        <div className="text-block-3">y</div>
                        <div className="text-block-4">
                        {customLanguage(localStorage.getItem('language')).registration.agent.banner_6sub}
                        </div>
                      </div>
                      <p className="description-text slider">
                      {customLanguage(localStorage.getItem('language')).registration.agent.banner_6desc}
                      </p>
                    </div>
                  </div>
              </div>
            </Carousel>
          </div>
      </div>
      <div className="container w-container">

      <div className="section-10 wf-section">
          <div
            style={{
              paddingTop: "56.17021276595745%",
              marginLeft: 'auto',
              marginRight: 'auto'
            }}
            className="w-embed-youtubevideo youtube"
          >
            <iframe
              src="https://www.youtube.com/embed/HOvSkyecj1M?rel=0&controls=1&autoplay=0&mute=0&start=0"
              frameBorder={0}
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                pointerEvents: "auto",
                borderColor: '#ffcd3d', 
                borderRadius: '5px', 
                borderWidth: '10px', 
                textShadow: 'none'
              }}
              allow="autoplay; encrypted-media"
              allowFullScreen
            />
          </div>
        </div>
      </div>
      <div className="wf-section">
        <div className="container with-paragraph w-container">
          <h2 className="heading-5">{customLanguage(localStorage.getItem('language')).registration.agent.reason_title2}</h2>
          <div className="yellow-line" style={{width: '200px', marginTop: '12px'}} />
          <p className="description-text">{customLanguage(localStorage.getItem('language')).registration.agent.reason_desc2}</p>
          {/* <div
            data-w-id="daea7551-0993-1f1a-2455-d802e792a1d8"
            style={{
              opacity: 0
            }}
            className="div-block-4"
          /> */}
          {/* <div className="w-layout-grid grid">
            <div className="div-offer">
              <div className="title-image">
                FOOD &<br />
                GROCERY
              </div>
            </div>
            <div className="div-offer petplant">
              <div className="title-image">
                PET &<br />
                PLANT
              </div>
            </div>
            <div className="div-offer goods">
              <div className="title-image">
                SURPRISE DELIVERY & VALUABLE GOODS
              </div>
            </div>
            <div className="div-offer largeitems">
              <div className="title-image">LARGE ITEMS</div>
            </div>
            <div className="div-offer smallitems">
              <div className="title-image">SMALL ITEMS</div>
            </div>
            <div className="div-offer movinghouse">
              <div className="title-image">
                MOVING
                <br />
                HOUSE
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div className="wf-section">
        <div className="container-2 faq-container">
          <div className="div-block-17">
            <h2 className="pricing-h1 faq-h2">{customLanguage(localStorage.getItem('language')).registration.agent.faqTitle}</h2>
            <div
              data-w-id="ecd20145-b589-5596-2637-0f5072b9b33a"
              style={{
                opacity: 1
              }}
              className="div-block-4"
            />
          </div>
          <BaseAccordion item={frequently} />
          {/* <div className="faq-wrap">
            <div className="faq-question">
              <div className="faq-plus-wrap">
                <div className="faq-plus-l" />
                <div className="faq-plus" />
              </div>
              <div className="faq-q-text">WHAT IS TAPAW?</div>
            </div>
            <div className="faq-answer">
              <p className="faq-answer-text">
                TAPAW is a growing logistics and delivery service start-up
                company. We offer the best service quality of all time through
                a complete ecosystem among merchants, riders and users. Now,
                delivery service is no longer a burden, all your solutions are
                here.
              </p>
            </div>
          </div>
          <div className="faq-wrap">
            <div className="faq-question">
              <div className="faq-plus-wrap">
                <div className="faq-plus-l" />
                <div className="faq-plus" />
              </div>
              <div className="faq-q-text">
                WHAT CAN BE DELIVERED BY TAPAW?
              </div>
            </div>
            <div className="faq-answer">
              <p className="faq-answer-text">
                Anything can be delivered using a rider service but the size
                of the item must be appropriate for our rider. It depends on
                the terms and conditions by TAPAW.
              </p>
            </div>
          </div>
          <div className="faq-wrap">
            <div className="faq-question">
              <div className="faq-plus-wrap">
                <div className="faq-plus-l" />
                <div className="faq-plus" />
              </div>
              <div className="faq-q-text">WHO WILL MAKE THE DELIVERY?</div>
            </div>
            <div className="faq-answer">
              <p className="faq-answer-text">
                Riders who have registered under TAPAW will make deliveries to
                your customers.
              </p>
            </div>
          </div>
          <div className="faq-wrap">
            <div className="faq-question">
              <div className="faq-plus-wrap">
                <div className="faq-plus-l" />
                <div className="faq-plus" />
              </div>
              <div className="faq-q-text">
                WHAT ARE THE OPERATING TIMES FOR TAPAW?
              </div>
            </div>
            <div className="faq-answer">
              <p className="faq-answer-text">
                Our delivery service is 24/7 and 365 Days but the processing
                time is between 9.00 am to 6.00 pm.
              </p>
            </div>
          </div>
          <div className="faq-wrap">
            <div className="faq-question">
              <div className="faq-plus-wrap">
                <div className="faq-plus-l" />
                <div className="faq-plus" />
              </div>
              <div className="faq-q-text">
                CAN I SEND ORDERS TO OTHER STATE?
              </div>
            </div>
            <div className="faq-answer">
              <p className="faq-answer-text">
                Yes, it should be as long as you are able to pay the delivery
                service fee according to the distance of the area that will be
                charged to the TAPAW rider. Everything becomes easier, faster
                and faster.
              </p>
            </div>
          </div>
          <div className="faq-wrap">
            <div className="faq-question">
              <div className="faq-plus-wrap">
                <div className="faq-plus-l" />
                <div className="faq-plus" />
              </div>
              <div className="faq-q-text">
                What are the benefits of using TAPAW? 
              </div>
            </div>
            <div className="faq-answer">
              <p className="faq-answer-text">
                On-time - Orders are sent on schedule
                <br />
                <strong>| Affordable </strong>- Meets consumerexpectations at
                a faster cost
                <br />
                <strong>| Safe </strong>- All assignments are handled by
                certified and experienced riders
                <br />
                <strong>| Reliable</strong> - High performance in ensuring
                orders aredelivered in good condition <br />
                <strong>| Flexibility</strong> - Various types of
                transportation to complete various delivery sizes
                <br />
                <strong>| Quality</strong> - Superior service quality for each
                process is always a priority at all times.
              </p>
            </div>
          </div>
          <div className="faq-wrap">
            <div className="faq-question">
              <div className="faq-plus-wrap">
                <div className="faq-plus-l" />
                <div className="faq-plus" />
              </div>
              <div className="faq-q-text">
                What should I do if after I successfully top up but ewallet no
                such transaction?
              </div>
            </div>
            <div className="faq-answer">
              <p className="faq-answer-text">
                Do not worry. Contact admin for assistance admin@tapaw.com
              </p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default withRouter(AgentRegistBody);
