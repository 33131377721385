import React, {useEffect, useState} from "react";
import {useForm} from 'react-hook-form';
import axios from "axios";
import {withRouter} from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DateFnsUtils from '@date-io/date-fns';
import {
  DatePicker,
  TimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { baseUrl } from "../../shared/constants";
import {storage, auth} from '../../shared/firebaseConfig';
import "../../assets/css/style-2.css";
import Logo from '../../assets/img/images/logo.svg';

const DriverRegist2Body = props => {
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const [loading, setLoading] = useState(false);
  const [statesLoading, setStatesLoading] = useState(false);
  const [brandsLoading, setBrandsLoading] = useState(false);
  const [statesData, setStatesData] = useState(null);
  const [brandsData, setBrandsData] = useState(null);
  const [triggerRefresh, setTriggerRefresh] = useState(0);
  const [allValidate, setAllValidate] = useState(false);
  const [selectedDate, handleDateChange] = useState(null);

  const [vehiclePhoto, setVehiclePhoto] = useState(null);
  const [vehicleUrl, setVehicleUrl] = useState('');
  const [vehicleProgress, setVehicleProgress] = useState(0);
  const [errorVehicle, setErrorVehicle] = useState('');
  const [uploadedVehicleFlag, setUploadedVehicleFlag] = useState(false);

  const [driverPic, setDriverPic] = useState(null);
  const [driverUrl, setDriverUrl] = useState('');
  const [driverProgress, setDriverProgress] = useState(0);
  const [errorDriver, setErrorDriver] = useState('');
  const [uploadedDriverFlag, setUploadedDriverFlag] = useState(false);

  const [profilePic, setProfilePic] = useState(null);
  const [profileUrl, setProfileUrl] = useState('');
  const [profileProgress, setProfileProgress] = useState(0);
  const [errorProfile, setErrorProfile] = useState('');
  const [uploadedProfileFlag, setUploadedProfileFlag] = useState(false);

  const [icPic, setIcPic] = useState(null);
  const [icUrl, setIcUrl] = useState('');
  const [icProgress, setIcProgress] = useState(0);
  const [errorIc, setErrorIc] = useState('');
  const [uploadedIcFlag, setUploadedIcFlag] = useState(false);

  useEffect(() => {
    getBrands();
    getStates();
  }, []);

  useEffect(() => {
    validationChecking();
  }, [vehiclePhoto, icPic, selectedDate, driverPic, profilePic]);

  useEffect(() => {
    handleDateChange(new Date())
  }, []);

  useEffect(() => {
    handleUpdateVehicle();
  }, [vehiclePhoto]);

  useEffect(() => {
    handleUpdateLicense();
  }, [driverPic]);

  useEffect(() => {
    handleUpdateProfile();
  }, [profilePic]);

  useEffect(() => {
    handleUpdateIC();
  }, [icPic]);

  async function getStates() {
    loadingSwitcher('states', true);
    const token = localStorage.getItem("idToken");
    // const token = 'eyJhbGciOiJSUzI1NiIsImtpZCI6ImFiMGNiMTk5Zjg3MGYyOGUyOTg5YWI0ODFjYzJlNDdlMGUyY2MxOWQiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vdGFwYXctc3RhZ2luZy0xNzI3OSIsImF1ZCI6InRhcGF3LXN0YWdpbmctMTcyNzkiLCJhdXRoX3RpbWUiOjE2MjMxNzE3MDksInVzZXJfaWQiOiJYRElvb2dLbTBzV0xXQVkxWjRtcGgwSkxJSTczIiwic3ViIjoiWERJb29nS20wc1dMV0FZMVo0bXBoMEpMSUk3MyIsImlhdCI6MTYyMzE3MTcwOSwiZXhwIjoxNjIzMTc1MzA5LCJlbWFpbCI6ImJyb211c3RhZmFAZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7ImVtYWlsIjpbImJyb211c3RhZmFAZ21haWwuY29tIl19LCJzaWduX2luX3Byb3ZpZGVyIjoicGFzc3dvcmQifX0.MufoU2KRPVU61wABvB0AvXzprDuadFIuXYnpSYjRu9kudEJThtPM--R9KWLJB-V2m7rzGsDeax2YW1cwutLz0I9VncNYywexoAOM-n1tLTIngO9fhrVaaZ5LtOMf_H1xUQHdiVVzkmXoOC_0SlwqmkTrxsaePxRnbYkpCqxD_IV1Y0B-NfqevWyXFY9xyCWC4kgZR1vZzMPQSKYa7bFlzJyEOHQyaSEGwOWqNupsL312Kzvc-KTt1_v-LNdo8Vi2kDJxEA39vPY8CCHgHYKKUJH6IbQhRwWXy5XO5SxFbSSZ0BidtZE7OMMFoBmVXvpyoLdXIKr1YqR6y3pUCHgTvg';
    await axios.post(`${baseUrl}/get_states`, {
        token
    })
    .then(res => {
        const data = res.data
        // console.log(`get states data ${JSON.stringify(data)}`);
        setStatesData(data);
        loadingSwitcher('states', false);
    })
    .catch(err => {
        console.log(`error response get states ${JSON.stringify(err.response)}`);
        // console.log(err.response);
        // loading = false;
        // error = err;
        loadingSwitcher('states', false);
        if (err.response!==undefined) {
          // client received error response (5xx, 4xx)
        //   console.log(`error response ${err.response}`);
            // console.log(err.response);
            if (err.response.data.message != undefined) {
                if (err.response.data.message.includes('Firebase ID token has expired')) {
                    // console.log('refresh');
                    refreshTokenRequest('states');
                }
            }
        } else if (err.request!==undefined) {
          // client never received a response, or a request never left
        //   console.log(`error request ${err.request}`);
        } else {
        //   console.log(`get error ${err}`);
        }
    })
  }

  async function getBrands() {
    loadingSwitcher('brands', true);
    const token = localStorage.getItem("idToken");
    // const token = 'eyJhbGciOiJSUzI1NiIsImtpZCI6ImFiMGNiMTk5Zjg3MGYyOGUyOTg5YWI0ODFjYzJlNDdlMGUyY2MxOWQiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vdGFwYXctc3RhZ2luZy0xNzI3OSIsImF1ZCI6InRhcGF3LXN0YWdpbmctMTcyNzkiLCJhdXRoX3RpbWUiOjE2MjMxNzE3MDksInVzZXJfaWQiOiJYRElvb2dLbTBzV0xXQVkxWjRtcGgwSkxJSTczIiwic3ViIjoiWERJb29nS20wc1dMV0FZMVo0bXBoMEpMSUk3MyIsImlhdCI6MTYyMzE3MTcwOSwiZXhwIjoxNjIzMTc1MzA5LCJlbWFpbCI6ImJyb211c3RhZmFAZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7ImVtYWlsIjpbImJyb211c3RhZmFAZ21haWwuY29tIl19LCJzaWduX2luX3Byb3ZpZGVyIjoicGFzc3dvcmQifX0.MufoU2KRPVU61wABvB0AvXzprDuadFIuXYnpSYjRu9kudEJThtPM--R9KWLJB-V2m7rzGsDeax2YW1cwutLz0I9VncNYywexoAOM-n1tLTIngO9fhrVaaZ5LtOMf_H1xUQHdiVVzkmXoOC_0SlwqmkTrxsaePxRnbYkpCqxD_IV1Y0B-NfqevWyXFY9xyCWC4kgZR1vZzMPQSKYa7bFlzJyEOHQyaSEGwOWqNupsL312Kzvc-KTt1_v-LNdo8Vi2kDJxEA39vPY8CCHgHYKKUJH6IbQhRwWXy5XO5SxFbSSZ0BidtZE7OMMFoBmVXvpyoLdXIKr1YqR6y3pUCHgTvg';
    await axios.post(`${baseUrl}/get_brands`, {
        token
    })
    .then(res => {
        const data = res.data
        // console.log(`get brands data ${JSON.stringify(data)}`);
        setBrandsData(data);
        loadingSwitcher('brands', false);
    })
    .catch(err => {
        console.log(`error response get brands ${JSON.stringify(err.response)}`);
        // console.log(err.response);
        // loading = false;
        // error = err;
        loadingSwitcher('brands', false);
        if (err.response!==undefined) {
          // client received error response (5xx, 4xx)
        //   console.log(`error response ${err.response}`);
            // console.log(err.response);
            if (err.response.data.message != undefined) {
                if (err.response.data.message.includes('Firebase ID token has expired')) {
                    // console.log('refresh');
                    refreshTokenRequest('brands');
                }
            }
        } else if (err.request!==undefined) {
          // client never received a response, or a request never left
        //   console.log(`error request ${err.request}`);
        } else {
        //   console.log(`get error ${err}`);
        }
    })
  }

  // vehicle picture stuff
  const handleChangeVehicle = e => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file["type"];
      const validImageTypes = ["image/gif", "image/jpeg", "image/jpg", "image/png"];
      if (validImageTypes.includes(fileType)) {
        setErrorVehicle("");
        setVehiclePhoto(file);
      } else {
        setErrorVehicle("Please select an image to upload");
      }
    }
  };

  const handleUpdateVehicle = () => {
    if (vehiclePhoto) {
      const uid = localStorage.getItem("uid");
      const customToken = localStorage.getItem("customToken");
      // console.log(`token at vehicle ${customToken}`)
      auth.signInWithCustomToken(customToken)
      .then(function(userCred) {
        const user = userCred.user;
        // console.log(`user login in regist driver ${JSON.stringify(userCred)}`);
        //
        const uploadTask = storage.ref(`${uid}/vehiclePhoto`).put(vehiclePhoto);

        uploadTask.on(
          "state_changed",
          snapshot => {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            console.log(`progress upload ${progress}`);
            setVehicleProgress(progress);
          },
          error => {
            // console.log(`error upload vehicle ${JSON.stringify(error)}`)
            alert(error);
            setErrorVehicle(error);
            setVehicleProgress(0);
          },
          () => {
            setUploadedVehicleFlag(true);
            setVehicleProgress(0);
            // storage
            //   .ref("images")
            //   .child(image.name)
            //   .getDownloadURL()
            //   .then(url => {
            //     setUrl(url);
            //     setProgress(0);
            //   });
          }
        );
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        alert('Please relogin');
        console.log(`error ${JSON.stringify(error)}`)
        //
      });

      
    } else {
      setErrorVehicle("Error please choose an image to upload");
      setVehicleProgress(0);
    }
  };

  //------------
  // driver's license picture stuff
  const handleChangeLicense = e => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file["type"];
      const validImageTypes = ["image/gif", "image/jpeg", "image/jpg", "image/png"];
      if (validImageTypes.includes(fileType)) {
        setErrorDriver("");
        setDriverPic(file);
      } else {
        setErrorDriver("Please select an image to upload");
      }
    }
  };

  const handleUpdateLicense = () => {
    if (driverPic) {
      const uid = localStorage.getItem("uid");
      const customToken = localStorage.getItem("customToken");
      // console.log(`token at auth ${customToken}`)
      auth.signInWithCustomToken(customToken)
      .then(function(userCred) {
        const user = userCred.user;
        // console.log(`user login in regist driver ${JSON.stringify(userCred)}`)
        //
        const uploadTask = storage.ref(`${uid}/drivingLicensePhoto`).put(driverPic);

        uploadTask.on(
          "state_changed",
          snapshot => {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            console.log(`progress upload ${progress}`);
            setDriverProgress(progress);
          },
          error => {
            alert(error);
            setErrorDriver(error);
            setDriverProgress(0);
          },
          () => {
            setUploadedDriverFlag(true);
            setDriverProgress(0);
            // storage
            //   .ref("images")
            //   .child(image.name)
            //   .getDownloadURL()
            //   .then(url => {
            //     setUrl(url);
            //     setProgress(0);
            //   });
          }
        );
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        alert('Please relogin');;
        //
      })
    } else {
      setErrorDriver("Error please choose an image to upload");
      setDriverProgress(0);
    }
  };

  // ---------
  // profile picture stuff
  const handleChangeProfile = e => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file["type"];
      const validImageTypes = ["image/gif", "image/jpeg", "image/jpg", "image/png"];
      if (validImageTypes.includes(fileType)) {
        setErrorProfile("");
        setProfilePic(file);
      } else {
        setErrorProfile("Please select an image to upload");
      }
    }
  };

  const handleUpdateProfile = () => {
    if (profilePic) {
      const uid = localStorage.getItem("uid");
      const customToken = localStorage.getItem("customToken");
      // console.log(`token at auth ${customToken}`)
      auth.signInWithCustomToken(customToken)
      .then(function(userCred) {
        const user = userCred.user;
        // console.log(`user login in regist driver ${JSON.stringify(userCred)}`)
        //
        const uploadTask = storage.ref(`${uid}/profilePhoto`).put(profilePic);

        uploadTask.on(
          "state_changed",
          snapshot => {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            console.log(`progress upload ${progress}`);
            setProfileProgress(progress);
          },
          error => {
            alert(error);
            setErrorProfile(error);
            setProfileProgress(0);
          },
          () => {
            setUploadedProfileFlag(true);
            setProfileProgress(0);
            // storage
            //   .ref("images")
            //   .child(image.name)
            //   .getDownloadURL()
            //   .then(url => {
            //     setUrl(url);
            //     setProgress(0);
            //   });
          }
        );
      })
      .catch((error) => {
        alert('Please relogin');;
        const errorCode = error.code;
        const errorMessage = error.message;
        //
      })
    } else {
      setErrorProfile("Error please choose an image to upload");
      setProfileProgress(0);
    }
  };

  // ---------
  // ic picture stuff
  const handleChangeIC = e => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file["type"];
      const validImageTypes = ["image/gif", "image/jpeg", "image/jpg", "image/png"];
      if (validImageTypes.includes(fileType)) {
        setErrorIc("");
        setIcPic(file);
      } else {
        setErrorIc("Please select an image to upload");
      }
    }
  };

  const handleUpdateIC = () => {
    if (icPic) {
      const uid = localStorage.getItem("uid");
      const customToken = localStorage.getItem("customToken");
      // console.log(`token at auth ${customToken}`)
      auth.signInWithCustomToken(customToken)
      .then(function(userCred) {
        const user = userCred.user;
        // console.log(`user login in regist driver ${JSON.stringify(userCred)}`)
        //
        const uploadTask = storage.ref(`${uid}/nricPhoto`).put(icPic);

        uploadTask.on(
          "state_changed",
          snapshot => {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            console.log(`progress upload ${progress}`);
            setIcProgress(progress);
          },
          error => {
            alert(error);
            setErrorIc(error);
            setIcProgress(0);
          },
          () => {
            setUploadedIcFlag(true);
            setIcProgress(0);
            // storage
            //   .ref("images")
            //   .child(image.name)
            //   .getDownloadURL()
            //   .then(url => {
            //     setUrl(url);
            //     setProgress(0);
            //   });
          }
        );
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        alert('Please relogin');;
        //
      })
    } else {
      setErrorIc("Error please choose an image to upload");
      setIcProgress(0);
    }
  };

  async function refreshTokenRequest(type) {
      loadingSwitcher(type, true);
      const refresh_token = localStorage.getItem("refreshToken");
      // console.log(`refresh token in profile ${refresh_token}`);
      await axios.post(`${baseUrl}/refresh_token`, {
          refresh_token
      })
          .then(res => {
              const data = res.data
              // console.log(`refresh data ${JSON.stringify(data)}`);
              localStorage.setItem("idToken", data.id_token);
              localStorage.setItem("refreshToken", data.refresh_token);
              loadingSwitcher(type, false);
              setTriggerRefresh(old => old+1);
          })
          .catch(err => {
              // console.log(`error response ${err.response}`);
              // console.log(err.response);
              // loading = false;
              // error = err;
              loadingSwitcher(type, false);
              if (err.response!==undefined) {
                // client received error response (5xx, 4xx)
                  localStorage.setItem("idToken", "");
                  localStorage.setItem("customToken", "");
                  localStorage.setItem("refreshToken", "");
                  localStorage.setItem("fullName", "");
                  localStorage.setItem("username", "");
                  localStorage.setItem("userType", "");
                  localStorage.setItem("uid", "");
                  localStorage.setItem("completedRegister", "");
                  props.history.push('/');
                  alert('Session expired. Please login again.');
              //   console.log(`error response ${err.response}`);
              //   console.log(err.response);
              } else if (err.request!==undefined) {
                // client never received a response, or a request never left
              //   console.log(`error request ${err.request}`);
              } else {
              //   console.log(`get error ${err}`);
              }
          })
  }

  function loadingSwitcher(type, flag) {
    switch(type) {
      case 'states':
        setStatesLoading(flag);
        break;
      case 'brands':
        setBrandsLoading(flag);
        break;
      default:
        break;
    }
  }

  function validationChecking () {
    //brand, model,plate, vehiclePhoto, ic, city, state,selectedDate, driverPic, profilePic, emergency
    // && (vehiclePhoto != '')
    // && (driverPic !== '')
    // && (profilePic != '')
    // ic photo

    // check if not in edit mode
    if (props.driverEdit===undefined || props.driverEdit===false) { 
      if ((selectedDate != '') && (uploadedVehicleFlag==true) && (uploadedIcFlag==true) && (uploadedDriverFlag==true) && (uploadedProfileFlag==true) ) {
        setAllValidate(true);
        // console.log(`allValidate ${allValidate}`);
      } else {
        setAllValidate(false);
        // console.log(`allValidate ${allValidate}`);
      }
    } else {
      setAllValidate(true);
    }
  }

  const onSubmit = data => {
    // console.log(data);
    setupUpdate(data);
  }

  function setupUpdate(dataPassed) {
    const token = localStorage.getItem("idToken");
    
    // check if in driverEdit mode or not, if not in driverEdit mode, needs to all data passed are required
    // if in driverEdit mode, data passed are optional
    const {brand, model, plate, nricNumber, city, states, emergency} = dataPassed;
    if (props.driverEdit===undefined || props.driverEdit===false) { 
      const data = {
        token,
        vehicleBrand: brand,
        vehicleModel: model,
        vehiclePlateNumber: plate,
        nricNumber,
        cityResidence: city,
        states,
        drivingLicenseEndData: selectedDate,
        emergencyNumber: emergency,
        completedRegister: true,
      }

      updateRequest(data);
    } else {
      let data = {};
      data.token = token;
      if (brand!==undefined) data.vehicleBrand = brand;
      if (model!==undefined) data.vehicleModel = model;
      if (plate!==undefined) data.vehiclePlateNumber = plate;
      if (nricNumber!==undefined) data.nricNumber = nricNumber;
      if (city!==undefined) data.cityResidence = city;
      if (states!==undefined) data.states = states;
      if (emergency!==undefined) data.emergencyNumber = emergency;
      // check if date is equal to current date, if yes, don't save it
      let tempDate = new Date();
      tempDate.setHours(0, 0, 0, 0);
      selectedDate.setHours(0, 0, 0, 0);  
      // console.log(`tmp date ${JSON.stringify(tempDate)}, selected date ${JSON.stringify(selectedDate)}`);      
      if (selectedDate.getTime()!==tempDate.getTime()) data.drivingLicenseEndData = selectedDate;
      
      // user that completed 2nd part registration at early account creation already has completedRegister: true anyways
      data.completedRegister = true;

      updateRequest(data);
    }
  }

  async function updateRequest(data) {
    setLoading(true);
    await axios.post(`${baseUrl}/update_profile`, data)
    .then(res => {
        const data = res.data;
        // console.log(`profile data ${JSON.stringify(data)}`);
        // setProfileData(data);
        setLoading(false);
        props.history.push("/profile");
    })
    .catch(err => {
        // console.log(`error response ${err.response}`);
        console.log(err.response);
        // loading = false;
        // error = err;
        setLoading(false);
        if (err.response!==undefined) {
          // client received error response (5xx, 4xx)
        //   console.log(`error response ${err.response}`);
            // console.log(err.response);
            if (err.response.data.includes('Firebase ID token has expired')) {
                // console.log('refresh');
                refreshTokenRequest();
            }
        } else if (err.request!==undefined) {
          // client never received a response, or a request never left
        //   console.log(`error request ${err.request}`);
        } else {
        //   console.log(`get error ${err}`);
        }
    })
  }

  function renderStates(data) {
    if (statesLoading) {
      return <div className="col-lg-12 profile-text-centre"><CircularProgress /></div>;
    }
    if (data != null && data != undefined && data.length != 0) {
      let tmp = [];
      data.forEach((a,idx) => {
        tmp.push({id: idx, label: a.state, value: a.state});
      });
      return (
        <select
          {...register("states", { required: true })}
          id="states"
          name="states"
          data-name="states"
          className="select-field w-select"
        >{tmp.map(a => (
          <option key={a.id} value={a.value}>{a.label}</option>
        ))}
      </select>
      );
    }
  }

  function renderBrand(data) {
    if (brandsLoading) {
      return <div className="col-lg-12 profile-text-centre"><CircularProgress /></div>;
    }
    if (data != null && data != undefined && data.length != 0) {
      let tmp = [];
      data.forEach((a,idx) => {
        tmp.push({id: idx, label: a.brand, value: a.brand});
      });
      return (
        <select
          {...register("brand", { required: true })}
          id="brand"
          name="brand"
          data-name="brand"
          className="select-field w-select"
        >{tmp.map(a => (
          <option key={a.id} value={a.value}>{a.label}</option>
        ))}
      </select>
      );
    }
  }

  return (
    <div>
      <div className="wf-section">
        <div className="div-block-8 driver">
          <div className="container w-container">
            <div className="columns-2 w-row">
              <div className="column-3 w-col w-col-6">
                <div className="div-block-18">
                  <div className="div-block-19 driver">
                    <h1 className="heading-9">Earn Extra Income with Us</h1>
                    <p className="paragraph-title">
                      Seek an opportunity to earn extras, but no where to start?
                      Take your journey with us by turning your smartphone and
                      any of your vehicles into a money making machine.
                    </p>
                  </div>
                </div>
              </div>
              <div className="column-4 w-col w-col-6">
                <div className="div-block-9">
                  <div className="form-block w-form">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <div className="col-md-12" style={{marginLeft: '65px'}}>
                        <img src={Logo} className="logo-regist" />
                      </div>
                      <div className="col-md-12" style={{marginLeft: '65px'}}>
                        <span className="regist-title">Driver Registration</span>
                      </div>
                      <form
                        onSubmit={handleSubmit(onSubmit)}
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        className="form-2 details"
                      >
                        <div className="div-block-23">
                          <h5 className="heading-11">You're Almost There!</h5>
                          <p className="paragraph-5">
                            A few more steps before you can start earning
                          </p>
                        </div>
                        <div className="text-block-6">DRIVER DETAILS Form</div>
                        <div className="columns-3 w-row">
                          <div className="w-col w-col-6">
                            <label
                              htmlFor="name"
                              className="field-label secondary"
                            >
                              Brand
                            </label>
                            <div
                              data-hover="false"
                              data-delay={0}
                              className="w-dropdown"
                              style={{paddingBottom: '11px'}}
                            >
                              {renderBrand(brandsData)}
                            </div>
                            <label
                              htmlFor="Vehicle-Plate"
                              className="field-label secondary"
                            >
                              Vehicle Plate Number
                            </label>
                            <input
                              {...register("plate", { required: true })}
                              type="text"
                              className="form w-input"
                              maxLength={256}
                              name="plate"
                              data-name="plate"
                              placeholder="Your Vehicle Plate No."
                              id="plate"
                            />
                            {errors.plate && <span>This field is required</span>}
                            <label
                              htmlFor="IC-Number"
                              className="field-label secondary"
                              style={{paddingTop: '24px'}}
                            >
                              IC Number
                            </label>
                            <input
                              {...register("nricNumber", { required: true })}
                              type="text"
                              className="form w-input"
                              maxLength={256}
                              name="nricNumber"
                              data-name="nricNumber"
                              placeholder="Insert IC Number Here"
                              id="nricNumber"
                            />
                            {errors.nricNumber && <span>This field is required</span>}
                            <label
                              htmlFor="city"
                              className="field-label secondary"
                            >
                              City of Residence
                            </label>
                            <input
                              {...register("city", { required: true })}
                              type="text"
                              className="form w-input"
                              maxLength={256}
                              name="city"
                              data-name="city"
                              placeholder="Kuala Lumpur, Seremban.."
                              id="city"
                            />
                            <label
                              htmlFor="Date"
                              className="field-label secondary"
                            >
                              Driver's License Expiry
                            </label>
                            <div >
                              <DatePicker style={{width:'90%'}} className="reg-date" format="dd/MM/yyyy" value={selectedDate} onChange={handleDateChange} InputProps={{ disableUnderline: true }} />
                            </div>
                            <label
                              htmlFor="IC-Number-3"
                              className="field-label secondary"
                              style={{paddingTop: '36px'}}
                            >
                              Profile Photo
                            </label>
                            <input className="dark-blue-text form-control" style={{paddingTop: '10px', paddingBottom: '10px', width: '90%'}} type="file" name="profile_photo" onChange={handleChangeProfile} required />
                            <div style={{height: '15px'}}>
                              {profileProgress>0?<progress value={profileProgress} max='100' />: ''}
                              {uploadedProfileFlag?<div><CheckCircleIcon style={{color: 'green'}} /><span>Status: Profile photo uploaded</span></div>: ''}
                            </div>
                          </div>
                          <div className="w-col w-col-6">
                            <label
                              htmlFor="name-6"
                              className="field-label secondary"
                            >
                              Model
                            </label>
                            <input
                            {...register("model", { required: true })}
                              type="text"
                              className="form w-input"
                              maxLength={256}
                              name="model"
                              data-name="model"
                              placeholder="Vehicle Model"
                              id="model"
                            />
                            {errors.model && <span>This field is required</span>}
                            <label
                              htmlFor="Vehicle-Plate-3"
                              className="field-label secondary"
                            >
                              Vehicle Photo
                            </label>
                            <input className="dark-blue-text form-control" style={{paddingTop: '10px', paddingBottom: '10px', width: '90%'}} type="file" name="vehicle_photo" onChange={handleChangeVehicle} required/>
                            <div style={{height: '15px'}}>
                              {vehicleProgress>0?<progress value={vehicleProgress} max='100' />: ''}
                              {uploadedVehicleFlag?<div><CheckCircleIcon style={{color: 'green'}} /><span>Status: Vehicle photo uploaded</span></div>: ''}
                            </div>
                            <label
                              htmlFor="Vehicle-Plate-3"
                              className="field-label secondary"
                            >
                              NRIC Photo
                            </label>
                            <input className="dark-blue-text form-control" style={{paddingTop: '10px', paddingBottom: '10px', width: '90%'}} type="file" name="ic_photo" onChange={handleChangeIC} required/>
                            <div style={{height: '15px'}}>
                              {icProgress>0?<progress value={icProgress} max='100' />: ''}
                              {uploadedIcFlag?<div><CheckCircleIcon style={{color: 'green'}} /><span>Status: NRIC photo uploaded</span></div>: ''}
                            </div>
                            <label
                              htmlFor="IC-Number-3"
                              className="field-label secondary"
                            >
                              State
                            </label>
                            <div
                              data-hover="false"
                              data-delay={0}
                              className="w-dropdown"
                            >
                              {renderStates(statesData)}
                            </div>
                            <label
                              htmlFor="Upload"
                              className="field-label secondary"
                              style={{paddingTop: '27px'}}
                            >
                              Picture of Driver's License
                            </label>
                            <input className="dark-blue-text form-control" style={{paddingTop: '10px', paddingBottom: '10px', width: '90%'}} type="file" name="license_photo" onChange={handleChangeLicense} required/>
                            <div style={{height: '15px'}}>
                              {driverProgress>0?<progress value={driverProgress} max='100' />: ''}
                              {uploadedDriverFlag?<div><CheckCircleIcon style={{color: 'green'}} /><span>Status: License uploaded</span></div>: ''}
                            </div>
                            <label
                              htmlFor="IC-Number-3"
                              className="field-label secondary"
                            >
                              Emergency Contact
                            </label>
                            <input
                              {...register("emergency", { required: true })}
                              type="text"
                              className="form w-input"
                              maxLength={256}
                              name="emergency"
                              data-name="emergency"
                              placeholder="Emergency contact"
                              id="emergency"
                            />
                            {errors.emergency && <span>This field is required</span>}
                          </div>
                        </div>
                        <div className="div-block-10" />
                        {
                        loading? <div className="col-lg-12 profile-text-centre"><CircularProgress /></div>:
                        <button type="submit" className="submit-register">SUBMIT</button>
                        // <input
                        //   type="submit"
                        //   defaultValue="Submit"
                        //   data-wait="Please wait..."
                        //   className='button w-button'
                        //   // className="button registration w-button"
                        // />
                        }
                      </form>
                    </MuiPickersUtilsProvider>
                    <div className="success w-form-done">
                      <img
                        src="images/success_button.gif"
                        loading="lazy"
                        width={219}
                        // alt
                        className="image-7"
                      />
                      <div className="text-block-2">
                        Thank you! Your submission has been received!
                      </div>
                    </div>
                    <div className="error-message error-message-2 w-form-fail">
                      <div>
                        Oops! Something went wrong while submitting the form.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        src="images/transition-products-transparent.png"
        loading="lazy"
        sizes="(max-width: 1919px) 100vw, 1920px"
        srcSet="images/transition-products-transparent-p-500.png 500w, images/transition-products-transparent-p-800.png 800w, images/transition-products-transparent-p-1080.png 1080w, images/transition-products-transparent-p-1600.png 1600w, images/transition-products-transparent.png 1920w"
        // alt
        className="image-5"
      />
    </div>
  );
}

export default withRouter(DriverRegist2Body);
