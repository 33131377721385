import React, { useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import logo from '../../assets/img/tapaw/logo.png';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { baseUrl } from '../../shared/constants';


const Login = (props) => {
    const [loginEmail, setLoginEmailState] = useState({
        email: "",
    })
    const [loginPassword, setLoginPasswordState] = useState({
        password: "",
    });
    const [loginLoading, setLoginLoading] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);
    const [forgotLoading, setForgotLoading] = useState(false);

    // const { } = useContext()
    async function loginRequest(email, password, props) {
        let data = null;
        let error = '';
        setLoginLoading(true);
        //console.log(`email ${email}, password ${password}`)
        await axios.post(`${baseUrl}/login`, {
            email,
            password,
        })
            .then(res => {
                // console.log('get data')
                setLoginLoading(false);
                data = res.data;
                // console.log(`login data ${JSON.stringify(data)}`);
                localStorage.setItem("idToken", data.idToken);
                localStorage.setItem("customToken", data.customToken);
                localStorage.setItem("refreshToken", data.refreshToken);
                localStorage.setItem("fullName", data.fullName);
                localStorage.setItem("username", data.username);
                localStorage.setItem("uid", data.uid);
                localStorage.setItem("userType", data.usertype[0].type);
                localStorage.setItem("completedRegister", data.completedRegister!==undefined?data.completedRegister:false);
                // props.history.push("/profile");
                // console.log(`custom token at login ${localStorage.getItem('customToken')}`)
                const userType = data.usertype[0].type;
                const completedRegister = data.completedRegister!==undefined?data.completedRegister:false;
                // console.log(`user type ${userType}, completed register ${completedRegister}`);
                // check if driver/vendor completed 2nd part registration, if not yet, go to 2nd part registration
                if (userType.toLowerCase() === 'user' || userType.toLowerCase() === 'agent') {
                // console.log('go to profile');
                // check if justCloseModal prop is supplied to indicate just close modal without routing to profile page
                    props.justCloseModal?props.onHide(false):
                    props.history.push("/profile");
                } else {
                    if (completedRegister===true) {
                        // console.log('completed true');
                        // check if justCloseModal prop is supplied to indicate just close modal without routing to profile page
                        props.justCloseModal?props.onHide(false):
                        props.history.push("/profile");
                    } else {
                        if(userType.toLowerCase()==='vendor') props.history.push({pathname:'/register', state:{vendorEdit: true}});
                        if(userType.toLowerCase()==='driver') props.history.push({pathname:'/register', state:{driverEdit: true}});
                    }
                }
                props.onHide(false)
            })
            .catch(err => {
                setLoginLoading(false);
                // error = err;
                // console.log(err.response)
                // console.log(`error ${JSON.stringify(error)}`);
                if (err.response!==undefined) {
                    if (err.response.data.error!==undefined) alert(err.response.data.error.message);
                    // client received error response (5xx, 4xx)
                    // console.log(`error response ${err.response}`);
                } else if (err.request!==undefined) {
                    // client never received a response, or a request never left
                    // console.log(`error request ${err.request}`);
                } else {
                    // console.log(`get error ${JSON.stringify(err)}`);
                }
            })
        // .finally(() => loading = false)

        // console.log(`loading ${loading}`);
        // console.log(`data ${data}`);
        return { data };
    }

    async function changePasswordRequest(email) {
        setForgotLoading(true);
        const token = localStorage.getItem("idToken");
        await axios.post(`${baseUrl}/forgot_password`, {
            email
        })
            .then(res => {
                const data = res.data;
                alert(`Please check the email ${email} for instructions to reset password`);
                // console.log(`forgot data ${JSON.stringify(data)}`);
                // setPasswordRes(data);
                setForgotLoading(false);
            })
            .catch(err => {
                // console.log(`error response ${err.response}`);
                // console.log(err.response);
                // loading = false;
                // error = err;
                setForgotLoading(false);
                if (err.response!==undefined) {
                  // client received error response (5xx, 4xx)
                //   console.log(`error response ${err.response}`);
                    // console.log(err.response);
                    if (typeof(err.response.data) === 'string') {
                        // console.log('error in string');
                        alert("Please fill in the email then press 'Forgot Password' button.");
                    } else {
                        alert(err.response.data.error.message);
                    }
                    
                } else if (err.request!==undefined) {
                  // client never received a response, or a request never left
                //   console.log(`error request ${err.request}`);
                } else {
                //   console.log(`get error ${err}`);
                }
            })
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="login-modal"
        >

            <Modal.Body className="modal-content">
                <form method="post">
                    <div className="col-lg-12 profile-text-centre">
                        <img src={logo} className="logo-login" alt="" />
                    </div>
                    <div className="col-lg-12">
                        <h4>Sign In</h4>
                    </div>
                    <div className="col-lg-12">
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" placeholder="Enter email" onChange={(evt) => {
                                setLoginEmailState({ email: evt.target.value })
                            }} />
                        </Form.Group>
                    </div>
                    <div className="col-lg-12">
                        <Form.Group controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <div className='input-group'> 
                                <Form.Control type={passwordShown?'text':'password'} placeholder="Password" onChange={(evt) => {
                                    setLoginPasswordState({ password: evt.target.value })
                                }} />
                                {
                                    passwordShown ? <div className="centre-blue-edit-text align-right"><VisibilityOffIcon size={16} onClick={() => setPasswordShown(false)} /></div> :
                                    <div className="centre-blue-edit-text align-right"><VisibilityIcon size={16} onClick={() => setPasswordShown(true)}/></div>
                                }
                            </div>
                        </Form.Group>
                    </div>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="form-check">
                                    {/* <input className="form-check-input" type="checkbox" value="" id="return_trip" />
                                    <label className="form-check-label" >Remember Me</label> */}
                                </div>
                            </div>
                            <div className="col-lg-6 profile-text-right blue-text">
                                {forgotLoading?<div className="col-lg-12 profile-text-centre"><CircularProgress /></div>:
                                <a className="cursor" onClick={() => changePasswordRequest(loginEmail.email)}>Forgot Password</a>}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 profile-text-centre padding-register">
                        <a className="bold-text cursor" onClick={() => { props.history.push("/registeruser"); props.onHide(false) }}>Register Account</a>
                    </div>
                    <div className="col-lg-12">
                    {
                        loginLoading?<div className="col-lg-12 profile-text-centre"><CircularProgress /></div>:
                        <button type="button" className="tempah-sekarang black-text" onClick={() => { loginRequest(loginEmail.email, loginPassword.password, props) }}>
                                Sign In
                        </button>
                    } 
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default withRouter(Login);