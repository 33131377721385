import React from 'react';
// import dummyImg from '../../assets/img/about-2.jpg';

// const items = [
//     { id: '1', name: 'Expert Drive', desc: 'Our Expert Drivers will deliver your goods safely and timely.', img: dummyImg },
//     { id: '2', name: 'Same Day Delivery', desc: 'We guaranteed your items will be delivered on the same day.', img: dummyImg },
//     { id: '3', name: 'System', desc: 'We offer competitive prices while meeting your high expectation.', img: dummyImg },
//     { id: '4', name: 'Trusted Partner', desc: 'We always make sure our service is up to our high-quality standard at all time.', img: dummyImg },
//     { id: '5', name: 'Any Size Package', desc: 'We have various types of vehicles and can deliver any size package up to 80kg.', img: dummyImg },
//     { id: '6', name: 'Chat With Your Driver', desc: 'We built and in-app messaging platform for you to connect with your Driver and keep update!', img: dummyImg },
// ];

const RegExtras = props => {
    return(
        <div className="white-background" style={{ paddingTop: '50px' }}>
            <div className="col" style={{ textAlign: 'center', marginTop: '40px', paddingBottom: '50px' }}>
                <h2 className="dark-blue-text">{props.title}</h2>
                <h5>{props.desc}</h5>
            </div>
            <div className="col-lg-10 offset-lg-1">
                <div className="row">
                    {props.items.map(i => <div className="col-lg-4 col-md-6" key={i.id}>
                        <div class="reg-extras home-services-box">
                            <img src={i.img} alt="img" style={{width: '100%'}}/>
                            <div >
                            <h5>
                                <a >{i.name}</a>
                            </h5>
                            <p>{i.desc}</p>
                            </div>
                        </div>
                    </div>)
                    }
                </div>
                {/* <div className="col-lg-6 offset-lg-3 home-page-carousel-container">
                    <button className="menu-logmasuk">Learn More</button>
                </div> */}
            </div>
        </div>
    );
}

export default RegExtras;
