import React, { useState } from "react";
import axios from "axios";
import CircularProgress from '@material-ui/core/CircularProgress';
import {useForm} from 'react-hook-form'; 
import bussiness from '../../assets/img/tapaw/bussiness.png';
import { contactUsUrl, tapawMobilePhone } from "../../shared/constants";
import whatsappIcon from '../../assets/img/whatsapp.png';
import { customLanguage } from "../../shared/languages";

const BusinessCentre = props => {
  const {register, handleSubmit, formState: {errors}} = useForm();
  const [loading, setLoading] = useState(false);

  function openWhatsapp(phone) {
    window.open(`https://api.whatsapp.com/send/?phone=+${phone}`, '_blank', 'noopener', 'noreferrer');
  } 

  const onSubmit = data => {
    console.log(data);
    submitRequest(data);
  }

  const submitRequest = async data => {
    setLoading(true);
    await axios.post(contactUsUrl, {data})
    .then(res => {
      setLoading(false);
      // console.log(res.data);
      alert(res.data);
    })
    .catch(err => {
      setLoading(false);
      alert(err);})
  }

  return (
    <div>
      <div
        className="sigma_subheader style-11 bg-cover bg-center"
        style={{
          backgroundPosition: 'center bottom',
          backgroundImage: `url(${bussiness})`,
          height: '397px'
        }}
      >
        <div className="container" style={{ position: 'relative', top: '40%'}}>
          <div className="">
            {/* <h3 className="text-white" style={{ textAlign: 'center' }}>Business Centre</h3> */}
          </div>
        </div>
      </div>
      <div className="section section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="sigma_info style-19">
                <div className="sigma_info-title">
                  <span className="sigma_info-icon bg-primary-1 text-white">
                    <i className="flaticon-address" />
                  </span>
                  <h5>{customLanguage(localStorage.getItem('language')).business.businessCentre.var1}</h5>
                </div>
                <div className="sigma_info-description">
                  <p>TAPAW GROUPS SDN BHD</p>
                  <p>10D TINGKAT 4</p>
                  <p>BLOK 1 PUSAT PERNIAGAAN WORLDWIDE</p>
                  <p>JALAN TINJU 13/50</p>
                  <p>40675 SHAH ALAM, SELANGOR</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="sigma_info style-19">
                <div className="sigma_info-title">
                  <span className="sigma_info-icon bg-primary-1 text-white">
                    <i className="flaticon-call" />
                  </span>
                  <h5>{customLanguage(localStorage.getItem('language')).business.businessCentre.var2}</h5>
                </div>
                <div className="sigma_info-description">
                  <div className="row cursor" style={{paddingLeft: '15px'}} onClick={() => openWhatsapp(tapawMobilePhone)}>
                    <p>
                    {customLanguage(localStorage.getItem('language')).business.businessCentre.var3}: 011-55555109
                    </p>
                    <img src={whatsappIcon} style={{width: '40px', height: '20px'}} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="sigma_info style-19">
                <div className="sigma_info-title">
                  <span className="sigma_info-icon bg-primary-1 text-white">
                    <i className="flaticon-mail" />
                  </span>
                  <h5>{customLanguage(localStorage.getItem('language')).business.businessCentre.var4}</h5>
                </div>
                <div className="sigma_info-description">
                  <p>
                  {customLanguage(localStorage.getItem('language')).business.businessCentre.var5}: admin@tapaw.com
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="section sigma_contact extra-padding bg-cover bg-center blue-background"
      >
        <div className="container">
          <div className="section-title centered">
            <p className="text-white">
            {customLanguage(localStorage.getItem('language')).business.businessCentre.var7}
            </p>
            <h3 className="title text-white">
            {customLanguage(localStorage.getItem('language')).business.businessCentre.title1}
            </h3>
            <p className="text-white">
            {customLanguage(localStorage.getItem('language')).business.businessCentre.desc1}
            </p>
          </div>
        </div>
      </div>
      <div className="sigma_contact-map is-absolute">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              {/* <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2624.9914406081493!2d2.292292615201654!3d48.85837360866272!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66e2964e34e2d%3A0x8ddca9ee380ef7e0!2sEiffel%20Tower!5e0!3m2!1sen!2sin!4v1571115084828!5m2!1sen!2sin"
                height={400}
                allowFullScreen
              ></iframe> */}
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.088041583505!2d101.53913231492844!3d3.071149354483228!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc4d76be1c6b59%3A0x4b0c8e3eb2119be9!2sPusat%20Perniagaan%20Worldwide%20Blok%202%20%26%204!5e0!3m2!1sen!2smy!4v1637911737798!5m2!1sen!2smy" 
              height={400}
              allowfullscreen 
              loading="lazy"></iframe>
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="container">
          <div className="section-title centered" >
            <h3>{customLanguage(localStorage.getItem('language')).business.businessCentre.title2}</h3>
            <div className="row">
              <div className="col-md-4" />
              <div
                  className="col-md-4"
                >
                  <div className="yellow-line" />
                </div>
            </div>
              
            
            
            <h3 className="title">{customLanguage(localStorage.getItem('language')).business.businessCentre.desc2}</h3>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-8">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row" style={{ justifyContent: 'center' }}>
                  <div className="col-md-12 contact-us-pad">
                    <input style={{width: '100%'}} type="text" name="name" placeholder={customLanguage(localStorage.getItem('language')).business.businessCentre.inp1} {...register("name", {required: true})} />
                    {errors.name && <span>{customLanguage(localStorage.getItem('language')).business.businessCentre.field}</span>}
                  </div>
                  <div className="col-md-6 contact-us-pad">
                    <input style={{width: '100%'}} type="text" name="phone" placeholder={customLanguage(localStorage.getItem('language')).business.businessCentre.inp2} {...register("phone", {required: true})} />
                    {errors.phone && <span>{customLanguage(localStorage.getItem('language')).business.businessCentre.field}</span>}
                  </div>
                  <div className="col-md-6 contact-us-pad">
                    <input style={{width: '100%'}} type="text" name="email" placeholder={customLanguage(localStorage.getItem('language')).business.businessCentre.inp3} {...register("email", {required: true})} />
                    {errors.email && <span>{customLanguage(localStorage.getItem('language')).business.businessCentre.field}</span>}
                  </div>
                  <div className="col-md-12 contact-us-pad">
                    <textarea
                      {...register("message", {required: true})}
                      style={{width: '100%'}}
                      name="message"
                      rows={5}
                      defaultValue={customLanguage(localStorage.getItem('language')).business.businessCentre.inp4}
                    />
                    {errors.message && <span>{customLanguage(localStorage.getItem('language')).business.businessCentre.field}</span>}
                  </div>
                  <div className="col-4">
                    {
                      loading?<div className="profile-text-centre"><CircularProgress /></div>:
                      <button type="submit" className="btn-block sigma_btn">
                        {customLanguage(localStorage.getItem('language')).business.businessCentre.btn}
                      </button>
                    }
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BusinessCentre;
