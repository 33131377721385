import React, {useState} from "react";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Logo from '../../assets/img/images/logo.svg';
import {withRouter} from 'react-router-dom';
import Login from "../modals/login";
import { Container, Nav, Navbar, NavDropdown, Offcanvas } from "react-bootstrap";
import { customLanguage } from "../../shared/languages";

const Header = props => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [loginFlag, setLoginFlag] = useState(false);
  const [show, setShow] = useState(false);

  const handleCloseOffcanvas = () => setShow(false);
  const handleShowOffcanvas = () => setShow(true);


  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const goLogout = () => {
    localStorage.clear();
    // console.log(`custom token ${localStorage.getItem('customToken')}`)
    window.location.href = "/";
  }
  const goProfile = () => {
    // setAnchorEl(null);
    const userType = localStorage.getItem("userType");
    const completedRegister = localStorage.getItem("completedRegister");
    // console.log(`user type ${userType}, completed register ${completedRegister}, completed register type ${typeof(completedRegister)}`);
    // check if driver/vendor completed 2nd part registration, if not yet, go to 2nd part registration
    if (userType.toLowerCase() === 'driver' || userType.toLowerCase() === 'vendor') {
      if (completedRegister==='true') {
        // console.log('completed true');
        props.history.push("/profile");
      } else {
        if(userType.toLowerCase()==='vendor') props.history.push('/registervendor2');
        if(userType.toLowerCase()==='driver') props.history.push('/registerdriver2');
      }
    } else {
      props.history.push('/profile')
    }
  };
  
  const loginChecker = () => {
    if (localStorage.getItem("idToken") === "" || localStorage.getItem("idToken") === null) {
      setLoginFlag(true);
    } else {
      setLoginFlag(false);
      window.location.href = "/order";
    }
  }
  return (
    <div
      data-collapse="medium"
      data-animation="default"
      data-duration={400}
      id="nav-main"
      data-easing="ease"
      data-easing2="ease"
      role="banner"
      className="navbar w-nav"
    > 
      <div className="container navbar w-container" style={{marginTop: '0px'}}>
        <div className="menu-button w-nav-button">
          <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
            <Navbar.Toggle aria-controls="responsive-navbar-nav" style={{color: 'black'}} />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link href="/">{customLanguage(localStorage.getItem('language')).header.var1}</Nav.Link>
                <Nav.Link href="/team">{customLanguage(localStorage.getItem('language')).header.var2}</Nav.Link>
                <Nav.Link href="/news">{customLanguage(localStorage.getItem('language')).header.var3}</Nav.Link>
                <Nav.Link href="#" onClick={() => loginChecker()}>{customLanguage(localStorage.getItem('language')).header.var4}</Nav.Link>
                <div>
                    <NavDropdown title={customLanguage(localStorage.getItem('language')).header.var5} id="offcanvasNavbarDropdown">
                      <NavDropdown.Item href="#" onClick={() => {
                        localStorage.setItem('language', 'english'); 
                        window.location.reload()
                      }}>ENGLISH</NavDropdown.Item>
                      <NavDropdown.Item href="#" onClick={() => {
                        localStorage.setItem('language', 'malay');
                        window.location.reload()
                      }}>BAHASA MELAYU</NavDropdown.Item>
                    </NavDropdown>
                  </div>
                {
                  localStorage.getItem("idToken") === "" ||
                  localStorage.getItem("idToken") === null ?
                  <div>
                    <NavDropdown title={customLanguage(localStorage.getItem('language')).header.var6} id="offcanvasNavbarDropdown">
                      <NavDropdown.Item href="/registeruser">{customLanguage(localStorage.getItem('language')).header.var8}</NavDropdown.Item>
                      <NavDropdown.Item href="/registerdriver">{customLanguage(localStorage.getItem('language')).header.var9}</NavDropdown.Item>
                      <NavDropdown.Item href="/registervendor">{customLanguage(localStorage.getItem('language')).header.var10}</NavDropdown.Item>
                      <NavDropdown.Item href="/registeragent">{customLanguage(localStorage.getItem('language')).header.var11}</NavDropdown.Item>
                      <NavDropdown.Item href="/connect">{customLanguage(localStorage.getItem('language')).header.var12}</NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link href="#" onClick={props.signInCallback}>{customLanguage(localStorage.getItem('language')).header.var7}</Nav.Link>
                  </div>
                  :
                  <div >
                    <Nav.Link href="/trackuser">TRACKING MY DELIVERY</Nav.Link>
                    <Nav.Link onClick={() => goProfile()}>{localStorage.getItem("fullName")}</Nav.Link>
                    <Nav.Link href="#" onClick={() => goLogout()}>SIGN OUT</Nav.Link>
                  </div>
                }
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
        <a href="/" className="brand w-nav-brand">
          <img src={Logo} loading="lazy" 
          // alt 
          className="image-3" />
        </a>
        <nav role="navigation" className="nav-menu w-nav-menu">
          <a href="/" className="nav-link w-nav-link">
          {customLanguage(localStorage.getItem('language')).header.var1}
          </a>
          <a href="/team" className="nav-link w-nav-link">
          {customLanguage(localStorage.getItem('language')).header.var2}
          </a>
          <a href="/news" className="nav-link w-nav-link">
          {customLanguage(localStorage.getItem('language')).header.var3}
          </a>
          <a href="#" className="nav-link w-nav-link" onClick={() => loginChecker()}>
          {customLanguage(localStorage.getItem('language')).header.var4}
          </a>
          <a href="#" className="nav-link w-nav-link" onClick={handleClick2}>
          {customLanguage(localStorage.getItem('language')).header.var5}
          </a>
          <Menu
            id="language"
            anchorEl={anchorEl2}
            keepMounted
            open={Boolean(anchorEl2)}
            onClose={handleClose2}
          >
            <MenuItem><a href="#" className="nav-link w-nav-link" onClick={() => {
              localStorage.setItem('language', 'english');
              window.location.reload()
            }}>English</a></MenuItem>
            <MenuItem><a href="#" className="nav-link w-nav-link" onClick={() => {
              localStorage.setItem('language', 'malay');
              window.location.reload()
            }}>Bahasa Melayu</a></MenuItem>
          </Menu>
          {/* {
            localStorage.getItem("idToken") === "" ||
              localStorage.getItem("idToken") === null ?
              <a href="/trackguest" className="nav-link w-nav-link">
                order
              </a>
              :
              <a href="/trackuser" className="nav-link w-nav-link">
                Track
              </a>
          } */}
          {
            localStorage.getItem("idToken") === "" ||
                localStorage.getItem("idToken") === null ?
            <div className="div-block-7">
              <a href="#" className="nav-button w-button" onClick={handleClick}>
              {customLanguage(localStorage.getItem('language')).header.var6}
              </a>
              <Menu
                id="user-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem><a href="/registeruser" className="nav-link w-nav-link">{customLanguage(localStorage.getItem('language')).header.var8}</a></MenuItem>
                <MenuItem><a href="/registerdriver" className="nav-link w-nav-link">{customLanguage(localStorage.getItem('language')).header.var9}</a></MenuItem>
                <MenuItem><a href="/registervendor" className="nav-link w-nav-link">{customLanguage(localStorage.getItem('language')).header.var10}</a></MenuItem>
                <MenuItem><a href="/registeragent" className="nav-link w-nav-link">{customLanguage(localStorage.getItem('language')).header.var11}</a></MenuItem>
                <MenuItem><a href="/connect" className="nav-link w-nav-link">{customLanguage(localStorage.getItem('language')).header.var12}</a></MenuItem>
              </Menu>
              <a href="#" className="nav-button sign-in w-button" onClick={props.signInCallback} >
              {customLanguage(localStorage.getItem('language')).header.var7}
              </a>
            </div>
            :
            <div style={{display: 'flex'}}>
              <a href="/trackuser" className="nav-link w-nav-link" style={{paddingTop: '35px'}}>
              {customLanguage(localStorage.getItem('language')).header.var13}
              </a>
              <a href="#" onClick={() => goProfile()} className="nav-link w-nav-link" style={{paddingTop: '35px'}}>
                {localStorage.getItem("fullName")}
              </a>
              <div className="div-block-7">
                <a href="#" className="nav-button sign-in w-button" onClick={() => goLogout()} >
                {customLanguage(localStorage.getItem('language')).header.var14}
                </a>
              </div>
            </div>
          }
        </nav>
      </div>
      <Login
          justCloseModal={true}
          show={loginFlag}
          onHide={(flag) => setLoginFlag(flag)}
      />
    </div>
  );
}

export default withRouter(Header);
