// CustomGoogleMaps.js
import React, { Component } from 'react';

import GoogleMapReact from 'google-map-react';

import styled from 'styled-components';

import AutoComplete from './autocomplete';
import Marker from './marker';
import { gmapKey } from '../../shared/constants';

const Wrapper = styled.main`
  width: 100%;
  height: 100%;
`;

class CustomGoogleMap extends Component {


    state = {
        mapApiLoaded: false,
        mapInstance: null,
        mapApi: null,
        geoCoder: null,
        places: [],
        center: [],
        zoom: 17,
        address: '',
        draggable: true,
        lat: null,
        lng: null,
        contactName: '',
        contactNo: '',
        info: '',
        allValidate: false,
    };

    componentWillMount() {
        this.setCurrentLocation();
    }

    validationChecking() {
        // pickupLocation, deliveryLocation, currentVehicle
        const defaultAddressData = { 
            address: '', 
            lat: null,
            lng: null,
            contact: '',
            phone: '',
        };
        
        if (this.state.address !== defaultAddressData.address && this.state.lat !== null && this.state.lng !== null && this.state.contactName !== '' && this.state.contactNo !== '') {
            this.setState({allValidate:true});
        } else {
            this.setState({allValidate:false});
        }
    }
    


    onMarkerInteraction = (childKey, childProps, mouse) => {
        this.setState({
            draggable: false,
            lat: mouse.lat,
            lng: mouse.lng
        });
    }
    onMarkerInteractionMouseUp = (childKey, childProps, mouse) => {
        this.setState({ draggable: true });
        this._generateAddress();
    }

    _onChange = ({ center, zoom }) => {
        this.setState({
            center: center,
            zoom: zoom,
        });

    }

    _onClick = (value) => {
        this.setState({
            lat: value.lat,
            lng: value.lng
        });
    }

    apiHasLoaded = (map, maps) => {
        this.setState({
            mapApiLoaded: true,
            mapInstance: map,
            mapApi: maps,
        });

        this._generateAddress();
    };

    addPlace = (place) => {
        this.setState({
            places: [place],
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng()
        });
        this._generateAddress()
    };

    _generateAddress() {
        const {
            mapApi
        } = this.state;

        const geocoder = new mapApi.Geocoder;

        geocoder.geocode({ 'location': { lat: this.state.lat, lng: this.state.lng } }, (results, status) => {
            // console.log(results);
            // console.log(status);
            if (status === 'OK') {
                if (results[0]) {
                    this.zoom = 12;
                    this.setState({ address: results[0].formatted_address });
                    this.validationChecking();
                } else {
                    window.alert('No results found');
                }
            } else {
                window.alert('Geocoder failed due to: ' + status);
            }

        });
    }

    // Get Current Location Coordinates
    setCurrentLocation() {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.setState({
                    center: [position.coords.latitude, position.coords.longitude],
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                });
            });
        } else {
            alert('Please enable location for this browser! Refresh after you have enable it');
        }
    }

    passData(lat,lng,address,contact,phone,info) {
        // console.log('test');
        this.props.confirmfunc({lat,lng,address,contact,phone,info});
    }

    render() {
        const {
            places, mapApiLoaded, mapInstance, mapApi,
        } = this.state;


        return (
            <Wrapper>
                {mapApiLoaded && (
                    <div>
                        <AutoComplete map={mapInstance} mapApi={mapApi} addplace={this.addPlace} />
                    </div>
                )}
                <GoogleMapReact
                    center={this.state.center}
                    zoom={this.state.zoom}
                    draggable={this.state.draggable}
                    onChange={this._onChange}
                    onChildMouseDown={this.onMarkerInteraction}
                    onChildMouseUp={this.onMarkerInteractionMouseUp}
                    onChildMouseMove={this.onMarkerInteraction}
                    onChildClick={() => console.log('child click')}
                    onClick={this._onClick}
                    bootstrapURLKeys={{
                        key: gmapKey,
                        libraries: ['places', 'geometry'],
                    }}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map, maps }) => this.apiHasLoaded(map, maps)}
                >

                    <Marker
                        text={this.state.address}
                        lat={this.state.lat}
                        lng={this.state.lng}
                    />


                </GoogleMapReact>

                <div className="info-wrapper">
                    {/* <div className="map-details">Latitude: <span>{this.state.lat}</span>, Longitude: <span>{this.state.lng}</span></div>
                    <div className="map-details">Zoom: <span>{this.state.zoom}</span></div> */}
                    <div className="map-details">Address: <span>{this.state.address}</span></div>
                    <div className="row" style={{ marginTop: '32px'}}>
                        <div className="col-lg-12">
                            <div className="row" style={{paddingBottom: '32px'}}>
                                    <div className="col-lg-6">
                                        <label>Contact Name</label>
                                        <input type="text" name="contact_name" placeholder="" onChange={txt => this.setState({contactName: txt.target.value}, () => this.validationChecking())} />
                                    </div>
                                    <div className="col-lg-6">
                                        <label>Contact Number</label>
                                        <input type="text" name="contact_no" placeholder="" onChange={txt => this.setState({contactNo: txt.target.value}, () => this.validationChecking())} />
                                    </div>
                            </div>
                            <div className="row" style={{paddingBottom: '32px'}}>
                                    <div className="col-lg-12">
                                        <label>Additional Info</label>
                                        <input type="text" name="info" placeholder="" onChange={txt => this.setState({info: txt.target.value}, () => this.validationChecking())} />
                                    </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 offset-lg-2">
                                    <button type="button" className="tempah-sekarang cancel-profile" onClick={() => this.props.cancelfunc()}>
                                        CANCEL
                                    </button>
                                </div>
                                <div className="col-lg-4">
                                    <button type="button" className={`tempah-sekarang ${this.state.allValidate?'enabled-btn':'disabled-btn'}`} disabled={this.state.allValidate===false?true:false} onClick={() => this.passData(this.state.lat,this.state.lng,this.state.address,this.state.contactName,this.state.contactNo, this.state.info)}>
                                    {/* <button type="button" className={`tempah-sekarang`} onClick={() => this.passData(this.state.lat,this.state.lng,this.state.address,this.state.contactName,this.state.contactNo, this.state.info)}> */}
                                        {this.props.confirmtext}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </Wrapper >
        );
    }
}

export default CustomGoogleMap;