/* api */

// production
const baseUrl = 'https://us-central1-tapaw-8fae0.cloudfunctions.net/app';

// staging
// const baseUrl = 'https://us-central1-tapaw-staging-17279.cloudfunctions.net/app';

/* **************************************************************************** */
// contact us api

// production
const contactUsUrl = 'https://us-central1-tapaw-8fae0.cloudfunctions.net/sendContactUsEmail';

// staging
// const contactUsUrl = 'https://us-central1-tapaw-staging-17279.cloudfunctions.net/sendContactUsEmail';

/* *************************************************************************** */

/* google api key */

// staging
// const gmapKey = 'AIzaSyDQaXLsN497XYEnmdQbjUCqGINvP3RDbRA';

// production
// const gmapKey = 'AIzaSyCEmv83qOao-KTuFasgDAPMwSaddPFWz5o'; (old)
const gmapKey = 'AIzaSyDMf2Qjpq1fanBufgiXCOdfzpI8afv6cY8';

/* **************************************************************************** */

/* wordpress api */

// const wpBase = 'http://34.126.78.45/index.php/wp-json/wp/v2';
const wpBase = 'https://testblog.tapaw.com/index.php/wp-json/wp/v2';

/* **************************************************************************** */
// for topup callback

// development
// const tapawLink = 'localhost:3000';

// staging
// const tapawLink = 'https://tapaw-site.web.app';

// production
const tapawLink = 'http://tapaw.com';

/* **************************************************************************** */
// mobile app links

// google play
const googlePlayLink = 'https://play.google.com/store/apps/details?id=com.tapaw.user';

// ios app store
const appStoreLink = 'https://apps.apple.com/my/app/tapaw-express-delivery/id1537216323';

const huaweiStoreLink = 'https://appgallery.huawei.com/#/app/C103305903';

/* **************************************************************************** */
// social media
const instagramLink = 'https://www.instagram.com/tapawmalaysia/';
const fbLink = 'https://www.facebook.com/TapawMalaysia';
const twitterLink = 'https://twitter.com/TapawHQ';
/* **************************************************************************** */

// terms and condition
const termsLink = 'https://tapaw-comingsoon.web.app/static/media/GeneralTerms.08000de2.pdf';

// tapaw mall
const tapawmallLink = 'https://tapawmall.com';
// tapaw shop
const tapawshopLink = 'https://tapawshop.com';

// tapaw mobile phone
const tapawMobilePhone = '+601155555109';

// tapaw api docs
const tapawApiDocs = 'https://tapaw-apidoc.web.app/';

/* **************************************************************************** */

export {baseUrl, wpBase, gmapKey, tapawLink, googlePlayLink, appStoreLink, huaweiStoreLink, contactUsUrl, instagramLink, fbLink, twitterLink, termsLink, tapawmallLink, tapawshopLink, tapawMobilePhone, tapawApiDocs};