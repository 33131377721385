import React from "react";
import DelyvaImg from '../../assets/img/images/DelyvaNow-Logo.svg';
import LogoverticalImg from '../../assets/img/images/logo-vertical.svg';
import OrderuImg from '../../assets/img/images/orderu-logo.PNG';
import UdexImg from '../../assets/img/images/logo_udexmedia_new.png';
import ApiconnectImg from '../../assets/img/images/api-connect-image.png';
import Apiconnect800Img from '../../assets/img/images/api-connect-image-p-800.png';
import Apiconnect1080Img from '../../assets/img/images/api-connect-image-p-1080.png';
import regagentImg from '../../assets/img/images/reg-agent.jpg';
import regagent1080Img from '../../assets/img/images/reg-agent-p-1080.jpeg';
import regagent1600Img from '../../assets/img/images/reg-agent-p-1600.jpeg';
import regdriverImg from '../../assets/img/images/reg-driver.jpg';
import regdriver500Img from '../../assets/img/images/reg-driver-p-500.jpeg';
import regdriver800Img from '../../assets/img/images/reg-driver-p-800.jpeg';
import regdriver1080Img from '../../assets/img/images/reg-driver-p-1080.jpeg';
import FullctrlImg from '../../assets/img/images/3-photo.25270a7c.jpg';
import { tapawMobilePhone } from "../../shared/constants";

const ConnectBody = props => {
  function openWhatsapp(phone) {
    window.open(`https://api.whatsapp.com/send/?phone=+${phone}`, '_blank', 'noopener', 'noreferrer');
  } 

  return (
    <div>
      <div className="section-9 wf-section">
        <div className="div-block-8 connect">
          <h1 className="heading-10 connect">INTEGRATE WITH US NOW</h1>
          <p className="paragraph-4">
            Business becomes easy as everything just at customer’s fingertips.
            Integrate with us via API, no more hustle, customer satisfaction
            is always our concern!
          </p>
        </div>
      </div>
      <div className="section-7 wf-section">
        <div className="container w-container">
          <h2 className="heading-5">Connect your Business With TAPAW</h2>
          <p className="description-text">
            Some of our partners connecting with us
          </p>
          <div
            data-w-id="596615e2-11b9-05f2-bf47-7f7055e79bc7"
            style={{
              opacity: 0
            }}
            className="div-block-4"
          />
          <div className="w-layout-grid grid-3">
            <img src={OrderuImg} loading="lazy" alt style={{opacity: 0}}/>
            <img src={DelyvaImg} loading="lazy" alt  />
            <img src={LogoverticalImg} loading="lazy" alt />
            <img src={UdexImg} loading="lazy" alt style={{opacity: 0}} />
          </div>
        </div>
      </div>
      <div className="section-8 wf-section cursor" onClick={() => openWhatsapp(tapawMobilePhone)}>
        <img
          src={ApiconnectImg}
          loading="lazy"
          sizes="(max-width: 1919px) 100vw, 1601.2000732421875px"
          srcSet={`${Apiconnect800Img} 800w, ${Apiconnect1080Img} 1080w, ${ApiconnectImg} 1325w`}
          alt
          className="image-14"
        />
      </div>
      <div className="wf-section">
        <div className="container with-paragraph w-container">
          <h2 className="heading-5">WHY DO YOU NEED TO JOIN US</h2>
          <p className="description-text">
            Why being part of us makes a big difference
          </p>
          <div
            data-w-id="f4913ed7-d73c-e3d5-98ca-50fb49f19903"
            style={{
              opacity: 0
            }}
            className="div-block-4"
          />
          <div className="w-layout-grid grid-2 features extra">
            <div className="div-tapaw-unique extra">
              <img
                src={regagentImg}
                loading="lazy"
                width={80}
                height={80}
                srcSet={`${regagent1080Img} 1080w, ${regagent1600Img} 1600w, ${regagentImg} 1920w`}
                sizes="80vw"
                alt
                className="image-10 extra1"
              />
              <div className="div-block-20">
                <h4 className="heading-4 tapaw-feature">Technical Support</h4>
                <p className="paragraph-3">
                  With no one to report to, you are your own boss
                </p>
              </div>
            </div>
            <div className="div-tapaw-unique extra">
              <img
                src={FullctrlImg}
                loading="lazy"
                width={80}
                height={80}
                alt
                className="image-10 extra2"
              />
              <div className="div-block-20">
                <h4 className="heading-4 tapaw-feature">
                  Full Control on Pricing
                  <br />
                </h4>
                <p className="paragraph-3">
                  Build the trust and credibility of your business by
                  fulfiling the needs of your customer timely.
                  <br />
                </p>
              </div>
            </div>
            <div className="div-tapaw-unique extra">
              <img
                src={regdriverImg}
                loading="lazy"
                width={80}
                height={80}
                srcSet={`${regdriver500Img} 500w, ${regdriver800Img} 800w, ${regdriver1080Img} 1080w, ${regdriverImg} 1920w`}
                sizes="80vw"
                alt
                className="image-10 extra3"
              />
              <div className="div-block-20">
                <h4 className="heading-4 tapaw-feature">
                  Save Time & Cost
                  <br />
                </h4>
                <p className="paragraph-3">
                  Save your operation cost and time by outsourcing your
                  delivery service to the expert.
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConnectBody;
