import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import { baseUrl, tapawLink } from '../../shared/constants';

const fixedTopup = [
    { id: '1', name: 'RM20', val: 20 },
    { id: '2', name: 'RM50', val: 50 },
    { id: '3', name: 'RM100', val: 100 },
    { id: '4', name: 'RM500', val: 500 }
];

const Topup = (props) => {
    const [topupVal, setTopupVal] = useState(0);
    const [topupLoading, setTopupLoading] = useState(false);
    const [triggerRefresh, setTriggerRefresh] = useState(0);
    const [topupData, setTopupData] = useState(null);

    // useEffect(() => {
    //     console.log(`topupVal ${topupVal}`)
    // }, [topupVal]);

    // useEffect(() => {
    //     if (topupVal !== 0) {
    //         topupRequest();
    //     }
    // }, [triggerRefresh]);

    // useEffect(() => {
    //     console.log(topupData);
    // }, [topupData]);

    function topupValidate() {
        if (topupVal !== '' && topupVal >= 10) {
            return false;
        }
        return true;
    }

    async function topupRequest() {
        setTopupLoading(true);
        const token = localStorage.getItem("idToken");
        // console.log(`data send to topup: token ${token}, topup value ${topupVal}, callback url ${tapawLink}/return_profile`);
        await axios.post(`${baseUrl}/top_up`, {
            token,
            topup_value: topupVal,
            callbackURL: `${tapawLink}/return_profile`
        })
            .then(res => {
                const data = res.data
                // console.log(`topup data ${JSON.stringify(data)}`);
                setTopupLoading(false);
                setTopupData(data);
                props.onHide(false);
                window.open(`${data[0].initialUrl}`, '_blank',  'noopener, noreferrer');
            })
            .catch(err => {
                // console.log(`error response ${err.response}`);
                // console.log(err.response);
                // loading = false;
                // error = err;
                setTopupLoading(false);
                if (err.response!==undefined) {
                  // client received error response (5xx, 4xx)
                //   console.log(`error response ${err.response}`);
                    // console.log(err.response);
                    if (err.response.data.message !== undefined&& typeof(err.response.data.message) === 'string') {
                        if (err.response.data.message.includes('Firebase ID token has expired')) {
                            // console.log('refresh');
                            refreshTokenRequest();
                        }
                    }
                } else if (err.request!==undefined) {
                  // client never received a response, or a request never left
                //   console.log(`error request ${err.request}`);
                } else {
                //   console.log(`get error ${err}`);
                }
            })
    }

    async function refreshTokenRequest() {
        setTopupLoading(true);
        const refresh_token = localStorage.getItem("refreshToken");
        // console.log(`refresh token in topup ${refresh_token}`);
        await axios.post(`${baseUrl}/refresh_token`, {
            refresh_token
            })
            .then(res => {
                const data = res.data
                // console.log(`refresh data ${JSON.stringify(data)}`);
                localStorage.setItem("idToken", data.id_token);
                localStorage.setItem("refreshToken", data.refresh_token);
                setTopupLoading(false);
                setTriggerRefresh(old => old+1);
                alert('Please try again.');
            })
            .catch(err => {
                // console.log(`error response ${err.response}`);
                // console.log(err.response);
                // loading = false;
                // error = err;
                setTopupLoading(false);
                if (err.response!==undefined) {
                  // client received error response (5xx, 4xx)
                    localStorage.setItem("idToken", "");
                    localStorage.setItem("customToken", "");
                    localStorage.setItem("refreshToken", "");
                    localStorage.setItem("fullName", "");
                    localStorage.setItem("username", "");
                    localStorage.setItem("userType", "");
                    localStorage.setItem("uid", "");
                    localStorage.setItem("completedRegister", "");
                    props.history.push('/');
                    alert('Session expired. Please login again.');
                //   console.log(`error response ${err.response}`);
                //   console.log(err.response);
                } else if (err.request!==undefined) {
                  // client never received a response, or a request never left
                //   console.log(`error request ${err.request}`);
                } else {
                //   console.log(`get error ${err}`);
                }
            })
    }

    function renderSuccess() {
        return(
            <div>
                <div className="col-lg-12">
                    <h5>Top Up Success</h5>
                </div>
                <div className="col-lg-12">
                    <div className="form-group">
                        <label>Successfully top up RM{props.topup} in your wallet</label>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3">
                            <button type="button" className="tempah-sekarang" onClick={() => {props.history.push("/profile"); props.onHide(false)}}>
                                CONFIRM
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function renderFail() {
        return(
            <div>
                <div className="col-lg-12">
                    <h5>Top Up Failed</h5>
                </div>
                <div className="col-lg-12">
                    <div className="form-group">
                        <label>Failed to top up RM{props.topup} in your wallet. You may try again</label>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3">
                            <button type="button" className="tempah-sekarang" onClick={() => {props.history.push("/profile"); props.onHide(false)}}>
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        // dialogClassName="modal-rounded"
        >
            
            <Modal.Body className="modal-content">
                <form method="post">
                    <div className="col-lg-12">
                        <h4>TOP UP</h4>
                    </div>
                    <div className="profile-text-centre">
                        <div className="col-lg-12">
                            <span>Your Current Balance</span>
                        </div>
                        <div className="col-lg-12">
                            <h3>RM{props.balance}</h3>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <span>Top up</span>
                    </div>
                    <div className="row">
                    {fixedTopup.map(a =>
                        <div className="col-lg-3" key={a.id}>
                          <label className="fixed-topup" >
                            <input type="radio" value={a.val} name="v1" id={a.id} onChange={e => setTopupVal(e.target.value)}/>
                            <span><p className="text-transform-none">{a.name}</p></span>
                          </label>
                        </div>)
                    }
                    </div>
                    {/* <div className="col-lg-12">
                        <div className="form-group">
                            <span>Or any amount (Min RM10.00</span>
                            <input type="number" min={10} name="topup" placeholder="topup" onChange={e => setTopupVal(e.target.value)} />
                        </div>
                    </div> */}
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-lg-5 offset-lg-1">
                                <button type="button" className="tempah-sekarang cancel-profile" onClick={() => {setTopupVal(0);props.onHide(false)}}>
                                    CANCEL
                                </button>
                            </div>
                            <div className="col-lg-5">
                               {
                                    topupLoading?<div className="col-lg-12 profile-text-centre"><CircularProgress /></div>:
                                    <button type="button" className={`tempah-sekarang ${topupValidate() === false?'enabled-btn':'disabled-btn'}`} disabled={topupValidate()} onClick={() => topupRequest()}>
                                        PROCEED PAYMENT
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default withRouter(Topup);