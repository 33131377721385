import React from "react";
import { tapawmallLink } from "../../shared/constants";
import { customLanguage } from "../../shared/languages";

const IndexPart3 = props => {
  return (
    <div className="section-5 wf-section">
      <div className="container w-container" style={{textAlign: 'center'}}>
        <h2 className="heading-5 capitalize-all">{customLanguage(localStorage.getItem('language')).home.indexPart3.title}</h2>
        <div className="div-block-4" />
        <div >
          <span className="description-text">{customLanguage(localStorage.getItem('language')).home.indexPart3.desc}</span>
        </div>
        <div className="div-block-5" style={{marginTop: '40px'}}>
          <div
            onClick={() => window.open(tapawmallLink)}
            id="w-node-b0e8775a-c587-12fa-d596-45b9b5468459-b5a66672"
            className="div-tapaw image1 cursor tm1"
          >
            <div className="text-block-7 profile-text-centre" style={{width: '100%'}}>
            {customLanguage(localStorage.getItem('language')).home.indexPart3.product1}            
            </div>
          </div>
          <div onClick={() => window.open(tapawmallLink)} className="div-tapaw image2 cursor tm2">
            <div className="text-block-7 profile-text-centre" style={{width: '100%'}}>
            {customLanguage(localStorage.getItem('language')).home.indexPart3.product2}
            </div>
          </div>
          <div onClick={() => window.open(tapawmallLink)} className="div-tapaw image3 cursor tm3">
            <div className="text-block-7 profile-text-centre" style={{width: '100%'}}>
            {customLanguage(localStorage.getItem('language')).home.indexPart3.product3}
            </div>
          </div>
          <div onClick={() => window.open(tapawmallLink)} className="div-tapaw image3 cursor tm4">
            <div className="text-block-7 profile-text-centre" style={{width: '100%'}}>
            {customLanguage(localStorage.getItem('language')).home.indexPart3.product4}
            </div>
          </div>
          <div onClick={() => window.open(tapawmallLink)} className="div-tapaw image5 cursor tm5">
            <div className="text-block-7 profile-text-centre" style={{width: '100%'}}>
            {customLanguage(localStorage.getItem('language')).home.indexPart3.product5}
            </div>
          </div>
          <div onClick={() => window.open(tapawmallLink)} className="div-tapaw image6 cursor tm6">
            <div className="text-block-7 profile-text-centre" style={{width: '100%'}}>
            {customLanguage(localStorage.getItem('language')).home.indexPart3.product6}
            </div>
          </div>
          <div onClick={() => window.open(tapawmallLink)} className="div-tapaw image7 cursor tm7">
            <div className="text-block-7 profile-text-centre" style={{width: '100%'}}>
            {customLanguage(localStorage.getItem('language')).home.indexPart3.product7}
            </div>
          </div>
          <div
            onClick={() => window.open(tapawmallLink)}
            id="w-node-_58be8472-b812-1f13-6f3d-50e2ed76fecc-b5a66672"
            className="div-tapaw image8 cursor tm8"
          >
            <div className="text-block-7 profile-text-centre" style={{width: '100%'}}>
            {customLanguage(localStorage.getItem('language')).home.indexPart3.product8}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IndexPart3;
