import React from "react";
import mdecImg from '../../assets/img/mdec.png';
import rezekiImg from '../../assets/img/logo-eRezeki.png';
import delyvaImg from '../../assets/img/images/DelyvaNow-Logo.svg';
import { customLanguage } from "../../shared/languages";
// import mdecImg from '../../assets/img/images/MDeC-eRezeki.png';

class IndexPart5 extends React.Component {
  render() {
    return (
      <div className="wf-section">
        <div className="container w-container">
          <h2 className="heading-5 capitalize-all">{customLanguage(localStorage.getItem('language')).home.indexPart5.title}</h2>
          <div className="div-block-4" />
          {/* <div className="w-layout-grid grid-4">
            <img
              src={mdecImg}
              loading="lazy"
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 80vw, (max-width: 991px) 50vw, (max-width: 1919px) 40vw, 686px"
              srcSet={`${mdecImg} 500w, ${mdecImg} 686w`}
              alt
              className="image-19"
            />
          </div> */}
          <div className="row">
            <div className='col-md-4'>
              <img src={mdecImg} />
            </div>
            <div className='col-md-4' style={{marginTop: '10px'}}>
              <img src={rezekiImg}  />
            </div>
            <div className='col-md-4' style={{marginTop: '15px'}}>
              <img src={delyvaImg} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default IndexPart5;
