import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import { appStoreLink, baseUrl, googlePlayLink, huaweiStoreLink, tapawLink } from '../../shared/constants';
import EditProfile from '../modals/edit_profile';
import AddAddress from './add_address';
import Topup from '../modals/topup';
import downloadImg from '../../assets/img/tapaw/download.png'
import google_play from '../../assets/img/tapaw-google-play.png';
import huawei from '../../assets/img/tapaw-gallery.png';
import apple from '../../assets/img/tapaw-appstore.png';
import { DuplicateIcon } from "@primer/octicons-react";
import ChangePassword from '../modals/change_password';
import { Link } from 'react-router-dom';
import EditProfileEmailPhone from '../modals/edit_profile_email_phone';

const ProfileDriver = props => {
    const username = localStorage.getItem("username");
    const textAreaRef = useRef(null);
    const [modalShow, setModalShow] = useState(false);
    const [editEmailPhoneShow, setEditEmailPhoneShow] = useState(false);
    const [editType, setEditType] = useState('');
    const [topupFlag, setTopupFlag] = useState(false);
    const [triggerRefreshEarnings, setTriggerRefreshEarnings] = useState(0);
    const [incomeLoading, setIncomeLoading] = useState(false);
    const [incomeData, setIncomeData] = useState(null);
    const [passwordFlag, setPasswordFlag] = useState(false);

    useEffect(() => {
        getPaymentHistory('income');
    }, [triggerRefreshEarnings]);

    function copyToClipboard() {
        textAreaRef.current.select();
        textAreaRef.current.setSelectionRange(0, 99999);
        document.execCommand('copy');
        alert('Copied');        
    }

    function loadingSwitcher(type, flag) {
        switch(type) {
            case 'income':
                setIncomeLoading(flag);
                break;
            default:
                break;
        }
    }

    async function getPaymentHistory(type) {
        loadingSwitcher(type, true)
        const token = localStorage.getItem("idToken");
        await axios.post(`${baseUrl}/get_my_transactions`, {
            token,
            type
        })
            .then(res => {
                const data = res.data
                // console.log(`${type} history data ${JSON.stringify(data)}`);
                switch(type) {
                    case 'income':
                        setIncomeData(data);
                        break;
                    default:
                        break;
                }
                loadingSwitcher(type, false);
            })
            .catch(err => {
                // console.log(`error response ${err.response}`);
                // console.log(err.response);
                // topupLoading = false;
                // error = err;
                loadingSwitcher(type, false);
                if (err.response!==undefined) {
                  // client received error response (5xx, 4xx)
                //   console.log(`error response ${err.response}`);
                    // console.log(err.response);
                    if (err.response.data.message != undefined) {
                        if (err.response.data.message.includes('Firebase ID token has expired')) {
                            // console.log('refresh');
                            refreshTokenRequest(type);
                        }
                    }
                } else if (err.request!==undefined) {
                  // client never received a response, or a request never left
                //   console.log(`error request ${err.request}`);
                } else {
                //   console.log(`get error ${err}`);
                }
            })
    }

    async function refreshTokenRequest(type) {
        loadingSwitcher(type, true);
        const refresh_token = localStorage.getItem("refreshToken");
        // console.log(`refresh token in purchase history ${refresh_token}`);
        await axios.post(`${baseUrl}/refresh_token`, {
            refresh_token
        })
            .then(res => {
                const data = res.data
                // console.log(`refresh data ${JSON.stringify(data)}`);
                localStorage.setItem("idToken", data.id_token);
                localStorage.setItem("refreshToken", data.refresh_token);
                loadingSwitcher(type, false);
                switch (type) {
                    case 'income':
                        setTriggerRefreshEarnings(old => old+1);
                        break;
                    default:
                        break;
                }
            })
            .catch(err => {
                // console.log(`error response ${err.response}`);
                // console.log(err.response);
                // topupLoading = false;
                // error = err;
                loadingSwitcher(type, false);
                if (err.response!==undefined) {
                  // client received error response (5xx, 4xx)
                    localStorage.setItem("idToken", "");
                    localStorage.setItem("customToken", "");
                    localStorage.setItem("refreshToken", "");
                    localStorage.setItem("fullName", "");
                    localStorage.setItem("username", "");
                    localStorage.setItem("userType", "");
                    localStorage.setItem("uid", "");
                    localStorage.setItem("completedRegister", "");
                    props.history.push('/');
                    alert('Session expired. Please login again.');
                //   console.log(`error response ${err.response}`);
                //   console.log(err.response);
                } else if (err.request!==undefined) {
                  // client never received a response, or a request never left
                //   console.log(`error request ${err.request}`);
                } else {
                //   console.log(`get error ${err}`);
                }
            })
    }

    function renderIncomeData(data) {
        if (incomeLoading) {
            return <div className="col-md-12 profile-text-centre"><CircularProgress /></div>;
        }
        if (data != null && data != undefined && data.length != 0 && data != 'Transactions not found') {
            data.map((a, idx) => {
                return (
                <div className="row profile-multiple-text profile-text-centre" key={idx}>
                    <div className="col-md-4 profile-text-left">
                        <span>{new Date(a.orderDate._seconds * 1000)}</span>
                    </div>
                    <div className="col-md-4 profile-text-centre" >
                        <span>{a.orderID}</span>
                    </div>
                    <div className="col-md-4 profile-text-right" >
                        <span>RM{a.fee}</span>
                    </div>
                    <div className="col-md-12 line-confirm" />
                </div>);
            })
        } else {
            return <div className="row profile-multiple-text profile-text-centre"><div className="col-md-12"><span>No data</span></div></div>
        }
    }
    
    return(
        <div className={`${props.showDiv==true?'show-stuff':'hide-stuff'}`}>
            <div className={`row justify-content-center padding-top-bottom-50`}>
                <div className="col-md-11">
                    <form method="post">
                        <div className="row">
                            <div className="col-md-6" style={{paddingBottom: '32px'}}>
                                <span>Welcome {localStorage.getItem("username")} </span><br/><br/>
                                <span style={{ fontWeight: '500'}}>DASHBOARD</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6"  style={{ paddingLeft: 0 }}>
                                <div className="col-md-12 extra-profile-box profile-text-centre white-text blue-background shadow-header">
                                    <div className="col-md-12" style={{marginBottom: '20px'}}><span>My Earnings</span></div>
                                    <div className="col-md-12"><h3 className="white-text">RM{props.data.total_income!=undefined?props.data.total_income:'0'}</h3></div>
                                    <div className="col-md-12"><span className="profile-box-underlined">CASH OUT</span></div>
                                </div>
                            </div>
                            <div className="col-md-6" style={{ paddingRight: 0 }}>
                                <div className="col-md-12 extra-profile-box white-background shadow-header">
                                    <div className="col-md-12"><span>TAPAW Gear</span></div>
                                    <div style={{paddingTop: '12px', paddingBottom: '12px'}}>
                                        {props.data.boughtTapawShirt!==undefined&&props.data.boughtTapawShirt===true?<div className="col-md-12" ><span className="blue-text">Purchased TAPAW T-Shirt</span></div>: <span/>}
                                        {props.data.boughtTapawBag!==undefined&&props.data.boughtTapawBag===true?<div className="col-md-12" ><span className="blue-text">Purchased TAPAW Bag</span></div>: <span />}
                                    </div>
                                    <div className="col-md-12">
                                        <span>To see more visit <span className="profile-box-underlined cursor" onClick={() => window.open('http://tapawshop.com', '_blank', 'noreferrer', 'noopener')}>http://tapawshop.com</span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-md-6" style={{ paddingLeft: 0 }}>
                                <div className="border-radius-15 shadow-header">
                                    <div className="col-md-12 profile-box-top">
                                        <span>Account Balance</span>
                                    </div>
                                    <div className="col-md-12 profile-box-bottom" style={{paddingBottom: '44px'}}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <span>RM{props.data.wallet!=undefined?props.data.wallet.balance:'0'}</span>
                                            </div>
                                            <div className="col-md-6 profile-text-right">
                                                <span className="profile-box-underlined cursor" onClick={() => setTopupFlag(true)}>TOP UP</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6" style={{ paddingRight: 0 }}>
                                <div className="border-radius-15 shadow-header">
                                    <div className="col-md-12 profile-box-top">
                                        <span>Referral Code</span>
                                    </div>
                                    <div className="col-md-12 profile-box-bottom">
                                        <div className="row">
                                            <div className="col-md-9">
                                                <textarea ref={textAreaRef} value={`${tapawLink}/${username}`} className="plain-text-area" style={{width: '100%'}} />
                                            </div>
                                            <div className="col-md-3 profile-text-centre" style={{cursor: 'pointer'}}>
                                                <div className="col-md-12"><span onClick={() => copyToClipboard()}><DuplicateIcon size={16} /></span></div>
                                                <div className="col-md-12"><span style={{fontSize: '12px'}}>Copy Link</span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="border-radius-15 shadow-header">
                            <div className="row">
                                <div className="col-md-12 profile-box-top">
                                    <span>Personal Information</span>
                                </div>
                                <div className="col-md-12 profile-box-bottom">
                                    <div className="row">
                                        <div className="col-md-8">
                                            <div className="row profile-multiple-text">
                                                <div className="col-md-6 col-xl-4">
                                                    <span>Full Name :</span>
                                                </div>
                                                <div className="col-md-6 col-xl-8" >
                                                    <span>{props.data.fullName}</span>
                                                </div>
                                            </div>
                                            <div className="row profile-multiple-text">
                                                <div className="col-md-6 col-xl-4">
                                                    <span>Email Address :</span>
                                                </div>
                                                <div className="col-md-6 col-xl-8" >
                                                    <span>{props.data.email}</span>
                                                </div>
                                            </div>
                                            <div className="row profile-multiple-text">
                                                <div className="col-md-6 col-xl-4">
                                                    <span>Preferred Contact Number :</span>
                                                </div>
                                                <div className="col-md-6 col-xl-8" >
                                                    <span>{props.data.phoneNumber}</span>
                                                </div>
                                            </div>
                                            <div className="row profile-multiple-text">
                                                <div className="col-md-6 col-xl-4">
                                                    <span>Password :</span>
                                                </div>
                                                <div className="col-md-6 col-xl-8" >
                                                    <span className="profile-box-underlined cursor" onClick={() => setPasswordFlag(true)}>CHANGE PASSWORD</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="col-md-12 profile-text-right">
                                                <span className="profile-box-underlined cursor" onClick={() => setModalShow(true)}>EDIT FULL NAME</span>
                                            </div>
                                            <div className="col-md-12 profile-text-right">
                                                <span className="profile-box-underlined cursor" onClick={() => {setEditType('email'); setEditEmailPhoneShow(true)}}>EDIT EMAIL</span>
                                            </div>
                                            <div className="col-md-12 profile-text-right">
                                                <span className="profile-box-underlined cursor" onClick={() => {setEditType('phone'); setEditEmailPhoneShow(true)}}>EDIT CONTACT NUMBER</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="border-radius-15 shadow-header">
                            <div className="row">
                                <div className="col-md-12 profile-box-top">
                                    <span>Driver Information</span>
                                </div>
                                <div className="col-md-12 profile-box-bottom">
                                    <div className="row">
                                        <div className="col-md-8">
                                            <div className="row profile-multiple-text">
                                                <div className="col-md-6 col-xl-4">
                                                    <span>Account Status :</span>
                                                </div>
                                                <div className="col-md-6 col-xl-8" >
                                                    <span>{props.data.status?props.data.status: 'No data'}</span>
                                                </div>
                                            </div> 
                                            <div className="row profile-multiple-text">
                                                <div className="col-md-6 col-xl-4">
                                                    <span>Driver Type :</span>
                                                </div>
                                                <div className="col-md-6 col-xl-8" >
                                                    <span>{props.data.drivertype?props.data.drivertype: 'No data'}</span>
                                                </div>
                                            </div>
                                            <div className="row profile-multiple-text">
                                                <div className="col-md-6 col-xl-4">
                                                    <span>Brand :</span>
                                                </div>
                                                <div className="col-md-6 col-xl-8" >
                                                    <span>{props.data.vehicleBrand?props.data.vehicleBrand: 'No data'}</span>
                                                </div>
                                            </div>
                                            <div className="row profile-multiple-text">
                                                <div className="col-md-6 col-xl-4">
                                                    <span>Plate Number :</span>
                                                </div>
                                                <div className="col-md-6 col-xl-8">
                                                    <span>{props.data.vehiclePlateNumber?props.data.vehiclePlateNumber: 'No data'}</span>
                                                </div>
                                            </div>
                                            <div className="row profile-multiple-text">
                                                <div className="col-md-6 col-xl-4">
                                                    <span>IC Number :</span>
                                                </div>
                                                <div className="col-md-6 col-xl-8">
                                                    <span>{props.data.nricNumber?props.data.nricNumber: 'No data'}</span>
                                                </div>
                                            </div>
                                            <div className="row profile-multiple-text">
                                                <div className="col-md-6 col-xl-4">
                                                    <span>City of Residence :</span>
                                                </div>
                                                <div className="col-md-6 col-xl-8">
                                                    <span>{props.data.cityResidence?props.data.cityResidence: 'No data'}</span>
                                                </div>
                                            </div>
                                            <div className="row profile-multiple-text">
                                                <div className="col-md-6 col-xl-4">
                                                    <span>State :</span>
                                                </div>
                                                <div className="col-md-6 col-xl-8">
                                                    <span>{props.data.states?props.data.states: 'No data'}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 profile-text-right">
                                            <div className="col-md-12">
                                                <span className="profile-box-underlined cursor" onClick={() => setModalShow(true)}>REQUEST CHANGE</span>
                                            </div>
                                            <div className="col-md-12" style={{marginTop: '8px'}}>
                                                <span className="profile-box-underlined header-yellow-text cursor" ><Link to={{ pathname: "/register", state:{driverEdit: true} }}>UPDATE PROFILE</Link></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br/>
                        <div className="col-md-12" style={{paddingLeft: 0, paddingRight: 0}}>
                            <span>Download APP</span>
                        </div>
                        <div className="col-md-10" style={{paddingLeft: 0, paddingRight: 0}}>
                            <div className="row">
                                <div className="col-md-4">
                                    <a onClick={() => window.open(googlePlayLink, '_blank',  'noopener, noreferrer')}><img src={google_play} alt="google" className="cursor" style={{ width: '100%' }} /></a>
                                </div>
                                <div className="col-md-4">
                                    <a onClick={() => window.open(appStoreLink, '_blank',  'noopener, noreferrer')}><img src={apple} alt="apple" className="cursor" style={{ width: '100%' }} /></a>
                                </div>
                                {/* <div className="col-md-4">
                                    <a onClick={() => window.open(huaweiStoreLink, '_blank',  'noopener, noreferrer')}><img src={huawei} alt="huawei" className="cursor" style={{ width: '100%' }} /></a>
                                </div> */}
                            </div>
                        </div>
                    </form>
                </div>
                <EditProfile
                    show={modalShow}
                    onHide={(flag) => setModalShow(flag)}
                    onRefresh={props.onRefresh}
                />
                <Topup
                    show={topupFlag}
                    onHide={(flag) => setTopupFlag(flag)}
                    balance={props.data.wallet!=undefined?props.data.wallet.balance:'0'}
                />
                <ChangePassword
                    show={passwordFlag}
                    onHide={(flag) => setPasswordFlag(flag)}
                    email={props.data.email}
                />
                <EditProfileEmailPhone
                    show={editEmailPhoneShow}
                    onHide={(flag) => setEditEmailPhoneShow(flag)}
                    onRefresh={props.onRefresh}
                    editType={editType} 
                />
            </div>
        </div>
    );
}

export default ProfileDriver;