import React, { useState, useEffect } from "react";
import { withRouter, Link } from 'react-router-dom';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from "@material-ui/core/FormControl";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import scooterImg from '../../assets/img/tapaw/vehicles/scooter.png';
import carImg from '../../assets/img/tapaw/vehicles/car.png';
import rangerImg from '../../assets/img/tapaw/vehicles/ranger.png';
import vanImg from '../../assets/img/tapaw/vehicles/van.png';
import lorry1Img from '../../assets/img/tapaw/vehicles/lorry1.png';
import lorry2Img from '../../assets/img/tapaw/vehicles/lorry2.png';

import { baseUrl } from "../../shared/constants";
import PleaseTopup from "../modals/please_topup";
import { customLanguage } from "../../shared/languages";

const payMethods = [
    { id: 1, label: 'Wallet', value: 'Wallet'},
    { id: 2, label: 'Cash on delivery', value: 'Cash'}
];

const Confirmation = props => {
    const [loading, setLoading] = useState(false);
    const [triggerRefresh, setTriggerRefresh] = useState(false);
    const [notes, setNotes] = useState('');
    const { state } = props.location;
    // const { currentVehicle, selectedDate, orderType, pickupLocation, deliveryLocation, addOn } = state;
    const [promoCode, setPromoCode] = useState('');
    const [feeData, setFeeData] = useState(null);
    const [payOptions, setPayOptions] = useState('Wallet');
    const [topupFlag, setTopupFlag] = useState(false);
    const [topupMessage, setTopupMessage] = useState('');

    // useEffect(() => {
    //     console.log(`passed data at confirmation ${JSON.stringify(state)}`)
    // }, []);

    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'})
    }, []);

    useEffect(() => {
        feeSetup();
    }, []);

    useEffect(() => {
        if (topupMessage!=='') {
            setTopupFlag(true);
        }
    }, [topupMessage]);

    useEffect(() => {
        // check if states pass using router are available, if not go back order page
        if (state!==undefined) {
            const { currentVehicle, selectedDate, orderType, pickupLocation, deliveryLocation, addOn } = state;
            if (currentVehicle===undefined||selectedDate===undefined||orderType===undefined||pickupLocation===undefined||deliveryLocation===undefined) {
                props.history.push('/order');
            }
        } else if (state===undefined) {
            props.history.push('/order');
        }
    }, []);

    // useEffect(() => {
    //     console.log(`promo code passed ${promoCode}`);
    // }, [promoCode]);

    // useEffect(() => {
    //     if (feeData!==null && feeData.totalFee!==undefined) {
    //         console.log(`fee data  ${JSON.stringify(feeData)}`)
    //     }
    // }, [feeData]);

    // useEffect(() => {
    //     console.log(`pay options ${JSON.stringify(payOptions)}`)
    // }, [payOptions]);

    function feeSetup() {
        if (state!==undefined) {
            const { currentVehicle, selectedDate, orderType, pickupLocation, deliveryLocation, addOn } = state;
            const token = localStorage.getItem("idToken");
            let data = {};
            const dropOff = deliveryLocation[0];
            let tempLocation = [...deliveryLocation];

            data = {
                token,
                vehicle_type: currentVehicle,
                add_on: addOn,  // [1]
                orderDate: selectedDate,
                orderType: orderType, // ondemand or scheduled
                paymentMethod: payOptions, // Wallet or Cash
                notes,
                states: "Selangor",
                isFavouriteDrivers: "false",
                promoCode: promoCode,
                pickupLocation: {
                    address: pickupLocation.address,
                    additionalInfo: pickupLocation.info,
                    contactName: pickupLocation.contact,
                    contactPhoneNo: pickupLocation.phone,
                    latitude: pickupLocation.lat,
                    longitude: pickupLocation.lng
                },
                dropOffLocation: {
                    address: dropOff.address,
                    additionalInfo: dropOff.info,
                    contactName: dropOff.contact,
                    contactPhoneNo: dropOff.phone,
                    latitude: dropOff.lat,
                    longitude: dropOff.lng
                }
            };
            if (deliveryLocation.length > 1) {
                let stoplocations = [];
                tempLocation.splice(0, 1);
                tempLocation.forEach(b => {
                    stoplocations.push({
                        address: b.address,
                        additionalInfo: b.info,
                        contactName: b.contact,
                        contactPhoneNo: b.phone,
                        latitude: b.lat,
                        longitude: b.lng
                    })
                });
                data.stoplocations = stoplocations;
            }
        
            // console.log(`data fee ${JSON.stringify(data)}`);
            feeRequest(data);

            // feeRequest(currentVehicle, pickupLocation, dropOff, stoplocations, addOn, promoCode);
        }
    }

    async function feeRequest(data) {
        // console.log(`data passed to fee ${JSON.stringify(data)}`);
        setLoading(true);
        // console.log(token);
        await axios.post(`${baseUrl}/get_fees`, data)
            .then(res => {
                const data = res.data
                // console.log(`fee  request data ${JSON.stringify(data)}`);
                setFeeData(data);
                setLoading(false);
            })
            .catch(err => {
                // console.log(`error response ${err.response}`);
                // console.log(err.response);
                // loading = false;
                // error = err;
                setLoading(false);
                if (err.response!==undefined) {
                    // client received error response (5xx, 4xx)
                    // console.log(`error response ${err.response}`);
                    // console.log(err.response);
                    if (err.response.data.message !== undefined && typeof(err.response.data.message) === 'string') {
                        if (err.response.data.message.includes('Firebase ID token has expired')) {
                            // console.log('refresh');
                            refreshTokenRequest();
                        }
                    }
                } else if (err.request!==undefined) {
                    // client never received a response, or a request never left
                    // console.log(`error request ${err.request}`);
                } else {
                    // console.log(`get error ${err}`);
                }
            })
    }

    function orderSetup() {
        if (state!==undefined) {
            const { currentVehicle, selectedDate, orderType, pickupLocation, deliveryLocation, addOn } = state;
            // console.log(`order date ${selectedDate}`);
            const token = localStorage.getItem("idToken");
            let data = {};
            const dropOff = deliveryLocation[0];
            let tempLocation = [...deliveryLocation];

            data = {
                token,
                vehicle_type: currentVehicle,
                add_on: addOn,  // [1]
                orderDate: selectedDate,
                orderType: orderType, // ondemand or scheduled
                paymentMethod: payOptions, // Wallet or Cash
                notes,
                states: "Selangor",
                isFavouriteDrivers: "false",
                promoCode: promoCode,
                pickupLocation: {
                    address: pickupLocation.address,
                    additionalInfo: pickupLocation.info,
                    contactName: pickupLocation.contact,
                    contactPhoneNo: pickupLocation.phone,
                    latitude: pickupLocation.lat,
                    longitude: pickupLocation.lng
                },
                dropOffLocation: {
                    address: dropOff.address,
                    additionalInfo: dropOff.info,
                    contactName: dropOff.contact,
                    contactPhoneNo: dropOff.phone,
                    latitude: dropOff.lat,
                    longitude: dropOff.lng
                }
            };
            if (deliveryLocation.length > 1) {
                let stoplocations = [];
                tempLocation.splice(0, 1);
                tempLocation.forEach(b => {
                    stoplocations.push({
                        address: b.address,
                        additionalInfo: b.info,
                        contactName: b.contact,
                        contactPhoneNo: b.phone,
                        latitude: b.lat,
                        longitude: b.lng
                    })
                });
                data.stoplocations = stoplocations;
            }
            orderRequest(data);
        }
    }

    async function orderRequest(data) {
        // console.log(`data passed to order ${JSON.stringify(data)}`);
        setLoading(true);
        await axios.post(`${baseUrl}/create_order`, data)
        .then(res => {
            const data = res.data;
            // console.log(`order data response ${JSON.stringify(data)}`);
            setLoading(false);
            if ( typeof(data)==='string' && (data.includes('You need to have at least') || data.includes('Please top up to complete the order')) ){
                setTopupMessage(data);
            } else {
                props.history.push('/trackuser');
            }
        })
        .catch(err => {
            // console.log(`error response order ${JSON.stringify(err)}`);
            // console.log(err.response);
            // loading = false;
            // error = err;
            setLoading(false);
            if (err.response!==undefined) {
                // client received error response (5xx, 4xx)
                // console.log(`error response ${err.response}`);
                // console.log(err.response);
                if (err.response.data.message != undefined) {
                    if (err.response.data.message.includes('Firebase ID token has expired')) {
                        // console.log('refresh');
                        refreshTokenRequest();
                    }
                }
            } else if (err.request!==undefined) {
                // client never received a response, or a request never left
                // console.log(`error request ${err.request}`);
            } else {
                // console.log(`get error ${err}`);
            }
        })
        
    }

    async function refreshTokenRequest() {
        setLoading(true);
        const refresh_token = localStorage.getItem("refreshToken");
        // console.log(`refresh token in profile ${refresh_token}`);
        await axios.post(`${baseUrl}/refresh_token`, {
            refresh_token
        })
            .then(res => {
                const data = res.data
                // console.log(`refresh data ${JSON.stringify(data)}`);
                localStorage.setItem("idToken", data.id_token);
                localStorage.setItem("refreshToken", data.refresh_token);
                setLoading(false);
                setTriggerRefresh(old => old + 1);
                alert('Somethings wrong. Please try again');
            })
            .catch(err => {
                // console.log(`error response ${err.response}`);
                // console.log(err.response);
                // loading = false;
                // error = err;
                setLoading(false);
                if (err.response!==undefined) {
                    // reset local storage
                    localStorage.setItem("idToken", "");
                    localStorage.setItem("customToken", "");
                    localStorage.setItem("refreshToken", "");
                    localStorage.setItem("fullName", "");
                    localStorage.setItem("username", "");
                    localStorage.setItem("userType", "");
                    localStorage.setItem("uid", "");
                    localStorage.setItem("completedRegister", "");
                    props.history.push('/');
                    alert('Session expired. Please login again.');

                    // client received error response (5xx, 4xx)
                    // console.log(`error response ${err.response}`);
                    // console.log(err.response);
                } else if (err.request!==undefined) {
                    alert('Connection error. Please try again');
                    // client never received a response, or a request never left
                    // console.log(`error request ${err.request}`);
                } else {
                    // console.log(`get error ${err}`);
                }
            })
    }

    function editHome() {
        if (state!==undefined) {
            const { currentVehicle, selectedDate, orderType, pickupLocation, deliveryLocation, addOn } = state;
            let allData = {
                passedCurrentVehicle: currentVehicle, 
                passedSelectedDate: selectedDate, 
                passedOrderType: orderType, 
                passedPickupLocation: pickupLocation, 
                passedDeliveryLocation: deliveryLocation,
                passedAddOn: addOn
            };
            props.history.push({pathname:'/order', state: allData});
        }
    }

    function vehicleUI(vehicleType) {
        // console.log('run vehicle ui')
        switch (vehicleType) {
            case 'Motorcycle':
                return (
                    <div className="col-lg-3">
                        <div className="confirmation-vehicle-box profile-text-centre">
                            <img src={scooterImg} alt="" className="confirmation-vehicle" />
                            <span>{customLanguage(localStorage.getItem('language')).confirmation.vehicle1}</span>
                        </div>
                    </div>
                );
            case 'Car':
                return (
                    <div className="col-lg-3">
                        <div className="confirmation-vehicle-box profile-text-centre">
                            <img src={carImg} alt="" className="confirmation-vehicle" />
                            <span>{customLanguage(localStorage.getItem('language')).confirmation.vehicle2}</span>
                        </div>
                    </div>
                );
            case 'Pickup (4x4)':
                return (
                    <div className="col-lg-3">
                        <div className="confirmation-vehicle-box profile-text-centre">
                            <img src={rangerImg} alt="" className="confirmation-vehicle" />
                            <span>{customLanguage(localStorage.getItem('language')).confirmation.vehicle3}</span>
                        </div>
                    </div>
                );
            case 'Van':
                return (
                    <div className="col-lg-3">
                        <div className="confirmation-vehicle-box profile-text-centre">
                            <img src={vanImg} alt="" className="confirmation-vehicle" />
                            <span>{customLanguage(localStorage.getItem('language')).confirmation.vehicle4}</span>
                        </div>
                    </div>
                );
            case '1-Ton Lorry':
                return (
                    <div className="col-lg-3">
                        <div className="confirmation-vehicle-box profile-text-centre">
                            <img src={lorry1Img} alt="" className="confirmation-vehicle" />
                            <span>{customLanguage(localStorage.getItem('language')).confirmation.vehicle5}</span>
                        </div>
                    </div>
                );
            case '3-Ton Lorry':
                return (
                    <div className="col-lg-3">
                        <div className="confirmation-vehicle-box profile-text-centre">
                            <img src={lorry2Img} alt="" className="confirmation-vehicle" />
                            <span>{customLanguage(localStorage.getItem('language')).confirmation.vehicle6}</span>
                        </div>
                    </div>
                );
            default:
                return (<div style={{ padding: '50px' }}></div>);
        }
    }

    if (state!==undefined) {
        const { currentVehicle, selectedDate, orderType, pickupLocation, deliveryLocation, addOn } = state;
        return (
            <div className="blue-background">
                <div className="section">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-lg-12 profile-text-centre">
                                        <h3 className="white-text">{customLanguage(localStorage.getItem('language')).confirmation.title1}</h3>
                                    </div>
                                    <div className="col-lg-12 confirmation-box">
                                        <form method="post">
                                            <div className="row padding-pengesahan-top">
                                                <div className="col-lg-9">
                                                    <div className="row">
                                                        <div className="col-lg-7">
                                                            <div className="col-lg-12"><span className="bold-text">{customLanguage(localStorage.getItem('language')).confirmation.var1}</span></div>
                                                            <div className="col-lg-12"><span>{pickupLocation?pickupLocation.address:'No data'}</span></div>
                                                            <div className="col-lg-12"><span className="bold-text">{customLanguage(localStorage.getItem('language')).confirmation.var2}</span></div>
                                                            <div className="col-lg-12"><span>{pickupLocation?pickupLocation.info:'No data'}</span></div>
                                                            <div className="col-lg-12"><span className="bold-text">{customLanguage(localStorage.getItem('language')).confirmation.var3}</span></div>
                                                            <div className="col-lg-12"><span>{pickupLocation?pickupLocation.contact:'No data'}</span></div>
                                                            <div className="col-lg-12"><span className="bold-text">{customLanguage(localStorage.getItem('language')).confirmation.var4}</span></div>
                                                            <div className="col-lg-12"><span>{pickupLocation?pickupLocation.phone:'No data'}</span></div>
                                                            <div className="col-lg-12"><span className="bold-text">{customLanguage(localStorage.getItem('language')).confirmation.var5}</span></div>
                                                            <div className="col-lg-12"><span>{selectedDate?selectedDate.toString():'No data'}</span></div>
                                                            <div className="col-lg-12"><span className="bold-text">{customLanguage(localStorage.getItem('language')).confirmation.var6}</span></div>
                                                            <div className="col-lg-12 form-group">
                                                                <textarea
                                                                    name="message"
                                                                    rows={5}
                                                                    // defaultValue={"Additional notes"}
                                                                    style={{ borderRadius: '10px' }}
                                                                    value={notes}
                                                                    onChange={a => setNotes(a.target.value)}
                                                                />
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <FormControl variant="outlined" className="drop">
                                                                    <Select id="paymentMethod" value={payOptions} onChange={a => setPayOptions(a.target.value)}>
                                                                        {/* <MenuItem value="">Please select</MenuItem> */}
                                                                        {payMethods.map(b => <MenuItem value={b.value}>{b.label}</MenuItem>)}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className="row" style={{ paddingLeft: '15px', marginTop: '30px' }}>
                                                                <div className="col-lg-6">
                                                                    <input type="text" name="promo" placeholder="Insert Promo Code" style={{ borderRadius: '10px' }} value={promoCode} onChange={a => setPromoCode(a.target.value)} />
                                                                </div>
                                                                <div className="col-lg-5">
                                                                    <button type="button" className="tempah-sekarang black-text" onClick={() => feeSetup()}>
                                                                    {customLanguage(localStorage.getItem('language')).confirmation.var7}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-5">
                                                            {
                                                                deliveryLocation?deliveryLocation.map((a, idx) => 
                                                                <div className="row">
                                                                    <div className="col-lg-12"><span className="bold-text">{idx===0?'Delivery Location':'Stop Location'}</span></div>
                                                                    <div className="col-lg-12"><span>{a.address}</span><div className="line-confirm" /></div>
                                                                    <div className="col-lg-12"><span className="bold-text">{customLanguage(localStorage.getItem('language')).confirmation.var2}</span></div>
                                                                    <div className="col-lg-12"><span>{a.info}</span></div>
                                                                    <div className="col-lg-12"><span className="bold-text">{customLanguage(localStorage.getItem('language')).confirmation.var3}</span></div>
                                                                    <div className="col-lg-12"><span>{a.contact}</span></div>
                                                                    <div className="col-lg-12"><span className="bold-text">{customLanguage(localStorage.getItem('language')).confirmation.var4}</span></div>
                                                                    <div className="col-lg-12"><span>{a.phone}</span></div>
                                                                    <hr />
                                                                </div>):'No data'
                                                            }
                                                            <div className="col-lg-12"><span className="bold-text cursor" onClick={() => editHome()}>{customLanguage(localStorage.getItem('language')).confirmation.var8}</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {vehicleUI(currentVehicle?currentVehicle:'No data')}
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-8">
                                                    <hr />
                                                    <div className="col-lg-6 offset-lg-6">
                                                        <div className="row">
                                                            <div className="col-lg-4">
                                                                <span>{customLanguage(localStorage.getItem('language')).confirmation.var9}</span>
                                                            </div>
                                                            <div className="col-lg-4 profile-text-centre">
                                                                <span> : </span>
                                                            </div>
                                                            <div className="col-lg-4 profile-text-right">
                                                                <span>RM{feeData !== null ? feeData.totalFee : 'Loading...'}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                </div>
                                                <div className="col-lg-4" style={{ alignSelf: 'center' }}>
                                                    {
                                                        loading ? <div className="col-lg-12 profile-text-centre"><CircularProgress /></div> :
                                                            <button type="button" className="tempah-sekarang black-text" onClick={() => orderSetup()}>
                                                                {customLanguage(localStorage.getItem('language')).confirmation.var10}
                                            </button>
                                                    }
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <PleaseTopup
                    show={topupFlag}
                    onHide={(flag) => setTopupFlag(flag)}
                    message={topupMessage}
                />
            </div>
        );
    }
    return (<div className="col-md-12">{customLanguage(localStorage.getItem('language')).confirmation.error}</div>)
    
}

export default withRouter(Confirmation);
