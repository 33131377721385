import React, {useEffect, useState} from "react";
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import accepted_grey from '../../assets/img/tapaw/accepted-grey.png';
import accepted_yellow from '../../assets/img/tapaw/accepted-yellow.png';
import assigned_grey from '../../assets/img/tapaw/assigned-grey.png';
import assigned_yellow from '../../assets/img/tapaw/assigned-yellow.png';
import ongoing_grey from '../../assets/img/tapaw/ongoing-grey.png';
import ongoing_yellow from '../../assets/img/tapaw/ongoing-yellow.png';
import completed_grey from '../../assets/img/tapaw/completed-grey.png';
import completed_yellow from '../../assets/img/tapaw/completed-yellow.png';
import { baseUrl } from "../../shared/constants";

const TrackGuest = props => {
    const [loading, setLoading] = useState(false);
    const [deliveryData, setDeliveryData] = useState(null);
    const {anoTrack} = props.location;

    useEffect(() => {
        if (anoTrack != undefined) {
            getDeliveryHistory(anoTrack);

           scheduler();
        }
    }, []);

    function scheduler() {
        // repeat every 5 minutes (1 seconds = 1000), 1000x60x5=300000
        const interval = setInterval(() => {
            getDeliveryHistory(anoTrack);
        }, 300000);
        return () => clearInterval(interval);
    }

    // useEffect(() => {
    //     console.log(`delivery data ${JSON.stringify(deliveryData)}`);
    // }, [deliveryData]);

    // useEffect(() => {
    //     console.log(`anotrack ${JSON.stringify(anoTrack)}`);
    // }, []);

    async function getDeliveryHistory(orderId) {
        if (orderId != '') {
            // console.log(`order id in func ${orderId}`)
            if (orderId.includes('#')) {
                let modId = orderId.replace('#', '%23');
                // console.log(`order id in guest ${modId}`);

                setLoading(true);
                await axios.get(`${baseUrl}/get_order_object?order_id=${modId}`)
                .then(res => {
                    const data = res.data
                    // console.log(`delivery history data in tracking ${JSON.stringify(data)}`);
                    setLoading(false);
                    setDeliveryData(data)
                })
                .catch(err => {
                    // console.log(`error response ${JSON.stringify(err.response)}`);
                    setLoading(false);
                    // loading = false;
                    // error = err;
                    if (err.response!==undefined) {
                        alert('something wrong. Please try again');
                        // client received error response (5xx, 4xx)
                        // console.log(`error response ${err.response}`);
                        // console.log(err.response);
                        //   if (err.response.data.message.includes('Firebase ID token has expired')) {
                        //       console.log('refresh');
                        //   }
                    } else if (err.request!==undefined) {
                        // client never received a response, or a request never left
                        // console.log(`error request ${err.request}`);
                    } else {
                        // console.log(`get error ${err}`);
                    }
                })
            }
        }
    }

    function renderProgress() {
        if (loading) {
            return <div className="col-md-12 profile-text-centre"><CircularProgress /></div>;
        }
        if (deliveryData != null) {
            switch(deliveryData.status) {
                case 'new':
                    return(
                        <div className="row justify-content-center padding-track">
                            <img src={accepted_yellow} style={{ width: '10%', height: '100%' }} />
                            <img src={assigned_grey} style={{ width: '30%', height: '100%' }} />
                            <img src={ongoing_grey} style={{ width: '30%', height: '100%' }} />
                            <img src={completed_grey} style={{ width: '30%', height: '100%' }} />
                        </div>
                    );
                case 'cancelled':
                    return(
                        <div className="row justify-content-center padding-track">
                            <img src={accepted_grey} style={{ width: '10%', height: '100%' }} />
                            <img src={assigned_grey} style={{ width: '30%', height: '100%' }} />
                            <img src={ongoing_grey} style={{ width: '30%', height: '100%' }} />
                            <img src={completed_grey} style={{ width: '30%', height: '100%' }} />
                        </div>
                    );
                case 'assigned':
                    return (
                        <div className="row justify-content-center padding-track">
                            <img src={accepted_yellow} style={{ width: '10%', height: '100%' }} />
                            <img src={assigned_yellow} style={{ width: '30%', height: '100%' }} />
                            <img src={ongoing_grey} style={{ width: '30%', height: '100%' }} />
                            <img src={completed_grey} style={{ width: '30%', height: '100%' }} />
                        </div>
                    );
                case 'ongoing':
                    return (
                        <div className="row justify-content-center padding-track">
                            <img src={accepted_yellow} style={{ width: '10%', height: '100%' }} />
                            <img src={assigned_yellow} style={{ width: '30%', height: '100%' }} />
                            <img src={ongoing_yellow} style={{ width: '30%', height: '100%' }} />
                            <img src={completed_grey} style={{ width: '30%', height: '100%' }} />
                        </div>
                    );
                case 'completed':
                    return(
                        <div className="row justify-content-center padding-track">
                            <img src={accepted_yellow} style={{ width: '10%', height: '100%' }} />
                            <img src={assigned_yellow} style={{ width: '30%', height: '100%' }} />
                            <img src={ongoing_yellow} style={{ width: '30%', height: '100%' }} />
                            <img src={completed_yellow} style={{ width: '30%', height: '100%' }} />
                        </div>
                    );
                default:
                    break;
            }
        }
        return (<div className="col-md-12 profile-text-centre dark-blue-text">No tracking data available</div>);
    }

    function renderInfo() {
        if (loading) {
            return <div className="col-md-12 profile-text-centre"><CircularProgress /></div>;
        } 
        if (deliveryData != null) {
            return(
                <div>
                    <div className="row justify-content-center" style={{ paddingLeft: 10, paddingRight: 20 }}>
                        <div className="col-3 text-justify">
                            <p className="no-padding-no-margin">{deliveryData.status === 'cancelled'?'Order Cancelled':'Order'}</p>
                            <p className="no-padding-no-margin">{deliveryData.orderDate?(new Date(deliveryData.orderDate._seconds * 1000)).toDateString() : ''}</p>
                            <p className="no-padding-no-margin bold-text">{deliveryData.status} RM{deliveryData.totalFare}</p>
                        </div>
                        <div className="col-3">
                            <p style={{ textAlign: 'center' }}>Rider Assigned</p>
                        </div>
                        <div className="col-3">
                            <p style={{ textAlign: 'end' }}>Driver Ongoing</p>
                        </div>
                        <div className="col-3">
                            <p style={{ textAlign: 'end' }}>Completed</p>
                        </div>
                    </div>
                    <div className={`row padding-pengesahan-top ${(deliveryData.status==='assigned') || (deliveryData.status==='ongoing') ? 'show-stuff':'hide-stuff'}`}>
                        <div className="col-md-12">
                            <div className="track-address-box">
                                <div className="col-md-12 white-text"><span className="bold-text">Rider's Info</span></div>
                                <div className="col-md-12 white-text"><span>{deliveryData.driver.fullName}</span></div>
                                <div className="col-md-12 white-text"><span>{deliveryData.driver.phoneNumber}</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="row padding-pengesahan-top">
                        <div className="col-md-12">
                            <div className="track-address-box">
                                <div className="col-md-12 white-text"><span className="bold-text">Delivery Location</span></div>
                                <div className="col-md-12 white-text"><span>{deliveryData.dropoffLocation.contactName}</span></div>
                                <div className="col-md-12 white-text"><span>{deliveryData.dropoffLocation.contactPhoneNo}</span></div>
                                <div className="col-md-12 white-text"><span>{deliveryData.dropoffLocation.address}</span></div>
                            </div>
                        </div>
                        <div className={`col-md-12 ${deliveryData.stopLocation1.address!==''?'show-stuff':'hide-stuff'}`} style={{marginTop: '10px'}}>
                            <div className="track-address-box">
                                <div className="col-md-12 white-text"><span className="bold-text">Stop Location 1</span></div>
                                <div className="col-md-12 white-text"><span>{deliveryData.stopLocation1.address!==''?deliveryData.stopLocation1.contactName:''}</span></div>
                                <div className="col-md-12 white-text"><span>{deliveryData.stopLocation1.address!==''?deliveryData.stopLocation1.contactPhoneNo:''}</span></div>
                                <div className="col-md-12 white-text"><span>{deliveryData.stopLocation1.address!==''?deliveryData.stopLocation1.address:''}</span></div>
                            </div>
                        </div>
                        <div className={`col-md-12 ${deliveryData.stopLocation2.address!==''?'show-stuff':'hide-stuff'}`} style={{marginTop: '10px'}}>
                            <div className="track-address-box">
                                <div className="col-md-12 white-text"><span className="bold-text">Stop Location 2</span></div>
                                <div className="col-md-12 white-text"><span>{deliveryData.stopLocation2.address!==''?deliveryData.stopLocation2.contactName:''}</span></div>
                                <div className="col-md-12 white-text"><span>{deliveryData.stopLocation2.address!==''?deliveryData.stopLocation2.contactPhoneNo:''}</span></div>
                                <div className="col-md-12 white-text"><span>{deliveryData.stopLocation2.address!==''?deliveryData.stopLocation2.address:''}</span></div>
                            </div>
                        </div>
                        <div className={`col-md-12 ${deliveryData.stopLocation3.address!==''?'show-stuff':'hide-stuff'}`} style={{marginTop: '10px'}}>
                            <div className="track-address-box">
                                <div className="col-md-12 white-text"><span className="bold-text">Stop Location 3</span></div>
                                <div className="col-md-12 white-text"><span>{deliveryData.stopLocation3.address!==''?deliveryData.stopLocation3.contactName:''}</span></div>
                                <div className="col-md-12 white-text"><span>{deliveryData.stopLocation3.address!==''?deliveryData.stopLocation3.contactPhoneNo:''}</span></div>
                                <div className="col-md-12 white-text"><span>{deliveryData.stopLocation3.address!==''?deliveryData.stopLocation3.address:''}</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }

    return (
        <div className="blue-background" style={{ paddingBottom: 100, marginBottom: -75 }}>
            <div className="section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10">
                            <div className="row">
                                <div className="col-md-12">
                                    <h4 className="white-text">Ref : {anoTrack!=undefined?anoTrack:''}</h4>
                                </div>
                                <div className="col-md-12 confirmation-box">
                                    {renderProgress()}
                                    {renderInfo()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ); 
}

export default withRouter(TrackGuest);
