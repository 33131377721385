import React, { useEffect, useState } from "react";
import axios from 'axios';
import Interweave from 'interweave';
import dummy1 from '../../assets/img/posts/md/1.jpg';
// import dummy2 from '../../assets/img/posts/details/1.jpg';
import services1 from '../../assets/img/tapaw/bussiness.png';
import { wpBase } from "../../shared/constants";
import { customLanguage } from "../../shared/languages";

const News = props => {
  const [totalPost, setTotalPost] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [wpData, setWpData] = useState([]);
  const [imgId, setImgId] = useState([]);
  const [imgData, setImgData] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    allNewsRequest();
  }, []);

  // useEffect(() => {
  //   // console.log('run img effect')
  //   if (wpData.length !== 0) {
  //     console.log('wpData not zero');
  //     loopImgRequest(imgId);
  //   }
  // }, [wpData]);

  useEffect(() => {
    // console.log(`imgId array ${JSON.stringify(imgId)}`);
    loopImgRequest(imgId);
  }, [imgId]);

  useEffect(() => {
    // console.log(`imgData array ${JSON.stringify(imgData)}`);
  }, [imgData]);

  async function allNewsRequest () {
    let data = null;
    let error = '';
    let loading = true;
    
    await axios.get(`${wpBase}/posts?page=1`)
    .then(res => {
        loading = false;
        data = res.data;
        // console.log(`all news data ${JSON.stringify(data)}`);
        setWpData(data);
        let tmp = [];
        data.forEach(a => {
          tmp.push(a.featured_media);
          // setImgId(old => [...old, a.featured_media]);
        });
        setImgId(tmp);
        setTotalPage(res.headers['x-wp-totalpages']);
        setTotalPost(res.headers['x-wp-total']);
    })
    .catch(err => {
        loading = false;
        error = err;
        if (err.response!==undefined) {
            // client received error response (5xx, 4xx)
            // console.log(`error response ${err.response}`);
        } else if(err.request!==undefined) {
            // client never received a response, or a request never left
            // console.log(`error request ${err.request}`);
        } else {
            // console.log(`get error ${err}`);
        }
    })
    // .finally(() => loading = false)

    // console.log(`loading ${loading}`);
    // console.log(`data ${data}`);
  }

  function loopImgRequest(imgArrayId) {
    if (imgArrayId.length !== 0) {
      imgArrayId.forEach(a => {
        imageRequest(a);
      });
    }
  }

  async function imageRequest (id) {
    // console.log(`id value ${id}`)
    let data = null;
    let error = '';
    let loading = true;
    
    await axios.get(`${wpBase}/media/${id}`)
    .then(res => {
        // console.log('get data')
        loading = false;
        data = res.data;
        setImgData(old => [...old, data]);
    })
    .catch(err => {
        loading = false;
        error = err;
        if (err.response!==undefined) {
            // client received error response (5xx, 4xx)
            // console.log(`error response ${err.response}`);
        } else if(err.request!==undefined) {
            // client never received a response, or a request never left
            // console.log(`error request ${err.request}`);
        } else {
            // console.log(`get error ${err}`);
        }
    })
    // .finally(() => loading = false)

    // console.log(`loading ${loading}`);
    // console.log(`data ${data}`);
  }

  function renderImgNews(newsArrLength, index) {
    // console.log(`index ${index}`);
    // provided that every news must have picture, make sure imgData length is same with article array length
    if (imgData.length === newsArrLength) {
      return (<img src={imgData[index].source_url} alt="post" className="news-img"/>);
    }
  }

  function renderExcerpt( content, link, id) {
    let temp = content;
    let newStr = temp.replace(link, `/newsDetails/${id}`);
    return (
      <Interweave
        content={newStr}
        // matchers={[new UrlMatcher('url'), new HashtagMatcher('hashtag')]}
      /> 
    );
  }

  return (
    <div className="section sigma_post-details" style={{paddingTop: '32px'}}>
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="sigma_post-details-inner">
              {
                wpData.filter(item => {
                  if (!searchValue) return true;
                  if (item.title.rendered.toUpperCase().includes(searchValue.toUpperCase())) return true;
                }).map((q, idx) => <div key={q.id} className="entry-content">
                <a
                  href={services1}
                  className="gallery-thumb"
                >
                 {renderImgNews(wpData.length, idx)}
                </a>
                <h2 className="entry-title">
                  {q.title.rendered}
                </h2>
                <div className="sigma_post-details-meta">
                  <span>
                    {" "}
                    <i className="far fa-calendar" /> {q.modified}
                  </span>
                  <span>
                    {" "}
                    <i className="far fa-eye" />{customLanguage(localStorage.getItem('language')).news.news.var1}
                  </span>
                  <span>
                    {" "}
                    {/* <i className="far fa-comments" /> Air Transport */}
                  </span>
                </div>
                {renderExcerpt(q.excerpt.rendered, q.link, q.id)}
                {/* <Interweave
                  content={q.excerpt.rendered}
                  // matchers={[new UrlMatcher('url'), new HashtagMatcher('hashtag')]}
                /> */}
                {/* <a
                  href="assets/img/posts/details/1.jpg"
                  className="gallery-thumb"
                >
                  <img src="assets/img/posts/details/1.jpg" alt="post" />
                </a>
                <a href="#">More Details</a> */}
                <hr />
              </div>)
              }
            </div>
          </div>
          <div className="col-lg-4">
            <div className="sidebar">
              {}
              <div className="widget widget-search">
                <h5 className="widget-title">{customLanguage(localStorage.getItem('language')).news.news.var2}</h5>
                <div className="input-group">
                  <input type="text" name="search" placeholder="Search" value={searchValue} onChange={inp => setSearchValue(inp.target.value)} />
                  <div className="input-group-append">
                    <button type="button">
                      <i className="fal fa-search mr-0" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="widget widget-sigma-recent-posts style-3">
                <h5 className="widget-title">{customLanguage(localStorage.getItem('language')).news.news.var3}</h5>
                <div className="sigma_recent-post">
                  <div className="recent-post-descr">
                    <div className="author-info d-flex align-items-center">
                      {/* <span>TA</span> */}
                      <div>
                        <a href={wpData.length !== 0 ? `/newsDetails/${wpData[wpData.length-1].id}` : '/'} className="date">
                          {wpData.length !== 0 ? wpData[wpData.length-1].modified : 'none'}
                        </a>
                        <a href={wpData.length !== 0 ? `/newsDetails/${wpData[wpData.length-1].id}` : '/'} className="author-name">
                          {wpData.length !== 0 ? wpData[wpData.length-1].title.rendered : 'none'}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="widget widget-sigma-recent-posts style-2">
                <h5 className="widget-title">{customLanguage(localStorage.getItem('language')).news.news.var4}</h5>
                <div className="sigma_recent-post">
                  <a href="blog-details.html" className="recent-post-image">
                    <img src={dummy1} alt="img" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default News;
