import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Interweave from 'interweave';
import { wpBase } from "../../shared/constants";
import dummy1 from '../../assets/img/posts/md/1.jpg';
import { customLanguage } from "../../shared/languages";

const NewsDetails = props => {
  const { id } = useParams();
  const [wpData, setWpData] = useState(null);

  useEffect(() => {
    pageRequest();
    // console.log('run')
  }, []);

  async function pageRequest () {
    let data = null;
    let error = '';
    let loading = true;
    
    await axios.get(`${wpBase}/posts/${id}`)
    .then(res => {
        // console.log('get news details')
        loading = false;
        data = res.data;
        setWpData(data);
        // console.log(`news results ${JSON.stringify(res.headers['x-wp-total'])}`);
    })
    .catch(err => {
        loading = false;
        error = err;
        if (err.response!==undefined) {
            // client received error response (5xx, 4xx)
            // console.log(`error response ${err.response}`);
        } else if(err.request!==undefined) {
            // client never received a response, or a request never left
            // console.log(`error request ${err.request}`);
        } else {
            // console.log(`get error ${err}`);
        }
    })
    // .finally(() => loading = false)

    // console.log(`loading ${loading}`);
    // console.log(`data ${JSON.stringify(data)}`);
  }

  return (
    <div className="section sigma_post-details">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="sigma_post-details-inner">
              <div className="entry-content">
                {/* <div className="sigma_post-details-categories">
                  <a href="#" className="sigma_post-category">
                    Construction 
                  </a>
                </div> */}
                <h2 className="entry-title">
                  {wpData !== null ? wpData.title.rendered : 'no data'}
                </h2>
                <div className="sigma_post-details-meta">
                  {/* <span>
                    {" "}
                    <i className="far fa-eye" /> 131 Views
                  </span>
                  <span>
                    {" "}
                    <i className="far fa-comments" /> 2 Comments
                  </span> */}
                  <span>
                    {" "}
                    <i className="far fa-calendar" /> {wpData !== null ? wpData.modified : 'no data'}
                  </span>
                </div>
                <Interweave
                  content={wpData !== null ? wpData.content.rendered : 'no data'}
                  // matchers={[new UrlMatcher('url'), new HashtagMatcher('hashtag')]}
                /> 
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="sidebar">
              {}
              {/* <div className="widget widget-search">
                <h5 className="widget-title">Search</h5>
                <div className="input-group">
                  <input type="text" name="search" placeholder="Search" />
                  <div className="input-group-append">
                    <button type="button">
                      <i className="fal fa-search mr-0" />
                    </button>
                  </div>
                </div>
              </div> */}
              {/* <div className="widget widget-sigma-recent-posts style-3">
                <h5 className="widget-title">Recent News</h5>
                <div className="sigma_recent-post">
                  <div className="recent-post-descr">
                    <div className="author-info d-flex align-items-center">
                      <span>TA</span>
                      <div>
                        <a href="#" className="author-name">
                          Tim Abell
                        </a>
                        <a href="blog-details.html" className="date">
                          June 4, 2021
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="widget widget-sigma-recent-posts style-2">
                <h5 className="widget-title">{customLanguage(localStorage.getItem('language')).news.news.var4}</h5>
                <div className="sigma_recent-post">
                  <a href="blog-details.html" className="recent-post-image">
                    <img src={dummy1} alt="img" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewsDetails;
