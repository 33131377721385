import React from 'react';
import ReactToPrint from 'react-to-print';

import PdfTemplate from './pdf_template';
import downloadImg from '../../../assets/img/tapaw/download.png';

class PdfComponent extends React.Component {
    render() {
        const {orderDate, pickupLocation, dropoffLocation, totalFare, status, orderID, customer} = this.props.pdfData;
        const {fullName, email, phoneNumber} = customer;
        const passedData = {
            date: orderDate._seconds,
            pickup: pickupLocation.address,
            delivery: dropoffLocation.address,
            fare: totalFare,
            status,
            orderID,
            customer: {
                fullName,
                email,
                phoneNumber,
            }
        }
      return (
        <div>
          <ReactToPrint
            content={() => this.componentRef}
            trigger={() =>                        
                <img src={downloadImg} className="downloadIcon cursor" />
            }
          />
            <div className="hide-stuff">
              <PdfTemplate 
                ref={(response) => (this.componentRef = response)} 
                data={passedData}
                />
            </div>
        </div>
      );
    }

}

export default PdfComponent;