import React from "react";
import BHImg from '../../assets/img/images/Berita-harian-Logo-New-1024x894.png';
import KosmoImg from '../../assets/img/images/Kosmo_logo.png';
import Tv1Img from '../../assets/img/images/1312px-Logo_of_TV1_Malaysia.svg.png';
import TvAlImg from '../../assets/img/images/1076px-Logo_of_the_TV_Alhijrah.svg.png';
import { customLanguage } from "../../shared/languages";

const IndexPart4 = props => {
  return (
    <div className="wf-section">
      <div className="container w-container">
        <h2 className="heading-5 capitalize-all">{customLanguage(localStorage.getItem('language')).home.indexPart4.title}</h2>
        <div className="div-block-4" />
        <div className="w-layout-grid grid-4">
          <img
            src={BHImg}
            loading="lazy"
            sizes="(max-width: 1919px) 114.53125px, 24vw"
            srcSet={`${BHImg} 500w, ${BHImg} 800w, ${BHImg} 1024w`}
            alt
            className="image-15"
          />
          <img
            src={KosmoImg}
            loading="lazy"
            alt
            className="image-16"
          />
          <img
            src={Tv1Img}
            loading="lazy"
            srcSet={`${Tv1Img} 500w, ${Tv1Img} 800w, ${Tv1Img} 1312w`}
            sizes="(max-width: 479px) 150px, (max-width: 767px) 200px, (max-width: 991px) 20vw, (max-width: 1919px) 175.390625px, 16vw"
            alt
            className="image-17"
          />
          <img
            src={TvAlImg}
            loading="lazy"
            sizes="(max-width: 767px) 200px, (max-width: 991px) 10vw, (max-width: 1919px) 89.734375px, 16vw"
            srcSet={`${TvAlImg} 500w, ${TvAlImg} 800w, ${TvAlImg} 1076w`}
            alt
            className="image-18"
          />
        </div>
      </div>
    </div>
  );
}

export default IndexPart4;
