import React, {useEffect, useState} from "react";
import {useForm} from 'react-hook-form';
import axios from "axios";
import {withRouter} from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import one_photoImg from '../../assets/img/images/1-photo.02dbcde0-1.jpg';
import two_photoImg from '../../assets/img/images/2-photo.048b62f9.jpg';
import three_photoImg from '../../assets/img/images/3-photo.25270a7c.jpg';
import four_photoImg from '../../assets/img/images/4-photo.6db3e5a2.jpg';
import five_photoImg from '../../assets/img/images/5-photo.1d2b0227.jpg';
import six_photoImg from '../../assets/img/images/6-photo.f6de640f.jpg';
import { baseUrl } from "../../shared/constants";
import Logo from '../../assets/img/images/logo.svg';
import DownloadApp from "../home/downloadapp";
import BaseAccordion from "./baseAccordion";

const vehicles = [
  { id: 1, label: 'Motorcycle', value: 'Motosikal' },
  { id: 2, label: 'Car', value: 'Kereta' },
  { id: 3, label: '4x4', value: '4 x 4' },
  { id: 4, label: 'Van', value: 'Van' },
  { id: 5, label: '1 Tonne Lorry', value: 'Lori 1-Ton' },
  { id: 6, label: '3 Tonne Lorry', value: 'Lori 3-Ton' },
];

const frequently = [
  { title: 'INTRODUCTION TO TAPAW DRIVER', desc: `TAPAW driver is a general term in TAPAW referred to driver and rider. Orders will be delivered directly, fast and safe. Expert drivers are ready to meet every delivery in a high-quality manner at all times.` },
  { title: 'WHAT ARE THE OPERATING HOURS FOR TAPAW DRIVERS?', desc: `Our delivery service is 24/7 (subject to government order in the currentsituation), but the management operating hours are between 10.00 amto 6.00 pm.` },
  { title: 'I ALREADY HAVE A PERMANENT JOB. CAN I BE A PART -TIME EMPLOYEE OFTAPAW RIDER?', desc: `Of course you can. You can specify how many orders you want to take each day. Be the boss for yourself!` },
  { title: 'HOW TO REGISTER TO BE A TAPAW RIDER?', desc: `To get started, just click on the form on top of this page.` },
  { title: 'DO I NEED A SPECIAL LICENSE TO WORK WITH TAPAW?', desc: `Unlike e-hailing services, you do not need a special license to make deliveries` },
  { title: 'REQUIREMENTS TO JOIN TAPAW?', 
  customDesc: <p className="faq-answer-text">
    1- Owns a motorcycle/car/van/4 x 4 truck/1-ton lorry/3-ton
    lorry
    <br />
    2- We only recruit Malaysians of 18 years and above with a
    valid driving license. Van or lorry drivers need to have a GDL
    license
    <br />
    3- Have a smartphone
  </p>},
  { title: 'ARE THERE ANY FEES?', desc: `Uniforms are compulsory for drivers.Applications for drivers (Cars, Vans, 4X4s, and Trucks) need to have the uniform. While the rider's (Motorcycles) application is charged for the purchase of uniform and bags (optional).Drivers are also required to top-up with a minimum of RM20 to the wallet in the TAPAW application to activate the account.` },
  { title: 'HOW LONG DOES IT TAKE TO BECOME A TAPAW RIDER?', desc: `Application period 2-3 days after you fill in the form.` },
  { title: 'WHAT SHOULD I DO IF AFTER I SUCCESSFULLY TOP UP BUT EWALLET NO SUCH TRANSACTION?', desc: `Do not worry. Contact admin for assistance admin@tapaw.com` },
  { title: 'CAN I RECEIVE MULTIPLE ORDERS?', desc: `Yes, you can receive many orders, as long as they are at different times.` },
];

const DriverRegistBody = props => {
  // const {state} = props.location; // inside editMode check if driverEdit or vendorEdit exist
  const {state} = props.location;
  const { register, handleSubmit, watch, formState: { errors } } = useForm({mode: "onBlur",
  reValidateMode: "onBlur",
  shouldUnregister: true});
  const [phone, setPhone] = useState('');
  const [phoneValidate, setPhoneValidate] = useState('');
  const [referralValidate, setReferralValidate] = useState('');
  const [referal, setReferral] = useState('');
  const [loading, setLoading] = useState(false);
  const [statesLoading, setStatesLoading] = useState(false);
  const [allValidate, setAllValidate] = useState(false);

   // check if in edit mode for driver and vendor
  //  useEffect(() => {
  //   editModeChecker();
  // }, []);

  useEffect(() => {
    if (localStorage.getItem('referralCode')) {
      setReferral(localStorage.getItem('referralCode'))
    }
  }, []);

  useEffect(() => {
    referralSetup();
  }, [referal]);

  // function editModeChecker() {
  //   if (state!==undefined) {
  //     console.log(`state ${JSON.stringify(state)}`);
  //     if (state.driverEdit!==undefined) {
  //       setSecondPhase('Driver');
  //       getBrands(); // load brands
  //       getStates(); // load states
  //     // } else if (state.vendorEdit!==undefined) {
  //     //   setSecondPhase('Vendor');
  //     //   getBusiness(); // load vendor types
  //     //   getStates(); // load states
  //     } else {
  //       setSecondPhase('Default');
  //     }
  //   }
  // }

  // function editModeFlag(type) {
  //   if (state!==undefined) {
  //     switch(type) {
  //       case 'Driver':
  //         if (state.driverEdit!==undefined) {
  //           return state.driverEdit;
  //         } else {
  //           return false;
  //         }
  //       case 'Vendor':
  //         if (state.vendorEdit!==undefined) {
  //           return state.vendorEdit;
  //         } else {
  //           return false;
  //         }
  //     }
  //   }
  //   return false;
  // }

  async function phoneRequest ( phone ) {
    let data = null;
    let error = '';
    let loadingtmp = true;
   
    await axios.post(`${baseUrl}/check_phone`, {
        phone, 
    })
    .then(res => {
        // console.log('get data')
        loadingtmp = false;
        data = res.data;
        setPhoneValidate(data);
        setPhone(phone);
    })
    .catch(err => {
        loadingtmp = false;
        error = err;
        if (err.response!==undefined) {
            // client received error response (5xx, 4xx)
            // console.log(`error response ${err.response}`);
            setPhoneValidate('error');
        } else if(err.request!==undefined) {
            // client never received a response, or a request never left
            // console.log(`error request ${err.request}`);
            setPhoneValidate('error');
        } else {
            // console.log(`get error ${err}`);
            setPhoneValidate('error');
        }
    })
    // .finally(() => loading = false)

    // console.log(`loading ${loading}`);
    // console.log(`data ${data}`);
  }

  function referralSetup() {
    if (referal != '') {
      referralRequest(referal)
    }
  }

  async function referralRequest ( referal ) {
    let data = null;
    let error = '';
    let loadingtmp = true;
    if (referal != '') {
      await axios.post(`${baseUrl}/check_referal`, {
          referal,
      })
      .then(res => {
          loadingtmp = false;
          data = res.data;
          // console.log(`referral request data ${JSON.stringify(data)}`);
          setReferralValidate(data);
          // setReferral(referal);
      })
      .catch(err => {
          loadingtmp = false;
          error = err;
          if (err.response!==undefined) {
              // client received error response (5xx, 4xx)
              // console.log(`error response ${err.response}`);
              setReferralValidate('error');
          } else if(err.request!==undefined) {
              // client never received a response, or a request never left
              // console.log(`error request ${err.request}`);
              setReferralValidate('error');
          } else {
              // console.log(`get error ${err}`);
              setReferralValidate('error');
          }
      })
    }
    // .finally(() => loading = false)

    // console.log(`loading ${loading}`);
    // console.log(`data ${data}`);
  }

  // const validationChecking = dataPassed => {
  //   const {email, password, name, username, drivertype} = dataPassed;
  //   if (email!==undefined && password!==undefined && name!==undefined && username!==undefined && drivertype!==undefined) {
  //     setAllValidate(true);
  //   } else {
  //     setAllValidate(false);
  //   }
  // }

  const onSubmit = data => {
    // console.log(data);
    // console.log(`data passed ${JSON.stringify(data)}`);
    // console.log(`phone ${phone}`);
    registerDriver(data);
  }

  const registerDriver = async (dataPassed) =>
  {
    // console.log(`data passed ${JSON.stringify(dataPassed)}`);
    // console.log(`phone ${phone}`);
    let data = null;
    let error = '';
    const {email, password, name, username, drivertype} = dataPassed;
    setLoading(true);
    await axios.post(`${baseUrl}/register`, {
      email,
      password,
      phone, 
      full_name: name, 
      username, 
      referal,
      usertype: 'driver',
      drivertype
    })
    .then(async res => {
      setLoading(false);
      // console.log('get data')
      data = res.data;
      // setSecondPhase('Driver');
      
      setLoading(true);
      //console.log(`email ${email}, password ${password}`)
      await axios.post(`${baseUrl}/login`, {
          email,
          password,
      })
      .then(res => {
          // console.log('get data')
          setLoading(false);
          data = res.data;
          // console.log(`login data ${JSON.stringify(data)}`);
          localStorage.setItem("idToken", data.idToken);
          localStorage.setItem("customToken", data.customToken);
          localStorage.setItem("refreshToken", data.refreshToken);
          localStorage.setItem("fullName", data.fullName);
          localStorage.setItem("username", data.username);
          localStorage.setItem("uid", data.uid);
          localStorage.setItem("userType", data.usertype[0].type);
          localStorage.setItem("completedRegister", data.completedRegister!==undefined?data.completedRegister:false);
          props.history.push('/registerdriver2');
          // getBrands();
          // getStates();
          // props.history.push("/profile");
          // props.onHide(false)
      })
      .catch(err => {
          setLoading(false);
          // console.log(err);
          error = err;
          if (err.response!==undefined) {
              // client received error response (5xx, 4xx)
              // console.log(`error response ${err.response}`);
          } else if (err.request!==undefined) {
              // client never received a response, or a request never left
              // console.log(`error request ${err.request}`);
          } else {
              // console.log(`get error ${err}`);
          }
      })
    })
    .catch(err => {
      setLoading(false);
      // console.log(err);
      error = err;
      if (err.response!==undefined) {
          // client received error response (5xx, 4xx)
          // console.log(`error response ${JSON.stringify(err.response)}`);
          alert(err.response.data.error.message) //dah betul
      } else if(err.request!==undefined) {
          // client never received a response, or a request never left
          // console.log(`error request ${err.request}`);
      } else {
          // console.log(`get error ${err}`);
      }
    })
  }
  return (
    <div>
      <div className="wf-section">
        <div className="div-block-8 driver">
          <div className="container w-container">
            <div className="columns-2 w-row">
              <div className="column-3 w-col w-col-6">
                <div className="div-block-18">
                  <div className="div-block-19 driver">
                    <h1 className="heading-9">Earn Extra Income with Us</h1>
                    <p className="paragraph-title">
                      Seek an opportunity to earn extras, but no where to start?
                      Take your journey with us by turning your smartphone and
                      any of your vehicles into a money making machine.
                    </p>
                  </div>
                </div>
              </div>
              <div className="column-4 w-col w-col-6">
                <div className="div-block-9">
                  <div className="form-block w-form">
                    <div className="col-md-12" style={{marginLeft: '65px'}}>
                      <img src={Logo} className="logo-regist" />
                    </div>
                    <div className="col-md-12" style={{marginLeft: '65px'}}>
                      <span className="regist-title">Driver Registration</span>
                    </div>
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      className="form-2"
                    >
                      <label htmlFor="name" className="field-label">
                        Name
                      </label>
                      <input
                        {...register("name", { required: true })}
                        type="text"
                        className="form w-input"
                        maxLength={256}
                        name="name"
                        data-name="Name"
                        placeholder="Enter your name"
                        id="name"
                      />
                      {errors.name && <span>This field is required</span>}
                      <label htmlFor="email" className="field-label">
                        Email Address
                      </label>
                      <input
                        {...register("email", { required: true })}
                        type="email"
                        className="form w-input"
                        maxLength={256}
                        name="email"
                        data-name="Email"
                        placeholder="Enter your email address"
                        id="email"
                      />
                      {errors.email && <span>This field is required</span>}
                      <label htmlFor="email-2" className="field-label">
                        Password
                      </label>
                      <input
                        {...register("password", { required: true })}
                        type="password"
                        className="form w-input"
                        maxLength={256}
                        name="password"
                        data-name="password"
                        placeholder="*****"
                        id="password"
                      />
                      {errors.password && <span>This field is required</span>}
                      <label htmlFor="name-6" className="field-label">
                        Phone Number{" "}
                      </label>
                      <input
                        // {...register("phoneNumber", { required: true })}
                        onChange={e => phoneRequest(e.target.value)}
                        type="text"
                        className="form w-input"
                        maxLength={256}
                        name="phoneNumber"
                        data-name="phoneNumber"
                        placeholder="Enter your phone number"
                        id="phoneNumber"
                        required
                      />
                      <span className={phoneValidate == 'Phone is available to use' ? 'validation-msg-passed' : 'validation-msg-error'}>{phoneValidate}</span>
                      {/* {errors.phoneNumber && <span>This field is required</span>} */}
                      <label htmlFor="Vehicle-Field" className="field-label">
                        Vehicle{" "}
                      </label>
                      <select
                        {...register("drivertype", { required: true })}
                        id="drivertype"
                        name="drivertype"
                        data-name="drivertype"
                        className="select-field w-select"
                      >{vehicles.map(a => (
                        <option key={a.id} value={a.value}>{a.label}</option>
                      ))}
                      </select>
                      {errors.vehicle && <span>This field is required</span>}
                      <label htmlFor="Vehicle-Field-2" className="field-label">
                        Username
                      </label>
                      <input
                        {...register("username", { required: true })}
                        type="text"
                        className="form username w-input"
                        maxLength={256}
                        name="username"
                        data-name="username"
                        placeholder="Enter your username"
                        id="username"
                      />
                      {errors.username && <span>This field is required</span>}
                      {/* <div className="div-block-10" /> */}
                      <label htmlFor="name-6" className="field-label">
                        Referred by
                      </label>
                      <input
                        type="text"
                        className="form no-edit w-input"
                        maxLength={256}
                        name="name-5"
                        data-name="Name 5"
                        placeholder={referal}
                        id="name-5"
                        disabled
                      />
                      <span className={'validation-msg-error'}>{referralValidate=='Referal not available'||referralValidate=='error'?referralValidate:''}</span>
                      <div className="div-block-11">
                        <label className="w-checkbox checkbox-field">
                          <input
                            type="checkbox"
                            id="checkbox"
                            name="checkbox"
                            data-name="Checkbox"
                            className="w-checkbox-input"
                            required
                          />
                          <span className="checkbox-label w-form-label">
                            By signing up, I agree to the{" "}
                            <a href="https://tapaw.com/static/media/GeneralTerms.08000de2.pdf">
                              <strong className="bold-text">
                                Terms and Conditions
                              </strong>
                            </a>{" "}
                            and{" "}
                            <a href="/privacy">
                              <span className="bold-text">
                                <strong>TAPAW's Privacy Policy</strong>
                              </span>
                            </a>
                          </span>
                        </label>
                        <label className="w-checkbox checkbox-field">
                          <input
                            type="checkbox"
                            id="checkbox-2"
                            name="checkbox-2"
                            data-name="Checkbox 2"
                            className="w-checkbox-input"
                            required
                          />
                          <span className="checkbox-label w-form-label">
                            I agree with the usage of my personal data in line
                            with the stipulated Privacy Policy for marketing
                            purpose.
                          </span>
                        </label>
                      </div>
                      {
                      loading? <div className="col-lg-12 profile-text-centre"><CircularProgress /></div>:
                      <button type="submit" className="submit-register">SUBMIT</button>
                      // <input
                      //   type="submit"
                      //   defaultValue="Submit"
                      //   data-wait="Please wait..."
                      //   className='button w-button'
                      //   // className="button registration w-button"
                      // />
                      }
                    </form>
                    <div className="success w-form-done">
                      <img
                        src="images/success_button.gif"
                        loading="lazy"
                        width={219}
                        alt
                        className="image-7"
                      />
                      <div className="text-block-2">
                        Thank you! Your submission has been received!
                      </div>
                    </div>
                    <div className="error-message error-message-2 w-form-fail">
                      <div>
                        Oops! Something went wrong while submitting the form.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{marginTop: '30px'}}>
        <DownloadApp />
      </div>
      <div className="section-7 wf-section">
        <div className="container w-container">
          <h2 className="heading-5">HOW TO MAKE MONEY WITH US</h2>
          <div className="row">
            <div className="col-md-4" />
            <div
                className="col-md-4"
              >
              <div className="yellow-line" style={{width: '60%', marginTop: '12px'}} />
            </div>
          </div>
          <p className="description-text">Sign up with TAPAW to generate more income. Schedule your pickup, log in and log out
anytime you want, you pick the time.</p>
          <div
            data-w-id="596615e2-11b9-05f2-bf47-7f7055e79bc7"
            style={{
              opacity: 0
            }}
            className="div-block-4"
          />
          <div
            style={{
              paddingTop: "56.17021276595745%",
              marginLeft: 'auto',
              marginRight: 'auto'
            }}
            className="w-embed-youtubevideo youtube"
          >
            <iframe
              src="https://www.youtube.com/embed/ahnHnzyFrY4?rel=0&controls=1&autoplay=0&mute=0&start=0"
              frameBorder={0}
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                pointerEvents: "auto",
                borderColor: '#ffcd3d', 
                borderRadius: '5px', 
                borderWidth: '10px', 
                textShadow: 'none'
              }}
              allow="autoplay; encrypted-media"
              allowFullScreen
            />
          </div>
        </div>
      </div>
      <div className="wf-section">
        <div className="container with-paragraph w-container">
          <h2 className="heading-5">WHY DO YOU NEED TO JOIN US</h2>
          <div className="yellow-line" style={{width: '200px', marginTop: '12px'}} />
          <p className="description-text">
          Discover and enjoy the opportunities to grow with us!
          </p>
          <div
            data-w-id="daea7551-0993-1f1a-2455-d802e792a1d8"
            style={{
              opacity: 0
            }}
            className="div-block-4"
          />
          <div className="w-layout-grid grid-2 features">
            <div className="div-tapaw-unique extra">
              <img
                src={one_photoImg}
                loading="lazy"
                width={80}
                height={80}
                alt
                className="image-10 _1"
              />
              <div className="div-block-20">
                <h4 className="heading-4 tapaw-feature">Be in Charge</h4>
                <p className="paragraph-3">
                  With no one to report to, you are your own boss
                </p>
              </div>
            </div>
            <div className="div-tapaw-unique extra">
              <img
                src={two_photoImg}
                loading="lazy"
                width={80}
                height={80}
                alt
                className="image-10 _2"
              />
              <div className="div-block-20">
                <h4 className="heading-4 tapaw-feature">
                  Be an Income Grower
                  <br />
                </h4>
                <p className="paragraph-3">
                  With competitive rates, money credited to you for every
                  completed task.
                  <br />
                </p>
              </div>
            </div>
            <div className="div-tapaw-unique extra">
              <img
                src={three_photoImg}
                loading="lazy"
                width={80}
                height={80}
                alt
                className="image-10 _3"
              />
              <div className="div-block-20">
                <h4 className="heading-4 tapaw-feature">
                  Be Flexible
                  <br />
                </h4>
                <p className="paragraph-3">
                  Schedule your pickup, log in and log out anytime you want,
                  you pick the time.
                  <br />
                </p>
              </div>
            </div>
            <div className="div-tapaw-unique extra">
              <img
                src={four_photoImg}
                loading="lazy"
                width={80}
                height={80}
                alt
                className="image-10 _4"
              />
              <div className="div-block-20">
                <h4 className="heading-4 tapaw-feature">
                  Be the City Expert
                  <br />
                </h4>
                <p className="paragraph-3">
                  All deliveries made within your area, you are faster than
                  anyone else.
                  <br />
                </p>
              </div>
            </div>
            <div className="div-tapaw-unique extra">
              <img
                src={five_photoImg}
                loading="lazy"
                width={80}
                height={80}
                alt
                className="image-10 _5"
              />
              <div className="div-block-20">
                <h4 className="heading-4 tapaw-feature">
                  Be Entertained
                  <br />
                </h4>
                <p className="paragraph-3">
                  Run different errands like foods, parcels, packages,
                  bouquet, etc.
                  <br />
                </p>
              </div>
            </div>
            <div className="div-tapaw-unique extra">
              <img
                src={six_photoImg}
                loading="lazy"
                width={80}
                height={80}
                alt
                className="image-10 _7"
              />
              <div className="div-block-20">
                <h4 className="heading-4 tapaw-feature">
                  Be Part of Gig Economy
                  <br />
                </h4>
                <p className="paragraph-3">
                  Join the wave of the industrial revolution in one of growing
                  logistic startup.
                  <a href="https://tapaw.com/en/">
                    <br />
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wf-section">
        <div className="container-2 faq-container">
          <div className="div-block-17">
            <h2 className="pricing-h1 faq-h2">Frequently Asked Questions</h2>
            <div
              data-w-id="ecd20145-b589-5596-2637-0f5072b9b33a"
              style={{
                opacity: 1
              }}
              className="div-block-4"
            />
          </div>
          <BaseAccordion item={frequently} />
          {/* <div className="faq-wrap">
            <div className="faq-question">
              <div className="faq-plus-wrap">
                <div className="faq-plus-l" />
                <div className="faq-plus" />
              </div>
              <div className="faq-q-text">Introduction to TAPAW driver</div>
            </div>
            <div className="faq-answer">
              <p className="faq-answer-text">
                TAPAW driver is a general term in TAPAW referred to driver and
                rider. Orders will be delivered directly, fast and safe.
                Expert drivers are ready to meet every delivery in a
                high-quality manner at all times.
              </p>
            </div>
          </div>
          <div className="faq-wrap">
            <div className="faq-question">
              <div className="faq-plus-wrap">
                <div className="faq-plus-l" />
                <div className="faq-plus" />
              </div>
              <div className="faq-q-text">
                What are the operating hours for TAPAW drivers?
              </div>
            </div>
            <div className="faq-answer">
              <p className="faq-answer-text">
                Our delivery service is 24/7 (subject to government order in
                the currentsituation), but the management operating hours are
                between 10.00 amto 6.00 pm.
              </p>
            </div>
          </div>
          <div className="faq-wrap">
            <div className="faq-question">
              <div className="faq-plus-wrap">
                <div className="faq-plus-l" />
                <div className="faq-plus" />
              </div>
              <div className="faq-q-text">
                I already have a permanent job. Can I be a part -time employee
                ofTapaw rider?
              </div>
            </div>
            <div className="faq-answer">
              <p className="faq-answer-text">
                Of course you can. You can specify how many orders you want to
                take each day. Be the boss for yourself!
              </p>
            </div>
          </div>
          <div className="faq-wrap">
            <div className="faq-question">
              <div className="faq-plus-wrap">
                <div className="faq-plus-l" />
                <div className="faq-plus" />
              </div>
              <div className="faq-q-text">
                How to register to be a Tapaw rider?
              </div>
            </div>
            <div className="faq-answer">
              <p className="faq-answer-text">
                To get started, just click on the form on top of this page.
              </p>
            </div>
          </div>
          <div className="faq-wrap">
            <div className="faq-question">
              <div className="faq-plus-wrap">
                <div className="faq-plus-l" />
                <div className="faq-plus" />
              </div>
              <div className="faq-q-text">
                Do I need a special license to work with TAPAW?
              </div>
            </div>
            <div className="faq-answer">
              <p className="faq-answer-text">
                Unlike e-hailing services, you do not need a special license
                to make deliveries
              </p>
            </div>
          </div>
          <div className="faq-wrap">
            <div className="faq-question">
              <div className="faq-plus-wrap">
                <div className="faq-plus-l" />
                <div className="faq-plus" />
              </div>
              <div className="faq-q-text">Requirements to Join Tapaw?</div>
            </div>
            <div className="faq-answer">
              <p className="faq-answer-text">
                1- Owns a motorcycle/car/van/4 x 4 truck/1-ton lorry/3-ton
                lorry
                <br />
                2- We only recruit Malaysians of 18 years and above with a
                valid driving license. Van or lorry drivers need to have a GDL
                license
                <br />
                3- Have a smartphone
              </p>
            </div>
          </div>
          <div className="faq-wrap">
            <div className="faq-question">
              <div className="faq-plus-wrap">
                <div className="faq-plus-l" />
                <div className="faq-plus" />
              </div>
              <div className="faq-q-text">Are there any fees?</div>
            </div>
            <div className="faq-answer">
              <p className="faq-answer-text">
                Uniforms are compulsory for drivers.Applications for drivers
                (Cars, Vans, 4X4s, and Trucks) need to have the uniform. While
                the rider's (Motorcycles) application is charged for the
                purchase of uniform and bags (optional).Drivers are also
                required to top-up with a minimum of RM20 to the wallet in the
                TAPAW application to activate the account.
              </p>
            </div>
          </div>
          <div className="faq-wrap">
            <div className="faq-question">
              <div className="faq-plus-wrap">
                <div className="faq-plus-l" />
                <div className="faq-plus" />
              </div>
              <div className="faq-q-text">
                How long does it take to become a tapaw rider?
              </div>
            </div>
            <div className="faq-answer">
              <p className="faq-answer-text">
                Application period 2-3 days after you fill in the form.
              </p>
            </div>
          </div>
          <div className="faq-wrap">
            <div className="faq-question">
              <div className="faq-plus-wrap">
                <div className="faq-plus-l" />
                <div className="faq-plus" />
              </div>
              <div className="faq-q-text">
                What should I do if after I successfully top up but Ewallet no
                such transaction?
              </div>
            </div>
            <div className="faq-answer">
              <p className="faq-answer-text">
                Do not worry. Contact admin for assistance admin@tapaw.com
              </p>
            </div>
          </div>
          <div className="faq-wrap">
            <div className="faq-question">
              <div className="faq-plus-wrap">
                <div className="faq-plus-l" />
                <div className="faq-plus" />
              </div>
              <div className="faq-q-text">Can I receive multiple orders?</div>
            </div>
            <div className="faq-answer">
              <p className="faq-answer-text">
                Yes, you can receive many orders, as long as they are at
                different times.
              </p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default withRouter(DriverRegistBody);
