import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactPaginate from 'react-paginate';
import { baseUrl, tapawLink } from '../../shared/constants';
import whatsappIcon from '../../../src/assets/img/whatsapp.png';

const ProfileReferralHistory = props => {
    const username = localStorage.getItem("username");
    const textAreaRef = useRef(null);
    const [referralData, setReferralData] = useState(null);
    const [referralLoading, setReferralLoading] = useState(false);
    const [commissionData, setCommissionData] = useState(null);
    const [commissionLoading, setCommissionLoading] = useState(false);
    const [triggerRefreshReferral, setTriggerRefreshReferral] = useState(false);
    const [triggerRefreshCommission, setTriggerRefreshCommission] = useState(false);
    const [offsetReferral, setOffsetReferral] = useState(0);
    const [perPageReferral] = useState(5);
    const [pageCountReferral, setPageCountReferral] = useState(0);
    const [offsetCommission, setOffsetCommission] = useState(0);
    const [perPageCommission] = useState(5);
    const [pageCountCommission, setPageCountCommission] = useState(0);

    useEffect(() => {
        getReferralHistory()
    }, [triggerRefreshReferral, offsetReferral]);

    useEffect(() => {
        getCommissionHistory()
    }, [triggerRefreshCommission, offsetCommission]);

    // useEffect(() => {
    //     console.log(`referral data ${JSON.stringify(referralData)}`);
    // }, [referralData]);

    function copyToClipboard() {
        textAreaRef.current.select();
        textAreaRef.current.setSelectionRange(0, 99999);
        document.execCommand('copy');
        alert('Copied');
    }

    function loadingSwitcher(type, flag) {
        switch (type) {
            case 'Referral':
                setReferralLoading(flag);
                break;
            case 'Commission':
                setCommissionLoading(flag);
                break;
            default:
                break;
        }
    }

    const handlePageClickReferral = (e) => {
        const selectedPage = e.selected;
        setOffsetReferral(selectedPage + 1)
    };

    const handlePageClickCommission = (e) => {
        const selectedPage = e.selected;
        setOffsetCommission(selectedPage + 1)
    };

    async function getReferralHistory() {
        loadingSwitcher('Referral', true);
        const token = localStorage.getItem("idToken");
        await axios.post(`${baseUrl}/get_my_referrals`, {
            token
        })
            .then(res => {
                const data = res.data
                // console.log(`referral data ${JSON.stringify(data)}`);
                if (typeof (data) !== 'string') {
                    data.reverse();
                    let slicedData = data.slice(offsetReferral, offsetReferral + perPageReferral);
                    // console.log(`sliced data ${JSON.stringify(slicedData)}`);
                    setReferralData(slicedData);
                    setPageCountReferral(Math.ceil(data.length / perPageReferral));
                } else {
                    setReferralData(data);
                }
                loadingSwitcher('Referral', false);
                // setReferralData(data)
            })
            .catch(err => {
                // console.log(`error response ${err.response}`);
                loadingSwitcher('Referral', false);
                // loading = false;
                // error = err;
                if (err.response !== undefined) {
                    // client received error response (5xx, 4xx)
                    // console.log(`error response ${err.response}`);
                    //   console.log(err.response);
                    if (err.response.data.message !== undefined && typeof (err.response.data.message) === 'string') {
                        if (err.response.data.message.includes('Firebase ID token has expired')) {
                            //   console.log('refresh');
                            refreshTokenRequest('Referral');
                        }
                    }
                } else if (err.request !== undefined) {
                    // client never received a response, or a request never left
                    // console.log(`error request ${err.request}`);
                } else {
                    // console.log(`get error ${err}`);
                }
            })
    }

    async function getCommissionHistory() {
        loadingSwitcher('Commission', true)
        const token = localStorage.getItem("idToken");
        await axios.post(`${baseUrl}/get_my_transactions`, {
            token,
            type: 'commission'
        })
            .then(res => {
                const data = res.data;
                // console.log(`${type} history data ${JSON.stringify(data)}`);
                if (typeof (data) !== 'string') {
                    data.reverse();
                    let slicedData = data.slice(offsetCommission, offsetCommission + perPageCommission);
                    setCommissionData(slicedData);
                    setPageCountCommission(Math.ceil(data.length / perPageCommission));
                } else {
                    setCommissionData(data);
                }
                loadingSwitcher('Commission', false);
            })
            .catch(err => {
                // console.log(`error response ${err.response}`);
                // console.log(err.response);
                // topupLoading = false;
                // error = err;
                loadingSwitcher('Commission', false);
                if (err.response !== undefined) {
                    // client received error response (5xx, 4xx)
                    //   console.log(`error response ${err.response}`);
                    // console.log(err.response);
                    if (err.response.data.message != undefined) {
                        if (err.response.data.message.includes('Firebase ID token has expired')) {
                            // console.log('refresh');
                            refreshTokenRequest('Commission');
                        }
                    }
                } else if (err.request !== undefined) {
                    // client never received a response, or a request never left
                    //   console.log(`error request ${err.request}`);
                } else {
                    //   console.log(`get error ${err}`);
                }
            })
    }

    async function refreshTokenRequest(type) {
        loadingSwitcher(type, true);
        const refresh_token = localStorage.getItem("refreshToken");
        // console.log(`refresh token in purchase history ${refresh_token}`);
        await axios.post(`${baseUrl}/refresh_token`, {
            refresh_token
        })
            .then(res => {
                const data = res.data
                // console.log(`refresh data ${JSON.stringify(data)}`);
                localStorage.setItem("idToken", data.id_token);
                localStorage.setItem("refreshToken", data.refresh_token);
                loadingSwitcher(type, false);
                switch (type) {
                    case 'Referral':
                        setTriggerRefreshReferral(old => old + 1);
                        break;
                    case 'Commission':
                        setTriggerRefreshCommission(old => old + 1);
                    default:
                        break;
                }
            })
            .catch(err => {
                // console.log(`error response ${err.response}`);
                // console.log(err.response);
                // topupLoading = false;
                // error = err;
                loadingSwitcher(type, false);
                if (err.response !== undefined) {
                    // client received error response (5xx, 4xx)
                    localStorage.setItem("idToken", "");
                    localStorage.setItem("customToken", "");
                    localStorage.setItem("refreshToken", "");
                    localStorage.setItem("fullName", "");
                    localStorage.setItem("username", "");
                    localStorage.setItem("userType", "");
                    localStorage.setItem("uid", "");
                    localStorage.setItem("completedRegister", "");
                    props.history.push('/');
                    alert('Session expired. Please login again.');
                    //   console.log(`error response ${err.response}`);
                    //   console.log(err.response);
                } else if (err.request !== undefined) {
                    // client never received a response, or a request never left
                    //   console.log(`error request ${err.request}`);
                } else {
                    //   console.log(`get error ${err}`);
                }
            })
    }

    function openWhatsapp(phone) {
        window.open(`https://api.whatsapp.com/send/?phone=+${phone}`, '_blank', 'noopener', 'noreferrer');
    }

    function renderReferral(data) {
        // console.log(`referral data ${JSON.stringify(data)}`);
        if (referralLoading) {
            return <div className="col-md-12 profile-text-centre"><CircularProgress /></div>;
        }
        if (data != null && data != undefined && data != "No referrals found" && data.length != 0) {
            // console.log(`referral data ${JSON.stringify(data)}`);
            return (
                data.map((a, idx) => {
                    return (
                        <div className="row profile-multiple-text profile-text-centre" key={idx}>
                            <div className="col-md-2">
                                <span className="font14">{a.fullName}</span>
                            </div>
                            <div className="col-2" >
                                <span className="font14">{a.email}</span>
                            </div>
                            <div className="col-2">
                                <div className="row cursor" onClick={() => openWhatsapp(a.phoneNumber)}>
                                    <div className="col-md-8"><span className="font14">{a.phoneNumber}</span></div>
                                    <div className="col-md-4"><img src={whatsappIcon} style={{ width: '40px', height: '20px' }} /></div>
                                </div>
                            </div>
                            <div className="col-2" >
                                <span className="font14">{a.dateJoined}</span>
                            </div>
                            <div className="col-2">
                                <span className="font14">{a.status}</span>
                            </div>
                            <div className="col-2" >
                                <span className="font14">{a.usertype[0].type}</span>
                            </div>
                        </div>
                    );
                })
            );
        } else {
            return <div className="row profile-multiple-text profile-text-centre"><div className="col-md-12"><span>No data</span></div></div>
        }
    }

    function renderCommission(data) {
        if (commissionLoading) {
            return <div className="col-md-12 profile-text-centre"><CircularProgress /></div>;
        }
        if (data != null && data != undefined && data.length != 0 && data != 'Transactions not found') {
            // console.log(`commission in render ${JSON.stringify(data)}`)
            return (
                data.map((a, idx) => {
                    return (
                        <div className="row profile-multiple-text profile-text-centre" key={idx}>
                        <div className="col-4 profile-text-left">
                                <span>{new Date(a.orderDate._seconds * 1000).toDateString()}</span>
                                {/* <span>{a.orderDate.toDate()}</span> */}
                            </div>
                        <div className="col-4 profile-text-centre" >
                                <span>{a.orderID}</span>
                            </div>
                        <div className="col-4 profile-text-right" >
                            <span style={{ paddingRight: '15px' }}>RM{a.fee}</span>
                            </div>
                        <div className="col-12 line-confirm" />
                        </div>);
                })
            );
        } else {
            return <div className="row profile-multiple-text profile-text-centre"><div className="col-md-12"><span>No data</span></div></div>
        }
    }

    return (
        <div className={`${props.showDiv == true ? 'show-stuff' : 'hide-stuff'}`}>
            <div className="row justify-content-center padding-top-bottom-50">
                <div className="col-md-11">
                    <div className="row" style={{ paddingBottom: '50px' }}>
                        <div className="col-md-12" style={{ paddingRight: 0, paddingLeft: 0 }}>
                            <div className="col-md-12 extra-profile-box white-background shadow-header" style={{ padding: '32px' }}>
                                <div className="col-md-12"><h3>Earn from 1.5% Passive Income from every order!</h3></div>
                                <div className="col-md-12"><span>Refer your friends with us and earn 1.5% every order they made as commision.</span></div>
                                <div className="col-md-12 profile-text-centre"><span><b>Share your link and earn more!</b></span></div>
                                <div className="col-md-12 referral-box-outer">
                                    <div className="row">
                                        <div className="col-md-10">
                                            <textarea ref={textAreaRef} value={`${tapawLink}/${username}`} className="plain-text-area" style={{ width: '100%' }} />
                                        </div>
                                        <div className="col-md-2">
                                            <div className="profile-text-centre referral-box-inner cursor" style={{ paddingTop: '5px', marginTop: '8px' }} onClick={() => copyToClipboard()}>
                                                <span>Copy</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ paddingBottom: '50px' }}>
                        <div className="border-radius-15 shadow-header">
                            <div className="row">
                                <div className="col-md-12 profile-box-top">
                                    <span>Referral History</span>
                                </div>
                                <div className="col-12 profile-box-bottom">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="row profile-multiple-text profile-text-centre">
                                                <div className="col-2">
                                                    <span className="bold-text">Name</span>
                                                </div>
                                                <div className="col-2" >
                                                    <span className="bold-text">Email</span>
                                                </div>
                                                <div className="col-2">
                                                    <span className="bold-text">Phone No.</span>
                                                </div>
                                                <div className="col-2" >
                                                    <span className="bold-text">Date Registered</span>
                                                </div>
                                                <div className="col-2">
                                                    <span className="bold-text">Status</span>
                                                </div>
                                                <div className="col-2" >
                                                    <span className="bold-text">Type</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            {renderReferral(referralData)}
                                        </div>
                                        <div className="col-md-12">
                                            <ReactPaginate
                                                previousLabel={"prev"}
                                                nextLabel={"next"}
                                                breakLabel={"..."}
                                                breakClassName={"break-me"}
                                                pageCount={pageCountReferral}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={5}
                                                onPageChange={handlePageClickReferral}
                                                containerClassName={"pagination"}
                                                subContainerClassName={"pages pagination"}
                                                activeClassName={"active"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ paddingBottom: '50px' }}>
                        <div className="border-radius-15 shadow-header">
                            <div className="row">
                                <div className="col-md-12 profile-box-top">
                                    <span>Commission History</span>
                                </div>
                                <div className="col-md-12 profile-box-bottom">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="row profile-multiple-text profile-text-centre">
                                                <div className="col-4 profile-text-left">
                                                    <span className="bold-text" style={{ paddingLeft: '15px'}}>DATE</span>
                                                </div>
                                                <div className="col-4 profile-text-centre" >
                                                    <span className="bold-text">ORDER ID</span>
                                                </div>
                                                <div className="col-4 profile-text-right">
                                                    <span className="bold-text" style={{ paddingRight: '15px' }}>FEE</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            {renderCommission(commissionData)}
                                        </div>
                                        <div className="col-md-12">
                                            <ReactPaginate
                                                previousLabel={"prev"}
                                                nextLabel={"next"}
                                                breakLabel={"..."}
                                                breakClassName={"break-me"}
                                                pageCount={pageCountCommission}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={5}
                                                onPageChange={handlePageClickCommission}
                                                containerClassName={"pagination"}
                                                subContainerClassName={"pages pagination"}
                                                activeClassName={"active"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProfileReferralHistory;