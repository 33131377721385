import React from 'react';
import { Accordion } from 'react-bootstrap';
import minusImg from '../../assets/img/minus-sign.png';
import plusImg from '../../assets/img/plus-sign.png';

const BaseAccordion = ({item}) => {
    // console.log(item)
    return (
        <Accordion>
            {item.map((a, idx) => 
                <Accordion.Item eventKey={idx.toString()} key={idx}>
                    <Accordion.Header className="bck">
                    <div className="faq-question">
                        <div className="faq-plus-wrap">
                            <img src={plusImg} className="plusImg" />
                            <img src={minusImg} className="minusImg" />
                        </div>
                        <div className="faq-q-text">{a.title}</div>
                    </div>
                    </Accordion.Header>
                    <Accordion.Body>
                    <p className="faq-answer-text">
                    {a.desc}
                    </p>
                    {a.customDesc}
                    </Accordion.Body>
                </Accordion.Item>
            )}
        </Accordion>
    );
}

export default BaseAccordion;