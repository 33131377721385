import React from "react";
import GroupImg from '../../assets/img/images/Group.png';
import Group1Img from '../../assets/img/images/Group-1.png';
import Group2Img from '../../assets/img/images/Group-2.png';
import Group3Img from '../../assets/img/images/Group-3.png';
import Group4Img from '../../assets/img/images/Group-4.png';
import { customLanguage } from "../../shared/languages";

const IndexPart1 = props => {
  return (
    <div className="section-3 wf-section" style={{marginTop: '30px'}}>
      <div className="container w-container">
        <div className="columns w-row">
          <div className="column w-col w-col-6">
            <div className="div-block-24" />
          </div>
          <div className="column-2 w-col w-col-6">
            <ul role="list" className="w-list-unstyled">
              <li className="list-feature">
                <div>
                  <img
                    src={GroupImg}
                    loading="lazy"
                    height={70}
                    width={70}
                    alt
                    className="image icons"
                  />
                </div>
                <div className="div-feature">
                  <h5 className="heading-3">{customLanguage(localStorage.getItem('language')).home.indexPart1.feature1}</h5>
                  <div className="text-block">
                  {customLanguage(localStorage.getItem('language')).home.indexPart1.sub1}                  </div>
                </div>
              </li>
              <li className="list-feature">
                <div>
                  <img
                    src={Group1Img}
                    loading="lazy"
                    width={70}
                    height={70}
                    alt
                    className="image icons"
                  />
                </div>
                <div className="div-feature">
                  <h5 className="heading-3">{customLanguage(localStorage.getItem('language')).home.indexPart1.feature2}</h5>
                  <div className="text-block">
                  {customLanguage(localStorage.getItem('language')).home.indexPart1.sub2}
                  </div>
                </div>
              </li>
              <li className="list-feature">
                <div>
                  <img
                    src={Group2Img}
                    loading="lazy"
                    width={70}
                    height={70}
                    alt
                    className="image icons"
                  />
                </div>
                <div className="div-feature">
                  <h5 className="heading-3">{customLanguage(localStorage.getItem('language')).home.indexPart1.feature3}</h5>
                  <div className="text-block">
                  {customLanguage(localStorage.getItem('language')).home.indexPart1.sub3}
                  </div>
                </div>
              </li>
              <li className="list-feature">
                <div>
                  <img
                    src={Group4Img}
                    loading="lazy"
                    width={70}
                    height={70}
                    alt
                    className="image icons"
                  />
                </div>
                <div className="div-feature">
                  <h5 className="heading-3">{customLanguage(localStorage.getItem('language')).home.indexPart1.feature4}</h5>
                  <div className="text-block">
                  {customLanguage(localStorage.getItem('language')).home.indexPart1.sub4}
                  </div>
                </div>
              </li>
              <li className="list-feature">
                <div>
                  <img
                    src={Group3Img}
                    loading="lazy"
                    width={70}
                    height={70}
                    alt
                    className="image icons"
                  />
                </div>
                <div className="div-feature">
                  <h5 className="heading-3">{customLanguage(localStorage.getItem('language')).home.indexPart1.feature5}</h5>
                  <div className="text-block">
                  {customLanguage(localStorage.getItem('language')).home.indexPart1.sub5}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container w-container" />
    </div>
  );
}

export default IndexPart1;
